<template>
    <div>
        <div class="filter-popup-box dis-fl jus-con-cen container-xxl">
            <div class="filter-popup-wrapper" ref="myDiv" v-if="isPcView">
                <div class="category-wrapper dis-fl">
                    <div class="main-category">
                        <h5>Buy & Sell</h5>
                        <router-link to="/ads/Buy & Sell/All" class="a-txt-dec-no">See All in Buy &
                            Sell</router-link><br><br>
                        <button class="pad-mar-no dis-fl jus-con-sp-bt al-it-cen" v-for="(category, index) in categories"
                            :key="index" @click="CategorySelect(index)">
                            <p class="pad-mar-no">{{ category.name }}</p>
                            <img :class="category.hasSub ? 'icn-fo-arr' : 'icn-fo-arr-hide'"
                                src="../../assets/icons/forward-black.png" alt="">
                        </button><br>
                    </div>
                    <div class="sub-category">
                        <button @click="subCategorySelect(index)" class="a-txt-dec-no" v-for="(item, index) in displaySub"
                            :key="index">{{
                                item.name
                            }}</button>
                        <div class="sub-cat-bg-img-box dis-fl">
                            <!-- <img class="img-fit" src="../../assets/test/house.png" alt=""> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="filter-popup-mob">
            <transition name="main-cat-trans">
                <div v-if="mainCatIsVisible" class="main-category-mob" v-click-outside="onClickOutside">
                    <div class="dis-fl jus-con-sp-bt al-it-cen">
                        <h5 class="pad-mar-no">Buy & Sell</h5>
                        <button class="btn-close" @click="mainClose()"></button>
                    </div>
                    <router-link to="/ads/Buy & Sell/All" class="a-txt-dec-no">See All in Buy &
                            Sell</router-link><br>
                    <button class="btn-mob-cat pad-mar-no dis-fl jus-con-sp-bt al-it-cen"
                        v-for="(category, index) in categories" :key="index" @click="CategorySelect(index)">
                        <p class="pad-mar-no">{{ category.name }}</p>
                        <img :class="category.hasSub ? 'icn-fo-arr' : 'icn-fo-arr-hide'"
                            src="../../assets/icons/forward-black.png" alt="">
                    </button>
                </div>
            </transition>
            <transition name="sub-cat-trans">
                <div v-if="subCatIsVisible" class="sub-category-mob">
                    <div class="top dis-fl jus-con-sp-bt al-it-cen">
                        <button class="btn-back pad-mar-no dis-fl al-it-cen" @click="subClose()">
                            <img src="../../assets/icons/backward_theme.png" alt="">
                            <p class="pad-mar-no">Back to Buy & Sell</p>
                        </button>
                        <button class="btn-close" @click="mainClose()"></button>
                    </div>
                    <div class="bot dis-fl">
                        <p class="selected-cat"> {{ selectedCat }} </p>
                        <!-- <a href="" class="a-txt-dec-no">See All in Audio</a> -->
                        <button @click="subCategorySelect(index)" class="dis-fl jus-con-sp-bt" v-for="(item, index) in displaySub" :key="index">
                            <p class="pad-mar-no">{{ item.name }}</p>
                        </button>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import vClickOutside from 'click-outside-vue3'

export default {

    data() {
        return {
            mainCatIsVisible: true,
            subCatIsVisible: false,
            selectedCat: '',
            displaySub: [],
            categories: [
                { name: 'Arts & Collectibles', hasSub: false, link: '/Buy & Sell/Arts & Collectibles' },
                { name: 'Audio', hasSub: true, id: 'audio' },
                { name: 'Baby Items', hasSub: true, id: 'babyItems' },
                { name: 'Books', hasSub: true, id: 'books' },
                { name: 'Business & Industrial', hasSub: true, id: 'BusinessIndustrial' },
                { name: 'Cameras & Camcorders', hasSub: false, link: '/Buy & Sell/Cameras & Camcorders' },
                { name: 'CDs, DVDs & Blue-ray', hasSub: false, link: '/Buy & Sell/CDs, DVDs & Blue-ray' },
                { name: 'Clothing', hasSub: true, id: 'clothing' },
                { name: 'Computers', hasSub: true, id: 'computers' },
                { name: 'Computer Accessories', hasSub: true, id: 'computerAccessories' },
                { name: 'Electronics', hasSub: true, id: 'electronics' },
                { name: 'Free Stuff', hasSub: false, link: '/Buy & Sell/Free Stuff' },
                { name: 'Furniture', hasSub: true, id: 'furniture' },
                { name: 'Garage Sales', hasSub: false, link: '/Buy & Sell/Garage Sales' },
                { name: 'Health & Special Needs', hasSub: false, link: '/Buy & Sell/Health & Special Needs' },
                { name: 'Hobbies & Crafts', hasSub: false, link: '/Buy & Sell/Hobbies & Crafts' },
                { name: 'Home Appliences', hasSub: true, id: 'homeAppliances' },
                { name: 'Home - Indoor', hasSub: true, id: 'homeIndoor' },
                { name: 'Home -Outdoor & Garden', hasSub: true, id: 'homeOutdoorGarden' },
                { name: 'Home Renovation Materials', hasSub: true, id: 'homeRenovationMaterials' },
                { name: 'Jewellery & Watches', hasSub: false, link: '/Buy & Sell/Jewellery & Watches' },
                { name: 'Musical Instruments', hasSub: true, id: 'musicalInstruments' },
                { name: 'Phones', hasSub: true, id: 'phones' },
                { name: 'Sporting Goods & Exercise', hasSub: true, id: 'sportingGoodsExercise' },
                { name: 'Tickets', hasSub: false, link: '/Buy & Sell/Tickets' },
                { name: 'Tools', hasSub: true, id: 'tools' },
                { name: 'Toys & Games', hasSub: false },
                { name: 'TVs & Video', hasSub: true, id: 'tvVideo' },
                { name: 'Video Games & Console', hasSub: true, id: 'videoGamesConsole' },
                { name: 'Other', hasSub: false, link: '/Buy & Sell/Other' },
            ],

            audio: [
                { name: 'See All in Audio', link: '/Buy & Sell/Audio : All' },
                { name: 'iPods & MP3s', link: '/Buy & Sell/Audio : iPods & MP3s' },
                { name: 'iPod & MP3 Accessories', link: '/Buy & Sell/Audio : iPods & MP3 Accessories' },
                { name: 'Headphones', link: '/Buy & Sell/Audio : Headphones' },
                { name: 'Speakers', link: '/Buy & Sell/Audio : Speakers' },
                { name: 'Stereo Systems & Home Theaters', link: '/Buy & Sell/Audio : Stereo Systems & Home Theaters' },
                { name: 'Other', link: '/Buy & Sell/Audio : Other' },
            ],

            babyItems: [
                { name: 'See All in Baby Items', link: '/Buy & Sell/Baby Items : All' },
                { name: 'Bathing & Changing', link: '/Buy & Sell/Baby Items : Bathing & Changing' },
                { name: 'Clothing - Preemie', link: '/Buy & Sell/Baby Items : Clothing - Preemie' },
                { name: 'Clothing - 0-3 Months', link: '/Buy & Sell/Baby Items : Clothing - 0-3 Months' },
                { name: 'Clothing - 3-6 Months', link: '/Buy & Sell/Baby Items : Clothing - 3-6 Months' },
                { name: 'Clothing - 6-9 Months', link: '/Buy & Sell/Baby Items : Clothing - 6-9 Months' },
                { name: 'Clothing - 9-12 Months', link: '/Buy & Sell/Baby Items : Clothing - 9-12 Months' },
                { name: 'Clothing - 12-18 Months', link: '/Buy & Sell/Baby Items : Clothing - 12-18 Months' },
                { name: 'Clothing - 18-24 Months', link: '/Buy & Sell/Baby Items : Clothing - 18-24 Months' },
                { name: 'Clothing - 2T', link: '/Buy & Sell/Baby Items : Clothing - 2T' },
                { name: 'Clothing - 3T', link: '/Buy & Sell/Baby Items : Clothing - 3T' },
                { name: 'Clothing - 4T', link: '/Buy & Sell/Baby Items : Clothing - 4T' },
                { name: 'Clothing - 5T', link: '/Buy & Sell/Baby Items : Clothing - 5T' },
                { name: 'Feeding & High Chairs', link: '/Buy & Sell/Baby Items : Feeding & High Chairs' },
                { name: 'Gates, Monitors & Safety', link: '/Buy & Sell/Baby Items : Gates, Monitors & Safety' },
                { name: 'Playpens, Swings Saucers', link: '/Buy & Sell/Baby Items : Playpens, Swings Saucers' },
                { name: 'Strollers, Careeiers & Car Seats', link: '/Buy & Sell/Baby Items : Strollers, Careeiers & Car Seats' },
                { name: 'Toys', link: '/Buy & Sell/Baby Items : Toys' },
                { name: 'Multi Items', link: '/Buy & Sell/Baby Items : Multi Items' },
                { name: 'Other', link: '/Buy & Sell/Baby Items : Other' },
            ],

            books: [
                { name: 'See All in Books', link: '/Buy & Sell/Books : All' },
                { name: 'Textbooks', link: '/Buy & Sell/Books : Textbooks' },
                { name: 'Magazines', link: '/Buy & Sell/Books : Magazines' },
                { name: 'Children & Young Adult', link: '/Buy & Sell/Books : Children & Young Adult' },
                { name: 'Comic & Graphic Novels', link: '/Buy & Sell/Books : Comic & Graphic Novels' },
                { name: 'Fiction', link: '/Buy & Sell/Books : Fiction' },
                { name: 'Non-Fiction', link: '/Buy & Sell/Books : Non-Fiction' },
                { name: 'Other', link: '/Buy & Sell/Books : Other' },
            ],

            BusinessIndustrial: [
                { name: 'See All in Business & Industrial', link: '/Buy & Sell/Business & Industrial : All' },
                { name: 'Industrial Kitchen Supply', link: '/Buy & Sell/Business & Industrial : Industrial Kitchen Supply' },
                { name: 'Industrial Shelving & Racking', link: '/Buy & Sell/Business & Industrial : Industrial Shelving & Racking' },
                { name: 'Storage Containers', link: '/Buy & Sell/Business & Industrial : Storage Containers' },
                { name: 'Other Business Industrial', link: '/Buy & Sell/Business & Industrial : Other Business Industrial' },
            ],

            clothing: [
                { name: 'See All in Clothing', link: '/Buy & Sell/Clothing : All' },
                { name: 'Costumes', link: '/Buy & Sell/Clothing : Costumes' },
                { name: 'Kids & Youth', link: '/Buy & Sell/Clothing : Kids & Youth' },
                { name: 'Mens\'s', link: '/Buy & Sell/Clothing : Mens\'s' },
                { name: 'Men\'s Shoes', link: '/Buy & Sell/Clothing : Men\'s Shoes' },
                { name: 'Multi-item', link: '/Buy & Sell/Clothing : Multi-item' },
                { name: 'Wedding', link: '/Buy & Sell/Clothing : Wedding' },
                { name: 'Women\'s - Bags & Wallets', link: '/Buy & Sell/Clothing : Women\'s - Bags & Wallets' },
                { name: 'Women\'s - Bottoms', link: '/Buy & Sell/Clothing : Women\'s - Bottoms' },
                { name: 'Women\'s - Dresses & Skirts', link: '/Buy & Sell/Clothing : Women\'s - Dresses & Skirts' },
                { name: 'Women\'s - Maternity', link: '/Buy & Sell/Clothing : Women\'s - Maternity' },
                { name: 'Women\'s - Shoes', link: '/Buy & Sell/Clothing : Women\'s - Shoes' },
                { name: 'Women\'s - Tops & Outerwear', link: '/Buy & Sell/Clothing : Women\'s - Tops & Outerwear' },
                { name: 'Women\'s - Other', link: '/Buy & Sell/Clothing : Women\'s - Other' },
                { name: 'Other', link: '/Buy & Sell/Clothing : Other' },
            ],

            computers: [
                { name: 'See All in Computers', link: '/Buy & Sell/Computers : All' },
                { name: 'Desktop Computers', link: '/Buy & Sell/Computers : Desktop Computers' },
                { name: 'iPads & Tablets', link: '/Buy & Sell/Computers : iPads & Tablets' },
                { name: 'Laptops', link: '/Buy & Sell/Computers : Laptops' },
                { name: 'Servers', link: '/Buy & Sell/Computers : Servers' },
                { name: 'Other', link: '/Buy & Sell/Computers : Other' },
            ],

            computerAccessories: [
                { name: 'See All in Computer Accessories', link: '/Buy & Sell/Computer Accessories : All' },
                { name: 'Cables & Connectors', link: '/Buy & Sell/Computer Accessories : Cables & Connectors' },
                { name: 'Flash Memory & USB Sticks', link: '/Buy & Sell/Computer Accessories : Flash Memory & USB Sticks' },
                { name: 'iPad & Tablet Accessories', link: '/Buy & Sell/Computer Accessories : iPad & Tablet Accessories' },
                { name: 'Laptop Accessories', link: '/Buy & Sell/Computer Accessories : Laptop Accessories' },
                { name: 'Mouse, Keyboards & Webcams', link: '/Buy & Sell/Computer Accessories : Mouse, Keyboards & Webcams' },
                { name: 'Monitors', link: '/Buy & Sell/Computer Accessories : Monitors' },
                { name: 'Networking', link: '/Buy & Sell/Computer Accessories : Networking' },
                { name: 'Printers, Scanners & Fax', link: '/Buy & Sell/Computer Accessories : Printers, Scanners & Fax' },
                { name: 'Services (Training & Rapire)', link: '/Buy & Sell/Computer Accessories : Services (Training & Rapire)' },
                { name: 'Software', link: '/Buy & Sell/Computer Accessories : Software' },
                { name: 'Speakers, Headsets & Mics', link: '/Buy & Sell/Computer Accessories : Speakers, Headsets & Mics' },
                { name: 'System Components', link: '/Buy & Sell/Computer Accessories : System Components' },
                { name: 'Other', link: '/Buy & Sell/Computer Accessories : Other' },
            ],

            electronics: [
                { name: 'See All in Electronics', link: '/Buy & Sell/Electronics : All' },
                { name: 'General Electronics', link: '/Buy & Sell/Electronics : General Electronics' },
                { name: 'Security Systems', link: '/Buy & Sell/Electronics : Security Systems' },
                { name: 'Other', link: '/Buy & Sell/Electronics : Other' },
            ],

            furniture: [
                { name: 'See All in Furniture', link: '/Buy & Sell/Furniture : All' },
                { name: 'Beds & Mattresses', link: '/Buy & Sell/Furniture : Beds & Mattresses' },
                { name: 'Bookcases & Shelving Units', link: '/Buy & Sell/Furniture : Bookcases & Shelving Units' },
                { name: 'Chairs & Recliners', link: '/Buy & Sell/Furniture : Chairs & Recliners' },
                { name: 'Coffee Tables', link: '/Buy & Sell/Furniture : Coffee Tables' },
                { name: 'Couches & Futons', link: '/Buy & Sell/Furniture : Couches & Futons' },
                { name: 'Desks', link: '/Buy & Sell/Furniture : Desks' },
                { name: 'Dining Tables & Sets', link: '/Buy & Sell/Furniture : Dining Tables & Sets' },
                { name: 'Dressers & Wardrobes', link: '/Buy & Sell/Furniture : Dressers & Wardrobes' },
                { name: 'Hutches & Display Cabinets', link: '/Buy & Sell/Furniture : Hutches & Display Cabinets' },
                { name: 'Other Tables', link: '/Buy & Sell/Furniture : Other Tables' },
                { name: 'TV Tables & Entertainment Units', link: '/Buy & Sell/Furniture : TV Tables & Entertainment Units' },
                { name: 'Multi-item', link: '/Buy & Sell/Furniture : Multi-item' },
                { name: 'Other', link: '/Buy & Sell/Furniture : Other' },
            ],

            homeAppliances: [
                { name: 'See All in Home Appliances', link: '/Buy & Sell/Home Appliance : All' },
                { name: 'Coffee Makers', link: '/Buy & Sell/Home Appliance : Coffee Makers' },
                { name: 'Dishwashers', link: '/Buy & Sell/Home Appliance : Dishwashers' },
                { name: 'Freezers', link: '/Buy & Sell/Home Appliance : Freezers' },
                { name: 'Heaters, Humidifiers & Dehumidifiers', link: '/Buy & Sell/Home Appliance : Heaters, Humidifiers & Dehumidifiers' },
                { name: 'Irons & Garment Steamers', link: '/Buy & Sell/Home Appliance : Irons & Garment Steamers' },
                { name: 'Microwaves & Cookers', link: '/Buy & Sell/Home Appliance : Microwaves & Cookers' },
                { name: 'Processors, Blenders & Juicers', link: '/Buy & Sell/Home Appliance : Processors, Blenders & Juicers' },
                { name: 'Refrigerators', link: '/Buy & Sell/Home Appliance : Refrigerators' },
                { name: 'Stoves, Ovens & Ranges', link: '/Buy & Sell/Home Appliance : Stoves, Ovens & Ranges' },
                { name: 'Toasters & Toaster Ovens', link: '/Buy & Sell/Home Appliance : Toasters & Toaster Ovens' },
                { name: 'Vacuums', link: '/Buy & Sell/Home Appliance : Vacuums' },
                { name: 'Washers & Dryers', link: '/Buy & Sell/Home Appliance : Washers & Dryers' },
                { name: 'Other', link: '/Buy & Sell/Home Appliance : Other' },
            ],

            homeIndoor: [
                { name: 'See All in Home - Indoor', link: '/Buy & Sell/Home - Indoor : All' },
                { name: 'Bathwares', link: '/Buy & Sell/Home - Indoor : Bathwares' },
                { name: 'Bedding', link: '/Buy & Sell/Home - Indoor : Bedding' },
                { name: 'Fireplace & Firewood', link: '/Buy & Sell/Home - Indoor : Fireplace & Firewood' },
                { name: 'Holiday, Event & Seasonal', link: '/Buy & Sell/Home - Indoor : Holiday, Event & Seasonal' },
                { name: 'Home Décor & Accents', link: '/Buy & Sell/Home - Indoor : Home Décor & Accents' },
                { name: 'Indoor Lighting & Fans', link: '/Buy & Sell/Home - Indoor : Indoor Lighting & Fans' },
                { name: 'Kitchen & Dining Wares', link: '/Buy & Sell/Home - Indoor : Kitchen & Dining Wares' },
                { name: 'Rugs, Carpets & Runners', link: '/Buy & Sell/Home - Indoor : Rugs, Carpets & Runners' },
                { name: 'Storage & Organization', link: '/Buy & Sell/Home - Indoor : Storage & Organization' },
                { name: 'Window Treatments', link: '/Buy & Sell/Home - Indoor : Window Treatments' },
                { name: 'Other', link: '/Buy & Sell/Home - Indoor : Other' },
            ],

            homeOutdoorGarden: [
                { name: 'See All in Home - Outdoor & Garden', link: '/Buy & Sell/Home - Outdoor & Garden : All' },
                { name: 'BBQs & Outdoor Cooking', link: '/Buy & Sell/Home - Outdoor & Garden : BBQs & Outdoor Cooking' },
                { name: 'Decks & Fences', link: '/Buy & Sell/Home - Outdoor & Garden : Decks & Fences' },
                { name: 'Garage Doors & Openers', link: '/Buy & Sell/Home - Outdoor & Garden : Garage Doors & Openers' },
                { name: 'Hot Tubs & Pools', link: '/Buy & Sell/Home - Outdoor & Garden : Hot Tubs & Pools' },
                { name: 'Lawnmowers & Leaf Blowers', link: '/Buy & Sell/Home - Outdoor & Garden : Lawnmowers & Leaf Blowers' },
                { name: 'Outdoor Décor', link: '/Buy & Sell/Home - Outdoor & Garden : Outdoor Décor' },
                { name: 'Outdoor Lighting', link: '/Buy & Sell/Home - Outdoor & Garden : Outdoor Lighting' },
                { name: 'Outdoor Tools & Storage', link: '/Buy & Sell/Home - Outdoor & Garden : Outdoor Tools & Storage' },
                { name: 'Patio & Garden Furniture', link: '/Buy & Sell/Home - Outdoor & Garden : Patio & Garden Furniture' },
                { name: 'Plants, Fertilizer & Soil', link: '/Buy & Sell/Home - Outdoor & Garden : Plants, Fertilizer & Soil' },
                { name: 'Snowblowers', link: '/Buy & Sell/Home - Outdoor & Garden : Snowblowers' },
                { name: 'Other', link: '/Buy & Sell/Home - Outdoor & Garden : Other' },
            ],

            homeRenovationMaterials: [
                { name: 'See All in Home Renovation Materials', link: '/Buy & Sell/Home Renovation Materials : All' },
                { name: 'Cabinets & Countertops', link: '/Buy & Sell/Home Renovation Materials : Cabinets & Countertops' },
                { name: 'Electrical', link: '/Buy & Sell/Home Renovation Materials : Electrical' },
                { name: 'Floors & Walls', link: '/Buy & Sell/Home Renovation Materials : Floors & Walls' },
                { name: 'Hardware, Nails & Screws', link: '/Buy & Sell/Home Renovation Materials : Hardware, Nails & Screws' },
                { name: 'Heating, Cooling & Air', link: '/Buy & Sell/Home Renovation Materials : Heating, Cooling & Air' },
                { name: 'Painting & Paint Supplies', link: '/Buy & Sell/Home Renovation Materials : Painting & Paint Supplies' },
                { name: 'Plumbing, Sinks, Toilets & Showers', link: '/Buy & Sell/Home Renovation Materials : Plumbing, Sinks, Toilets & Showers' },
                { name: 'Roofing', link: '/Buy & Sell/Home Renovation Materials : Roofing' },
                { name: 'Windows, Doors & Trim', link: '/Buy & Sell/Home Renovation Materials : Windows, Doors & Trim' },
                { name: 'Other', link: '/Buy & Sell/Home Renovation Materials : Other' },
            ],

            musicalInstruments: [
                { name: 'See All in Musical Instruments', link: '/Buy & Sell/Musical Instruments : All' },
                { name: 'Amps & Pedals', link: '/Buy & Sell/Musical Instruments : Amps & Pedals' },
                { name: 'Brass', link: '/Buy & Sell/Musical Instruments : Brass' },
                { name: 'Drums & Percussion', link: '/Buy & Sell/Musical Instruments : Drums & Percussion' },
                { name: 'Guitars', link: '/Buy & Sell/Musical Instruments : Guitars' },
                { name: 'Performance & DJ Equipment', link: '/Buy & Sell/Musical Instruments : Performance & DJ Equipment' },
                { name: 'Pianos & Keyboards', link: '/Buy & Sell/Musical Instruments : Pianos & Keyboards' },
                { name: 'Pro Audio & Recording Equipment', link: '/Buy & Sell/Musical Instruments : Pro Audio & Recording Equipment' },
                { name: 'String', link: '/Buy & Sell/Musical Instruments : String' },
                { name: 'Woodwind', link: '/Buy & Sell/Musical Instruments : Woodwind' },
                { name: 'Other', link: '/Buy & Sell/Musical Instruments : Other' },
            ],

            phones: [
                { name: 'See All in Phones', link: '/Buy & Sell/Phones : All' },
                { name: 'Cell Phones', link: '/Buy & Sell/Phones : Cell Phones' },
                { name: 'Cell Phone Accessories', link: '/Buy & Sell/Phones : Cell Phone Accessories' },
                { name: 'Cell Phone Services', link: '/Buy & Sell/Phones : All' },
                { name: 'Home Phones & Answering Machines', link: '/Buy & Sell/Phones : Home Phones & Answering Machines' },
                { name: 'Other', link: '/Buy & Sell/Phones : Other' },
            ],

            sportingGoodsExercise: [
                { name: 'See All in Sporting Goods & Exercise', link: '/Buy & Sell/Sporting Goods & Exercise : All' },
                { name: 'Basketball', link: '/Buy & Sell/Sporting Goods & Exercise : Basketball' },
                { name: 'Cricket', link: '/Buy & Sell/Sporting Goods & Exercise : Cricket' },
                { name: 'Exercise Equipment', link: '/Buy & Sell/Sporting Goods & Exercise : Exercise Equipment' },
                { name: 'Fishing, Camping & Outdoors', link: '/Buy & Sell/Sporting Goods & Exercise : Fishing, Camping & Outdoors' },
                { name: 'Football', link: '/Buy & Sell/Sporting Goods & Exercise : Football' },
                { name: 'Golf', link: '/Buy & Sell/Sporting Goods & Exercise : Golf' },
                { name: 'Hockey', link: '/Buy & Sell/Sporting Goods & Exercise : Hockey' },
                { name: 'Paintball', link: '/Buy & Sell/Sporting Goods & Exercise : Paintball' },
                { name: 'Rugby', link: '/Buy & Sell/Sporting Goods & Exercise : Rugby' },
                { name: 'Tennis & Racquet', link: '/Buy & Sell/Sporting Goods & Exercise : Tennis & Racquet' },
                { name: 'Vollyball', link: '/Buy & Sell/Sporting Goods & Exercise : Vollyball' },
                { name: 'Water Sports', link: '/Buy & Sell/Sporting Goods & Exercise : Water Sports' },
                { name: 'Other', link: '/Buy & Sell/Sporting Goods & Exercise : Other' },
            ],

            tools: [
                { name: 'See All in Tools', link: '/Buy & Sell/Tools : All' },
                { name: 'Hand Tools', link: '/Buy & Sell/Tools : Hand Tools' },
                { name: 'Power Tools', link: '/Buy & Sell/Tools : Power Tools' },
                { name: 'Tool Storage & Benches', link: '/Buy & Sell/Tools : Tool Storage & Benches' },
                { name: 'Ladders & Scaffolding', link: '/Buy & Sell/Tools : Ladders & Scaffolding' },
                { name: 'Other', link: '/Buy & Sell/Tools : Other' },
            ],

            tvVideo: [
                { name: 'See All in TVs & Video', link: '/Buy & Sell/TVs & Video : All' },
                { name: 'TVs', link: '/Buy & Sell/TVs & Video : TVs' },
                { name: 'Video & TV Accessories', link: '/Buy & Sell/TVs & Video : Video & TV Accessories' },
            ],

            videoGamesConsole: [
                { name: 'See All in Video Games & Consoles', link: '/Buy & Sell/Video Games & Console : All' },
                { name: 'Nintendo DS', link: '/Buy & Sell/Video Games & Console : Nintendo DS' },
                { name: 'Nintendo Switch', link: '/Buy & Sell/Video Games & Console : Nintendo Switch' },
                { name: 'Nintendo Wii', link: '/Buy & Sell/Video Games & Console : Nintendo Wii' },
                { name: 'Nintendo Wii U', link: '/Buy & Sell/Video Games & Console : Nintendo Wii U' },
                { name: 'Sony Playstation 5', link: '/Buy & Sell/Video Games & Console : Sony Playstation 5' },
                { name: 'Sony Playstation 4', link: '/Buy & Sell/Video Games & Console : Sony Playstation 4' },
                { name: 'Sony Playstation 3', link: '/Buy & Sell/Video Games & Console : Sony Playstation 3' },
                { name: 'Sony PSP & Vita', link: '/Buy & Sell/Video Games & Console : Sony PSP & Vita' },
                { name: 'Xbox Series X & S', link: '/Buy & Sell/Video Games & Console : Xbox Series X & S' },
                { name: 'XBOX 360', link: '/Buy & Sell/Video Games & Console : XBOX 360' },
                { name: 'XBOX One', link: '/Buy & Sell/Video Games & Console : XBOX One' },
                { name: 'Older Generation', link: '/Buy & Sell/Video Games & Console : Older Generation' },
                { name: 'PC Games', link: '/Buy & Sell/Video Games & Console : PC Games' },
                { name: 'Other', link: '/Buy & Sell/Video Games & Console : Other' },
            ],
            deviceWidth: null,
            isPcView: true,
        }
    },


    directives: {
        clickOutside: vClickOutside.directive
    },

    mounted() {
        this.getDeviceWidth();
    },

    methods: {
        getDeviceWidth() {
            this.deviceWidth = screen.width;
            if(this.deviceWidth > 991){
                this.isPcView = true;
            } else {
                this.isPcView = false;
            }
        },

        mainClose() {
            this.$emit('isVisible', false);
        },

        subClose() {
            this.subCatIsVisible = false;
            this.mainCatIsVisible = true;
        },

        CategorySelect(index) {
            if (this.categories[index].hasSub === true) {
                switch (this.categories[index].id) {
                    case 'audio':
                        this.displaySub = this.audio;
                        break;
                    case 'babyItems':
                        this.displaySub = this.babyItems;
                        break;
                    case 'books':
                        this.displaySub = this.books;
                        break;
                    case 'BusinessIndustrial':
                        this.displaySub = this.BusinessIndustrial;
                        break;
                    case 'clothing':
                        this.displaySub = this.clothing;
                        break;
                    case 'computers':
                        this.displaySub = this.computers;
                        break;
                    case 'computerAccessories':
                        this.displaySub = this.computerAccessories;
                        break;
                    case 'electronics':
                        this.displaySub = this.electronics;
                        break;
                    case 'furniture':
                        this.displaySub = this.furniture;
                        break;
                    case 'homeAppliances':
                        this.displaySub = this.homeAppliances;
                        break;
                    case 'homeIndoor':
                        this.displaySub = this.homeIndoor;
                        break;
                    case 'homeOutdoorGarden':
                        this.displaySub = this.homeOutdoorGarden;
                        break;
                    case 'homeRenovationMaterials':
                        this.displaySub = this.homeRenovationMaterials;
                        break;
                    case 'musicalInstruments':
                        this.displaySub = this.musicalInstruments;
                        break;
                    case 'phones':
                        this.displaySub = this.phones;
                        break;
                    case 'sportingGoodsExercise':
                        this.displaySub = this.sportingGoodsExercise;
                        break;
                    case 'tools':
                        this.displaySub = this.tools;
                        break;
                    case 'tvVideo':
                        this.displaySub = this.tvVideo;
                        break;
                    case 'videoGamesConsole':
                        this.displaySub = this.videoGamesConsole;
                        break;
                    case 'others':
                        this.displaySub = this.others;
                        break;
                    default:
                        return;
                }
                this.mainCatIsVisible = false;
                this.subCatIsVisible = true;
                this.selectedCat = this.categories[index].name;
            } else {
                this.$router.push('/ads' + this.categories[index].link);
            }
        },

        subCategorySelect(index) {
            this.$router.push('/ads' + this.displaySub[index].link);
        },

        onClickOutside() {
            this.$emit('isVisible', false);
        }
    },

}

</script>

<style scoped>

h5{
    margin-bottom: 15px;
}

.selected-cat {
    font-weight: bold;
    font-size: 20px;
}

.icn-fo-arr {
    width: 10px;
    height: 15px;
}

.icn-fo-arr-hide {
    display: none;
}

.filter-popup-box {
    width: 100%;
    height: 50vh;
    position: absolute;
    top: 100px;
    z-index: 2;
}

.category-wrapper {
    height: 100%;
    padding: 20px;
    padding-right: 0;
}

.filter-popup-wrapper {
    width: 100%;
    height: 100%;
    margin: 0 50px;
    background-color: white;
}

.filter-popup-mob {
    display: none;
}

.main-category {
    width: 300px;
    height: 100%;
    overflow: auto;
}

.main-category::-webkit-scrollbar {
    width: 6px;
}

.main-category::-webkit-scrollbar-track {
    border-radius: 10px;
}

.main-category::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.315);
    border-radius: 10px;
}

.main-category::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 128, 128, 0.493);
}

.main-category button,
.btn-mob-cat,
.sub-category-mob .bot button {
    width: 100%;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.050);
    background-color: transparent;
    position: relative;
}

.sub-category {
    width: 100%;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
}

.sub-category button {
    margin: 8px 0;
    color: black;
    border: none;
    background-color: transparent;
    z-index: 1;
}

.sub-cat-bg-img-box {
    height: 100%;
    opacity: .5;
    align-items: flex-end;
    position: absolute;
    right: 0;
}

@media only screen and (max-width: 1200px) {
    .filter-popup-box {
        top: 130px;
    }

    .sub-cat-bg-img-box {
        display: none;
    }
}

@media only screen and (max-width: 991px) {

    .filter-popup-box {
        display: none;
    }

    .filter-popup-mob {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 2;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.281);
        backdrop-filter: blur(2px);
    }

    .main-category-mob {
        width: 700px;
        margin: auto;
        padding: 20px 10px 50px 10px;
        display: flex;
        position: relative;
        flex-direction: column;
        background-color: white;
    }

    .sub-category-mob {
        width: 700px;
        margin: auto;
        display: flex;
        position: relative;
        flex-direction: column;
        background-color: white;
    }

    .sub-category-mob .top {
        padding: 15px 10px;
        background-color: #8EC64111;
    }

    .btn-back {
        border: none;
        background-color: transparent;
    }

    .btn-back img {
        width: 6px;
        height: 11px;
        margin-right: 5px;
    }

    .btn-back p {
        color: #8EC641;
    }

    .sub-category-mob .bot {
        padding: 20px 10px 50px 10px;
        flex-direction: column;
    }
}

@media only screen and (max-width: 767px) {

    .main-category-mob,
    .sub-category-mob {
        width: 100%;
    }
}

</style>