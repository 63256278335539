<template>
    <div>
        <transition name="popup-trans">
            <div v-if="popupIsVisible" @click="close()" class="main">
                <div @click.stop class="fol-box-main">
                    <div class="close-wrp col-theme">
                        <div></div>
                        <p class="pad-mar-no">Followers ({{ followersIds.length }})</p>
                        <button @click="close()" class="btn-close btn-close-white"></button>
                    </div>
                    <div class="content-box">
                        <div v-if="followersIds.length === 0" class="no-follow-box">
                            <p>Empty Followers</p>
                        </div>
                        <div v-for="(item, index) in followers" :key="index" @click="visitClick(index)" class="item-box">
                            <div class="le">
                                <div class="profile-img-box bor-col-theme">
                                    <img :src="item.profile" alt="">
                                </div>
                                <div>
                                    <p class="pad-mar-no">{{ item.firstName + ' ' + item.lastName }}</p>
                                    <p class="p-username pad-mar-no f-sz-sm">{{ item.city }}</p>
                                </div>
                            </div>
                            <div class="ri">
                                <button @click="visitClick(index)" class="btn-follow col-theme">
                                    Visit
                                </button>
                            </div>
                            <div class="hor-line"></div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { mapState } from 'vuex';

export default {

    props: {
        popupVisible: Boolean,
    },

    data() {
        return {
            popupIsVisible: false,
            userID: null,
            followersIds: [],
            followers: [],
        }
    },

    computed: {
        ...mapState(['api'])
    },

    mounted() {
        this.getUserId();
        this.getFollowers();
    },

    watch: {
        popupVisible(val) {
            this.popupIsVisible = val;
        }
    },

    methods: {

        async getUserId() {
            const token = localStorage.getItem('token');
            try {
                const decoded = jwtDecode(token);
                this.userID = decoded.id;
            } catch (err) {
                console.log(err);
            }
        },

        async getFollowers() {
            await axios.get(`${this.api}/follow-op/get-followers/${this.userID}`).then(async (response) => {
                this.followersIds = response.data;
                this.$emit('followersCount', response.data.length);
                for (let i = 0; i < response.data.length; i++) {
                    await axios.get(`${this.api}/users/fetch-user-info/${response.data[i]}`).then((response) => {
                        response.data.profile = `data:image/jpeg;base64,${response.data.profile}`;
                        this.followers.push(response.data);
                    })
                }
            });
        },

        visitClick(index) {
            this.$router.push(`/shop/${this.followersIds[index]}`);
        },

        close() {
            this.followers = [];
            this.followersIds = [];
            this.getFollowers();
            this.$emit('popupVisibleToParent', false);
        }
    }
}

</script>
<style scoped>
.hor-line {
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.133);
}

.main {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.fol-box-main {
    width: 500px;
    height: 500px;
    border-radius: 20px;
    background-color: white;
    overflow: hidden;
}

.close-wrp {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.close-wrp p {
    color: white;
}

.content-box {
    width: 500px;
    height: 450px;
    padding: 10px;
    padding-bottom: 50px;
    overflow-y: auto;
}

.content-box::-webkit-scrollbar {
    width: .5vw;
}

.content-box::-webkit-scrollbar-thumb {
    background-color: #8EC64127;
    border-radius: 20px;
}

.content-box::-webkit-scrollbar-thumb:hover {
    background-color: #8EC64152;
}

.content-box::-webkit-scrollbar-track {
    background-color: transparent;
}

.no-follow-box{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-box {
    /* margin-bottom: 4px; */
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.le {
    display: flex;
}

.profile-img-box {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border: 2px solid;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.profile-img-box img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
}

.p-username {
    color: grey;
}

.btn-follow {
    width: 100px;
    height: 35px;
    color: white;
    border: none;
    border-radius: 10px;
}

@media only screen and (max-width: 575px) {
    .fol-box-main {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }

    .content-box {
        width: 100%;
        height: 100%;
    }
}

.popup-trans-enter-from,
.popup-trans-leave-to {
    opacity: 0;
}

.popup-trans-enter-active,
.popup-trans-leave-active {
    transition: all .3s ease;
}
</style>