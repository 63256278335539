<template>
    <div>
        <div class="main">
            <div class="lg">
                <div class="sub">
                    <div class="le">
                        <div class="img com"></div>
                        <div class="content">
                            <div class="con-1 com"></div>
                            <div class="con-2 com"></div>
                            <div class="con-3">
                                <div class="con-3-sub-1 com"></div>
                                <div class="con-3-sub-2 com"></div>
                            </div>
                        </div>
                    </div>
                    <div class="ri"></div>
                </div>
            </div>
            <div class="sm">
                <div class="sm-item">
                    <div class="sm-img com"></div>
                    <div class="sm-content com"></div>
                    <div class="sm-content com"></div>
                    <div class="sm-content com"></div>
                    <div class="sm-date-box">
                        <div class="sm-date com"></div>
                    </div>
                </div>
                <div class="sm-item">
                    <div class="sm-img com"></div>
                    <div class="sm-content com"></div>
                    <div class="sm-content com"></div>
                    <div class="sm-content com"></div>
                    <div class="sm-date-box">
                        <div class="sm-date com"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.main {
    width: 100%;
    overflow: hidden;
}

.com {
    background-color: rgba(0, 0, 0, 0.164);
    color: white;
    animation: ani 2s both infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.sub {
    width: 100%;
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.116);
    display: flex;
    justify-content: space-between;
}

.le {
    width: 100%;
    display: flex;
}

.img {
    width: 150px;
    height: 100px;
    margin-right: 10px;
}

.content {
    width: 100%;
}

.con-1 {
    width: 400px;
    height: 30px;
    margin-bottom: 10px;
}

.con-2 {
    width: 200px;
    height: 20px;
    margin-bottom: 10px;
}

.con-3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.con-3-sub-1 {
    width: 150px;
    height: 20px;
}

.con-3-sub-2 {
    width: 150px;
    height: 20px;
}

.sm {
    display: none;
}

.sm-img {
    height: 150px;
    margin-bottom: 20px;
}

.sm-content{
    height: 20px;
    margin-bottom: 5px;
}

.sm-date-box{
    display: flex;
    justify-content: flex-end;
}

.sm-date{
    margin-top: 10px;
    width: 70%;
    height: 10px;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@media only screen and (max-width: 575px) {

    .lg {
        display: none;
    }

    .sm {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .sm-item {
        width: 49%;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid rgba(0, 0, 0, 0.073);
    }

    .img {
        min-width: 100px;
        height: 70px;
    }
}
</style>