import { createApp } from 'vue'
import App from './App.vue'
import Router from './routes.js'
import store from './store/store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import './assets/style/main.css'
import { createHead } from '@vueuse/head'

const head = createHead();

createApp(App)
.use(Router)
.use(store)
.use(head)
.mount('#app')
