<template>
    <div>
        <Navi />
        <Chat :hideChat="chatIsVisible" :adOwnerId="shopId" :mentionedItemImg="null" :mentionedItemName="null"
            @hideChat="hideChat" />
        <div class="main res-py container-xxl">
            <div v-if="shopInfoIsLoading">
                <ShopCovProSkel />
            </div>
            <div v-if="!shopInfoIsLoading" class="cov-pro-img-wrp">
                <div class="cov-img-box">
                    <img :src="userInfo.cover" alt="">
                </div>
                <div class="pro-img-con dis-fl jus-con-cen">
                    <div class="pro-img-box dis-fl jus-con-cen al-it-cen bor-col-theme">
                        <img class="" :src="userInfo.profile" alt="">
                    </div>
                </div>
            </div>
            <div class="item-info-wrp dis-fl">
                <div v-if="shopInfoIsLoading">
                    <ShopInfoSkel />
                </div>
                <div v-if="!shopInfoIsLoading" class="info-box">
                    <div class="info-1">
                        <p class="name pad-mar-no">{{ userInfo.firstName + ' ' + userInfo.lastName }}</p>
                        <button v-if="userID != shopId" @click="followClick()"
                            :class="followBtnContent === 'Follow' ? 'btn-subscribe-follow col-theme' : 'btn-subscribe-unfollow f-col-theme bor-col-theme'">{{
                                followBtnContent }}</button>
                        <!-- <p class="status pad-mar-no">Click to the future</p> -->
                        <!-- <span class="membership pad-mar-no">MEMBER</span> -->
                        <!-- <p class="member-since pad-mar-no f-sz-sm">Member since june 2017</p> -->
                    </div>
                    <div class="info-2 chat-call-box mob dis-fl al-it-cen">
                        <div class="icn">
                            <img class="img-fit" src="../assets/icons/phone-theme.png" alt="">
                        </div>
                        <a href="tel:{{ userInfo.mobile }}">{{ userInfo.mobile }}</a>
                        <!-- <p class="pad-mar-no">{{ userInfo.mobile }}</p> -->
                    </div>
                    <div v-if="userID != shopId" @click="chat()" class="info-2 chat-call-box cht dis-fl al-it-cen">
                        <div class="icn">
                            <img class="img-fit" src="../assets/icons/chat-theme.png" alt="">
                        </div>
                        <p class="pad-mar-no">Chat</p>
                    </div>
                    <div class="info-2 dis-fl">
                        <div class="icn">
                            <img class="img-fit" src="../assets/icons/location-theme.png" alt="">
                        </div>
                        <p class="pad-mar-no">{{ userInfo.address_1 }}, <br> {{ userInfo.address_2 }} <br> {{ userInfo.city
                        }}</p>
                    </div>
                    <div class="info-3">
                        <p class="pad-mar-no">{{ userInfo.about }}</p>
                    </div>
                </div>

                <div class="items-wrp-lg">
                    <!-- <div class="search-box dis-fl al-it-cen">
                        <div class="inp-srch-box">
                            <input type="text" name="" id="">
                        </div>
                        <button class="btn-srch-box pad-mar-no">
                            <img src="../assets/icons/search-white.png" alt="">
                        </button>
                    </div> -->
                    <div v-if="!itemsAreLoading">
                        <div v-for="(item, index) in displayItems" :key="index" href=""
                            class="item-wrp-lg pad-mar-no dis-fl a-txt-dec-no" @click="itemClick(index)">
                            <div class="img-box dis-fl jus-con-cen">
                                <img :src="item.ph_sm" alt="">
                            </div>
                            <div class="content-box">
                                <p class="item-name pad-mar-no">{{ item.title }}</p>
                                <p class="condition-1 pad-mar-no f-sz-sm">{{ item.condition_1 }}</p>
                                <span
                                    :class="(item.isMember === true ? 'membership pad-mar-no f-sz-sm' : 'membership-none')">MEMBER</span>
                                <p class="pad-mar-no">{{ item.district }}</p>
                                <p v-if="!item.is_bid && item.main_cat !== 'Jobs'" class="price pad-mar-no f-col-theme">Rs.
                                    {{ item.price.toLocaleString() }}</p>
                                <div v-if="item.is_bid" class="bid-info-wrp">
                                    <div class="bid-icon-box">
                                        <img src="../assets/icons/BID.png" alt="">
                                    </div>
                                    <p class="price pad-mar-no f-col-theme">Current Bid &nbsp; Rs {{
                                        JSON.parse(item.bids)[JSON.parse(item.bids).length - 1].price.toLocaleString() }}
                                    </p>
                                </div>
                                <div class="dis-fl jus-con-sp-bt al-it-cen f-sz-sm">
                                    <p class="pad-mar-no"></p>
                                    <p class="pad-mar-no">{{ item.saved_at }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="items-loading-skel-lg" v-if="itemsAreLoading">
                        <ItemsSkeleton />
                    </div>
                    <div ref="endOfList"></div>
                    <div v-if="itemLimitLoading">
                        <ItemsSkeletonLimit />
                    </div>
                </div>
                <div class="srch-sm">
                    <!-- <div class="items-loading-skel-sm" v-if="itemsAreLoading">
                        <ItemsSkeleton />
                    </div> -->
                    <!-- <div class="search-box dis-fl al-it-cen">
                        <div class="inp-srch-box">
                            <input type="text" name="" id="">
                        </div>
                        <button class="btn-srch-box pad-mar-no">
                            <img src="../assets/icons/search-white.png" alt="">
                        </button>
                    </div> -->
                </div>
                <div v-if="!itemsAreLoading" class="items-wrp-sm">
                    <div class="items-wrapper-left-wrapper-sm">
                        <div v-for="(item, index) in displayItems" :key="index">
                            <div v-if="index % 2 === 0" class="items-sm dis-fl a-txt-dec-no" @click="itemClick(index)">
                                <img :src="item.ph_sm" alt="">
                                <p class="item-name pad-mar-no f-sz-sm">{{ item.title }}</p>
                                <p class="pad-mar-no f-sz-sm">{{ item.condition_1 }}</p>
                                <p v-if="!item.is_bid && item.main_cat !== 'Jobs'"
                                    class="price pad-mar-no f-sz-sm f-col-theme">Rs. {{ item.price.toLocaleString() }}</p>
                                <div v-if="item.is_bid" class="bid-info-wrp">
                                    <div class="bid-icon-box">
                                        <img src="../assets/icons/BID.png" alt="">
                                    </div>
                                    <p class="price pad-mar-no f-sz-sm f-col-theme">CB &nbsp; Rs. {{
                                        JSON.parse(item.bids)[JSON.parse(item.bids).length - 1].price.toLocaleString() }}
                                    </p>
                                </div>
                                <p class="location pad-mar-no f-sz-sm">{{ item.district }}</p>
                                <div class="dis-fl jus-con-sp-bt">
                                    <p class="pad-mar-no f-sz-sm"></p>
                                    <p class="time-status pad-mar-no">{{ item.saved_at }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="items-wrapper-right-wrapper-sm">
                        <div v-for="(item, index) in displayItems" :key="index">
                            <div v-if="index % 2 === 1" class="items-sm dis-fl a-txt-dec-no" @click="itemClick(index)">
                                <img :src="item.ph_sm" alt="">
                                <p class="item-name pad-mar-no f-sz-sm">{{ item.title }}</p>
                                <p class="pad-mar-no f-sz-sm">{{ item.condition_1 }}</p>
                                <p v-if="!item.is_bid && item.main_cat !== 'Jobs'"
                                    class="price pad-mar-no f-sz-sm f-col-theme">Rs. {{ item.price.toLocaleString() }}</p>
                                <div v-if="item.is_bid" class="bid-info-wrp">
                                    <div class="bid-icon-box">
                                        <img src="../assets/icons/BID.png" alt="">
                                    </div>
                                    <p class="price pad-mar-no f-sz-sm f-col-theme">CB &nbsp; Rs. {{
                                        JSON.parse(item.bids)[JSON.parse(item.bids).length - 1].price.toLocaleString() }}
                                    </p>
                                </div>
                                <p class="location pad-mar-no f-sz-sm">{{ item.district }}</p>
                                <div class="dis-fl jus-con-sp-bt">
                                    <p class="pad-mar-no f-sz-sm"></p>
                                    <p class="time-status pad-mar-no">{{ item.saved_at }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="items-limit-load-sm">
                    <div v-if="itemsAreLoading">
                        <ItemsSkeleton />
                    </div>
                    <div ref="endOfListSm"></div>
                    <div v-if="itemLimitLoading">
                        <ItemsSkeletonLimit />
                    </div>
                </div>
            </div><br>
            <div class="con-det-wrp-mob dis-fl jus-con-sp-bt al-it-cen">
                <button class="dis-fl pad-mar-no col-theme al-it-cen jus-con-cen">
                    <img class="icn-phone" @click="call()" src="../assets/icons/phone-white.png" alt="">
                    <p class="pad-mar-no">Call</p>
                </button>
                <button v-if="userID != shopId" @click="chat()" class="dis-fl pad-mar-no col-theme al-it-cen jus-con-cen">
                    <img class="icn-chat" src="../assets/icons/chat-white.png" alt="">
                    <p class="pad-mar-no">Chat</p>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import Navi from '../components/navigation-com.vue';
import { mapState } from 'vuex';
import jwtDecode from 'jwt-decode';
import Swal from 'sweetalert2';
import Chat from '../components/chat-com.vue';
import { useHead } from '@vueuse/head';
import ShopCovProSkel from '../components/skeleton-loading/shop/shop-cov-pro.vue';
import ShopInfoSkel from '../components/skeleton-loading/shop/shop-info.vue';
import ItemsSkeleton from '../components/skeleton-loading/items-skel-com.vue';
import ItemsSkeletonLimit from '../components/skeleton-loading/items-limit-skel-com.vue';
import CryptoJS from 'crypto-js';

export default {

    data() {
        return {
            userID: null,
            mobileFilterIsVisible: false,
            userInfo: [],
            displayItems: [],
            followBtnContent: 'Follow',
            chatIsVisible: false,
            shopId: null,
            shopInfoIsLoading: true,
            itemsAreLoading: true,
            itemLimitLoading: true,
            page: 1,
            pageSize: 10,
        }
    },

    components: {
        Navi,
        Chat,
        ShopCovProSkel,
        ShopInfoSkel,
        ItemsSkeleton,
        ItemsSkeletonLimit,
    },

    computed: {
        ...mapState(['api', 'idSecKey'])
    },

    async mounted() {
        this.getUserId();
        this.isFollowedCheck();
        await this.loadUserInfo();
        this.loadAds();
        this.addScrollListener();
        this.addScrollListenerSm();
        useHead({
            title: `${this.userInfo.firstName} ${this.userInfo.lastName} | popit.lk`,
            meta: [
                {
                    name: 'description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:title',
                    content: `${this.userInfo.firstName} ${this.userInfo.lastName} | popit.lk`,
                },
                {
                    property: 'og:description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
            ],
        });
    },

    methods: {

        encrypt(plainText) {
            const encrypted = CryptoJS.AES.encrypt(plainText, this.idSecKey, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            });
            return encrypted.toString().replace(/\//g, '-');
        },

        decrypt(encryptedString) {
            const decrypted = CryptoJS.AES.decrypt(encryptedString.replace(/-/g, '/'), this.idSecKey, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            });

            return decrypted.toString(CryptoJS.enc.Utf8)
        },

        addScrollListener() {
            const endOfList = this.$refs.endOfList;
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    this.loadAds();
                }
            });
            observer.observe(endOfList);
        },

        addScrollListenerSm() {
            const endOfList = this.$refs.endOfListSm;
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    this.loadAds();
                }
            });
            observer.observe(endOfList);
        },

        async getUserId() {
            const token = localStorage.getItem('token');
            try {
                const decoded = jwtDecode(token);
                this.userID = decoded.id;
            } catch (err) {
                this.userID = null
                this.followBtnContent = 'Follow'
            }
            this.shopId = parseInt(this.decrypt(this.$route.params.id));
        },

        async isFollowedCheck() {
            if (this.userID !== null) {
                await axios.get(`${this.api}/follow-op/get-following/${this.userID}`).then((response) => {
                    let index = response.data.indexOf(parseInt(this.decrypt(this.$route.params.id)));
                    if (index > -1) {
                        this.followBtnContent = 'Unfollow';
                    } else {
                        this.followBtnContent = 'Follow';
                    }
                })
            }
        },

        async loadUserInfo() {
            this.shopInfoIsLoading = true;
            this.itemsAreLoading = true;
            await axios.get(`${this.api}/users/fetch-user-info/${this.decrypt(this.$route.params.id)}`).then(async (response) => {
                response.data.profile = `data:image/jpeg;base64,${response.data.profile}`;
                response.data.cover = `data:image/jpeg;base64,${response.data.cover}`;

                this.userInfo = await response.data;
                this.shopInfoIsLoading = false;
            });
        },

        async loadAds() {
            this.itemLimitLoading = true;
            await axios.get(`${this.api}/users/get-ads/${this.decrypt(this.$route.params.id)}?page=${this.page}&pageSize=${this.pageSize}`).then((response) => {
                if (response.data.length === 0) {
                    this.itemLimitLoading = false;
                }

                let enabledAds = response.data.filter(ad => ad.is_dissable !== 0);

                for (let i = 0; i < enabledAds.length; i++) {
                    enabledAds[i].ph_sm = `data:image/jpeg;base64,${enabledAds[i].ph_sm}`;
                    let timeDiff = new Date() - new Date(enabledAds[i].saved_at);
                    let seconds = Math.floor(timeDiff / 1000);
                    let minutes = Math.floor(seconds / 60);
                    let hours = Math.floor(minutes / 60);
                    let days = Math.floor(hours / 24);
                    if (days > 0) {
                        enabledAds[i].saved_at = days + ' Day(s) ago';
                    } else if (hours > 0) {
                        enabledAds[i].saved_at = hours + ' H ' + minutes % 60 + ' Min(s) ago';
                    } else if (minutes > 0) {
                        enabledAds[i].saved_at = minutes + ' Min(s) ago';
                    } else {
                        enabledAds[i].saved_at = 'Just now';
                    }

                    this.displayItems.push(enabledAds[i]);
                }

                this.itemsAreLoading = false;
                this.page = this.page + 1;
                this.itemLimitLoading = false;
            })
        },

        itemClick(index) {
            if (this.displayItems[index].is_bid !== null) {
                if (this.displayItems[index].is_bid === 0) {
                    this.$router.push('/item/' + this.displayItems[index].main_cat + '/' + this.displayItems[index].title + '/' + this.encrypt(this.displayItems[index].id.toString()) + '/' + this.encrypt(this.displayItems[index].user_id.toString()))
                } else {
                    this.$router.push('/bid/' + this.displayItems[index].main_cat + '/' + this.displayItems[index].title + '/' + this.encrypt(this.displayItems[index].id.toString()) + '/' + this.encrypt(this.displayItems[index].user_id.toString()))
                }
            } else {
                if (this.displayItems[index].main_cat === 'Jobs') {
                    this.$router.push('/job/' + this.displayItems[index].main_cat + '/' + this.displayItems[index].title + '/' + this.encrypt(this.displayItems[index].id.toString()) + '/' + this.encrypt(this.displayItems[index].user_id.toString()))
                } else {
                    this.$router.push('/item/' + this.displayItems[index].main_cat + '/' + this.displayItems[index].title + '/' + this.encrypt(this.displayItems[index].id.toString()) + '/' + this.encrypt(this.displayItems[index].user_id.toString()))
                }
            }
        },

        async followClick() {
            if (this.userID !== null) {
                try {
                    await axios.post(`${this.api}/follow-op/follow`, {
                        followingId: this.userID,
                        followerId: parseInt(this.decrypt(this.$route.params.id)),
                    });
                    if (this.followBtnContent === 'Follow') {
                        this.followBtnContent = 'Unfollow';
                    } else {
                        this.followBtnContent = 'Follow';
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'ERROR..!',
                        text: "Something went wrong",
                        icon: 'error',
                    });
                }
            } else {
                Swal.fire({
                    title: "Profile required..!",
                    text: "For use this feature you need to sign in with your account",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sign in",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.$router.push('/login');
                    }
                });
            }
        },

        chat() {
            if (this.userID !== null) {
                this.chatIsVisible = true;
            } else {
                Swal.fire({
                    title: "Profile required..!",
                    text: "For use this feature you need to sign in with your account",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sign in",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.$router.push('/login');
                    }
                });
            }
        },

        call() {
            window.location.href = `tel:${this.userInfo.mobile}`;
        },

        hideChat(val) {
            this.chatIsVisible = val;
        },
    },

}
</script>

<style scoped>
a {
    text-decoration: none;
}

a:hover {
    color: black;
}

.con-det-wrp-mob {
    display: none;
}

.srch-sm {
    display: none;
}

.cov-pro-img-wrp {
    width: 100%;
    /* height: 400px; */
    margin-bottom: 70px;
    position: relative;
}

.cov-img-box {
    width: 100%;
    height: 0;
    padding-bottom: 27.25%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.089);
}

.cov-img-box img {
    width: 100%;
    position: absolute;
}

.pro-img-con {
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: -40px;
}

.pro-img-box {
    width: 78px;
    height: 78px;
    border: 2px solid;
    border-radius: 50%;
    background-color: white;
    overflow: hidden;
}

.pro-img-box img {
    max-width: 100%;
    max-height: 100%;
}

.info-box {
    width: 300px;
    flex-shrink: 0;
}

.items-box {
    width: 100%;
    padding-left: 20px;
}

.info-box .info-1 {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.137);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.info-box .info-1 .name {
    font-size: 18px;
    font-weight: 500;
}

.info-box .info-1 .status {
    color: grey;
}

.info-box .info-1 .membership {
    font-size: 10px;
    padding: 1px 5px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.137);
}

.info-box .info-1 .member-since {
    font-weight: 500;
}

.info-box .info-2 {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.137);
    border-top: none;
}

.chat-call-box {
    cursor: pointer;
}

.icn {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.info-box .info-3 {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.137);
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.main {
    min-height: 100vh;
    margin: 0 auto;
}

.items-wrp-lg {
    width: 100%;
    padding-left: 10px;
}

.items-wrp-sm {
    display: none;
}

.item-wrp-lg {
    padding: 10px;
    padding-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.048);
    color: black;
}

.item-wrp-lg .img-box {
    width: 200px;
    height: 115px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    background-color: rgba(0, 0, 0, 0.034);
}

.item-wrp-lg .img-box img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.item-wrp-lg .content-box {
    width: 100%;
}

.item-wrp-lg .content-box .item-name {
    font-weight: 500;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.item-wrp-lg .content-box .membership {
    padding: 0 4px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.048);
}

.item-wrp-lg .content-box .membership-none {
    display: none;
}

.item-wrp-lg .content-box .price {
    font-weight: 500;
}

.search-box {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.219);
    border-radius: 25px;
}

.search-box .inp-srch-box {
    width: 100%;
}

.search-box .inp-srch-box input {
    width: 100%;
    height: 50px;
    padding: 20px;
    border-radius: 25px;
    outline: none;
    border: none;
}

.search-box .btn-srch-box {
    width: 42px;
    height: 42px;
    margin-right: 5px;
    border-radius: 50%;
    border: none;
    background-color: #228B22;
}

.search-box .btn-srch-box img {
    max-width: 70%;
}

.bid-icon-box {
    width: 50px;
    height: 12px;
    margin-left: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bid-icon-box img {
    max-width: 100%;
    max-height: 100%;
}

.btn-subscribe-follow {
    width: 100px;
    height: 35px;
    margin-top: 5px;
    border: none;
    border-radius: 10px;
    color: white;
}

.btn-subscribe-unfollow {
    width: 100px;
    height: 35px;
    margin-top: 5px;
    border: none;
    border-radius: 10px;
    background-color: white;
    border: 2px solid;
}

.items-limit-load-sm {
    display: none;
}

.items-limit-load-lg {
    display: block;
}

.items-loading-skel-lg {
    display: block;
}

@media only screen and (max-width: 767px) {

    .item-info-wrp {
        flex-direction: column;
    }

    .info-box {
        width: 100%;
    }

    .items-wrp-lg {
        padding: 0;
        margin-top: 20px;
    }

    .item-wrp-lg .img-box {
        min-width: 100px;
        height: 100px;
    }
}

@media only screen and (max-width: 575px) {

    .items-limit-load-sm {
        display: block;
    }

    .items-limit-load-lg {
        display: none;
    }

    .items-wrp-lg,
    .mob,
    .cht {
        display: none;
    }

    .items-wrp-sm {
        display: flex;
    }

    .items-sm {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        vertical-align: top;
        border-radius: 5px;
        display: inline-block;
        color: black;
        background-color: rgba(0, 0, 0, 0.062);
    }

    .items-sm img {
        width: 100%;
    }

    .items-sm .item-name {
        font-weight: 900;
        font-size: 14px;
    }

    .items-sm .price {
        font-weight: 500;
        font-size: 14px;
    }

    .items-sm .membership {
        padding: 0 4px;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.048);
    }

    .items-sm div .time-status {
        font-size: 10px;
        font-weight: 300;
    }

    .srch-sm {
        display: block;
        margin: 20px 0;
    }

    .icn-phone {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .icn-chat {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .con-det-wrp-mob {
        width: 100%;
        height: 60px;
        padding: 10px;
        background-color: white;
        border-top: 1px solid rgba(0, 0, 0, 0.151);
        display: flex;
        position: sticky;
        bottom: 0;
        left: 0;
    }

    .con-det-wrp-mob button {
        width: 49%;
        height: 100%;
        border: none;
        border-radius: 5px;
        font-weight: 900;
        color: white;
    }

    /* .items-loading-skel-lg{
        display: none;
    } */
}

.items-wrapper-left-wrapper-sm,
.items-wrapper-right-wrapper-sm {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.items-wrapper-left-wrapper-sm {
    padding-right: 5px;
}

.items-wrapper-right-wrapper-sm {
    padding-left: 5px;
}
</style>