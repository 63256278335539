<template>
    <router-view></router-view>
    <Foo />
</template>

<script>

import Foo from './components/footer-com.vue'

export default {

    components: {
        Foo,
    }

}
</script>

<style>

</style>
