<template>
    <div>
        <Navi />
        <JobProfileCreate :popupVisible="jobProfileCreatePopupIsVisible"
            @jobProfileCreatePopupVisibleFromChild="jobProfileCreatePopupVisibleFromChild" />
        <div class="main">
            <div class="form">
                <router-link to="/profile" class="f-sz-sm f-col-theme">
                    <img src="../assets/icons/backward_theme.png" alt="">
                    Back to Profile
                </router-link>
                <h5 class="header-main">Job Profile</h5>
                <div v-if="profileInfo.length === 0" @click="jobProfileCreatePopupIsVisible = true" class="add-info-box">
                    <p class="pad-mar-no">Add your job profile</p>
                    <div class="add-icn-box">
                        <img src="../assets/icons/plus-white.png" alt="">
                    </div>
                </div>
                <div v-if="profileInfo.length !== 0" class="info-show-box">
                    <div class="le">
                        <div class="info-sec">
                            <div class="name">
                                <p class="pad-mar-no f-sz-sm">First Name: </p>
                            </div>
                            <div class="value">
                                <p class="pad-mar-no f-sz-sm">{{ profileInfo[0].first_name }}</p>
                            </div>
                        </div>
                        <div class="info-sec">
                            <div class="name">
                                <p class="pad-mar-no f-sz-sm">Last Name: </p>
                            </div>
                            <div class="value">
                                <p class="pad-mar-no f-sz-sm">{{ profileInfo[0].last_name }}</p>
                            </div>
                        </div>
                        <div class="info-sec">
                            <div class="name">
                                <p class="pad-mar-no f-sz-sm">Mobile: </p>
                            </div>
                            <div class="value">
                                <p class="pad-mar-no f-sz-sm">{{ profileInfo[0].mobile }}</p>
                            </div>
                        </div>
                        <div class="info-sec">
                            <div class="name">
                                <p class="pad-mar-no f-sz-sm">Email: </p>
                            </div>
                            <div class="value">
                                <p class="pad-mar-no f-sz-sm">{{ profileInfo[0].email }}</p>
                            </div>
                        </div>
                    </div>
                    <button @click="remove()" class="btn-remove f-col-theme">Remove</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Navi from '../components/navigation-com.vue'
import JobProfileCreate from '../components/job-profile-create-com.vue'
import axios from 'axios'
import { mapState } from 'vuex'
import jwtDecode from 'jwt-decode';
import { useHead } from '@vueuse/head';

export default {
    data() {
        return {
            jobProfileCreatePopupIsVisible: false,
            userID: null,
            profileInfo: [],
        }
    },

    computed: {
        ...mapState(['api'])
    },

    components: {
        Navi,
        JobProfileCreate,
    },

    mounted() {
        this.getUserId();
        this.loadProfileInfo();
        useHead({
            title: `Job Profile | popit.lk`,
            meta: [
                {
                    name: 'description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:title',
                    content: `Job Profile | popit.lk`,
                },
                {
                    property: 'og:description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
            ],
        });
    },

    methods: {
        async getUserId() {
            const token = localStorage.getItem('token');
            try {
                const decoded = jwtDecode(token);
                this.userID = decoded.id;
            } catch (err) {
                console.log(err);
            }
        },

        jobProfileCreatePopupVisibleFromChild(val) {
            this.jobProfileCreatePopupIsVisible = val;
            this.loadProfileInfo();
        },

        async loadProfileInfo() {
            await axios.get(`${this.api}/jobs/get-job-profile/${this.userID}`).then((response) => {
                this.profileInfo = response.data;
            })
        },

        async remove() {
            await axios.get(`${this.api}/jobs/remove-job-profile/${this.userID}`);
            this.loadProfileInfo();
        }
    },
}
</script>
<style scoped>
a {
    text-decoration: none;
}

a img {
    width: 7px;
    height: 13px;

}

.main {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.112);
}

.form {
    width: 800px;
    min-height: 100vh;
    margin: auto;
    padding: 20px 20px;
    background-color: white;
}

.header-main {
    text-align: center;
}

.add-info-box {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #8EC6411e;
}

.info-show-box {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #8EC6411e;
}

.info-sec{
    display: flex;
    justify-content: space-between;
}

.name{
    width: 80px;
    /* color: #8EC641; */
}

.value{
    width: 150px;
    /* color: #8EC641; */
}

.btn-remove{
    border: none;
    background-color: transparent;
    font-size: 13px;
}

.add-info-box p {
    font-weight: bold;
    color: #8EC641a6;
}

.add-icn-box {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8EC64164;
}

.add-icn-box img {
    max-width: 50%;
    max-height: 50%;
}

@media only screen and (max-width: 850px) {
    .form {
        width: 100%;
        padding: 10px
    }
}
</style>