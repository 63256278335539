<template>
    <div>
        <div class="cover-img-box anim-shine"></div>
        <div class="pro-det-wrapper dis-fl">
            <div class="pro-container">
                <div class="pro-box anim-shine dis-fl jus-con-cen al-it-cen"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>

.anim-shine{
    background-color: rgba(0, 0, 0, 0.164);
    color: white;
    animation: ani 2s both infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.cover-img-box {
    width: 100%;
    height: 0;
    padding-bottom: 27.25%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.089);
}

.pro-container {
    width: 100%;
    height: 70px;
    position: relative;
    display: flex;
    justify-content: center;
}

.pro-box {
    width: 78px;
    height: 78px;
    border: 2px solid #8EC641;
    border-radius: 50%;
    position: absolute;
    top: -40px;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 575px) {

}
</style>