<template>
    <div>
        <!-- <input v-model="name" type="text" name="" id="">
        <input v-model="age" type="text" name="" id="">
        <button @click="send()">send</button> -->
        {{ token }}
    <button @click="increment">Increment</button>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            imageUrl: '',
            imgData: null,
            name: 'pathum',
            age: '18'
        }
    },

    mounted() {
        // this.fetchImage();
    },

    computed: {
    ...mapState(['token'])
  },

    methods: {
        ...mapActions(['increment']),

        async fetchImage() {
            try {
                const res = await axios.get('http://localhost:4000/api/image')
                this.imageUrl = `data:image/jpeg;base64,${res.data}`
                console.log(this.imageUrl)
            } catch (err) {
                console.error(err)
            }
        },

        async send() {
            const formData = new FormData();
            formData.append('name', this.name);
            formData.append('age', this.age);
            await axios.post('http://localhost:3000/test/post-data', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }
    }
}
</script>

<style scoped>

</style>