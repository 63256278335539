<template>
    <div>
        <div class="main row">
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="item com"></div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="item com"></div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="item com"></div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="item com"></div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="item com"></div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="item com"></div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="item com"></div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="item com"></div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="item com"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>

.com {
    background-color: rgba(0, 0, 0, 0.164);
    color: white;
    animation: ani 2s both infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}
.main{
    margin-top: 30px;
}
.item {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@media only screen and (max-width: 1200px) {
    .item {
        height: 160px;
    }
}

@media only screen and (max-width: 575px) {
    .item {
        height: 130px;
        margin-bottom: 1px;
    }
    .col-md-4{
        padding: 1px;
    }
}

@media only screen and (max-width: 300px) {
    .item {
        height: 80px;
    }
}
</style>