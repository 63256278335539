<template>
    <div>
        <loading v-model:active="isLoading" loader="bars" color="#228B22" />
        <Navi />
        <Chat :hideChat="chatIsVisible" @hideChat="getCloseOp" :adOwnerId="adOwnedUserId" :mentionedItemImg="mainImage"
            :mentionedItemName="title" />
        <ItemSkeleton v-if="pageIsLoading" />
        <div class="img-prev-main" v-if="imagePreviewIsVisible">
            <div class="img-prev-wrapper">
                <div class="btn-close-wrp">
                    <button @click="imagePreviewIsVisible = false" class="btn-close btn-close-white"></button>
                </div>
                <div class="img-prev-main-img-btns-wrp">
                    <button @click="expandImagesChange('prev')" class="img-prev-next">
                        <img src="../assets/icons/backward.png" alt="">
                    </button>
                    <div class="img-prev-main-img">
                        <img :src="mainImage" alt="" class="img-main">
                    </div>
                    <button @click="expandImagesChange('next')" class="img-prev-next">
                        <img src="../assets/icons/forward.png" alt="">
                    </button>
                </div>
                <div class="sub-images-wrapper dis-fl al-it-cen">
                    <div class="imgs-wrp dis-fl" :class="isSubImgMin === false ? 'imgs-wrp' : 'imgs-wrp-min'"
                        id="sub-imgs-wrp">
                        <button v-for="(item, index) in subImages" :key="index" @click="subImageClick(index)"
                            :class="item.isSelected === true ? 'img-selected dis-fl jus-con-cen al-it-cen pad-mar-no' : 'img dis-fl jus-con-cen al-it-cen pad-mar-no'">
                            <img :src="item.imgURL" alt="">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!pageIsLoading" class="main res-py container-xxl">
            <div class="img-con-wrp row pad-mar-no mar-bet-sec">
                <div class="main-img-wrapper col-lg-8 pad-mar-no">
                    <div class="main-img-box dis-fl jus-con-cen al-it-cen">
                        <div class="action-btns-wrapper">
                            <transition name="social-trans">
                                <div v-if="socialIconsAreVisible" class="social-icons-wrapper">
                                    <div @click="share('facebook')" class="btn-social-icon">
                                        <img class="img-social-icon" src="../assets/icons/social-media/fb.png" alt="">
                                    </div>
                                    <div @click="share('whatsapp')" class="btn-social-icon">
                                        <img class="img-social-icon" src="../assets/icons/social-media/wa.png" alt="">
                                    </div>
                                    <div @click="share('gmail')" class="btn-social-icon">
                                        <img class="img-social-icon" src="../assets/icons/social-media/gmail.png" alt="">
                                    </div>
                                    <div @click="share('copy')" class="btn-social-icon">
                                        <img class="img-social-icon" src="../assets/icons/social-media/copy.png" alt="">
                                    </div>
                                </div>
                            </transition>
                            <div></div>
                            <button @click="imagePreviewIsVisible = true" class="pad-mar-no">
                                <div class="share-expand-img-box" id="expand"></div>
                            </button>
                            <!-- <button @click="wishlistCLick()" class="pad-mar-no">
                                <div :class="isWishlist === false ? 'wishlist-img-box' : 'wishlist-img-box-active'"></div>
                            </button> -->
                            <!-- <button @click="socialIconsAreVisible = !socialIconsAreVisible" class="pad-mar-no">
                                <div class="share-expand-img-box" id="share"></div>
                            </button> -->
                        </div>
                        <img class="main-img-box-img" :src="mainImage" alt="">
                    </div>
                    <div class="sub-images-wrapper dis-fl al-it-cen" id="sub-imgs-box">
                        <button v-if="!isSubImgMin" @click="imgPrev()" class="btn-left-box pad-mar-no" id="btn-left">
                            <img class="img-fit" src="../assets/icons/backward_theme.png" alt="">
                        </button>
                        <div class="imgs-wrp dis-fl" :class="isSubImgMin === false ? 'imgs-wrp' : 'imgs-wrp-min'"
                            id="sub-imgs-wrp">
                            <button v-for="(item, index) in subImages" :key="index" @click="subImageClick(index)"
                                :class="item.isSelected === true ? 'img-selected dis-fl jus-con-cen al-it-cen pad-mar-no bor-col-theme' : 'img dis-fl jus-con-cen al-it-cen pad-mar-no'">
                                <img :src="item.imgURL" alt="">
                            </button>
                        </div>
                        <button v-if="!isSubImgMin" @click="imgNext()" class="btn-right-box pad-mar-no" id="btn-right">
                            <img class="img-fit" src="../assets/icons/forward_theme.png" alt="">
                        </button>
                    </div>
                    <div class="price-desc-wrapper">
                        <p class="item-name pad-mar-no">{{ title }}</p>
                        <p class="pad-mar-no f-sz-sm">Posted on {{ date }} at {{ time }}, {{ district }}</p>
                        <hr>
                        <div class="count-down-wrp">
                            <h3 v-if="cdDays === '00' && cdHours === '00' && cdMins === '00' && cdSecs === '00'"
                                class="pad-mar-no f-col-theme">Auction End</h3>
                            <div v-else class="count-down-box bor-col-theme">
                                <div class="count-down-num-box">
                                    <p class="count pad-mar-no">{{ cdDays }}</p>
                                    <p class="title pad-mar-no">Days</p>
                                </div>
                                <div class="colo-box f-col-theme">
                                    <p class="count pad-mar-no">:</p>
                                </div>
                                <div class="count-down-num-box">
                                    <p class="count pad-mar-no">{{ cdHours }}</p>
                                    <p class="title pad-mar-no">Hours</p>
                                </div>
                                <div class="colo-box f-col-theme">
                                    <p class="count pad-mar-no">:</p>
                                </div>
                                <div class="count-down-num-box">
                                    <p class="count pad-mar-no">{{ cdMins }}</p>
                                    <p class="title pad-mar-no">MIns</p>
                                </div>
                                <div class="colo-box f-col-theme">
                                    <p class="count pad-mar-no">:</p>
                                </div>
                                <div class="count-down-num-box">
                                    <p class="count pad-mar-no">{{ cdSecs }}</p>
                                    <p class="title pad-mar-no">Secs</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div style="display: flex; align-items: flex-end;">
                                    <p style="padding-bottom: 2px;" class="pad-mar-no">Started from:</p>&nbsp;
                                    <p class="current-bid-price pad-mar-no">Rs {{ parseInt(startPrice).toLocaleString() }}
                                    </p>
                                    <p class="f-sz-sm">[{{ topBids.length - 1 }} bids]</p>
                                </div>
                                <div style="display: flex; align-items: flex-end;">
                                    <p style="padding-bottom: 2px;" class="pad-mar-no">Current bid:</p>&nbsp;
                                    <p class="current-bid-price pad-mar-no">Rs {{ parseInt(currentBid).toLocaleString() }}
                                    </p>
                                </div>
                                <div style="display: flex; align-items: flex-end;">
                                    <p style="padding-bottom: 3px;" class="pad-mar-no">Next Bid:</p>&nbsp;
                                    <p class="price pad-mar-no f-col-theme">Rs {{ parseInt(nextBid).toLocaleString() }}</p>
                                </div>
                                <button v-if="!isCurrentUserLastBid && !isCurrentUserAd" @click="placeBid()"
                                    class="btn-place-bid pad-mar-no col-theme">Place a Bid</button>
                                <p v-if="isCurrentUserLastBid && !isCurrentUserAd" class="txt-bid-top">Your bid is on top
                                </p>
                            </div>
                            <div class="bid-places-mob col-md-6">
                                <div class="bid-places-wrapper">
                                    <p class="top-bids-head">Top Bids</p>
                                    <hr>
                                    <div v-if="topBids.length === 1" class="no-record">
                                        <p>No Record(s)</p>
                                    </div>
                                    <div v-if="topBids.length > 1" class="bid-place-box dis-fl jus-con-sp-bt">
                                        <div class="icn-name-wrp dis-fl">
                                            <div class="icn">
                                                <img class="img-fit" src="../assets/icons/place-badge-1st.png" alt="">
                                            </div>
                                            <p class="name pad-mar-no">{{ topBids[topBids.length - 1].userName }}</p>
                                        </div>
                                        <p class="bid-price">{{ topBids[topBids.length - 1].price.toLocaleString() }}</p>
                                    </div>
                                    <div v-if="topBids.length > 2" class="bid-place-box dis-fl jus-con-sp-bt">
                                        <div class="icn-name-wrp dis-fl">
                                            <div class="icn">
                                                <img class="img-fit" src="../assets/icons/place-badge-2nd.png" alt="">
                                            </div>
                                            <p class="name pad-mar-no">{{ topBids[topBids.length - 2].userName }}</p>

                                        </div>
                                        <p class="bid-price">{{ topBids[topBids.length - 2].price.toLocaleString() }}</p>
                                    </div>
                                    <div v-if="topBids.length > 3" class="bid-place-box dis-fl jus-con-sp-bt">
                                        <div class="icn-name-wrp dis-fl">
                                            <div class="icn">
                                                <img class="img-fit" src="../assets/icons/place-badge-3rd.png" alt="">
                                            </div>
                                            <p class="name pad-mar-no">{{ topBids[topBids.length - 3].userName }}</p>

                                        </div>
                                        <p class="bid-price">{{ topBids[topBids.length - 3].price.toLocaleString() }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <!-- <p class="pad-mar-no">Condition: Used</p>
                        <p class="pad-mar-no">Model: iPhone 11</p>
                        <p class="pad-mar-no">Brand: Apple</p>
                        <p class="pad-mar-no">Edition: 64GB</p><br>
                        <p class="features pad-mar-no">Features</p>
                        <p>Camera, Bluetooth</p> -->
                        <p class="features pad-mar-no">Description :</p>
                        <p class="pad-mar-no">{{ description }}</p><br>
                        <div class="seller-info-mob">
                            <p class="features pad-mar-no">About Seller</p>
                            <button @click="aboutSellerClick()" class="btn-selle-info f-col-theme">{{ sellerName }}</button>
                        </div><br>
                    </div>
                </div>
                <div class="con-det-wrapper col-lg-4 pad-mar-no">
                    <div class="con-det">
                        <div class="seller-info">
                            <p class="pad-mar-no"><b>About Seller</b></p>
                            <button @click="aboutSellerClick()" class="btn-selle-info f-col-theme">{{ sellerName }}</button>
                        </div>
                    </div>
                    <div class="bid-place-pc">
                        <div class="bid-places-wrapper">
                            <p class="top-bids-head">Top Bids</p>
                            <hr>
                            <div v-if="topBids.length === 1" class="no-record">
                                <p>No Record(s)</p>
                            </div>
                            <div v-if="topBids.length > 1" class="bid-place-box dis-fl jus-con-sp-bt">
                                <div class="icn-name-wrp dis-fl">
                                    <div class="icn">
                                        <img class="img-fit" src="../assets/icons/place-badge-1st.png" alt="">
                                    </div>
                                    <p class="name pad-mar-no">{{ topBids[topBids.length - 1].userName }}</p>
                                </div>
                                <p class="bid-price">Rs {{ topBids[topBids.length - 1].price.toLocaleString() }}</p>
                            </div>
                            <div v-if="topBids.length > 2" class="bid-place-box dis-fl jus-con-sp-bt">
                                <div class="icn-name-wrp dis-fl">
                                    <div class="icn">
                                        <img class="img-fit" src="../assets/icons/place-badge-2nd.png" alt="">
                                    </div>
                                    <p class="name pad-mar-no">{{ topBids[topBids.length - 2].userName }}</p>

                                </div>
                                <p class="bid-price">Rs {{ topBids[topBids.length - 2].price.toLocaleString() }}</p>
                            </div>
                            <div v-if="topBids.length > 3" class="bid-place-box dis-fl jus-con-sp-bt">
                                <div class="icn-name-wrp dis-fl">
                                    <div class="icn">
                                        <img class="img-fit" src="../assets/icons/place-badge-3rd.png" alt="">
                                    </div>
                                    <p class="name pad-mar-no">{{ topBids[topBids.length - 3].userName }}</p>
                                </div>
                                <p class="bid-price">Rs {{ topBids[topBids.length - 3].price.toLocaleString() }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="img-preview-box">
                <div class="close-wrp dis-fl">
                    <button class="btn-close btn-close-white"></button>
                </div>
                <div class="btns-img-wrapper dis-fl al-it-cen">
                    <button class="pad-mar-no">
                        <img src="" alt="">
                    </button>
                    <div class="main-img-box">

                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import Navi from '../components/navigation-com.vue';
import Chat from '../components/chat-com.vue';
import axios from 'axios';
import { mapState } from 'vuex';
import Swal from 'sweetalert2';
import jwtDecode from 'jwt-decode';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ItemSkeleton from '../components/skeleton-loading/item-skel-com.vue';
import { useHead } from '@vueuse/head';
import CryptoJS from 'crypto-js';

export default {

    data() {
        return {
            imagePreviewIsVisible: false,
            pageIsLoading: true,
            isLoading: false,
            userID: null,
            adOwnedUserId: null,
            userId: null,
            mainImage: '',
            subImages: [],
            title: null,
            district: null,
            description: null,
            date: null,
            time: null,
            topBids: [],
            highestBidPrice: null,
            startPrice: null,
            currentBid: null,
            nextBid: null,
            bidGap: null,
            currentIndex: 0,
            bidInputIsVisible: false,
            cdDays: '--',
            cdHours: '--',
            cdMins: '--',
            cdSecs: '--',
            intervalId: null,
            bidConfirmPopupVisible: false,
            adIsExpired: false,
            isCurrentUserAd: true,
            isCurrentUserLastBid: true,
            isSubImgMin: false,
            sellerName: null,
            sellerMobile: null,
            chatIsVisible: false,
        }
    },

    components: {
        Navi,
        ItemSkeleton,
        Chat,
        Loading,
    },

    computed: {
        ...mapState(['api', 'idSecKey'])
    },

    mounted() {
        this.getUserId();
        this.itemInfoLoad();
        useHead({
            title: `${this.title} Bid ad | popit.lk`,
            meta: [
                {
                    name: 'description',
                    content: `${this.description} | popit.lk`
                },
                {
                    property: 'og:title',
                    content: `${this.title} Bid ad | popit.lk`,
                },
                {
                    property: 'og:description',
                    content: `${this.description} | popit.lk`
                },
                {
                    property: 'og:image',
                    content: 'https://www.octick.com/img/Octic_Logo_Black_landscape.6583c7ab.png'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
            ],
        });
    },

    methods: {

        decrypt(encryptedString) {
            const decrypted = CryptoJS.AES.decrypt(encryptedString.replace(/-/g, '/'), this.idSecKey, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            });

            return decrypted.toString(CryptoJS.enc.Utf8)
        },

        async itemInfoLoad() {
            let token = localStorage.getItem('token');
            this.userId = await jwtDecode(token).id;
            // this.isLoading = true;
            this.pageIsLoading = true;
            await axios.get(`${this.api}/get-ad/${this.$route.params.itemCategory}/${this.decrypt(this.$route.params.itemId.toString())}/${this.decrypt(this.$route.params.userId.toString())}`).then(async (response) => {
                this.mainImage = `data:image/jpeg;base64,${response.data[0].ph_1}`;
                this.subImages[0] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_1}`, isSelected: true };
                if (response.data[0].ph_2 !== "") {
                    this.subImages[1] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_2}`, isSelected: false }
                }
                if (response.data[0].ph_3 !== "") {
                    this.subImages[2] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_3}`, isSelected: false }
                }
                if (response.data[0].ph_4 !== "") {
                    this.subImages[3] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_4}`, isSelected: false }
                }
                if (response.data[0].ph_5 !== "") {
                    this.subImages[4] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_5}`, isSelected: false }
                }
                if (response.data[0].ph_6 !== "") {
                    this.subImages[5] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_6}`, isSelected: false }
                }

                if (this.subImages.length >= 5) {
                    this.isSubImgMin = false;
                } else {
                    this.isSubImgMin = true;
                }

                this.title = response.data[0].title;

                this.price = response.data[0].price !== 0 ? parseInt(response.data[0].price).toLocaleString() : 'Free';

                this.district = response.data[0].district;

                this.description = response.data[0].description;
                this.adOwnedUserId = response.data[0].user_id;

                const dateTimeString = response.data[0].saved_at;
                const [dateString, timeString] = dateTimeString.split("T");
                const timeWithoutMillis = timeString.slice(0, -5);
                this.date = dateString;
                this.time = timeWithoutMillis;

                // if (this.subImages.length > 4) {
                //     document.getElementById('btn-left').style.display = 'block';
                //     document.getElementById('btn-right').style.display = 'block';
                //     // if (window.screen.width > 767) {
                //     //     document.getElementById('sub-imgs-box').style.width = '550px';
                //     // } else {
                //     //     document.getElementById('sub-imgs-box').style.width = '100%';
                //     // }
                // } else {
                //     document.getElementById('btn-left').style.display = 'none';
                //     document.getElementById('btn-right').style.display = 'none';
                //     document.getElementById('sub-imgs-box').style.width = '100%';
                //     if (window.screen.width > 575) {
                //         document.getElementById('sub-imgs-box').style.width = 'max-content';
                //     } else {
                //         document.getElementById('sub-imgs-box').style.width = '250px';
                //     }
                // }

                this.topBids = JSON.parse(response.data[0].bids);

                for (let i = 0; i < this.topBids.length; i++) {
                    await axios.get(`${this.api}/users/fetch-user-info/${this.topBids[i].userId}`).then((response) => {
                        this.topBids[i].userName = response.data.firstName;
                    })
                }

                this.startPrice = this.topBids[0].price;
                this.currentBid = this.topBids[this.topBids.length - 1].price;
                this.bidGap = response.data[0].bid_gap;
                this.nextBid = this.topBids[this.topBids.length - 1].price + this.bidGap;

                //check loggedin user's ad
                if (response.data[0].user_id == this.userID) {
                    this.isCurrentUserAd = true;
                } else {
                    this.isCurrentUserAd = false;
                }

                //check lastbid is current user
                if (this.topBids[this.topBids.length - 1].userId == this.userID) {
                    this.isCurrentUserLastBid = true;
                } else {
                    this.isCurrentUserLastBid = false;
                }

                this.cdDays = this.cdDays.toString().padStart(2, 0);
                this.cdHours = this.cdHours.toString().padStart(2, 0);
                this.cdMins = this.cdMins.toString().padStart(2, 0);
                this.cdSecs = this.cdSecs.toString().padStart(2, 0);

                this.intervalId = setInterval(() => {
                    let savedDate = new Date(response.data[0].saved_at);
                    let expireAt = savedDate.getTime() + (response.data[0].duration * 24 * 60 * 60 * 1000)
                    let distance = new Date(expireAt) - new Date();
                    this.cdDays = (Math.floor(distance / (1000 * 60 * 60 * 24))).toString().padStart(2, 0);
                    this.cdHours = (Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).toString().padStart(2, 0);
                    this.cdMins = (Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).toString().padStart(2, 0);
                    this.cdSecs = (Math.floor((distance % (1000 * 60)) / 1000)).toString().padStart(2, 0);
                    if (this.cdSecs <= -1) {
                        this.cdDays = (0).toString().padStart(2, 0);
                        this.cdHours = (0).toString().padStart(2, 0);
                        this.cdMins = (0).toString().padStart(2, 0);
                        this.cdSecs = (0).toString().padStart(2, 0);
                        this.adIsExpired = true;
                    }
                }, 1000);
            })

            await axios.get(`${this.api}/users/fetch-user-info/${this.decrypt(this.$route.params.userId.toString())}`).then((response) => {
                this.sellerName = response.data.firstName + ' ' + response.data.lastName;
                this.sellerMobile = response.data.mobile;
            })
            // this.isLoading = false;
            this.pageIsLoading = false;
        },

        async getUserId() {
            if (localStorage.getItem('token')) {
                const token = localStorage.getItem('token');
                try {
                    const decoded = jwtDecode(token);
                    this.userID = decoded.id;
                } catch (err) {
                    console.log(err);
                }
            } else {
                this.userID = null;
            }
        },

        subImageClick(index) {
            for (let i = 0; i < this.subImages.length; i++) {
                this.subImages[i].isSelected = false;
            }
            if (this.currentIndex < index) {
                if (index >= 2) {
                    this.imgNext();
                }
            }
            if (this.currentIndex > index) {
                if (index <= 3) {
                    this.imgPrev();
                }
            }
            this.subImages[index].isSelected = true;
            this.mainImage = this.subImages[index].imgURL;
            this.currentIndex = index;
        },

        getCloseOp(value) {
            this.chatIsVisible = value;
        },

        imgPrev() {
            document.getElementById("sub-imgs-wrp").scrollBy(-400, 0);
        },

        imgNext() {
            document.getElementById("sub-imgs-wrp").scrollBy(400, 0);
        },

        bidInputVisible() {
            this.bidInputIsVisible = !this.bidInputIsVisible;
        },

        placeBid() {
            if (this.userID !== null) {
                if (this.adIsExpired !== true) {
                    Swal.fire({
                        title: "Place Bid..!",
                        text: `Bidding amount is Rs: ${parseInt(this.nextBid).toLocaleString()}`,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "PLACE BID",
                        cancelButtonText: "CANCEL",
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true;
                            await axios.get(`${this.api}/get-ad/${this.$route.params.itemCategory}/${this.decrypt(this.$route.params.itemId.toString())}/${this.decrypt(this.$route.params.userId.toString())}`).then(async (response) => {
                                let highestBid = JSON.parse(response.data[0].bids);
                                let hightestBidPrice = highestBid[highestBid.length - 1].price;
                                let nextBid = hightestBidPrice + parseInt(this.bidGap);
                                if (this.nextBid == nextBid) {
                                    await axios.post(`${this.api}/bids/place-bid`, {
                                        userId: this.userID,
                                        price: nextBid,
                                        category: this.$route.params.itemCategory,
                                        itemId: this.decrypt(this.$route.params.itemId.toString()),
                                    }).then(async () => {
                                        this.isLoading = false;
                                        await axios.post(`${this.api}/notifications/user-bid-for-ad`, {
                                            mainCategory: this.$route.params.itemCategory,
                                            itemId: this.decrypt(this.$route.params.itemId.toString()),
                                            userId: this.userID,
                                            title: this.title,
                                        })
                                        try {
                                            Swal.fire({
                                                title: "SUCCESS!",
                                                text: "Your bid has been placed.",
                                                icon: "success",
                                                cancelButtonText: "OK",
                                            }).then(() => {
                                                location.reload();
                                            })
                                        } catch (error) {
                                            Swal.fire({
                                                title: "ERROR!",
                                                text: "Something went wrong..!",
                                                icon: "error",
                                                cancelButtonText: "OK",
                                            })
                                        }
                                    })
                                } else {
                                    Swal.fire({
                                        title: "Late Bid Submission",
                                        text: "Your bid amount already has been placed.",
                                        icon: "error",
                                        cancelButtonText: "RELOAD",
                                    }).then(() => {
                                        location.reload();
                                    })
                                }
                            })
                        }
                    });
                } else {
                    Swal.fire({
                        title: "ERROR..!",
                        text: "Ad is expired..!",
                        icon: "error",
                        cancelButtonText: "OK",
                    }).then(() => {
                        this.$router.back();
                    })
                }
            } else {
                this.$router.push('/login')
            }

        },

        chat() {
            if (this.userId !== null) {
                this.chatIsVisible = true;
            } else {
                Swal.fire({
                    title: "Profile required..!",
                    text: "For use this feature you need to sign in with your account",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sign in",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.$router.push('/login');
                    }
                });
            }
        },

        aboutSellerClick() {
            this.$router.push(`/shop/${this.$route.params.userId.toString()}`);
        }
    },
}
</script>

<style scoped>
#expand {
    background-image: url('../assets/icons/full-screen.png');
}

.img-prev-main {
    width: 100%;
    height: 100vh;
    padding: 50px;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}

.img-prev-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.btn-close-wrp {
    width: 100%;
    height: 50px;
}

.img-prev-wrapper .btn-close {
    position: absolute;
    right: 0;
}

.img-prev-main-img-btns-wrp {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.img-prev-next {
    width: 80px;
    height: 80px;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
}

.img-prev-main-img {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-main {
    max-width: 100%;
    max-height: 100%;
}

.count-down-wrp {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
}

.count-down-box {
    /* width: 300px; */
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 4px solid;
}

.count-down-num-box {
    width: 55px;
    height: 55px;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.colo-box {
    height: 55px;
    display: flex;
    align-items: flex-start;
}

.count-down-num-box p,
.colo-box p {
    text-align: center;
}

.count-down-num-box .count,
.colo-box .count {
    font-size: 40px;
    line-height: 30px;
}

.count-down-num-box .title,
.colo-box .title {
    font-size: 13px;
}

.row {
    padding: 0;
    margin: 0;
}

.top-bids-head {
    font-weight: 500;
    font-size: 20px;
}

.seller-info-mob {
    display: none;
}

.bid-places-mob {
    display: none;
}

.img-preview-box {
    width: 100%;
    height: 100%;
    padding: 50px;
    background-color: grey;
    position: fixed;
    top: 0;
    left: 0;
}

.img-preview-box .close-wrp {
    justify-content: flex-end;
}

.img-preview-box .btns-img-wrapper button {
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
}

.main-img-box {
    width: 100%;
    height: 470px;
    margin-bottom: 20px;
    position: relative;
}

.main-img-box-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
}

.action-btns-wrapper {
    width: 55px;
    height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
}

.action-btns-wrapper button {
    width: 25px;
    height: 25px;
    background-color: rgba(255, 255, 255, 0.534);
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icons-wrapper {
    height: 150px;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.534);
    position: absolute;
    top: 40px;
    right: 0;
    overflow: hidden;
}

.btn-social-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.img-social-icon {
    max-width: 100%;
    max-height: 100%;
}

.share-expand-img-box {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
}

.btn-left-box,
.btn-right-box {
    width: 15px;
    border: none;
    background-color: transparent;
}

.sub-images-wrapper {
    width: 550px;
    height: 100px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.imgs-wrp-min {
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    justify-content: center;
}

.imgs-wrp {
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
}

.imgs-wrp::-webkit-scrollbar {
    height: 0px;
}

.imgs-wrp .img {
    min-width: 100px;
    height: 90px;
    margin: 0 5px;
    border: none;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.103);
    transition: .1s;
}

.imgs-wrp .img-selected {
    min-width: 100px;
    height: 90px;
    margin: 0 5px;
    border: 3px solid;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.103);
    transition: .1s;
}

.imgs-wrp .img img,
.imgs-wrp .img-selected img {
    max-height: 100%;
}

.con-det-wrapper {
    padding-left: 20px;
}

.btn-selle-info {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    background-color: transparent;
    border: none;
}

.bid-place-box {
    border-bottom: 1px solid rgba(0, 0, 0, 0.123);
}

.bid-places-wrapper .icn-name-wrp .icn {
    width: 30px;
    height: 30px;
}

.con-det-wrapper .con-box {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.123);
    border-top: 1px solid rgba(0, 0, 0, 0.123);
    background-color: transparent;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
}

.con-det-wrapper .con-box .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.con-det-wrapper .con-box .icon img {
    max-width: 50%;
}

.con-det-wrapper .con-box .content {
    flex-direction: column;
    align-items: flex-start;
}

.price-desc-wrapper .item-name {
    font-weight: 700;
    font-size: 30px;
}

.price-desc-wrapper .price {
    font-weight: 700;
    font-size: 25px;
}

.price-desc-wrapper .current-bid-price {
    font-weight: 700;
    font-size: 20px;
}

.bid-inp-wrapper {
    width: 250px;
}

.price-desc-wrapper .features {
    font-weight: 500;
}

.con-det-wrp-mob {
    display: none;
}

.btn-place-bid {
    padding: 5px;
    margin-bottom: 30px;
    border: none;
    color: white;
    font-weight: 500;
    border-radius: 5px;
}

.txt-bid-top {
    color: rgb(4, 247, 4);
}

.no-record {
    width: 100%;
    display: flex;
    justify-content: center;
}

.no-record p {
    text-align: center;
}

@media only screen and (max-width: 991px) {

    .seller-info-mob {
        display: block;
    }

    .main-img-box {
        height: 500px;
    }

    .con-det-wrapper {
        padding: 0;
    }

    .con-det-wrapper {
        display: none;
    }

    .bid-places-mob {
        display: block;
    }
}

@media only screen and (max-width: 767px) {

    .img-prev-main {
        padding: 10px;
    }

    .img-prev-next {
        display: none;
    }

    .main-img-box {
        height: 400px;
    }

    .btn-right-box,
    .btn-left-box {
        display: none;
    }

    .sub-images-wrapper {
        width: 450px;
    }

    .price-desc-wrapper .item-name {
        font-size: 25px;
    }

    .price-desc-wrapper .price {
        font-size: 20px;
    }

    .bid-places-mob {
        padding: 0;
    }
}

@media only screen and (max-width: 575px) {

    .main-img-box {
        height: 300px;
    }

    .sub-images-wrapper {
        width: 100%;
    }

    .con-det-wrp-mob {
        width: 100%;
        height: 60px;
        padding: 10px;
        background-color: white;
        border-top: 1px solid rgba(0, 0, 0, 0.151);
        display: flex;
        position: sticky;
        bottom: 0;
        left: 0;
    }

    .con-det-wrp-mob button {
        width: 49%;
        height: 100%;
        border: none;
        border-radius: 5px;
        font-weight: 900;
        color: white;
    }

    .icn-phone {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .icn-chat {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 400px) {

    .main-img-box {
        height: 250px;
    }
}

/* @media only screen and (max-width: 350px) {
    .count-down-box{
        width: 100%;
    }
} */

.bid-input-trans-enter-from {
    opacity: 0;
}

.bid-input-trans-enter-active {
    transition: all .2s ease;
}
</style>