<template>
    <div>
        <div class="cover-img-box anim-shine"></div>
        <div class="pro-det-wrapper dis-fl">
            <div class="pro-container">
                <div class="pro-box anim-shine dis-fl jus-con-cen al-it-cen bor-col-theme"></div>
            </div>
            <div class="details">
                <div class="dis-fl al-it-cen">
                    <div class="name txt-l anim-shine"></div>
                </div>
                <div class="username txt-sm-2 anim-shine"></div>
                <div class="count-mob dis-fl jus-con-sp-bt">
                    <div class="count-item dis-fl al-it-cen">
                        <div class="items txt-md anim-shine"></div>
                    </div>
                    <div @click="followersPopupIsVisible = true;" class="count-item dis-fl al-it-cen"
                        id="count-items-click">
                        <div class="foll txt-md anim-shine"></div>
                    </div>
                    <div @click="followingPopupIsVisible = true;" class="count-item dis-fl al-it-cen"
                        id="count-items-click">
                        <div class="foll txt-md anim-shine"></div>
                    </div>
                </div>
                <div class="abut">
                    <div class="txt-sm-1 anim-shine"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>

.anim-shine{
    background-color: rgba(0, 0, 0, 0.164);
    color: white;
    animation: ani 2s both infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.cover-img-box {
    width: 100%;
    height: 0;
    padding-bottom: 27.25%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.089);
}

.pro-container {
    width: 150px;
    height: 100px;
    margin-right: 20px;
    position: relative;
}

.pro-box {
    width: 78px;
    height: 78px;
    border: 2px solid;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: -10px;
}

.txt-l{
    width: 300px;
    height: 25px;
    margin: 5px 0;
}

.txt-md{
    width: 60px;
    height: 20px;
    margin: 5px 0;
}

.txt-sm-1{
    width: 100%;
    height: 15px;
    margin: 5px 0;
}

.txt-sm-2{
    width: 200px;
    height: 15px;
    margin: 5px 0;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 575px) {

}
</style>