<template>
    <div>
        <transition name="popup-trans">
            <div v-if="popupIsVisible" @click="close()" class="main">
                <div @click.stop class="form">
                    <div class="close-wrp">
                        <button @click="close()" class="btn-close"></button>
                    </div>
                    <div class="inp">
                        <label class="f-sz-sm" for="">First Name</label>
                        <input v-model="firstName" type="text" name="" id="" class="form-control f-sz-sm">
                    </div>
                    <div class="inp">
                        <label class="f-sz-sm" for="">Last Name</label>
                        <input v-model="lastName" type="text" name="" id="" class="form-control f-sz-sm">
                    </div>
                    <div class="inp">
                        <label class="f-sz-sm" for="">Mobile</label>
                        <input v-model="mobile" type="text" name="" id="" class="form-control f-sz-sm">
                    </div>
                    <div class="inp">
                        <label class="f-sz-sm" for="">Email</label>
                        <input v-model="email" type="email" name="" id="" class="form-control f-sz-sm">
                    </div>
                    <div class="inp">
                        <label class="f-sz-sm" for="">Upload your CV (.pdf)</label><br>
                        <input type="file" name="" id="" ref="file">
                    </div>
                    <div class="save-wrapper">
                        <button @click="saveData()" class="btn-save col-theme">SAVE</button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

export default {

    props: {
        popupVisible: Boolean,
    },

    data() {
        return {
            popupIsVisible: false,
            userID: null,
            firstName: '',
            lastName: '',
            mobile: '',
            email: '',
        }
    },

    computed: {
        ...mapState(['api'])
    },

    watch: {
        popupVisible(val) {
            this.popupIsVisible = val;
        },
    },

    mounted() {
        this.getUserId();
    },

    methods: {

        async getUserId() {
            const token = localStorage.getItem('token');
            try {
                const decoded = jwtDecode(token);
                this.userID = decoded.id;
            } catch (err) {
                this.userID = null;
            }
        },

        close() {
            this.$emit('jobProfileCreatePopupVisibleFromChild', false)
        },

        async saveData() {
            let files = [];
            files[0] = this.$refs.file.files[0];

            const formData = new FormData();

            formData.append('files', files[0]);
            formData.append('id', this.userID);
            formData.append('firstName', this.firstName);
            formData.append('lastName', this.lastName);
            formData.append('mobile', this.mobile);
            formData.append('email', this.email);

            try {
                await axios.post(`${this.api}/jobs/add-job-profile`, formData);
                this.close();
            } catch (error) {
                console.log(error);
            }
        }
    },
}

</script>

<style scoped>
.main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.139);
}

.form {
    width: 500px;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
}

.close-wrp {
    display: flex;
    justify-content: flex-end;
}

.inp {
    margin-bottom: 10px;
}

.save-wrapper {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.btn-save {
    width: 100px;
    height: 35px;
    border: none;
    color: white;
    border-radius: 5px;
}

.popup-trans-enter-from,
.popup-trans-leave-to {
    opacity: 0;
}

.popup-trans-enter-active,
.popup-trans-leave-active {
    transition: all .3s ease;
}</style>