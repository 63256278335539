<template>
    <div>
        <Chat :hideChat="chatIsVisible" @hideChat="getCloseOp" :adOwnerId="adOwnedUserId" :mentionedItemImg="mainImage"
            :mentionedItemName="title" />
        <Navi />
        <ItemSkeleton v-if="pageIsloading" />
        <div class="img-prev-main" v-if="imagePreviewIsVisible">
            <div class="img-prev-wrapper">
                <div class="btn-close-wrp">
                    <button @click="imagePreviewIsVisible = false" class="btn-close btn-close-white"></button>
                </div>
                <div class="img-prev-main-img-btns-wrp">
                    <button @click="expandImagesChange('prev')" class="img-prev-next">
                        <img src="../assets/icons/backward.png" alt="">
                    </button>
                    <div class="img-prev-main-img">
                        <img :src="mainImage" alt="" class="img-main">
                    </div>
                    <button @click="expandImagesChange('next')" class="img-prev-next">
                        <img src="../assets/icons/forward.png" alt="">
                    </button>
                </div>
                <div class="sub-images-wrapper dis-fl al-it-cen">
                    <div class="imgs-wrp dis-fl" :class="isSubImgMin === false ? 'imgs-wrp' : 'imgs-wrp-min'"
                        id="sub-imgs-wrp">
                        <button v-for="(item, index) in subImages" :key="index" @click="subImageClick(index)"
                            :class="item.isSelected === true ? 'img-selected dis-fl jus-con-cen al-it-cen pad-mar-no' : 'img dis-fl jus-con-cen al-it-cen pad-mar-no'">
                            <img :src="item.imgURL" alt="">
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="main res-py container-xxl">
            <div v-if="!pageIsloading" class="img-con-wrp row pad-mar-no mar-bet-sec">
                <div class="main-img-wrapper col-lg-8 pad-mar-no">
                    <div class="main-img-box dis-fl jus-con-cen al-it-cen">
                        <div class="action-btns-wrapper">
                            <transition name="social-trans">
                                <div v-if="socialIconsAreVisible" class="social-icons-wrapper">
                                    <div @click="share('facebook')" class="btn-social-icon">
                                        <img class="img-social-icon" src="../assets/icons/social-media/fb.png" alt="">
                                    </div>
                                    <div @click="share('whatsapp')" class="btn-social-icon">
                                        <img class="img-social-icon" src="../assets/icons/social-media/wa.png" alt="">
                                    </div>
                                    <div @click="share('gmail')" class="btn-social-icon">
                                        <img class="img-social-icon" src="../assets/icons/social-media/gmail.png" alt="">
                                    </div>
                                    <div @click="share('copy')" class="btn-social-icon">
                                        <img class="img-social-icon" src="../assets/icons/social-media/copy.png" alt="">
                                    </div>
                                </div>
                            </transition>
                            <button @click="imagePreviewIsVisible = true" class="pad-mar-no">
                                <div class="share-expand-img-box" id="expand"></div>
                            </button>
                            <button @click="wishlistCLick()" class="pad-mar-no">
                                <div :class="isWishlist === false ? 'wishlist-img-box' : 'wishlist-img-box-active'"></div>
                            </button>
                            <!-- <button @click="socialIconsAreVisible = !socialIconsAreVisible" class="pad-mar-no">
                                <div class="share-expand-img-box" id="share"></div>
                            </button> -->
                        </div>
                        <img class="main-img-box-img" :src="mainImage" alt="">
                    </div>
                    <div class="sub-images-wrapper dis-fl al-it-cen">
                        <button v-if="!isSubImgMin" @click="imgPrev()" class="btn-left-box pad-mar-no" id="btn-left">
                            <img class="img-fit" src="../assets/icons/backward_theme.png" alt="">
                        </button>
                        <div class="imgs-wrp dis-fl" :class="isSubImgMin === false ? 'imgs-wrp' : 'imgs-wrp-min'"
                            id="sub-imgs-wrp">
                            <button v-for="(item, index) in subImages" :key="index" @click="subImageClick(index)"
                                :class="item.isSelected === true ? 'img-selected dis-fl jus-con-cen al-it-cen pad-mar-no bor-col-theme' : 'img dis-fl jus-con-cen al-it-cen pad-mar-no'">
                                <img :src="item.imgURL" alt="">
                            </button>
                        </div>
                        <button v-if="!isSubImgMin" @click="imgNext()" class="btn-right-box pad-mar-no" id="btn-right">
                            <img class="img-fit" src="../assets/icons/forward_theme.png" alt="">
                        </button>
                    </div>
                    <div class="price-desc-wrapper">
                        <p class="item-name pad-mar-no">{{ title }}</p>
                        <p class="pad-mar-no f-sz-sm">Posted on {{ date }} at {{ time }}, {{ district }}</p><br>
                        <p class="price pad-mar-no f-col-theme">Rs. {{ price }}</p><br>
                        <!-- <p class="pad-mar-no">Condition: Used</p>
                        <p class="pad-mar-no">Model: iPhone 11</p>
                        <p class="pad-mar-no">Brand: Apple</p>
                        <p class="pad-mar-no">Edition: 64GB</p><br>
                        <p class="features pad-mar-no">Features</p>
                        <p>Camera, Bluetooth</p> -->
                        <div v-if="$route.params.itemCategory === 'Cars & Vehicles'">
                            <p class="features pad-mar-no">Year of manufacture: </p>
                            <p class="pad-mar-no">{{ yearOfManufacture }}</p><br>
                            <p class="features pad-mar-no">Milage: </p>
                            <p class="pad-mar-no">{{ milage }}</p><br>
                            <p class="features pad-mar-no">Engine Capacity: </p>
                            <p class="pad-mar-no">{{ engineCapacity }}</p><br>
                            <p class="features pad-mar-no">Fuel Type: </p>
                            <p class="pad-mar-no">{{ fuelType }}</p><br>
                            <p class="features pad-mar-no">Transmittion: </p>
                            <p class="pad-mar-no">{{ transmittion }}</p><br>
                        </div><br>
                        <p class="features pad-mar-no">Description</p>
                        <p class="pad-mar-no" :style="{ whiteSpace: 'pre-line' }">
                            {{ description }}
                        </p><br>
                        <div class="seller-info-mob">
                            <p class="features pad-mar-no">About Seller</p>
                            <button @click="aboutSellerClick()" class="btn-selle-info f-col-theme">{{ sellerName }}</button>
                        </div><br>
                    </div>
                </div>
                <div class="con-det-wrapper col-lg-4 pad-mar-no">
                    <div class="con-det">
                        <div class="seller-info">
                            <p class="pad-mar-no"><b>About Seller</b></p>
                            <button @click="aboutSellerClick()" class="btn-selle-info f-col-theme">{{ sellerName }}</button>
                        </div>
                        <a href="tel:{{ sellerMobile }}" class="con-box pad-mar-no dis-fl">
                            <div class="icon col-theme dis-fl jus-con-cen al-it-cen">
                                <img src="../assets/icons/phone-white.png" alt="">
                            </div>
                            <div class="content dis-fl jus-con-sp-bt al-it-cen">
                                <p class="pad-mar-no">{{ sellerMobile }}</p>
                                <!-- <p class="pad-mar-no f-sz-sm">Click to show phone number</p> -->
                            </div>
                        </a>
                        <button v-if="userId !== adOwnedUserId" @click="chat()" class="con-box pad-mar-no dis-fl">
                            <div class="icon col-theme dis-fl jus-con-cen al-it-cen">
                                <img src="../assets/icons/chat-white.png" alt="">
                            </div>
                            <div class="content dis-fl jus-con-cen">
                                <p class="pad-mar-no">Chat</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

        </div>
        <div v-if="!pageIsloading" class="con-det-wrp-mob dis-fl jus-con-sp-bt al-it-cen">
            <a href="tel:{{ sellerMobile }}" class="dis-fl pad-mar-no col-theme al-it-cen jus-con-cen">
                <img class="icn-phone" src="../assets/icons/phone-white.png" alt="">
                <p class="pad-mar-no">Call</p>
            </a>
            <button @click="chat()" class="dis-fl pad-mar-no col-theme al-it-cen jus-con-cen">
                <img class="icn-chat" src="../assets/icons/chat-white.png" alt="">
                <p class="pad-mar-no">Chat</p>
            </button>
        </div>
    </div>
</template>

<script>

import Navi from '../components/navigation-com.vue';
import Chat from '../components/chat-com.vue';
import ItemSkeleton from '../components/skeleton-loading/item-skel-com.vue';
import axios from 'axios'
import { mapState } from 'vuex'
import jwtDecode from 'jwt-decode'
import Swal from "sweetalert2"
import { useHead } from '@vueuse/head';
import CryptoJS from 'crypto-js';

export default {

    data() {
        return {
            imagePreviewIsVisible: false,
            pageIsloading: true,
            adOwnedUserId: null,
            socialIconsAreVisible: false,
            userId: null,
            mainImage: '',
            convertedImageUrl: null,
            subImages: [],
            title: null,
            date: null,
            time: null,
            price: null,
            district: null,
            yearOfManufacture: null,
            milage: null,
            engineCapacity: null,
            fuelType: null,
            transmittion: null,
            description: null,
            currentIndex: 0,
            chatIsVisible: false,
            imgPreviewIsVisible: false,
            isWishlist: false,
            sellerName: '',
            sellerMobile: '',
            isSubImgMin: false,
        }
    },

    // { property: 'og:title', content: this.title },
    // { property: 'og:description', content: this.description },
    // { property: 'og:image', content: this.mainImage },
    // { property: 'og:image:secure_url', content: this.mainImage }, // For HTTPS
    // { property: 'og:image:type', content: 'image/jpeg' },
    // { property: 'og:url', content: window.location.href }

    components: {
        Navi,
        Chat,
        ItemSkeleton,
    },

    computed: {
        ...mapState(['api', 'idSecKey'])
    },

    async mounted() {
        this.itemInfoLoad();
        this.checkInWishlist();
        // this.convertAndDownload();
        // useHead({
        //     title: `${this.title} | popit.lk`,
        //     meta: [
        //         {
        //             name: 'description',
        //             content: `${this.description} | popit.lk`
        //         },
        //         {
        //             property: 'og:title',
        //             content: `${this.title} | popit.lk`,
        //         },
        //         {
        //             property: 'og:description',
        //             content: `${this.description} | popit.lk`
        //         },
        //         {
        //             property: 'og:type',
        //             content: 'website'
        //         },
        //     ],
        // });
    },

    methods: {

        decrypt(encryptedString) {
            const decrypted = CryptoJS.AES.decrypt(encryptedString.replace(/-/g, '/'), this.idSecKey, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            });

            return decrypted.toString(CryptoJS.enc.Utf8)
        },

        async itemInfoLoad() {
            try {
                let token = localStorage.getItem('token');
                this.userId = await jwtDecode(token).id;
            } catch (error) {
                this.userId = null;
            }
            await axios.get(`${this.api}/get-ad/${this.$route.params.itemCategory}/${this.decrypt(this.$route.params.itemId)}/${this.decrypt(this.$route.params.userId)}`).then((response) => {
                this.mainImage = !response.data[0].ph_1 ? `data:image/jpeg;base64,${response.data[0].ph_clogo}` : `data:image/jpeg;base64,${response.data[0].ph_1}`;
                this.subImages[0] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_1}`, isSelected: true };
                // this.subImages[1] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_2}`, isSelected: false };
                if (response.data[0].ph_2 !== "") {
                    this.subImages[1] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_2}`, isSelected: false }
                }
                if (response.data[0].ph_3 !== "") {
                    this.subImages[2] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_3}`, isSelected: false }
                }
                if (response.data[0].ph_4 !== "") {
                    this.subImages[3] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_4}`, isSelected: false }
                }
                if (response.data[0].ph_5 !== "") {
                    this.subImages[4] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_5}`, isSelected: false }
                }
                if (response.data[0].ph_6 !== "") {
                    this.subImages[5] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_6}`, isSelected: false }
                }

                if (this.subImages.length >= 5) {
                    this.isSubImgMin = false;
                } else {
                    this.isSubImgMin = true;
                }

                this.adOwnedUserId = response.data[0].user_id;

                this.title = response.data[0].title;

                this.price = response.data[0].price !== 0 ? parseInt(response.data[0].price).toLocaleString() : 'Free';

                this.district = response.data[0].district;

                this.description = response.data[0].description;

                this.yearOfManufacture = response.data[0].year_of_manufacture;
                this.milage = response.data[0].milage;
                this.engineCapacity = response.data[0].engine_capacity;
                this.fuelType = response.data[0].fuel_type;
                this.transmittion = response.data[0].transmittion;

                const dateTimeString = response.data[0].saved_at;
                const [dateString, timeString] = dateTimeString.split("T");
                const timeWithoutMillis = timeString.slice(0, -5);
                this.date = dateString;
                this.time = timeWithoutMillis;

                // if(this.subImages.length > 4) {
                //     document.getElementById('btn-left').style.display = 'block';
                //     document.getElementById('btn-right').style.display = 'block';
                // } else {
                //     document.getElementById('btn-left').style.display = 'none';
                //     document.getElementById('btn-right').style.display = 'none';
                // }
            })
            await axios.get(`${this.api}/users/fetch-user-info/${this.decrypt(this.$route.params.userId)}`).then((response) => {
                this.sellerName = response.data.firstName + ' ' + response.data.lastName;
                this.sellerMobile = response.data.mobile;
            })
            this.pageIsloading = false;
            useHead({
                title: this.$route.params.itemName + '| Popit.lk',
                meta: [
                    {
                        name: 'description',
                        content: this.description
                    },
                    {
                        property: 'og:title',
                        content: this.title
                    },
                    {
                        property: 'og:description',
                        content: this.description
                    },
                    {
                        property: 'og:image',
                        content: this.mainImage
                    },
                    {
                        property: 'og:url',
                        content: window.location.href
                    },
                ]
            });
        },

        async checkInWishlist() {
            let token = localStorage.getItem('token');
            const deToken = await jwtDecode(token);
            let category = this.$route.params.itemCategory;

            switch (category) {
                case "Buy & Sell":
                    category = "ads_buy_sell";
                    break;
                case "Cars & Vehicles":
                    category = "ads_cars_vehicles";
                    break;
                case "Real Estate":
                    category = "ads_real_estate";
                    break;
                case "Jobs":
                    category = "ads_jobs";
                    break;
                case "Services":
                    category = "ads_services";
                    break;
                case "Pets":
                    category = "ads_pets";
                    break;
                case "Community":
                    category = "ads_community";
                    break;
                case "Vacation Rentals":
                    category = "ads_vacation_rentals";
                    break;
            }
            await axios.get(`${this.api}/wishlist/get-wishlist-main/${deToken.id}`).then((response) => {
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].category == category && response.data[i].user_id == deToken.id && response.data[i].item_id == this.decrypt(this.$route.params.itemId)) {
                        this.isWishlist = true;
                    }
                }
            })
        },

        async wishlistCLick() {
            if (this.userId !== null) {
                let category = this.$route.params.itemCategory;
                this.isWishlist = !this.isWishlist;

                switch (category) {
                    case "Buy & Sell":
                        category = "ads_buy_sell";
                        break;
                    case "Cars & Vehicles":
                        category = "ads_cars_vehicles";
                        break;
                    case "Real Estate":
                        category = "ads_real_estate";
                        break;
                    case "Jobs":
                        category = "ads_jobs";
                        break;
                    case "Services":
                        category = "ads_services";
                        break;
                    case "Pets":
                        category = "ads_pets";
                        break;
                    case "Community":
                        category = "ads_community";
                        break;
                    case "Vacation Rentals":
                        category = "ads_vacation_rentals";
                        break;
                }
                if (this.isWishlist === true) {
                    await axios.post(`${this.api}/wishlist/add-wishlist`, {
                        category: category,
                        user_id: this.userId,
                        item_id: this.decrypt(this.$route.params.itemId)
                    }).then((response) => {
                        if (response.status !== 200) {
                            Swal.fire({
                                title: "Error occured",
                                text: "Something went wrong. Please try again.",
                                icon: "error",
                            });
                        }
                    })
                } else {
                    await axios.get(`${this.api}/wishlist/remove-wishlist/${category}/${this.userId}/${this.decrypt(this.$route.params.itemId)}`).then((response) => {
                        if (response.status !== 200) {
                            Swal.fire({
                                title: "Error occured",
                                text: "Something went wrong. Please try again.",
                                icon: "error",
                            });
                        }
                    })
                }
            } else {
                Swal.fire({
                    title: "Profile required..!",
                    text: "For use this feature you need to sign in with your account",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sign in",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.$router.push('/login');
                    }
                });
            }
        },

        expandImagesChange(trigger) {
            const mainImgIndex = this.subImages.findIndex(subImage => subImage.imgURL === this.mainImage);
            if (trigger === 'prev') {
                if (mainImgIndex === 0) {
                    this.subImageClick(this.subImages.length - 1);
                } else {
                    this.subImageClick(mainImgIndex - 1);
                }
            } else {
                if (mainImgIndex === this.subImages.length - 1) {
                    this.subImageClick(0);
                } else {
                    this.subImageClick(mainImgIndex + 1);
                }
            }
        },

        share(platform) {
            if (platform === 'facebook') {
                // const url = `https://www.facebook.com/sharer.php?u=${encodeURIComponent('http://localhost:8080' + this.$route.href)}&quote=${encodeURIComponent(this.title)}&picture=${encodeURIComponent(this.mainImage)}`;
                // window.open(url, "_blank");
            } else if (platform === 'whatsapp') {

                console.log(this.mainImage);
            } else if (platform === 'gmail') {
                null
            } else {
                null
            }
        },

        subImageClick(index) {
            for (let i = 0; i < this.subImages.length; i++) {
                this.subImages[i].isSelected = false;
            }
            if (this.currentIndex < index) {
                if (index >= 2) {
                    this.imgNext();
                }
            }
            if (this.currentIndex > index) {
                if (index <= 3) {
                    this.imgPrev();
                }
            }
            this.subImages[index].isSelected = true;
            this.mainImage = this.subImages[index].imgURL;
            this.currentIndex = index;
        },

        imgPrev() {
            document.getElementById("sub-imgs-wrp").scrollBy(-400, 0);
        },

        imgNext() {
            document.getElementById("sub-imgs-wrp").scrollBy(400, 0);
        },

        chat() {
            if (this.userId !== null) {
                this.chatIsVisible = true;
            } else {
                Swal.fire({
                    title: "Profile required..!",
                    text: "For use this feature you need to sign in with your account",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sign in",
                    cancelButtonText: "Cancel",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.$router.push('/login');
                    }
                });
            }
        },

        getCloseOp(value) {
            this.chatIsVisible = value;
        },

        aboutSellerClick() {
            this.$router.push(`/shop/${this.$route.params.userId}`);
        },

        convertAndDownload() {
            const byteCharacters = atob(this.mainImage.split(',')[1]); // Remove data URI part
            const byteArray = new Uint8Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteArray[i] = byteCharacters.charCodeAt(i);
            }

            const blob = new Blob([byteArray], { type: 'image/jpeg' });

            this.convertedImageUrl = URL.createObjectURL(blob);

            // Call a function to download the Blob as a .jpg file
            this.downloadBlob(blob);
        },
        downloadBlob(blob) {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'converted_image.jpg'; // File name
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    },
}
</script>

<style scoped>
.img-prev-main {
    width: 100%;
    height: 100vh;
    padding: 50px;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}

.img-prev-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.btn-close-wrp {
    width: 100%;
    height: 50px;
}

.img-prev-wrapper .btn-close {
    position: absolute;
    right: 0;
}

.img-prev-main-img-btns-wrp {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.img-prev-next {
    width: 80px;
    height: 80px;
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
}

.img-prev-main-img {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-main {
    max-width: 100%;
    max-height: 100%;
}

.seller-info-mob {
    display: none;
}

.img-preview-box {
    width: 100%;
    height: 100%;
    padding: 50px;
    background-color: grey;
    position: fixed;
    top: 0;
    left: 0;
}

.img-preview-box .close-wrp {
    justify-content: flex-end;
}

.img-preview-box .btns-img-wrapper button {
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
}

.main-img-box {
    width: 100%;
    height: 470px;
    margin-bottom: 20px;
    position: relative;
}

.main-img-box-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
}

.action-btns-wrapper {
    width: 55px;
    height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
}

.action-btns-wrapper button {
    width: 25px;
    height: 25px;
    background-color: rgba(255, 255, 255, 0.534);
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wishlist-img-box {
    width: 20px;
    height: 20px;
    background-image: url('../assets/icons/heart-grey.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.wishlist-img-box-active {
    width: 20px;
    height: 20px;
    background-image: url('../assets/icons/heart-red.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#expand {
    background-image: url('../assets/icons/full-screen.png');
}

#share {
    background-image: url('../assets/icons/share.png');
}

.share-expand-img-box {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
}

.social-icons-wrapper {
    height: 150px;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.534);
    position: absolute;
    top: 40px;
    right: 0;
    overflow: hidden;
}

.social-trans-enter-from,
.social-trans-leave-to {
    height: 0%;
}

.social-trans-enter-active,
.social-trans-leave-active {
    transition: all 100ms ease;
}

.btn-social-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.img-social-icon {
    max-width: 100%;
    max-height: 100%;
}

.img-prev-trig-btn-img {
    max-width: 80%;
    max-height: 80%;
    transition: all .3s ease;
}

.img-prev-trig-btn:hover>.img-prev-trig-btn-img {
    max-width: 90%;
    max-height: 90%;
}

.btn-left-box,
.btn-right-box {
    width: 15px;
    border: none;
    background-color: transparent;
}

.sub-images-wrapper {
    width: 550px;
    max-width: 550px;
    height: 100px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.imgs-wrp-min {
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    justify-content: center;
}

.imgs-wrp {
    width: 100%;
    overflow: auto;
    scroll-behavior: smooth;
}

.imgs-wrp::-webkit-scrollbar {
    height: 0px;
}

.imgs-wrp .img {
    width: 100px;
    height: 90px;
    margin: 0 5px;
    border: none;
    overflow: hidden;
    position: relative;
    background-color: rgba(0, 0, 0, 0.103);
    transition: .1s;
    flex-shrink: 0;
}

.imgs-wrp .img-selected {
    width: 100px;
    height: 90px;
    margin: 0 5px;
    border: 3px solid;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.103);
    transition: .1s;
    flex-shrink: 0;
}

.imgs-wrp .img img,
.imgs-wrp .img-selected img {
    max-width: 100%;
    max-height: 100%;
}

.con-det-wrapper {
    padding-left: 20px;
}

.con-det-wrapper .con-box {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.123);
    border-top: 1px solid rgba(0, 0, 0, 0.123);
    background-color: transparent;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;

}

.con-det-wrapper .con-box .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.con-det-wrapper .con-box .icon img {
    max-width: 50%;
}

.con-det-wrapper .con-box .content {
    flex-direction: column;
    align-items: flex-start;
}

.con-det {
    position: sticky;
    top: 0;
}

.btn-selle-info {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    background-color: transparent;
    border: none;
}

.price-desc-wrapper .item-name {
    font-weight: 700;
    font-size: 30px;
}

.price-desc-wrapper .price {
    font-weight: 700;
    font-size: 25px;
}

.price-desc-wrapper .features {
    font-weight: 500;
}

.con-det-wrp-mob {
    display: none;
}

@media only screen and (max-width: 991px) {

    .main-img-box {
        height: 500px;
    }

    .con-det-wrapper {
        padding: 0;
    }
}

@media only screen and (max-width: 767px) {

    .img-prev-main {
        padding: 10px;
    }

    .img-prev-next {
        display: none;
    }

    .main-img-box {
        height: 400px;
    }

    .btn-right-box,
    .btn-left-box {
        display: none;
    }

    .sub-images-wrapper {
        width: 450px;
    }

    .price-desc-wrapper .item-name {
        font-size: 25px;
    }

    .price-desc-wrapper .price {
        font-size: 20px;
    }
}

@media only screen and (max-width: 575px) {

    .main-img-box {
        height: 300px;
    }

    .seller-info-mob {
        display: block;
    }

    .con-det-wrapper {
        display: none;
    }

    .sub-images-wrapper {
        width: 100%;
    }

    .con-det-wrp-mob {
        width: 100%;
        height: 60px;
        padding: 10px;
        background-color: white;
        border-top: 1px solid rgba(0, 0, 0, 0.151);
        display: flex;
        position: sticky;
        bottom: 0;
        left: 0;
    }

    .con-det-wrp-mob button {
        width: 49%;
        height: 100%;
        border: none;
        border-radius: 5px;
        font-weight: 900;
        color: white;
    }

    .con-det-wrp-mob a {
        width: 49%;
        height: 100%;
        border: none;
        border-radius: 5px;
        font-weight: 900;
        color: white;
        text-decoration: none;
    }

    .icn-phone {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .icn-chat {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 400px) {

    .main-img-box {
        height: 250px;
    }
}
</style>