<template>
    <div>
        <transition name="fade">
            <div class="main" v-if="popupIsVisible" v-click-outside="onClickOutside">
                <div class="close-wrp col-theme">
                    <div></div>
                    <p class="p-notifications-header pad-mar-no">Notifications</p>
                    <button @click="close()" class="btn-close btn-close-white"></button>
                </div>
                <div v-if="notifications.length === 0" class="empty-content">
                    <p>Notifications Empty</p>
                </div>
                <div v-if="notifications.length > 0" class="main-box">
                    <div v-for="(notification, index) in notifications" :key="index" class="content">
                        <div :class="notification.is_read === 0 ? 'notification-container-unread' : 'notification-container-read'"
                            @click="notificationClick(index)">
                            <div class="notification-img">
                                <img class="img-notification" :src="notification.image" alt="">
                            </div>
                            <div class="notification-content-box">
                                <div class="title-date-box">
                                    <p class="title pad-mar-no f-sz-sm">{{ notification.title }}</p>
                                    <p class="date pad-mar-no">{{ notification.time }}</p>
                                </div>
                                <p class="desc pad-mar-no f-sz-sm">{{ notification.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <button @click="notificationsClick()" class="btn-mark-as-all-read col-theme">Mark as all read</button>
            </div>
        </transition>
    </div>
</template>
<script>
import vClickOutside from 'click-outside-vue3'
import jwtDecode from 'jwt-decode'
import { mapState } from 'vuex'
import axios from 'axios'

export default {

    props: {
        popupVisible: Boolean,
    },

    data() {
        return {
            popupIsVisible: false,
            inpMessage: '',
            notifications: [],
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {
        ...mapState(['api'])
    },

    watch: {
        popupVisible(value) {
            this.popupIsVisible = value;
            this.getNotifications();
        },
    },

    mounted() {
        this.getNotifications();
    },

    methods: {

        async getNotifications() {
            try {
                const token = localStorage.getItem('token');
                const decoded = jwtDecode(token);
                await axios.get(`${this.api}/notifications/push-notifications/${decoded.id}`).then(async (response) => {
                    for (const notification of response.data) {
                        notification.image = await notification.image !== null ? `data:image/jpeg;base64,${notification.image}` : null;
                        let timeDiff = new Date() - new Date(notification.time);
                        let seconds = Math.floor(timeDiff / 1000);
                        let minutes = Math.floor(seconds / 60);
                        let hours = Math.floor(minutes / 60);
                        let days = Math.floor(hours / 24);
                        if (days > 0) {
                            notification.time = days + ' Day(s) ago';
                        } else if (hours > 0) {
                            notification.time = hours + ' H ' + minutes % 60 + ' Min(s) ago';
                        } else if (minutes > 0) {
                            notification.time = minutes + ' Min(s) ago';
                        } else {
                            notification.time = 'Just now';
                        }
                    }
                    this.notifications = await response.data;
                });
            } catch (e) {
                console.log(e)
            }
        },

        async notificationClick(index) {
            if (this.notifications[index].is_read === 0) {
                this.notifications[index].is_read = 1;
                axios.get(`${this.api}/notifications/notifications-read/single/${this.notifications[index].id}/${0}`).then(() => {
                    this.$emit('update-notification-count');
                });
            }

            if (this.notifications[index].link !== null || this.notifications[index].link !== '') {
                this.$router.push(this.notifications[index].link);
            }
        },

        async notificationsClick() {
            const token = localStorage.getItem('token');
            const decoded = jwtDecode(token);
            for (const notification of this.notifications) {
                notification.is_read = 1;
            }
            axios.get(`${this.api}/notifications/notifications-read/all/${0}/${decoded.id}`).then(() => {
                this.$emit('update-notification-count');
            });
        },

        close() {
            this.$emit('notificationVisibleFromChild', false);
        },

        onClickOutside() {
            this.$emit('notificationVisibleFromChild', false);
        }
    }
}
</script>

<style scoped>
.p-notifications-header {
    color: white;
}

.close-wrp {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    flex-shrink: 0;
}

.main {
    width: 400px;
    height: 80vh;
    margin: 20px;
    padding-bottom: 30px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.363);
    border-radius: 15px;
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 2;
    overflow: hidden;
}

.empty-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-box {
    height: 100%;
    overflow-y: auto;
    position: relative;
}

.btn-mark-as-all-read {
    width: 100%;
    padding: 6px;
    position: absolute;
    bottom: 0;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.19);
    background-color: rgb(235, 235, 235)
}

.notification-container-read {
    padding: 5px;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.145);
    cursor: pointer;
}

.notification-container-unread {
    padding: 5px;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.145);
    background-color: #8ec6412e;
    cursor: pointer;
}

.notification-img {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.167);
    overflow: hidden;
}

.img-notification {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.notification-content-box {
    width: 100%;
}

.title-date-box {
    display: flex;
    justify-content: space-between;
}

.title {
    font-weight: bold;
    margin-bottom: 3px;
}

.date {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.434);
}

.fade-enter-from,
.fade-leave-to {
    right: -100%;
    bottom: -100%;
}

.fade-enter-active,
.fade-leave-active {
    transition: all .3s ease;
}

@media only screen and (max-width: 575px) {
    .main {
        width: 100%;
        height: 80vh;
        margin: 0;
        margin-bottom: 50px;
    }
}
</style>