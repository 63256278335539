<template>
    <div>
        <div class="main res-py">
            <div class="row">
                <div class="col-lg-8">
                    <div class="le-1"></div>
                    <div class="le-2">
                        <div class="le-2-mid">
                            <div class="le-sub"></div>
                            <div class="le-sub"></div>
                            <div class="le-sub"></div>
                            <div class="le-sub"></div>
                            <div class="le-sub"></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="ri">
                        <div class="ri-1"></div>
                        <div class="ri-2"></div>
                        <div class="ri-3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.row {
    padding: 0;
    margin: 0;
}

.main {
    width: 100%;
    height: 100vh;
}

.le-1 {
    width: 100%;
    height: 70vh;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.164);
    color: white;
    animation: ani 2s both infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.le-2 {
    width: 100%;
    height: 13vh;
}

.le-2-mid {
    width: 550px;
    height: 13vh;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.le-sub {
    width: 100px;
    height: 13vh;
    background-color: rgba(0, 0, 0, 0.164);
    color: white;
    animation: ani 2s both infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.ri-1 {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.164);
    color: white;
    animation: ani 2s both infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.ri-2,
.ri-3 {
    width: 100%;
    height: 50px;
    margin-bottom: 5px;
    background-color: rgba(0, 0, 0, 0.164);
    color: white;
    animation: ani 2s both infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@media only screen and (max-width: 991px) {

    .le-1 {
        height: 50vh;
    }

    .ri {
        margin-top: 50px;
    }

    .le-2-mid {
        height: 80px;
    }

    .le-sub {
        width: 100px;
        height: 80px;
    }

    .ri-1 {
        width: 300px;
    }

    /* .le-2-mid {
        width: 100%;
    } */
}

@media only screen and (max-width: 575px) {

    .col-lg-8 , .col-lg-4{
        padding: 0;
    }

    .ri-1 {
        width: 100%;
    }

    .le-2-mid {
        width: 100%;
        overflow: auto;
    }

    .le-2-mid::-webkit-scrollbar {
        display: none;
    }

    .le-sub {
        min-width: 100px;
        height: 80px;
        margin-right: 10px;
    }
}
</style>