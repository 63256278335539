<template>
    <div>
        <Navi />
        <loading v-model:active="isLoading" loader="bars" color="#228B22" />
        <div class="main res-py container-xxl">
            <div class="login dis-fl jus-con-sp-bt">
                <div class="le ">
                    <p class="tx-title-main pad-mar-no">Register</p>
                    <input v-model="email" @input="validate" type="email" id="email"
                        :class="errors.email === '' ? 'input-field' : 'input-field-err'" :required="true"
                        placeholder="Email" />
                    <div class="err f-sz-sm" v-if="errors.email">{{ errors.email }}</div>
                    <input v-model="password" @input="validate" type="password"
                        :class="errors.password === '' ? 'input-field' : 'input-field-err'" :required="true"
                        placeholder="Password" />
                    <div class="err f-sz-sm" v-if="errors.password">{{ errors.password }}</div>
                    <input v-model="confirm" @input="validate" type="password"
                        :class="errors.confirm === '' ? 'input-field' : 'input-field-err'" :required="true"
                        placeholder="Confirm Password" />
                    <div class="err f-sz-sm" v-if="errors.confirm">{{ errors.confirm }}</div>
                    <button @click="signUp()" class="btn-main col-theme">Create</button>
                    <div class="navigate-other-op dis-fl jus-con-cen al-it-cen">
                        <p class="pad-mar-no">Already have an account?</p> &nbsp;
                        <router-link to="/login">Sign in</router-link>
                    </div>
                </div>
                <div class="ri dis-fl al-it-cen">
                    <p class="tx-title-sub">Sign in</p>
                    <p class="tx-content">Already have an account</p>
                    <button @click="this.$router.push('/login');" class="btn-sub col-theme">Sign in</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navi from '../components/navigation-com.vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import jwtDecode from 'jwt-decode'
import { mapState } from 'vuex'
import { useHead } from '@vueuse/head';

export default {

    data() {
        return {
            email: '',
            password: '',
            confirm: '',
            errors: {
                email: '',
                password: '',
                confirm: ''
            },
            isLoading: false,
        }
    },

    components: {
        Navi,
        Loading,
    },

    computed: {
        ...mapState(['api'])
    },

    mounted() {
        useHead({
            title: `Register | popit.lk`,
            meta: [
                {
                    name: 'description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:title',
                    content: `Register | popit.lk`,
                },
                {
                    property: 'og:description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
            ],
        });
    },

    methods: {
        async signUp() {
            this.validate();
            if (this.errors.email === '' && this.errors.password === '' && this.errors.confirm === '') {
                this.isLoading = true;
                await axios.post(`${this.api}/users/register`, {
                    email: this.email,
                    password: this.password,
                }).then(async (response) => {
                    this.isLoading = false;
                    localStorage.setItem('token', response.data.token);
                    try {
                        const decoded = await jwtDecode(response.data.token);
                        await axios.get(`${this.api}/users/fetch-user-info/${decoded.id}`).then((response) => {
                            let profile = `data:image/jpeg;base64,${response.data.profile}`
                            localStorage.setItem('profileImg', profile);
                        });
                    } catch (err) {
                        console.log(err);
                    }
                    window.location.href = '/user-info-add';
                }).catch(error => {
                    this.isLoading = false;
                    if (error.response.status === 500) {
                        Swal.fire({
                            title: 'Login Error',
                            text: "An account with the email that you are entered already exist. Please use a different one",
                            icon: 'error',
                        });
                    } else {
                        Swal.fire({
                            title: 'Login Error',
                            text: "Something went wrong. Please try again later",
                            icon: 'error',
                        });
                    }
                });
            }
        },

        validate() {
            if (!this.email) {
                this.errors.email = 'Email is required!';
            } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                this.errors.email = 'Invalid email format!';
            } else {
                this.errors.email = '';
            }
            if (!this.password) {
                this.errors.password = 'Password is Required!';
            } else if (this.password.length < 6) {
                this.errors.password = 'Password is too short!'
            } else {
                this.errors.password = '';
            }
            if (this.password !== this.confirm) {
                this.errors.confirm = 'Password did not matched!'
            } else {
                this.errors.confirm = '';
            }
        }
    }
}
</script>

<style scoped>
.input-field {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.212);
    border-radius: 5px;
    outline: none;
}

.input-field-err {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    border: 1px solid red;
    border-radius: 5px;
    outline: none;
}

.input-field:focus {
    border: 1px solid rgba(0, 0, 255, 0.534);
}

.err {
    color: red;
}

.main {
    min-height: 80vh;
    padding-top: 50px;
    background-color: rgba(0, 0, 0, 0.050);
}

.login {
    align-content: flex-start;
    align-items: flex-start;
}

.le {
    width: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
}

.ri {
    width: 400px;
    padding: 50px;
    margin-left: 30px;
    flex-direction: column;
    background-color: white;
}

.tx-title-main {
    font-size: 25px;
    font-weight: 500;
}

.for-pw {
    margin-top: 15px;
    border: none;
    font-weight: 500;
    color: rgb(0, 110, 255);
    background-color: transparent;
}

.btn-main {
    width: 150px;
    height: 50px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 500;
}

.tx-title-sub {
    font-size: 20px;
    font-weight: 500;
}

.tx-content {
    text-align: center;
}

.btn-sub {
    width: 140px;
    height: 40px;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 500;
}

.navigate-other-op {
    display: none;
}

@media only screen and (max-width: 767px) {

    .main {
        padding-top: 20px;
    }

    .le {
        padding: 20px;
    }

    .ri {
        display: none;
    }

    .navigate-other-op {
        width: 100%;
        margin-top: 30px;
        display: flex;
    }
}

@media only screen and (max-width: 575px) {
    .btn-main {
        width: 100%;
    }
}
</style>