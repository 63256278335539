<template>
    <div>
        <transition name="form-fade-trans">
            <div v-if="popupIsVisible" class="main">
                <div class="content-box">
                    <div class="opt-btn-box">
                        <button @click="adDeleteClick()" class="btn-delete">
                            <img src="../assets/icons/pro-ad-delete-white.png" alt="">
                        </button>
                    </div>
                    <div class="close-wrp">
                        <button @click="close()" class="btn-close"></button>
                    </div>
                    <div class="form-box">
                        <div class="main-info-box col-theme">
                            <div class="le">
                                <p v-if="cdDays == 0 && cdHours == 0 && cdMins == 0 && cdSecs == 0"
                                    class="txt-end pad-mar-no">Auction Ended</p>
                                <div v-else class="le">
                                    <div class="val-box">
                                        <div class="timer-count-box">
                                            <p class="p-timer pad-mar-no">{{ cdDays }}</p>
                                        </div>
                                        <p class="p-timer p-colon pad-mar-no">:</p>
                                        <div class="timer-count-box">
                                            <p class="p-timer pad-mar-no">{{ cdHours }}</p>
                                        </div>
                                        <p class="p-timer p-colon pad-mar-no">:</p>
                                        <div class="timer-count-box">
                                            <p class="p-timer pad-mar-no">{{ cdMins }}</p>
                                        </div>
                                        <p class="p-timer p-colon pad-mar-no">:</p>
                                        <div class="timer-count-box">
                                            <p class="p-timer pad-mar-no">{{ cdSecs }}</p>
                                        </div>
                                    </div>
                                    <div class="name-box">
                                        <p class="txt-auction-end pad-mar-no">Auction End</p>
                                    </div>
                                </div>
                            </div>
                            <div class="hor-line-ver"></div>
                            <div class="mid">
                                <div class="val-box">
                                    <p class="p-timer pad-mar-no">LKR {{ (bids[bids.length - 1].price) }}</p>
                                </div>
                                <div class="name-box">
                                    <p class="pad-mar-no">Current Bid</p>
                                </div>
                            </div>
                            <div class="hor-line-ver"></div>
                            <div class="ri">
                                <div class="val-box">
                                    <p class="p-timer pad-mar-no">{{ bids.length - 1 }}</p>
                                </div>
                                <div class="name-box">
                                    <p class="pad-mar-no">Total Bids</p>
                                </div>
                            </div>
                        </div>
                        <p class="p-title pad-mar-no">{{ itemInfo.title }}</p>
                        <p><span class="sp-col-theme f-col-theme">Starting Price:</span> LKR.
                            {{ bids[0].price }}
                            &nbsp; <span class="sp-col-theme">Category:</span> {{ itemInfo.main_cat }}, {{
                                itemInfo.condition_1 }}
                        </p>
                        <p>{{ itemInfo.description }}</p>
                        <div class="imgs-box row pad-mar-no">
                            <div v-for="(item, index) in itemImages" :key="index" class="col-lg-4 col-md-4 col-sm-4 col-6">
                                <div class="img-box">
                                    <img :src="item" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="highest-bids-box">
                            <div class="highest-bids-head-box">
                                <div class="icon-box col-theme">
                                    <img src="../assets/icons/bid-ham.png" alt="">
                                </div>
                                <p class="pad-mar-no">Highest Bids</p>
                            </div>
                            <p v-if="bids.length === 0">No one bid to this item yet</p>
                            <div v-if="bids.length > 1" @click="bidUserProfileClick('first')" class="highest-bid-box">
                                <div class="bid-box-le">
                                    <div class="icon">
                                        <img src="../assets/icons/place-badge-1st.png" alt="">
                                    </div>
                                    <div class="bid-box-mid">
                                        <div class="user-name">{{ bids[bids.length - 1].userName }}</div>
                                        <p class="user-email pad-mar-no f-sz-sm">{{ bids[bids.length - 1].email }}</p>
                                    </div>
                                </div>
                                <div class="bid-box-ri">
                                    <p class="f-sz-sm pad-mar-no">LKR</p>&nbsp;
                                    <p class="pad-mar-no">{{ bids[bids.length - 1].price }}</p>
                                </div>
                            </div>
                            <div v-if="bids.length > 2" @click="bidUserProfileClick('second')" class="highest-bid-box">
                                <div class="bid-box-le">
                                    <div class="icon">
                                        <img src="../assets/icons/place-badge-2nd.png" alt="">
                                    </div>
                                    <div class="bid-box-mid">
                                        <div class="user-name">{{ bids[bids.length - 2].userName }}</div>
                                        <p class="user-email pad-mar-no f-sz-sm">{{ bids[bids.length - 2].email }}</p>
                                    </div>
                                </div>
                                <div class="bid-box-ri">
                                    <p class="f-sz-sm pad-mar-no">LKR</p>&nbsp;
                                    <p class="pad-mar-no">{{ bids[bids.length - 2].price }}</p>
                                </div>
                            </div>
                            <div v-if="bids.length > 3" @click="bidUserProfileClick('third')" class="highest-bid-box">
                                <div class="bid-box-le">
                                    <div class="icon">
                                        <img src="../assets/icons/place-badge-3rd.png" alt="">
                                    </div>
                                    <div class="bid-box-mid">
                                        <div class="user-name">{{ bids[bids.length - 3].userName }}</div>
                                        <p class="user-email pad-mar-no f-sz-sm">{{ bids[bids.length - 3].email }}</p>
                                    </div>
                                </div>
                                <div class="bid-box-ri">
                                    <p class="f-sz-sm pad-mar-no">LKR</p>&nbsp;
                                    <p class="pad-mar-no">{{ bids[bids.length - 3].price }}</p>
                                </div>
                            </div>
                            <div v-if="bidsForOthers.length !== 0 && !showMoreIsVisible" class="show-all-btn-wrp">
                                <button class="f-col-theme" @click="showMoreIsVisible = true">show more</button>
                            </div>
                            <div v-if="showMoreIsVisible" class="all-bids-box">
                                <div v-for="(bid, index) in bidsForOthers" :key="index" @click="bidUserProfileClick(index)"
                                    class="highest-bid-box">
                                    <div class="bid-box-le">
                                        <div class="icon"></div>
                                        <div class="bid-box-mid">
                                            <div class="user-name">{{ bid.userName }}</div>
                                            <p class="user-email pad-mar-no f-sz-sm">{{ bids[bids.length - 1].email }}</p>
                                        </div>
                                    </div>
                                    <div class="bid-box-ri">
                                        <p class="f-sz-sm pad-mar-no">LKR</p>&nbsp;
                                        <p class="pad-mar-no">{{ bid.price }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import { mapState } from 'vuex'
import Swal from "sweetalert2"

export default {
    props: {
        popupVisible: Boolean,
        mainCategory: String,
        itemId: Number,
    },

    data() {
        return {
            popupIsVisible: false,
            parentMainCat: null,
            parentItemId: null,
            showMoreIsVisible: false,
            itemInfo: [],
            bids: [{}],
            bidsForOthers: [{}],
            itemImages: [],
            cdDays: '--',
            cdHours: '--',
            cdMins: '--',
            cdSecs: '--',
            intervalId: null,
        }
    },

    computed: {
        ...mapState(['api'])
    },

    watch: {
        popupVisible(val) {
            this.popupIsVisible = val;
        },

        mainCategory(val) {
            this.parentMainCat = val;
        },

        itemId(val) {
            this.parentItemId = val;
        },

        parentMainCat() {
            this.itemInfoLoad();
        }
    },

    methods: {
        close() {
            this.popupIsVisible = false;
            this.$emit('bidPopupVisibleToParent', false);
            this.itemInfo = [];
            this.bids = [{}];
            this.itemImages = [];
            this.cdDays = '--';
            this.cdHours = '--';
            this.cdMins = '--';
            this.cdSecs = '--';
            clearInterval(this.intervalId);
        },

        async itemInfoLoad() {
            await axios.get(`${this.api}/get-ad/${this.parentMainCat}/${this.parentItemId}/1}`).then(async (response) => {
                this.bids = await JSON.parse(response.data[0].bids);
                for (const bid of this.bids) {
                    bid.price = bid.price.toLocaleString();
                    await axios.get(`${this.api}/users/fetch-user-info/${bid.userId}`).then((response) => {
                        bid.email = response.data.email;
                        bid.userName = response.data.firstName + ' ' + response.data.lastName;
                    })
                }

                if (this.bids.length > 4) {
                    this.bidsForOthers = this.bids.slice(1, -3);
                }

                this.itemInfo = response.data[0];
                if (response.data[0].ph_1 !== "") {
                    response.data[0].ph_1 = `data:image/jpeg;base64,${response.data[0].ph_1}`;
                    this.itemImages.push(response.data[0].ph_1);
                }
                if (response.data[0].ph_2 !== "") {
                    response.data[0].ph_2 = `data:image/jpeg;base64,${response.data[0].ph_2}`;
                    this.itemImages.push(response.data[0].ph_2);
                }
                if (response.data[0].ph_3 !== "") {
                    response.data[0].ph_3 = `data:image/jpeg;base64,${response.data[0].ph_3}`;
                    this.itemImages.push(response.data[0].ph_3);
                }
                if (response.data[0].ph_4 !== "") {
                    response.data[0].ph_4 = `data:image/jpeg;base64,${response.data[0].ph_4}`;
                    this.itemImages.push(response.data[0].ph_4);
                }
                if (response.data[0].ph_5 !== "") {
                    response.data[0].ph_5 = `data:image/jpeg;base64,${response.data[0].ph_5}`;
                    this.itemImages.push(response.data[0].ph_5);
                }
                if (response.data[0].ph_6 !== "") {
                    response.data[0].ph_6 = `data:image/jpeg;base64,${response.data[0].ph_6}`;
                    this.itemImages.push(response.data[0].ph_6);
                }

                this.cdDays = this.cdDays.toString().padStart(2, 0);
                this.cdHours = this.cdHours.toString().padStart(2, 0);
                this.cdMins = this.cdMins.toString().padStart(2, 0);
                this.cdSecs = this.cdSecs.toString().padStart(2, 0);

                this.intervalId = setInterval(() => {
                    let savedDate = new Date(response.data[0].saved_at);
                    let expireAt = savedDate.getTime() + (response.data[0].duration * 24 * 60 * 60 * 1000)
                    let distance = new Date(expireAt) - new Date();
                    this.cdDays = (Math.floor(distance / (1000 * 60 * 60 * 24))).toString().padStart(2, 0);
                    this.cdHours = (Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).toString().padStart(2, 0);
                    this.cdMins = (Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).toString().padStart(2, 0);
                    this.cdSecs = (Math.floor((distance % (1000 * 60)) / 1000)).toString().padStart(2, 0);
                    if (this.cdSecs <= -1) {
                        this.cdDays = (0).toString().padStart(2, 0);
                        this.cdHours = (0).toString().padStart(2, 0);
                        this.cdMins = (0).toString().padStart(2, 0);
                        this.cdSecs = (0).toString().padStart(2, 0);
                    }
                }, 1000);

                // this.cdDays = this.cdDays.toString().padStart(2, 0);
                // this.cdHours = this.cdHours.toString().padStart(2, 0);
                // this.cdMins = this.cdMins.toString().padStart(2, 0);
                // this.cdSecs = this.cdSecs.toString().padStart(2, 0);
            })
        },

        // setCountdown() {
        //     let savedDate = new Date(this.saved_at);
        //     console.log(savedDate)
        //     let expireAt = savedDate.getTime() + (35 * 24 * 60 * 60 * 1000)
        //     console.log(new Date(expireAt))
        //     let distance = new Date(expireAt) - new Date();
        //     const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        //     const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        //     const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        //     const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        //     console.log(days + " : " + hours + " : " + minutes + " : " + seconds);
        // },

        bidUserProfileClick(index) {
            if(index === 'first') {
                this.$router.push(`/shop/${this.bids[this.bids.length - 1].userId}`)
            } else if(index === 'second') {
                this.$router.push(`/shop/${this.bids[this.bids.length - 2].userId}`)
            } else if(index === 'third') {
                this.$router.push(`/shop/${this.bids[this.bids.length - 3].userId}`)
            } else {
                this.$router.push(`/shop/${this.bidsForOthers[index].userId}`)
            }
        },

        adDeleteClick() {
            Swal.fire({
                title: "Warning..!",
                text: "Are you sure want to delete this ad? ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                cancelButtonText: "NO",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    await axios.get(`${this.api}/ads-op/delete/${this.parentMainCat}/${this.parentItemId}`);
                    this.close();
                }
            });

        }
    }
}
</script>
<style scoped>
.main {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.527);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content-box {
    width: 500px;
    height: 500px;
    padding: 10px;
    padding-right: 0;
    border-radius: 10px;
    background-color: white;
    position: relative;
}

.form-box {
    width: 100%;
    height: 460px;
    padding-right: 10px;
    overflow-x: auto;
}

.form-box::-webkit-scrollbar {
    width: .5vw;
}

.form-box::-webkit-scrollbar-thumb {
    background-color: #228b2221;
    border-radius: 20px;
}

.form-box::-webkit-scrollbar-thumb:hover {
    background-color: #228b2221;
}

.form-box::-webkit-scrollbar-track {
    background-color: transparent;
}

.opt-btn-box {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.opt-btn-box button {
    width: 40px;
    height: 40px;
    padding: 0 10px;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 1;
}

.opt-btn-box button img {
    max-width: 100%;
    max-height: 100%;
}

.opt-btn-box .btn-edit {
    background-color: rgb(255, 208, 0);
}

.opt-btn-box .btn-dissable {
    background-color: orangered;
}

.opt-btn-box .btn-delete {
    background-color: red;
}

.close-wrp {
    padding: 0 15px;
    display: flex;
    justify-content: flex-end;
}

.main-info-box {
    width: 100%;
    /* height: ; */
    margin-bottom: 20px;
    padding: 20px 10px;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.txt-end {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}

.le,
.mid,
.ri {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.le,
.mid {
    width: 50%;

}

.ri {
    width: 150px;
}

.val-box {
    height: 40px;
    display: flex;
}

.timer-count-box {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.233);
}

.txt-auction-end {
    text-align: center;
}

.p-timer {
    font-weight: 500;
    font-size: 25px;
}

.p-colon {
    margin: 0 2px;
}

.hor-line-ver {
    width: 1px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.233);
}

.p-title {
    font-weight: 900;
    font-size: 20px;
}

.highest-bids-box {
    margin-top: 30px;
}

.highest-bids-head-box {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.highest-bids-head-box {
    font-weight: 900;
    font-size: 18px;
}

.icon-box {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border-radius: 50%;
}

.highest-bid-box {
    width: 400px;
    padding: 0 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.123);
    cursor: pointer;
}

.bid-box-le {
    display: flex;
    align-items: center;
}

.bid-box-le .icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bid-box-le .icon img {
    max-width: 100%;
    max-height: 100%;
}

.bid-box-mid .user-email {
    color: rgba(0, 0, 0, 0.419);
}

.bid-box-ri {
    display: flex;
    align-items: flex-end;
}

.show-all-btn-wrp {
    width: 400px;
    display: flex;
    justify-content: flex-end;
}

.show-all-btn-wrp button {
    margin-bottom: 10px;
    border: none;
    background-color: white;
}

.col-lg-4 {
    padding: 3px;
}

.imgs-box .img-box {
    width: 100%;
    height: 100px;
    margin-bottom: 2px;
    /* background-color: yellow; */
    position: relative;
    overflow: hidden;
}

.imgs-box .img-box img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
}

.form-fade-trans-enter-from,
.form-fade-trans-leave-to {
    opacity: 0;
}

.form-fade-trans-enter-active,
.form-fade-trans-leave-active {
    transition: all .3s ease;
}

@media only screen and (max-width: 575px) {

    .main-info-box {
        flex-direction: column;
    }

    .content-box {
        width: 100%;
        height: 100vh;
        border-radius: 0;
    }

    .highest-bid-box {
        width: 100%;
    }

    .form-box {
        width: 100%;
        height: 90vh;
    }

    .le,
    .mid {
        margin-bottom: 20px;
    }

    .imgs-box .img-box,
    .skel-img-box {
        height: 130px;
    }
}
</style>