<template>
    <div>
        <div class="main pad-mar-no container-xxl">
            <Navi />
            <transition name="filter-trans">
                <div v-if="mobileFilterIsVisible" class="filter-wrp-sm">
                    <div class="btn-wrapper dis-fl">
                        <button @click="(mobileFilterIsVisible = false)" class="btn-close"></button>
                    </div>
                    <div class="filter-wrp-lg">
                        <div :class="$route.params.main === 'Jobs' ? 'filter-dissable' : ''">
                            <p class="f-sz-sm pad-mar-no">Short result by</p>
                            <select v-model="filterShortResult" name="" id="">
                                <option value="newestTop">Date: Newest on top</option>
                                <option value="oldestTop">Date: Oldest on top</option>
                                <option value="priceHigh">Price: High to low</option>
                                <option value="priceLow">Price: Low to high</option>
                            </select><br><br>
                            <p class="f-sz-sm pad-mar-no">Short item type by</p>
                            <select v-model="filterShortType" name="" id="">
                                <option value="all">All</option>
                                <option value="new">New</option>
                                <option value="used">Used</option>
                            </select><br><br>
                            <div
                                :class="$route.params.main === 'Buy & Sell' || $route.params.main === 'Cars & Vehicles' || $route.params.main === 'Pets' || $route.params.main === 'Vapes' ? '' : 'filter-dissable'">
                                <p class="f-sz-sm pad-mar-no">Short ad type by</p>
                                <select v-model="filterShortAd" name="" id="">
                                    <option value="all">All</option>
                                    <option value="normal">Normal</option>
                                    <option value="bid">Bid</option>
                                </select><br><br>
                            </div>
                            <p class="f-sz-sm pad-mar-no">Price (Rs)</p>
                            <div class="dis-fl jus-con-sp-bt">
                                <input v-model="priceMin" type="text" name="" id="" placeholder="Min">
                                <input v-model="priceMax" type="text" name="" id="" placeholder="Max">
                            </div><br>
                        </div>
                        <!-- <div class="filter-dissable">
                            <p class="f-sz-sm pad-mar-no">Job Type</p>
                            <select v-model="filterJobType" name="" id="">
                                <option value="All">All</option>
                                <option value="Accounting & Management">Accounting & Management</option>
                                <option value="Child Care">Child Care</option>
                                <option value="Bar, Food & Hospitality">Bar, Food & Hospitality</option>
                                <option value="Cleaning & Housekeeping">Cleaning & Housekeeping</option>
                                <option value="Construction & Trades">Construction & Trades</option>
                                <option value="Customer Service">Customer Service</option>
                                <option value="Cannabis Sector">Cannabis Sector</option>
                                <option value="Drivers & Security">Drivers & Security</option>
                                <option value="General Labour">General Labour</option>
                                <option value="Graphic & Web Design">Graphic & Web Design</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Hair Stylist & Salon">Hair Stylist & Salon</option>
                                <option value="Office Manager & Receptionist">Office Manager & Receptionist</option>
                                <option value="Part Time & Students">Part Time & Students</option>
                                <option value="Programmers & Computer">Programmers & Computer</option>
                                <option value="Sales & Retail Sales">Sales & Retail Sales</option>
                                <option value="TV, Media, & Fashion">TV, Media, & Fashion</option>
                                <option value="Other">Other</option>
                            </select><br><br>
                        </div> -->
                        <button @click="loadItems(true)" class="pad-mar-no col-theme">FILTER</button>
                    </div>
                </div>
            </transition>
            <div class="top-info dis-fl jus-con-sp-bt res-py">
                <router-link class="a-txt-dec-no f-sz-sm dis-fl al-it-cen f-col-theme" to="/">
                    <img src="../assets/icons/backward_theme.png" alt="">
                    <p class="pad-mar-no">Back to Home</p>
                </router-link>
                <button @click="(mobileFilterIsVisible = true)" class="btn-filter pad-mar-no">
                    <img src="../assets/icons/filter.png" alt="">
                </button>
            </div><br>
            <p class="res-py">Search results of "{{ this.$route.params.srchKey }}" in "{{ $route.params.main }}"</p>
            <div class="items-filter-lg-wrapper dis-fl jus-con-sp-bt res-py">
                <div class="lg-filter">
                    <div class="filter-wrp-lg">
                        <div :class="$route.params.main === 'Jobs' ? 'filter-dissable' : ''">
                            <p class="f-sz-sm pad-mar-no">Short result by</p>
                            <select v-model="filterShortResult" name="" id="">
                                <option value="newestTop">Date: Newest on top</option>
                                <option value="oldestTop">Date: Oldest on top</option>
                                <option value="priceHigh">Price: High to low</option>
                                <option value="priceLow">Price: Low to high</option>
                            </select><br><br>
                            <p class="f-sz-sm pad-mar-no">Short item type by</p>
                            <select v-model="filterShortType" name="" id="">
                                <option value="all">All</option>
                                <option value="new">New</option>
                                <option value="used">Used</option>
                            </select><br><br>
                            <div
                                :class="$route.params.main === 'Buy & Sell' || $route.params.main === 'Cars & Vehicles' || $route.params.main === 'Pets' || $route.params.main === 'Vapes' ? '' : 'filter-dissable'">
                                <p class="f-sz-sm pad-mar-no">Short ad type by</p>
                                <select v-model="filterShortAd" name="" id="">
                                    <option value="all">All</option>
                                    <option value="normal">Normal</option>
                                    <option value="bid">Bid</option>
                                </select><br><br>
                            </div>
                            <p class="f-sz-sm pad-mar-no">Price (Rs)</p>
                            <div class="dis-fl jus-con-sp-bt">
                                <input v-model="priceMin" type="text" name="" id="" placeholder="Min">
                                <input v-model="priceMax" type="text" name="" id="" placeholder="Max">
                            </div><br>
                        </div>
                        <!-- <div>
                            <p class="f-sz-sm pad-mar-no">Job Type</p>
                            <select v-model="filterJobType" name="" id="">
                                <option value="All">All</option>
                                <option value="Accounting & Management">Accounting & Management</option>
                                <option value="Child Care">Child Care</option>
                                <option value="Bar, Food & Hospitality">Bar, Food & Hospitality</option>
                                <option value="Cleaning & Housekeeping">Cleaning & Housekeeping</option>
                                <option value="Construction & Trades">Construction & Trades</option>
                                <option value="Customer Service">Customer Service</option>
                                <option value="Cannabis Sector">Cannabis Sector</option>
                                <option value="Drivers & Security">Drivers & Security</option>
                                <option value="General Labour">General Labour</option>
                                <option value="Graphic & Web Design">Graphic & Web Design</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Hair Stylist & Salon">Hair Stylist & Salon</option>
                                <option value="Office Manager & Receptionist">Office Manager & Receptionist</option>
                                <option value="Part Time & Students">Part Time & Students</option>
                                <option value="Programmers & Computer">Programmers & Computer</option>
                                <option value="Sales & Retail Sales">Sales & Retail Sales</option>
                                <option value="TV, Media, & Fashion">TV, Media, & Fashion</option>
                                <option value="Other">Other</option>
                            </select><br><br>
                        </div> -->
                        <button @click="loadItems(true)" class="pad-mar-no col-theme">FILTER</button>
                    </div>
                </div>

                <div class="items-wrp-lg">
                    <div v-if="itemsAreLoading" class="skel-wrp">
                        <ItemsSkeleton />
                    </div>
                    <p v-if="displayItemsLast.length === 0" class="no-items" id="no-items">No Item(s) Found</p>
                    <div v-for="(item, index) in displayItemsLast" :key="index" @click="itemClick(index)">
                        <!-- <a @click.stop href="/bla" v-if="index === 0 || index % 3 === 0">google ad</a> -->
                        <div class="item-wrp-lg pad-mar-no dis-fl a-txt-dec-no">
                            <div class="img-box dis-fl jus-con-cen">
                                <img :src="item.ph_sm" alt="">
                            </div>
                            <div class="content-box">
                                <p class="item-name pad-mar-no">{{ item.title }}</p>
                                <p class="condition-1 pad-mar-no f-sz-sm f-col-theme">{{ item.condition_1 }}</p>
                                <span
                                    :class="(item.isMember === true ? 'membership pad-mar-no f-sz-sm' : 'membership-none')">MEMBER</span>
                                <p class="pad-mar-no">{{ item.district }}</p>
                                <p v-if="!item.is_bid && item.main_cat !== 'Jobs'" class="price pad-mar-no f-col-theme">Rs. {{ item.price }}</p>
                                <div v-if="item.is_bid" class="bid-info-wrp">
                                    <div class="bid-icon-box">
                                        <img src="../assets/icons/BID.png" alt="">
                                    </div>
                                    <p class="price pad-mar-no f-col-theme">Current Bid &nbsp; Rs {{ item.bids }}</p>
                                </div>
                                <div class="dis-fl jus-con-sp-bt al-it-cen f-sz-sm">
                                    <p class="pad-mar-no"></p>
                                    <p class="pad-mar-no">{{ item.saved_at }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- <a @click.stop href="/bla" v-if="displayItemsLast.length - 1 === index">google ad</a> -->
                    </div>
                    <div ref="endOfList"></div>
                    <div v-if="itemLimitLoading">
                        <ItemsSkeletonLimit />
                    </div>
                </div>
                <div v-if="!itemsAreLoading" class="items-wrp-sm">
                    <div class="items-wrapper-left-wrapper-sm">
                        <div v-for="(item, index) in displayItemsLast" :key="index">
                            <div v-if="index % 2 === 0" class="items-sm dis-fl a-txt-dec-no" @click="itemClick(index)">
                                <img :src="item.ph_sm" alt="">
                                <p class="item-name pad-mar-no f-sz-sm">{{ item.title }}</p>
                                <p class="pad-mar-no f-sz-sm f-col-theme">{{ item.condition_1 }}</p>
                                <p v-if="!item.is_bid" class="price pad-mar-no f-sz-sm f-col-theme">Rs. {{ item.price }}</p>
                                <div v-if="item.is_bid" class="bid-info-wrp">
                                    <div class="bid-icon-box">
                                        <img src="../assets/icons/BID.png" alt="">
                                    </div>
                                    <p class="price pad-mar-no f-sz-sm f-col-theme">CB &nbsp; Rs. {{ item.bids }}</p>
                                </div>
                                <p class="location pad-mar-no f-sz-sm">{{ item.district }}</p>
                                <div class="dis-fl jus-con-sp-bt">
                                    <p class="pad-mar-no f-sz-sm"></p>
                                    <p class="time-status pad-mar-no">{{ item.saved_at }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="items-wrapper-right-wrapper-sm">
                        <div v-for="(item, index) in displayItemsLast" :key="index">
                            <div v-if="index % 2 === 1" class="items-sm dis-fl a-txt-dec-no" @click="itemClick(index)">
                                <img :src="item.ph_sm" alt="">
                                <p class="item-name pad-mar-no f-sz-sm">{{ item.title }}</p>
                                <p class="pad-mar-no f-sz-sm f-col-theme">{{ item.condition_1 }}</p>
                                <p v-if="!item.is_bid" class="price pad-mar-no f-sz-sm f-col-theme">Rs. {{ item.price }}</p>
                                <div v-if="item.is_bid" class="bid-info-wrp">
                                    <div class="bid-icon-box">
                                        <img src="../assets/icons/BID.png" alt="">
                                    </div>
                                    <p class="price pad-mar-no f-sz-sm f-col-theme">CB &nbsp; Rs. {{ item.bids }}</p>
                                </div>
                                <p class="location pad-mar-no f-sz-sm">{{ item.district }}</p>
                                <div class="dis-fl jus-con-sp-bt">
                                    <p class="pad-mar-no f-sz-sm"></p>
                                    <p class="time-status pad-mar-no">{{ item.saved_at }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="items-skel-sm">
                <div v-if="itemsAreLoading" class="skel-wrp">
                    <ItemsSkeleton />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Navi from '../components/navigation-com.vue';
import axios from 'axios';
// import Loading from 'vue-loading-overlay';
import ItemsSkeleton from '../components/skeleton-loading/items-skel-com.vue';
import ItemsSkeletonLimit from '../components/skeleton-loading/items-limit-skel-com.vue';
import { mapState } from 'vuex';
import { watch } from 'vue';
import { useHead } from '@vueuse/head';
import CryptoJS from 'crypto-js';

export default {

    data() {
        return {
            searchKey: this.$route.params.srchKey,
            itemsAreLoading: true,
            isLoading: false,
            mobileFilterIsVisible: false,
            displayItems: [],
            itemIsEmpty: false,
            page: 1,
            pageSize: 10,
            filterShortResult: 'newestTop',
            filterShortType: 'all',
            filterShortAd: 'all',
            priceMin: '',
            priceMax: '',
            displayItemsLast: [],
            filterSType: [], //get filter short type items
            filterPrice: [], //get filter price items
        }
    },

    components: {
        Navi,
        ItemsSkeleton,
        ItemsSkeletonLimit,
        // Loading,
    },

    computed: {
        ...mapState(['api', 'idSecKey'])
    },

    created() {
        watch(() => this.$route.params.srchKey,
            () => {
                this.loadItems(true);
            }
        );
    },

    // watch: {
    //     searchKey(val) {
    //        console.log(val);
    //     }
    // },

    mounted() {
        this.loadItems(false);
        this.addScrollListener();
        useHead({
            title: `${this.$route.params.srchKey} | popit.lk`,
            meta: [
                {
                    name: 'description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:title',
                    content: `${this.$route.params.srchKey} | popit.lk`,
                },
                {
                    property: 'og:description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
            ],
        });
    },

    methods: {

        encrypt(plainText) {
            const encrypted = CryptoJS.AES.encrypt(plainText, this.idSecKey, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            });
            return encrypted.toString().replace(/\//g, '-');
        },

        async loadItems(isFilter) {
            this.page = isFilter === true ? 1 : this.page;
            this.isLoading = true;
            let location = localStorage.getItem("selectedLocation");
            let category = this.$route.params.main;
            // let separator = " - ";
            let srchKey = this.$route.params.srchKey;

            axios.get(`${this.api}/get-ad-search/${category}/${srchKey}/${location}?page=${this.page}&pageSize=${this.pageSize}&filterShortResult=${this.filterShortResult}`).then((response) => {
                this.isLoading = false;
                this.itemsAreLoading = false;
                if (response.data.length === 0 && this.page === 1) {
                    this.itemIsEmpty = true;
                    this.displayItemsLast = [];
                } else {
                    this.displayItems = response.data;
                    for (let i = 0; i < this.displayItems.length; i++) {
                        if(this.displayItems[i].is_bid === undefined) {
                            this.displayItems[i].is_bid = null;
                        }

                        let timeDiff = new Date() - new Date(this.displayItems[i].saved_at);
                        let seconds = Math.floor(timeDiff / 1000);
                        let minutes = Math.floor(seconds / 60);
                        let hours = Math.floor(minutes / 60);
                        let days = Math.floor(hours / 24);
                        if (days > 0) {
                            this.displayItems[i].saved_at = days + ' Day(s) ago';
                        } else if (hours > 0) {
                            this.displayItems[i].saved_at = hours + ' H ' + minutes + ' Min(s) ago';
                        } else if (minutes > 0) {
                            this.displayItems[i].saved_at = minutes + ' Min(s) ago';
                        } else {
                            this.displayItems[i].saved_at = 'Just now';
                        }

                        this.displayItems[i].ph_sm = `data:image/jpeg;base64,${this.displayItems[i].ph_sm}`;

                        this.displayItems[i].price = this.displayItems[i].price !== 0 ? parseInt(this.displayItems[i].price).toLocaleString() : 'Free'
                        if (this.displayItems[i].is_bid) {
                            this.displayItems[i].bids = JSON.parse(this.displayItems[i].bids);
                            this.displayItems[i].bids = this.displayItems[i].bids[this.displayItems[i].bids.length - 1].price;
                            this.displayItems[i].bids = parseInt(this.displayItems[i].bids).toLocaleString();
                        }
                    }

                    //filter ad by new, used
                    if (this.filterShortType === 'all') {
                        this.filterSType = this.displayItems;
                    }

                    if (this.filterShortType === 'new') {
                        this.filterSType = this.displayItems.filter(item => item.condition_1 === 'New');
                    }

                    if (this.filterShortType === 'used') {
                        this.filterSType = this.displayItems.filter(item => item.condition_1 === 'Used');
                    }

                    //filter ad by price
                    if (this.priceMin === '' && this.priceMax === '') {
                        this.filterPrice = this.filterSType;
                    }

                    if (this.priceMin !== '' && this.priceMax === '') {
                        this.filterPrice = this.filterSType.filter(item => {
                            let price = parseInt(item.price.replace(/,/g, ""), 10);
                            let nPriceMin = parseInt(this.priceMin);
                            return price >= nPriceMin;
                        });
                    }

                    if (this.priceMin === '' && this.priceMax !== '') {
                        this.filterPrice = this.filterSType.filter(item => {
                            let price = parseInt(item.price.replace(/,/g, ""), 10);
                            let nPriceMax = parseInt(this.priceMax);
                            return price <= nPriceMax;
                        });
                    }

                    if (this.priceMin !== '' && this.priceMax !== '') {
                        this.filterPrice = this.filterSType.filter(item => {
                            let price = parseInt(item.price.replace(/,/g, ""), 10);
                            let nPriceMin = parseInt(this.priceMin);
                            let nPriceMax = parseInt(this.priceMax);
                            return price >= nPriceMin && price <= nPriceMax;
                        });
                    }

                    // filter ad by bid, normal
                    if (this.filterShortAd === 'all') {
                        this.displayItemsFilter = this.filterPrice;
                    }

                    if (this.filterShortAd === 'normal') {
                        this.displayItemsFilter = this.filterPrice.filter(item => item.is_bid === 0);
                    }

                    if (this.filterShortAd === 'bid') {
                        this.displayItemsFilter = this.filterPrice.filter(item => item.is_bid === 1);
                    }

                    if (isFilter === true) {
                        this.displayItemsLast = [];
                    }

                    for (let i = 0; i < this.displayItemsFilter.length; i++) {
                        this.displayItemsLast.push(this.displayItemsFilter[i]);
                    }
                }
                this.page++;
            });
            this.mobileFilterIsVisible = false;
        },

        addScrollListener() {
            const endOfList = this.$refs.endOfList;
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    this.loadItems(false);
                }
            });
            observer.observe(endOfList);
        },

        itemClick(index) {
            if (this.displayItemsLast[index].is_bid !== null) {
                if (this.displayItemsLast[index].is_bid === 0) {
                    this.$router.push('/item/' + this.displayItemsLast[index].main_cat + '/' + this.displayItemsLast[index].title + '/' + this.encrypt(this.displayItemsLast[index].id.toString()) + '/' + this.encrypt(this.displayItemsLast[index].user_id.toString()))
                } else {
                    this.$router.push('/bid/' + this.displayItemsLast[index].main_cat + '/' + this.displayItemsLast[index].title + '/' + this.encrypt(this.displayItemsLast[index].id.toString()) + '/' + this.encrypt(this.displayItemsLast[index].user_id).toString())
                }
            } else {
                if (this.displayItemsLast[index].main_cat === 'Jobs') {
                    this.$router.push('/job/' + this.displayItemsLast[index].main_cat + '/' + this.displayItemsLast[index].title + '/' + this.encrypt(this.displayItemsLast[index].id.toString()) + '/' + this.encrypt(this.displayItemsLast[index].user_id.toString()))
                } else {
                    this.$router.push('/item/' + this.displayItemsLast[index].main_cat + '/' + this.displayItemsLast[index].title + '/' + this.encrypt(this.displayItemsLast[index].id.toString()) + '/' + this.encrypt(this.displayItemsLast[index].user_id.toString()))
                }
            }
        },
    }
}
</script>

<style scoped>
.no-items {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.filter-wrp-sm {
    display: none;
}

.main {
    min-height: 100vh;
    margin: 0 auto;
}

.btn-filter {
    display: none;
}

.top-info a img {
    width: 7px;
    height: 10px;
    margin-right: 5px;
}

.filter-wrp-lg {
    width: 300px;
    padding-right: 20px;
    margin-bottom: 20px;
    position: sticky;
    top: 0;
}

.lg-filter {
    width: 320px;
    position: relative;
}

.filter-wrp-lg select {
    width: 100%;
    height: 35px;
    outline: none;
    border-radius: 5px;
}

.filter-wrp-lg input {
    width: 45%;
    height: 35px;
    outline: none;
    border: 1px solid black;
    border-radius: 5px;
}

.filter-wrp-lg button {
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    color: white;
}

.skel-wrp {
    width: 100%;
}

.items-wrp-lg {
    width: 100%;
}

.items-wrp-sm {
    display: none;
}

.item-wrp-lg {
    padding: 10px;
    padding-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.048);
    color: black;
    cursor: pointer;
}

.item-wrp-lg .img-box {
    width: 200px;
    height: 115px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    background-color: rgba(0, 0, 0, 0.034);
}

.item-wrp-lg .img-box img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;

}

.item-wrp-lg .content-box {
    width: 100%;
}

.item-wrp-lg .content-box .item-name {
    font-weight: 500;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.condition-1 {
    color: black;
}

.item-wrp-lg .content-box .membership {
    padding: 0 4px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.048);
}

.item-wrp-lg .content-box .membership-none {
    display: none;
}

.item-wrp-lg .content-box .price {
    font-weight: 500;
}

.bid-icon-box {
    width: 50px;
    height: 12px;
    margin-left: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bid-icon-box img {
    max-width: 100%;
    max-height: 100%;
}

.items-wrapper-left-wrapper-sm,
.items-wrapper-right-wrapper-sm {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.items-wrapper-left-wrapper-sm {
    padding-right: 5px;
}

.items-wrapper-right-wrapper-sm {
    padding-left: 5px;
}

@media only screen and (max-width: 991px) {

    .filter-wrp-lg {
        padding: 0;
    }

    .lg-filter {
        display: none;
    }

    .top-info {
        padding: 5px 30px;
        margin-top: 10px;
        background-color: #8ec64123;
    }

    .btn-filter {
        width: 30px;
        height: 30px;
        border: none;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .filter-wrp-sm {
        width: 400px;
        height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 0;
        right: 0;
        background-color: rgb(241, 241, 241);
    }

    .filter-trans-enter-from,
    .filter-trans-leave-to {
        right: -100%;
    }

    .filter-trans-enter-active,
    .filter-trans-leave-active {
        transition: all .2s ease;
    }

    .filter-wrp-sm .btn-wrapper {
        width: 100%;
        margin-bottom: 30px;
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 767px) {

    .top-info {
        padding: 5px 10px;
        margin: 0;
    }

    .item-wrp-lg .img-box {
        width: 150px;
        height: 100px;
    }
}

@media only screen and (max-width: 575px) {

    .items-not-found-box{
        position: fixed;
        left: 0;
    }

    .filter-wrp-sm {
        width: 100%;
    }

    .filter-wrp-sm .btn-wrapper {
        justify-content: flex-end;
    }

    .items-wrp-lg {
        display: none;
    }

    .items-wrp-sm {
        width: 100%;
        display: flex;
    }

    .items-sm {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        vertical-align: top;
        border-radius: 5px;
        display: inline-block;
        color: black;
        background-color: rgba(0, 0, 0, 0.062);
    }

    .items-sm img {
        width: 100%;
    }

    .items-sm .item-name {
        font-weight: 900;
        font-size: 14px;
    }

    .items-sm .price {
        font-weight: 500;
        font-size: 14px;
    }

    .items-sm .membership {
        padding: 0 4px;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.048);
    }

    .items-sm div .time-status {
        font-size: 10px;
        font-weight: 300;
    }

}
</style>