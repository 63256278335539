import { createStore } from 'vuex'

const store = createStore({
  state: {
    token: localStorage.getItem('token') || null,
    profileImgURL: localStorage.getItem('profileImg') || null,
    // api: 'http://localhost:3000',
    api: 'https://api.ceylonentrepreneur.com',
    pwResSecKey: 'sd$g37gd63#&%vf^73ey',
    idSecKey: 'w@j&3S9#dN9^&A',
  },
  mutations: {
    increment(state) {
      state.count++
    },

    logOut(state) {
      state.token === null
    }
  },
  actions: {
    increment({ commit }) {
      commit('increment')
    }, 

    logOut({ commit }) {
      commit('logOut')
    }
  }
})

export default store
