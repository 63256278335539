<template>
    <div>
        <loading v-model:active="isLoading" loader="bars" color="#228B22" />
        <div class="main">
            <div class="form-box">
                <button @click="$router.push('/login')" class="btn-close"></button>
                <img src="../assets/logos/popit-logo-black.png" alt="" class="logo">
                <h5>Reset Password</h5>
                <p class="txt-instruction f-sz-sm pad-mar-no">Please fill in the fields above to reset your password.</p>
                <div class="form-content">
                    <input v-model="password" type="password" name="" id="" class="form-control" placeholder="New Password"
                        :class="errors.password === '' ? '' : 'inp-err'">
                    <p v-if="errors.password !== ''" class="txt-err f-sz-sm pad-mar-no">{{ errors.password }}</p>
                    <input v-model="passwordConfirm" type="password" name="" id="" class="form-control"
                        placeholder="Re-Enter the New Password" :class="errors.passwordConfirm === '' ? '' : 'inp-err'">
                    <p v-if="errors.passwordConfirm !== ''" class="txt-err f-sz-sm pad-mar-no">{{ errors.passwordConfirm }}
                    </p>
                    <button @click="confirmClick()" class="btn-confirm col-theme">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios'
import { mapState } from 'vuex'
import CryptoJS from 'crypto-js';
import { useHead } from '@vueuse/head';

export default {
    data() {
        return {
            isLoading: false,
            password: '',
            passwordConfirm: '',
            email: null,
            errors: {
                password: '',
                passwordConfirm: '',
            }
        }
    },

    components: {
        Loading,
    },

    computed: {
        ...mapState(['api', 'pwResSecKey'])
    },

    mounted() {
        useHead({
            title: `Reset Password | popit.lk`,
            meta: [
                {
                    name: 'description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:title',
                    content: `Reset Password | popit.lk`,
                },
                {
                    property: 'og:description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
            ],
        });
    },

    methods: {

        validation() {
            if (!this.password) {
                this.errors.password = 'Password is Required!';
            } else if (this.password.length < 6) {
                this.errors.password = 'Password is too short!'
            } else {
                this.errors.password = '';
            }
            if (this.password !== this.passwordConfirm) {
                this.errors.passwordConfirm = 'Password did not matched!'
            } else {
                this.errors.passwordConfirm = '';
            }
        },

        decryptOtp() {
            const key = CryptoJS.enc.Utf8.parse(this.pwResSecKey);

            const decrypted = CryptoJS.AES.decrypt(this.$route.params.otp.replace(/-/g, '/'), key, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            });

            return decrypted.toString(CryptoJS.enc.Utf8);
        },

        async confirmClick() {
            this.validation();

            if (this.errors.password === '' && this.errors.passwordConfirm === '') {
                this.isLoading = true;
                this.email = localStorage.getItem('pwResEmail');
                if (!this.email) {
                    this.isLoading = false;
                    Swal.fire({
                        title: 'Reset Password',
                        text: `Unauthorized event detected.`,
                        icon: 'error',
                    }).then(() => {
                        this.$router.push('/login')
                    })
                } else {
                    try {
                        axios.post(`${this.api}/users/reset-password`, {
                            email: this.email,
                            otp: this.decryptOtp(),
                            password: this.password,
                        }).then((response) => {
                            this.isLoading = false;
                            if (response.status === 201) {
                                Swal.fire({
                                    title: 'Success',
                                    text: `Pasword has been reset`,
                                    icon: 'success',
                                }).then(() => {
                                    this.$router.push('/login')
                                })
                            } else {
                                Swal.fire({
                                    title: 'Reset Password',
                                    text: `Unauthorized event detected.`,
                                    icon: 'error',
                                }).then(() => {
                                    this.$router.push('/login')
                                })
                            }
                        })
                    } catch (e) {
                        this.isLoading = false;
                        Swal.fire({
                            title: 'Reset Password',
                            text: `Unauthorized event detected.`,
                            icon: 'error',
                        }).then(() => {
                            this.$router.push('/login')
                        })
                    }
                }
            }
        }
    }

}
</script>
<style scoped>
h5 {
    text-align: center;
}

.txt-instruction {
    text-align: center;
}

.txt-err {
    color: red;
}

.inp-err {
    border: 1px solid red;
}

.main {
    width: 100%;
    min-height: 100vh;
    background-image: url(../assets/others/bg-image.jpg);
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-box {
    width: 500px;
    padding: 50px;
    background-color: rgba(0, 0, 0, 0.063);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-close {
    margin: 10px 10px 0 0;
    position: absolute;
    right: 0;
    top: 0;
}

.logo {
    width: 100px;
    margin-bottom: 20px;
}

.form-control {
    margin-top: 20px;
}

.form-content {
    width: 100%;
}

.btn-confirm {
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}

@media only screen and (max-width: 575px) {
    .form-box {
        margin: 10px;
        padding: 20px;
    }
}
</style>
