<template>
    <div>
        <loading v-model:active="isLoading" loader="bars" color="#228B22" />
        <!-- <Navi /> -->
        <div class="main res-py container-xxl">
            <div class="top">
                <div class="cover-profile-wrapper bor-col-theme">
                    <div style="position: relative;" class="dis-fl">
                        <!-- <div class="icn-edit dis-fl jus-con-cen al-it-cen">
                            <img src="../assets/icons/edit-box.png" alt="">
                        </div> -->
                        <label class="cover-img-box dis-fl jus-con-cen" for="cover-img-box" id="cover-img">
                            <img :class="coverImgURL === '' ? 'cover-img-box-def' : 'cover-img-box-img'"
                                :src="coverImgURL || require('../assets/icons/' + defaultImg2)" id="cover-img-height"
                                alt="">
                        </label>
                        <input @change="onFileChnageCover" class="inp-cover-img-box d-none" type="file" name=""
                            id="cover-img-box" ref="filecov" multiple>
                    </div>
                    <div class="profile-wrp">
                        <div style="position: relative;">
                            <label class="profile dis-fl jus-con-cen al-it-cen" for="profile-img">
                                <img :class="profileImgURL === '' ? 'profile-image-def' : 'profile-img'"
                                    :src="profileImgURL || require('../assets/icons/' + defaultImg)" alt="">
                            </label>
                            <input @change="onFileChnageProfile" class="d-none" type="file" name="" id="profile-img"
                                ref="filepro" multiple>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bot">
                <div class="form">
                    <div class="inp-field-box">
                        <label for="">First Name</label><br>
                        <input v-model="firstName" class="form-control" :class="errors.firstName ? 'inp-field-err' : ''"
                            type="text" name="" id="">
                        <p v-if="errors.firstName" class="inp-err">{{ errors.firstName }}</p>
                    </div>
                    <div class="inp-field-box">
                        <label for="">Last Name</label><br>
                        <input v-model="lastName" class="form-control" :class="errors.lastName ? 'inp-field-err' : ''"
                            type="text" name="" id="">
                        <p v-if="errors.lastName" class="inp-err">{{ errors.lastName }}</p>
                    </div>
                    <div class="inp-field-box">
                        <label for="">About</label><br>
                        <textarea v-model="about" class="form-control" :class="errors.about ? 'inp-field-err' : ''" name=""
                            id="" cols="30" rows="5"></textarea>
                        <p v-if="errors.about" class="inp-err">{{ errors.about }}</p>
                    </div>
                    <div class="inp-field-box">
                        <label for="">Address Line 1</label><br>
                        <input v-model="address_1" class="form-control" :class="errors.address_1 ? 'inp-field-err' : ''"
                            type="text" name="" id="">
                        <p v-if="errors.address_1" class="inp-err">{{ errors.address_1 }}</p>
                    </div>
                    <div class="inp-field-box">
                        <label for="">Address Line 2</label><br>
                        <input v-model="address_2" class="form-control" :class="errors.address_2 ? 'inp-field-err' : ''"
                            type="text" name="" id="">
                        <p v-if="errors.address_2" class="inp-err">{{ errors.address_2 }}</p>
                    </div>
                    <div class="inp-field-box">
                        <label for="">City</label><br />
                        <select v-model="city" class="form-control" :class="errors.city ? 'inp-field-err' : ''" name=""
                            id="" @change="onCitySelectedChange">
                            <option value="" disabled selected>Select an option</option>
                            <option value="Colombo">Colombo</option>
                            <option value="Kandy">Kandy</option>
                            <option value="Galle">Galle</option>
                            <option value="Ampara">Ampara</option>
                            <option value="Anuradhapura">Anuradhapura</option>
                            <option value="Badulla">Badulla</option>
                            <option value="Batticaloa">Batticaloa</option>
                            <option value="Gampaha">Gampaha</option>
                            <option value="Hambantota">Hambantota</option>
                            <option value="Jaffna">Jaffna</option>
                            <option value="Kalutara">Kalutara</option>
                            <option value="Kegalle">Kegalle</option>
                            <option value="Kilinochchi">Kilinochchi</option>
                            <option value="Kurunegala">Kurunegala</option>
                            <option value="Manner">Manner</option>
                            <option value="Matale">Matale</option>
                            <option value="Matara">Matara</option>
                            <option value="Monaragala">Monaragala</option>
                            <option value="Mullativu">Mullativu</option>
                            <option value="Nuwara Eliya">Nuwara Eliya</option>
                            <option value="Polonnaruwa">Polonnaruwa</option>
                            <option value="Puttalam">Puttalam</option>
                            <option value="Ratnapura">Ratnapura</option>
                            <option value="Trincomalee">Trincomalee</option>
                            <option value="Vavunia">Vavunia</option>
                        </select>
                        <p v-if="errors.city" class="inp-err">{{ errors.city }}</p>
                    </div>
                    <div class="inp-field-box">
                        <label for="">Mobile Number</label><br>
                        <input v-model="mobile" class="form-control" :class="errors.mobile ? 'inp-field-err' : ''"
                            type="number" name="" id="">
                        <p v-if="errors.mobile" class="inp-err">{{ errors.mobile }}</p>
                    </div>
                    <div class="btns-wrp">
                        <button @click="saveData()" class="pad-mar-no" id="btn-save">SAVE</button>
                        <button @click="this.$router.push('/profile')" class="pad-mar-no" id="btn-cancel">CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
// import Navi from '../components/navigation-com.vue';
import axios from 'axios';
import jwtDecode from 'jwt-decode'
import { mapState } from 'vuex'
import Swal from "sweetalert2"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useHead } from '@vueuse/head';

export default {

    data() {
        return {
            isLoading: false,
            userID: null,
            defaultImg: 'add-photo.png',
            defaultImg2: 'image-icn.png',
            profileImgURL: '',
            coverImgURL: '',
            profileImg: null,
            coverImg: null,
            firstName: '',
            lastName: '',
            about: '',
            address_1: '',
            address_2: '',
            city: '',
            mobile: '',
            errors: {
                firstName: '',
                lastName: '',
                about: '',
                address_1: '',
                address_2: '',
                city: '',
                mobile: '',
            }
        }
    },

    components: {
        // Navi,
        Loading,
    },

    computed: {
        ...mapState(['api'])
    },

    mounted() {
        this.getUserId();
        this.userInfoLoad();
        useHead({
            title: `Edit Profile | popit.lk`,
            meta: [
                {
                    name: 'description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:title',
                    content: `Edit Profile | popit.lk`,
                },
                {
                    property: 'og:description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
            ],
        });
    },

    methods: {

        async getUserId() {
            const token = localStorage.getItem('token');
            try {
                const decoded = jwtDecode(token);
                this.userID = decoded.id;
            } catch (err) {
                console.log(err);
            }
        },

        async formValidation() {
            if (!this.firstName) {
                this.errors.firstName = 'Required';
            } else {
                this.errors.firstName = '';
            }

            if (!this.lastName) {
                this.errors.lastName = 'Required';
            } else {
                this.errors.lastName = '';
            }

            if (!this.about) {
                this.errors.about = 'Required';
            } else {
                this.errors.about = '';
            }

            if (!this.address_1) {
                this.errors.address_1 = 'Required';
            } else {
                this.errors.address_1 = '';
            }

            if (!this.address_2) {
                this.errors.address_2 = 'Required';
            } else {
                this.errors.address_2 = '';
            }

            if (!this.city) {
                this.errors.city = 'Required';
            } else {
                this.errors.city = '';
            }

            if (!this.mobile) {
                this.errors.mobile = 'Enter a valid mobile number';
            } else if (/^\d{9}$/.test(this.mobile)) {
                this.errors.mobile = '';
            } else {
                this.errors.mobile = 'Enter a valid mobile number';
            }
        },

        async userInfoLoad() {
            await axios.get(`${this.api}/users/fetch-user-info/${this.userID}`).then((response) => {
                if (response.data.length !== 0) {
                    this.firstName = response.data.firstName !== null ? response.data.firstName : '';
                    this.lastName = response.data.lastName !== null ? response.data.lastName : '';
                    this.about = response.data.about !== null ? response.data.about : '';
                    this.address_1 = response.data.address_1 !== null ? response.data.address_1 : '';
                    this.address_2 = response.data.address_2 !== null ? response.data.address_2 : '';
                    this.mobile = response.data.mobile !== null ? response.data.mobile : '';
                    this.city = response.data.city !== null ? response.data.city : '';
                    this.profileImgURL = response.data.profile !== '' ? `data:image/jpeg;base64,${response.data.profile}` : '';
                    this.coverImgURL = response.data.cover !== '' ? `data:image/jpeg;base64,${response.data.cover}` : '';
                }
            });
        },

        onFileChnageProfile() {
            let file = this.$refs.filepro.files[0];
            this.profileImgURL = URL.createObjectURL(file);

            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = 120;
                const maxHeight = 120;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.profileImg = reducedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);
        },

        onFileChnageCover() {
            let file = this.$refs.filecov.files[0];
            this.coverImgURL = URL.createObjectURL(file);

            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = 1300;
                const maxHeight = 350;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.coverImg = reducedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);
        },

        onCitySelectedChange(event) {
            this.city = event.target.value;
        },

        async saveData() {
            this.formValidation();
            if (!this.errors.firstName && !this.errors.lastName && !this.errors.about && !this.errors.address_1 && !this.errors.address_2 && !this.errors.city && !this.errors.mobile) {
                Swal.fire({
                    title: "Warning..!",
                    text: "Are you sure? ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "YES",
                    cancelButtonText: "NO",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        let imagesTypes;

                        if (this.profileImg === null && this.coverImg === null) {
                            imagesTypes = '*emt';
                        } else if (this.profileImg !== null && this.coverImg === null) {
                            imagesTypes = 'proDat';
                        } else if (this.profileImg === null && this.coverImg !== null) {
                            imagesTypes = 'covDat';
                        } else {
                            imagesTypes = '*dat';
                        }

                        const formData = new FormData();
                        formData.append('images', this.profileImg);
                        formData.append('images', this.coverImg);
                        formData.append('firstName', this.firstName);
                        formData.append('lastName', this.lastName);
                        formData.append('about', this.about);
                        formData.append('address_1', this.address_1);
                        formData.append('address_2', this.address_2);
                        formData.append('city', this.city);
                        formData.append('mobile', this.mobile);
                        formData.append('imagesTypes', imagesTypes);

                        await axios.post(`${this.api}/users/insert-user-info/${this.userID}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then((response) => {
                            this.isLoading = false;
                            if (response.status === 200) {
                                Swal.fire({
                                    title: "Success..!",
                                    text: "Profile Data saved ",
                                    icon: "success",
                                }).then(() => {
                                    this.$router.push('/profile');
                                });
                            }
                        }).catch((err) => {
                            Swal.fire({
                                title: "Error..!",
                                text: "Something went wrong. Please try again",
                                icon: "error",
                            });
                        });
                    }
                });
            }
        }
    }
}

</script>
<style scoped>
.inp-field-err {
    border-color: red;
}

.inp-err {
    font-size: 13px;
    color: red;
}

.inp-field-box {
    margin-bottom: 20px;
}

.top {
    margin-top: 20px;
}

.icn-edit {
    width: 30px;
    height: 30px;
    border-bottom-left-radius: 5px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.781);
    z-index: 1;
}

.icn-edit img,
.icn-edit-1 img {
    max-width: 80%;
    max-height: 80%;
}

.icn-edit-1 {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border-top-right-radius: 15px;
    position: absolute;
    top: -35px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.781);
    z-index: 1;
}

.cover-img-box {
    width: 100%;
    height: 0;
    padding-bottom: 27.25%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.096);
}

.cover-img-box-img {
    width: 100%;
    position: absolute;
}

.cover-img-box-def {
    width: 150px;
    height: 150px;
    margin-top: 6%;
}

.profile-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    border: 2px solid;
    background-color: white;
    position: relative;
    bottom: 40px;
    overflow: hidden;
    cursor: pointer;
}

.profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-image-def {
    max-width: 50%;
    max-height: 50%;
}

.form {
    width: 600px;
    margin: 0 auto;
}

.btns-wrp {
    width: 100%;
    height: 40px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.btns-wrp button {
    width: 150px;
    height: 35px;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 500;
}

#btn-save {
    margin-right: 10px;
    background-color: green;
}

#btn-cancel {
    margin-right: 10px;
    background-color: red;
}

@media only screen and (max-width: 991px) {
    .cover-img-box-def {
        width: 100px;
        height: 100px;
    }
}

@media only screen and (max-width: 767px) {
    .form {
        width: 100%;
    }

    .cover-img-box-def {
        width: 80px;
        height: 80px;
        margin-top: 7%;
    }

    .profile {
        bottom: 20px;
    }
}

@media only screen and (max-width: 500px) {

    .cover-img-box-def {
        width: 50px;
        height: 50px;
        margin-top: 5%;
    }

    .profile-wrp {
        justify-content: flex-start;
    }

    .profile {
        width: 65px;
        height: 65px;
    }
}</style>