<template>
    <div style="min-height: 100vh;">
        <loading v-model:active="isLoading" loader="bars" color="#228B22" />
        <div v-if="isLoggedIn" class="main res-py container-xxl">
            <div class="top dis-fl jus-con-sp-bt al-it-cen">
                <div class="logo-box">
                    <router-link to="/">
                        <img class="img-fit" src="../assets/logos/popit-logo-black.png" alt="" />
                    </router-link>
                </div>
                <p class="pad-mar-no">Post Ad</p>
                <button class="btn-close" @click="this.$router.push('/')"></button>
            </div>
            <div class="content">
                <h1 class="title">{{ categorySub }} in {{ categoryMain }}</h1>
                <p class="sub-title f-sz-sm">Fill out the form below</p>
                <div class="form">
                    <!-- district -->
                    <div class="sec">
                        <label class="f-sz-sm" for="">District</label><br>
                        <select v-model="districtInp" class="form-control" name="" id=""
                            :class="errors.district !== '' ? 'inp-error' : ''">
                            <option value="" disabled selected>Select District</option>
                            <option value="Colombo">Colombo</option>
                            <option value="Kandy">Kandy</option>
                            <option value="Galle">Galle</option>
                            <option value="Ampara">Ampara</option>
                            <option value="Anuradhapura">Anuradhapura</option>
                            <option value="Badulla">Badulla</option>
                            <option value="Batticaloa">Batticaloa</option>
                            <option value="Gampaha">Gampaha</option>
                            <option value="Hambantota">Hambantota</option>
                            <option value="Jaffna">Jaffna</option>
                            <option value="Kalutara">Kalutara</option>
                            <option value="Kegalle">Kegalle</option>
                            <option value="Kilinochchi">Kilinochchi</option>
                            <option value="Kurunegala">Kurunegala</option>
                            <option value="Manner">Manner</option>
                            <option value="Matale">Matale</option>
                            <option value="Matara">Matara</option>
                            <option value="Monaragala">Monaragala</option>
                            <option value="Mullativu">Mullativu</option>
                            <option value="Nuwara Eliya">Nuwara Eliya</option>
                            <option value="Polonnaruwa">Polonnaruwa</option>
                            <option value="Puttalam">Puttalam</option>
                            <option value="Ratnapura">Ratnapura</option>
                            <option value="Trincomalee">Trincomalee</option>
                            <option value="Vavunia">Vavunia</option>
                        </select>
                        <p v-if="errors.district !== ''" class="txt-error f-sz-sm pad-mar-no">{{ errors.district }}</p>
                    </div>
                    <!-- Type of animal -->
                    <!-- <div v-if="typeOfAnimal" class="sec">
                        <label class="f-sz-sm" for="">Type of animal</label><br>
                        <select v-model="typeOfAnimalInp" class="form-control" name="" id="">
                            <option value="" disabled selected>Select category</option>
                            <option value="">Bird</option>
                        </select>
                    </div> -->
                    <!-- property type -->
                    <!-- <div v-if="propertyType" class="sec">
                        <label class="f-sz-sm" for="">Property type</label><br>
                        <select v-model="propertyTypeInp" class="form-control" name="" id="">
                            <option value="" disabled selected>Select category</option>
                            <option value="">Building</option>
                        </select>
                    </div> -->
                    <!-- Bedrooms -->
                    <div v-if="bedRooms" class="sec">
                        <label class="f-sz-sm" for="">Bedrooms</label><br>
                        <select v-model="bedRoomsInp" class="form-control" name="" id="">
                            <option value="" disabled selected>Select category</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    <!-- Bathrooms -->
                    <div v-if="bathRooms" class="sec">
                        <label class="f-sz-sm" for="">Bathrooms</label><br>
                        <select v-model="bathRoomsInp" class="form-control" name="" id="">
                            <option value="" disabled selected>Select category</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    <!-- Bike type -->
                    <!-- <div v-if="bikeType" class="sec">
                        <label class="f-sz-sm" for="">Bike type</label><br>
                        <select v-model="bikeTypeInp" class="form-control" name="" id="">
                            <option value="" disabled selected>Select category</option>
                            <option value="">cable</option>
                        </select>
                    </div> -->
                    <!-- land type -->
                    <div v-if="landType" class="sec">
                        <label class="f-sz-sm" for="">Land type</label><br>
                        <div class="row">
                            <div class="dis-fl col-lg-6">
                                <input v-model="landTypeInp" value="Agricultural" type="radio" name="condition" id="">&nbsp;
                                <p class="pad-mar-no">Agricultural</p>
                            </div>
                            <div class="dis-fl col-lg-6">
                                <input v-model="landTypeInp" value="Residential" type="radio" name="condition" id="">&nbsp;
                                <p class="pad-mar-no">Residential</p>
                            </div>
                            <div class="dis-fl col-lg-6">
                                <input v-model="landTypeInp" value="Commercial" type="radio" name="condition" id="">&nbsp;
                                <p class="pad-mar-no">Commercial</p>
                            </div>
                            <div class="dis-fl col-lg-6">
                                <input v-model="landTypeInp" value="Other" type="radio" name="condition" id="">&nbsp;
                                <p class="pad-mar-no">Other</p>
                            </div>
                        </div>
                    </div>
                    <!-- condition -->
                    <div v-if="condition" class="sec">
                        <label class="f-sz-sm" for="">Condition</label><br>
                        <div class="row">
                            <div class="dis-fl col-lg">
                                <input v-model="conditionInp" value="Used" type="radio" name="condition" id="">&nbsp;
                                <p class="pad-mar-no">Used</p>
                            </div>
                            <div class="dis-fl col-lg">
                                <input v-model="conditionInp" value="New" type="radio" name="condition" id="">&nbsp;
                                <p class="pad-mar-no">New</p>
                            </div>
                        </div>
                    </div>
                    <!-- condition recon-->
                    <div v-if="conditionRe" class="sec">
                        <label class="f-sz-sm" for="">Condition</label><br>
                        <div class="row">
                            <div class="dis-fl col-lg-6">
                                <input v-model="conditionReInp" value="Used" type="radio" name="condition" id="">&nbsp;
                                <p class="pad-mar-no">Used</p>
                            </div>
                            <div class="dis-fl col-lg-6">
                                <input v-model="conditionReInp" value="New" type="radio" name="condition" id="">&nbsp;
                                <p class="pad-mar-no">New</p>
                            </div>
                            <div class="col-lg-6"></div>
                            <div class="dis-fl col-lg-6">
                                <input v-model="conditionReInp" value="Reconditioned" type="radio" name="condition"
                                    id="">&nbsp;
                                <p class="pad-mar-no">Reconditioned</p>
                            </div>
                        </div>
                    </div>
                    <!-- item type -->
                    <!-- <div v-if="itemType" class="sec">
                        <label class="f-sz-sm" for="">Item type</label><br>
                        <select v-model="itemTypeInp" class="form-control" name="" id="">
                            <option value="" disabled selected>Select category</option>
                            <option value="cable">cable</option>
                        </select>
                    </div> -->
                    <!-- Land size -->
                    <div v-if="landSize" class="sec">
                        <label class="f-sz-sm" for="">Land size (perches)</label><br>
                        <input v-model="landSizeInp" type="number" class="form-control" name="" id="">
                    </div>
                    <!-- house size -->
                    <div v-if="houseSize" class="sec">
                        <label class="f-sz-sm" for="">House size (sqft)</label><br>
                        <input v-model="houseSizeInp" type="number" class="form-control" name="" id="">
                    </div>
                    <!-- size -->
                    <div v-if="size" class="sec">
                        <label class="f-sz-sm" for="">Size (sqft)</label><br>
                        <input v-model="sizeInp" type="number" class="form-control" name="" id="">
                    </div>
                    <!-- Address -->
                    <div v-if="address" class="sec">
                        <label class="f-sz-sm" for="">Address (optional)</label><br>
                        <input v-model="addressInp" type="text" class="form-control" name="" id="">
                    </div>
                    <!-- brand -->
                    <div v-if="brand" class="sec">
                        <label class="f-sz-sm" for="">Brand</label><br>
                        <input v-model="brandInp" type="text" class="form-control" name="" id="" :class="errors.brand !== '' ? 'inp-error' : ''">
                        <p v-if="errors.brand !== ''" class="txt-error f-sz-sm pad-mar-no">{{ errors.brand }}</p>
                    </div>
                    <!-- model -->
                    <div v-if="model" class="sec">
                        <label class="f-sz-sm" for="">Model</label><br>
                        <input v-model="modelInp" type="text" class="form-control" name="" id="" :class="errors.model !== '' ? 'inp-error' : ''">
                        <p v-if="errors.model !== ''" class="txt-error f-sz-sm pad-mar-no">{{ errors.model }}</p>
                    </div>
                    <!-- title -->
                    <div v-if="title" class="sec">
                        <label class="f-sz-sm" for="">Title</label><br>
                        <input v-model="titleInp" type="text" class="form-control" name="" id=""
                            :class="errors.title !== '' ? 'inp-error' : ''">
                        <p v-if="errors.title !== ''" class="txt-error f-sz-sm pad-mar-no">{{ errors.title }}</p>
                    </div>
                    <!-- screen size -->
                    <!-- <div v-if="screenSize" class="sec">
                        <label class="f-sz-sm" for="">Screen size (optional)</label><br>
                        <select v-model="screenSizeInp" class="form-control" name="" id="">
                            <option value="" disabled selected>Select category</option>
                            <option value="">23</option>
                        </select>
                    </div> -->
                    <!-- Capacity -->
                    <!-- <div v-if="capacity" class="sec">
                        <label class="f-sz-sm" for="">Capacity (optional)</label><br>
                        <select v-model="capacityInp" class="form-control" name="" id="">
                            <option value="" disabled selected>Select category</option>
                            <option value="">cable</option>
                        </select>
                    </div> -->
                    <!-- edition -->
                    <div v-if="edition" class="sec">
                        <label class="f-sz-sm" for="">Edition (optional)</label><br>
                        <input v-model="editionInp" type="text" class="form-control" name="" id="">
                    </div>
                    <!-- Year of Manufacture -->
                    <div v-if="yearOfManufacture" class="sec">
                        <label class="f-sz-sm" for="">Year of Manufacture (optional)</label><br>
                        <input v-model="yearOfManufactureInp" type="text" class="form-control" name="" id="">
                    </div>
                    <!-- Model year (km) -->
                    <div v-if="modelYear" class="sec">
                        <label class="f-sz-sm" for="">Model Year (optional)</label><br>
                        <input v-model="modelYearInp" type="number" class="form-control" name="" id="">
                    </div>
                    <!-- Mileage (km) -->
                    <div v-if="mileage" class="sec">
                        <label class="f-sz-sm" for="">Mileage (km) (optional)</label><br>
                        <input v-model="mileageInp" type="text" class="form-control" name="" id="">
                    </div>
                    <!-- Engine capacity (cc) -->
                    <div v-if="engineCapacity" class="sec">
                        <label class="f-sz-sm" for="">Engine capacity (cc) (optional)</label><br>
                        <input v-model="engineCapacityInp" type="text" class="form-control" name="" id="">
                    </div>
                    <!-- Fuel type -->
                    <div v-if="fuelType" class="sec">
                        <label class="f-sz-sm" for="">Fuel type (optional)</label><br>
                        <select v-model="fuelTypeInp" class="form-control" name="" id="">
                            <option value="" disabled selected>Select category</option>
                            <option value="Diesel">Diesel</option>
                            <option value="Petrol">Petrol</option>
                            <option value="CNG">CNG</option>
                            <option value="Hybrid">Hybrid</option>
                            <option value="Electric">Electric</option>
                            <option value="Other fuel type">Other fuel type</option>
                        </select>
                    </div>
                    <!-- Transmission -->
                    <div v-if="transmission" class="sec">
                        <label class="f-sz-sm" for="">Transmission (optional)</label><br>
                        <select v-model="transmissionInp" class="form-control" name="" id="">
                            <option value="" disabled selected>Select category</option>
                            <option value="Manual">Manual</option>
                            <option value="Automatick">Automatick</option>
                            <option value="Tiptronic">Tiptronic</option>
                            <option value="Other Transmission">Other Transmission</option>
                        </select>
                    </div>
                    <!-- Body type (optional) -->
                    <!-- <div v-if="bodyType" class="sec">
                        <label class="f-sz-sm" for="">Body type (optional)</label><br>
                        <select v-model="bodyTypeInp" class="form-control" name="" id="">
                            <option value="" disabled selected>Select category</option>
                            <option value="test">cable</option>
                        </select>
                    </div> -->
                    <!-- features -->
                    <!-- <div v-if="features" class="sec">
                        <label class="f-sz-sm" for="">Features (optional)</label><br>
                        <div class="row">
                            <div class="col-lg">
                                <div class="dis-fl">
                                    <input v-model="featuresInp" value="3G" type="checkbox" name="" id="1">&nbsp;
                                    <p class="pad-mar-no">3G</p>
                                </div>
                                <div class="dis-fl">
                                    <input v-model="featuresInp" value="4G" type="checkbox" name="" id="2">&nbsp;
                                    <p class="pad-mar-no">4G</p>
                                </div>
                                <div class="dis-fl">
                                    <input v-model="featuresInp" value="4G" type="checkbox" name="" id="">&nbsp;
                                    <p class="pad-mar-no">4G</p>
                                </div>
                                <div class="dis-fl">
                                    <input v-model="featuresInp" value="4G" type="checkbox" name="" id="">&nbsp;
                                    <p class="pad-mar-no">4G</p>
                                </div>
                                <div class="dis-fl">
                                    <input v-model="featuresInp" value="4G" type="checkbox" name="" id="">&nbsp;
                                    <p class="pad-mar-no">4G</p>
                                </div>
                            </div>
                            <div class="col-lg">
                                <div class="dis-fl">
                                    <input v-model="featuresInp" value="4G" type="checkbox" name="" id="">&nbsp;
                                    <p class="pad-mar-no">4G</p>
                                </div>
                                <div class="dis-fl">
                                    <input v-model="featuresInp" value="4G" type="checkbox" name="" id="">&nbsp;
                                    <p class="pad-mar-no">4G</p>
                                </div>
                                <div class="dis-fl">
                                    <input v-model="featuresInp" value="4G" type="checkbox" name="" id="">&nbsp;
                                    <p class="pad-mar-no">4G</p>
                                </div>
                                <div class="dis-fl">
                                    <input v-model="featuresInp" value="4G" type="checkbox" name="" id="">&nbsp;
                                    <p class="pad-mar-no">4G</p>
                                </div>
                                <div class="dis-fl">
                                    <input v-model="featuresInp" value="4G" type="checkbox" name="" id="">&nbsp;
                                    <p class="pad-mar-no">4G</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- description -->
                    <div class="sec">
                        <label class="f-sz-sm" for="">Description</label><br>
                        <textarea v-model="descriptionInp" class="form-control" name="" id="" rows="5"
                            :class="errors.description !== '' ? 'inp-error' : ''"></textarea>
                        <p v-if="errors.description !== ''" class="txt-error f-sz-sm pad-mar-no">{{ errors.description }}
                        </p>
                    </div>
                    <!-- Job type -->
                    <div v-if="jobType" class="sec">
                        <label class="f-sz-sm" for="">Job Type</label><br>
                        <div class="row">
                            <div class="dis-fl col-lg-6">
                                <input v-model="jobType_1Inp" value="On Site" type="radio" name="condition" id="">&nbsp;
                                <p class="pad-mar-no">On Site</p>
                            </div>
                            <div class="dis-fl col-lg-6">
                                <input v-model="jobType_1Inp" value="Work From Home" type="radio" name="condition"
                                    id="">&nbsp;
                                <p class="pad-mar-no">Work From Home</p>
                            </div>
                            <div class="col-lg-6"></div>
                            <div class="dis-fl col-lg-6">
                                <input v-model="jobType_1Inp" value="Hydbrid" type="radio" name="condition" id="">&nbsp;
                                <p class="pad-mar-no">Hydbrid</p>
                            </div>
                        </div>
                    </div>
                    <!-- job position -->
                    <div v-if="jobPosition" class="sec">
                        <select v-model="jobType_2Inp" class="form-control" name="" id="" :class="errors.jobType2 !== '' ? 'inp-error' : ''">
                            <option value="" disabled selected>Select category</option>
                            <option value="Full Time">Full Time</option>
                            <option value="Part Time">Part Time</option>
                        </select>
                        <p v-if="errors.jobType2 !== ''" class="txt-error f-sz-sm pad-mar-no">{{ errors.jobType2 }}</p>
                    </div>
                    <!-- required experience -->
                    <div v-if="requiredExperience" class="sec">
                        <label class="f-sz-sm" for="">Required Experience (optional)</label><br>
                        <input v-model="requiredExperienceInp" type="text" class="form-control" />
                    </div>
                    <!-- required education -->
                    <div v-if="requiredEducation" class="sec">
                        <label class="f-sz-sm" for="">Required Education (optional)</label><br>
                        <select v-model="requiredEducationInp" class="form-control" name="" id="">
                            <option value="" disabled selected>Select category</option>
                            <option value="Ordinary Level">Ordinary Level</option>
                            <option value="Advanced Level">Advanced Level</option>
                            <option value="Certificate">Certificate</option>
                            <option value="Diploma">Diploma</option>
                            <option value="Higer Diploma">Higer Diploma</option>
                            <option value="Degree">Degree</option>
                            <option value="Masters">Masters</option>
                            <option value="Doctorate">Doctorate</option>
                            <option value="Skilled Apprentice">Skilled Apprentice</option>
                        </select>
                    </div>
                    <!-- bid -->
                    <div v-if="bid" class="sec">
                        <label class="f-sz-sm" for="">Ad Type</label><br>
                        <div class="row">
                            <div class="dis-fl col-lg">
                                <input v-model="adTypeInp" value="normal" type="radio" name="ad-type" id="bid">&nbsp;
                                <p class="pad-mar-no">Normal</p>
                            </div>
                            <div class="dis-fl col-lg">
                                <input v-model="adTypeInp" value="bid" type="radio" name="ad-type" id="bid">&nbsp;
                                <p class="pad-mar-no">Bid</p>
                            </div>
                        </div>
                    </div>
                    <transition name="bid-trans">
                        <div v-if="isBid">
                            <div v-if="true" class="sec">
                                <label class="f-sz-sm" for="">Start Price (Rs)</label><br>
                                <input v-model="startPrice" class="form-control" type="text" name="" id=""
                                    :class="errors.bidStartPrice !== '' ? 'inp-error' : ''">
                                <p v-if="errors.bidStartPrice !== ''" class="txt-error f-sz-sm pad-mar-no">{{
                                    errors.bidStartPrice }}</p>
                            </div>
                            <div v-if="true" class="sec">
                                <div class="row">
                                    <div class="col-lg-6" id="duration">
                                        <label class="f-sz-sm" for="">Duration</label><br>
                                        <select v-model="duration" class="form-control" name="" id=""
                                            :class="errors.bidDuration !== '' ? 'inp-error' : ''">
                                            <option value="" disabled selected>Select category</option>
                                            <option value="1">1 Day</option>
                                            <option value="2">2 Day</option>
                                            <option value="3">3 Day</option>
                                            <option value="5">5 Day</option>
                                            <option value="7">7 Day</option>
                                        </select>
                                        <p v-if="errors.bidDuration !== ''" class="txt-error f-sz-sm pad-mar-no">{{
                                            errors.bidDuration }}</p>
                                    </div>
                                    <div class="col-lg-6" id="gap">
                                        <label class="f-sz-sm" for="">Bid Gap (Rs)</label><br>
                                        <select v-model="bidGap" class="form-control" name="" id=""
                                            :disabled="startPrice === null || startPrice < 1000"
                                            :class="errors.bidGap !== '' ? 'inp-error' : ''">
                                            <option value="" disabled selected>Select category</option>
                                            <option v-if="parseInt(startPrice) >= 1000 && parseInt(startPrice) <= 4999"
                                                value="50">50</option>
                                            <option v-if="parseInt(startPrice) >= 1000 && parseInt(startPrice) <= 4999"
                                                value="75">75</option>
                                            <option v-if="parseInt(startPrice) >= 1000 && parseInt(startPrice) <= 9999"
                                                value="100">100</option>
                                            <option v-if="parseInt(startPrice) >= 5000 && parseInt(startPrice) <= 9999"
                                                value="250">250</option>
                                            <option v-if="parseInt(startPrice) >= 5000 && parseInt(startPrice) <= 19999"
                                                value="500">500</option>
                                            <option v-if="parseInt(startPrice) >= 10000 && parseInt(startPrice) <= 19999"
                                                value="750">750</option>
                                            <option v-if="parseInt(startPrice) >= 10000 && parseInt(startPrice) <= 49999"
                                                value="1000">1000</option>
                                            <option v-if="parseInt(startPrice) >= 20000 && parseInt(startPrice) <= 49999"
                                                value="1500">1500</option>
                                            <option v-if="parseInt(startPrice) >= 20000 && parseInt(startPrice) <= 99999"
                                                value="2000">2000</option>
                                            <option v-if="parseInt(startPrice) >= 50000 && parseInt(startPrice) <= 99999"
                                                value="3500">3500</option>
                                            <option v-if="parseInt(startPrice) >= 50000 && parseInt(startPrice) <= 499999"
                                                value="5000">5000</option>
                                            <option v-if="parseInt(startPrice) >= 100000 && parseInt(startPrice) <= 499999"
                                                value="7500">7500</option>
                                            <option v-if="parseInt(startPrice) >= 100000 && parseInt(startPrice) <= 499999"
                                                value="10000">10000</option>
                                            <!-- upto 500000 -->
                                            <option v-if="parseInt(startPrice) >= 500000" value="5000">5000</option>
                                            <option v-if="parseInt(startPrice) >= 500000" value="10000">10000</option>
                                            <option v-if="parseInt(startPrice) >= 500000" value="25000">25000</option>
                                        </select>
                                        <p v-if="errors.bidGap !== ''" class="txt-error f-sz-sm pad-mar-no">{{ errors.bidGap
                                        }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                    <!-- price -->
                    <transition name="bid-trans">
                        <div v-if="price" class="sec">
                            <label class="f-sz-sm" for="">Price (Rs)</label><br>
                            <input v-model="priceInp" class="form-control" name="" id=""
                                :class="errors.price !== '' ? 'inp-error' : ''">
                            <p v-if="errors.price !== ''" class="txt-error f-sz-sm pad-mar-no">{{ errors.price }}</p>
                        </div>
                    </transition>
                    <!-- negotiable -->
                    <!-- <div v-if="negotiable" class="sec">
                        <input v-model="negotiableInp" type="checkbox" name="" id="">&nbsp;Negotiable
                    </div> -->
                    <hr>
                    <!-- add photos -->
                    <div v-if="addPhotos" class="sec">
                        <p>add photos</p>
                        <div class="item-images dis-fl">
                            <label class="dis-fl jus-con-cen al-it-cen" for="upload-photo-1"
                                :class="errors.image !== '' ? 'img-upload-error' : 'pho-pre'">
                                <img :src="imgURL_1" alt="">
                                <input class="test" type="file" name="" id="upload-photo-1" @change="onFileChange_1"
                                    ref="file_1">
                            </label>
                            <label class="pho-pre dis-fl jus-con-cen al-it-cen" for="upload-photo-2">
                                <img :src="imgURL_2" alt="">
                                <input class="test" type="file" name="" id="upload-photo-2" @change="onFileChange_2"
                                    ref="file_2">
                            </label>
                            <label class="pho-pre dis-fl jus-con-cen al-it-cen" for="upload-photo-3">
                                <img :src="imgURL_3" alt="">
                                <input class="test" type="file" name="" id="upload-photo-3" @change="onFileChange_3"
                                    ref="file_3">
                            </label>
                            <label class="pho-pre dis-fl jus-con-cen al-it-cen" for="upload-photo-4">
                                <img :src="imgURL_4" alt="">
                                <input class="test" type="file" name="" id="upload-photo-4" @change="onFileChange_4"
                                    ref="file_4">
                            </label>
                            <label class="pho-pre dis-fl jus-con-cen al-it-cen" for="upload-photo-5">
                                <img :src="imgURL_5" alt="">
                                <input class="test" type="file" name="" id="upload-photo-5" @change="onFileChange_5"
                                    ref="file_5">
                            </label>
                            <label class="pho-pre dis-fl jus-con-cen al-it-cen" for="upload-photo-6">
                                <img :src="imgURL_6" alt="">
                                <input class="test" type="file" name="" id="upload-photo-6" @change="onFileChange_6"
                                    ref="file_6">
                            </label>
                        </div>
                        <p v-if="errors.image !== ''" class="txt-error f-sz-sm pad-mar-no">{{ errors.image }}</p>
                    </div>
                    <!-- add company logo -->
                    <div v-if="addCompanyLogo" class="sec">
                        <p>Add Company logo</p>
                        <label class="dis-fl jus-con-cen al-it-cen" for="upload-photo"
                            :class="errors.cLogo === '' ? 'pho-pre' : 'img-upload-error'">
                            <img :src="imgURLCLogo" alt="">
                            <input class="test" type="file" name="" id="upload-photo" @change="onFileChange_cLogo"
                                ref="file_c_logo">
                        </label>
                        <p v-if="errors.cLogo !== ''" class="txt-error f-sz-sm pad-mar-no">{{ errors.cLogo }}</p>
                    </div>
                    <button class="btn-post-ad pad-mar-no col-theme" @click="postAd()">POST AD</button>
                    <hr>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Swal from 'sweetalert2';
import { mapState } from 'vuex';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useHead } from '@vueuse/head';
import CryptoJS from 'crypto-js';

export default {
    data() {
        return {
            isLoading: false,
            userID: '',
            jwt: '',
            categoryMain: this.$route.params.main,
            categorySub: this.$route.params.sub,
            imgURL_1: null,
            imgURL_2: null,
            imgURL_3: null,
            imgURL_4: null,
            imgURL_5: null,
            imgURL_6: null,
            imgURLCLogo: null,
            ph_1: null,
            ph_2: null,
            ph_3: null,
            ph_4: null,
            ph_5: null,
            ph_6: null,
            ph_sm: null,
            typeOfAnimal: false,
            propertyType: false,
            bedRooms: false,
            bathRooms: false,
            bikeType: false,
            landType: false,
            condition: false,
            conditionRe: false,
            itemType: false,
            landSize: false,
            houseSize: false,
            size: false,
            address: false,
            brand: false,
            model: false,
            title: false,
            screenSize: false,
            capacity: false,
            edition: false,
            yearOfManufacture: false,
            modelYear: false,
            mileage: false,
            engineCapacity: false,
            fuelType: false,
            transmission: false,
            bodyType: false,
            features: false,
            jobPosition: false,
            jobType: false,
            requiredExperience: false,
            requiredEducation: false,
            addCompanyLogo: false,
            negotiable: true,
            bid: false,
            price: true,
            addPhotos: true,
            isLoggedIn: null,
            districtInp: '',
            typeOfAnimalInp: '',
            propertyTypeInp: '',
            bedRoomsInp: '',
            bathRoomsInp: '',
            bikeTypeInp: '',
            landTypeInp: '',
            conditionInp: '',
            conditionReInp: 'Used',
            itemTypeInp: '',
            landSizeInp: '',
            houseSizeInp: '',
            sizeInp: '',
            addressInp: '',
            brandInp: '',
            modelInp: '',
            titleInp: '',
            screenSizeInp: '',
            capacityInp: '',
            editionInp: '',
            yearOfManufactureInp: '',
            modelYearInp: '',
            mileageInp: '',
            engineCapacityInp: '',
            fuelTypeInp: '',
            transmissionInp: '',
            bodyTypeInp: '',
            featuresInp: '',
            descriptionInp: '',
            jobType_1Inp: 'On Site',
            jobType_2Inp: '',
            requiredExperienceInp: '',
            requiredEducationInp: '',
            adTypeInp: 'normal',
            priceInp: '',
            negotiableInp: '',
            isBid: false,
            startPrice: null,
            duration: null,
            bidGap: null,
            watermark: "popit.lk",
            watermarkFont: "30px Arial",
            watermarkColor: "white",
            watermarkAlign: "center",
            watermarkOpacity: ".5",
            phLargeWidth: 1000,
            phLargeHeight: 600,
            errors: {
                district: '',
                title: '',
                description: '',
                price: '',
                image: '',
                cLogo: '',
                bidStartPrice: '',
                bidDuration: '',
                bidGap: '',
                brand: '',
                model: '',
                jobType2: ''
            },
            invalidTitles: ['nic', 'nicotene', '%', 'Ganja', 'KG', 'Kush', 'Hash', 'Thc', 'Cbd', 'Tobacco', 'Meth', 'Ayahuasca', 'DMT', 'Ketamine', 'Khat', 'LSD', 'Mescaline', 'peyote', 'PCP', 'Angel Dust', 'Psilocybin mushrooms', 'Salvia', 'Marijuana', 'MDMA', 'Ecstasy', 'Molly', 'Methamphetamine', 'Opium', 'Heroin', 'Opioids', 'Rohypnol', 'Steroids', 'Cannabinoids', 'Cocaine', 'Booze', 'Amphetamines', 'Pot', 'Skunk', 'Spliff', 'Crack', 'Charlie', 'Coke', 'Snow', 'Sniff', 'Pills', 'Mandy', 'Khat', 'Acid', 'Tabs', 'Trips', 'Shrooms', 'Mushies', 'Magics', 'Ciggies', 'Tobacco', 'Fags', 'DMT', 'PCP', 'Peyote', 'Morphine', 'Tramadol', 'Propoxyphene', 'Amphetamine', 'Antidepressants', 'Stamps', 'Kola', 'J', 'Joint', 'Rolled', 'prerolled', 'Liquid', 'Salt', 'Freebase', 'Menthol', 'E-Liquid', 'e-juice', 'Vg', 'Pg']
        }
    },

    components: {
        Loading,
    },

    mounted() {
        this.getUserId();
        this.isUserLoggedIn();
        this.inpFieldVisible();
        useHead({
            title: `Post ad | popit.lk`,
            meta: [
                {
                    name: 'description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:title',
                    content: `Post ad | popit.lk`,
                },
                {
                    property: 'og:description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
            ],
        });
    },

    computed: {
        ...mapState(['api', 'token', 'idSecKey'])
    },

    watch: {
        adTypeInp(val) {
            if (val === 'normal') {
                this.isBid = false;
                this.price = true;
            } else {
                this.isBid = true;
                this.price = false;
            }
        }
    },

    methods: {

        encrypt(plainText) {
            const encrypted = CryptoJS.AES.encrypt(plainText, this.idSecKey, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            });
            return encrypted.toString().replace(/\//g, '-');
        },

        async isUserLoggedIn() {
            if (this.token !== null) {
                this.isLoggedIn = true;
            } else {
                this.isLoggedIn = false;
                Swal.fire({
                    title: 'User account required',
                    text: "This feature is only for logged in users.",
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sign in'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push('/login');
                    } else {
                        this.$router.push('/');
                    }
                })
            }
        },

        async getUserId() {
            const token = localStorage.getItem('token');
            this.jwt = token;
            try {
                const decoded = jwtDecode(token);
                this.userID = decoded.id;
            } catch (err) {
                console.log(err);
            }
        },

        onFileChange_1() {
            let file = this.$refs.file_1.files[0];
            this.imgURL_1 = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();

            img.onload = () => {
                const canvas_sm = document.createElement('canvas');
                const ctx_sm = canvas_sm.getContext('2d');
                const canvas_1 = document.createElement('canvas');
                const ctx_1 = canvas_1.getContext('2d');

                const maxWidth = 250;
                const maxHeight = 155;
                const customWidth = this.phLargeWidth;
                const customHeight = this.phLargeHeight;
                const customText = this.watermark;
                const customFont = this.watermarkFont;

                let width_sm = img.width;
                let height_sm = img.height;
                let width_1 = img.width;
                let height_1 = img.height;

                // Resize for ph_sm
                if (width_sm > maxWidth) {
                    height_sm *= maxWidth / width_sm;
                    width_sm = maxWidth;
                }

                if (height_sm > maxHeight) {
                    width_sm *= maxHeight / height_sm;
                    height_sm = maxHeight;
                }

                // Resize for ph_1
                if (width_1 > customWidth) {
                    height_1 *= customWidth / width_1;
                    width_1 = customWidth;
                }

                if (height_1 > customHeight) {
                    width_1 *= customHeight / height_1;
                    height_1 = customHeight;
                }

                canvas_sm.width = width_sm;
                canvas_sm.height = height_sm;
                canvas_1.width = width_1;
                canvas_1.height = height_1;

                ctx_sm.drawImage(img, 0, 0, width_sm, height_sm);
                ctx_1.drawImage(img, 0, 0, width_1, height_1);

                canvas_sm.toBlob((blob_sm) => {
                    const reducedImage = new File([blob_sm], file.name, { type: file.type });
                    this.ph_sm = reducedImage;
                }, file.type);

                // Add custom font to ph_1
                ctx_1.font = customFont;
                ctx_1.fillStyle = this.watermarkColor;
                ctx_1.textAlign = this.watermarkAlign;
                ctx_1.globalAlpha = this.watermarkOpacity;
                ctx_1.fillText(customText, width_1 / 2, height_1 / 2);

                canvas_1.toBlob((blob_1) => {
                    const customizedImage = new File([blob_1], file.name, { type: file.type });
                    this.ph_1 = customizedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);

        },

        onFileChange_2() {
            let file = this.$refs.file_2.files[0];
            this.imgURL_2 = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = this.phLargeWidth;
                const maxHeight = this.phLargeHeight;
                const customText = this.watermark;
                const customFont = this.watermarkFont;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Add custom font to the middle of the image
                ctx.font = customFont;
                ctx.fillStyle = this.watermarkColor;
                ctx.textAlign = this.watermarkAlign;
                ctx.globalAlpha = this.watermarkOpacity;
                ctx.fillText(customText, width / 2, height / 2);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.ph_2 = reducedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);

        },

        onFileChange_3() {
            let file = this.$refs.file_3.files[0];
            this.imgURL_3 = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = this.phLargeWidth;
                const maxHeight = this.phLargeHeight;
                const customText = this.watermark;
                const customFont = this.watermarkFont; // Customize font style and size

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Add custom font to the middle of the image
                ctx.font = customFont;
                ctx.fillStyle = this.watermarkColor;
                ctx.textAlign = this.watermarkAlign;
                ctx.globalAlpha = this.watermarkOpacity;
                ctx.fillText(customText, width / 2, height / 2);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.ph_3 = reducedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);
        },

        onFileChange_4() {
            let file = this.$refs.file_4.files[0];
            this.imgURL_4 = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = this.phLargeWidth;
                const maxHeight = this.phLargeHeight;
                const customText = this.watermark;
                const customFont = this.watermarkFont;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Add custom font to the middle of the image
                ctx.font = customFont;
                ctx.fillStyle = this.watermarkColor;
                ctx.textAlign = this.watermarkAlign;
                ctx.globalAlpha = this.watermarkOpacity;
                ctx.fillText(customText, width / 2, height / 2);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.ph_4 = reducedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);
        },

        onFileChange_5() {
            let file = this.$refs.file_5.files[0];
            this.imgURL_5 = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = this.phLargeWidth;
                const maxHeight = this.phLargeHeight;
                const customText = this.watermark;
                const customFont = this.watermarkFont;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Add custom font to the middle of the image
                ctx.font = customFont;
                ctx.fillStyle = this.watermarkColor;
                ctx.textAlign = this.watermarkAlign;
                ctx.globalAlpha = this.watermarkOpacity;
                ctx.fillText(customText, width / 2, height / 2);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.ph_5 = reducedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);
        },

        onFileChange_6() {
            let file = this.$refs.file_6.files[0];
            this.imgURL_6 = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = this.phLargeWidth;
                const maxHeight = this.phLargeHeight;
                const customText = this.watermark;
                const customFont = this.watermarkFont;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Add custom font to the middle of the image
                ctx.font = customFont;
                ctx.fillStyle = this.watermarkColor;
                ctx.textAlign = this.watermarkAlign;
                ctx.globalAlpha = this.watermarkOpacity;
                ctx.fillText(customText, width / 2, height / 2);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.ph_6 = reducedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);
        },

        onFileChange_cLogo() {
            let file = this.$refs.file_c_logo.files[0];
            this.imgURLCLogo = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = 250;
                const maxHeight = 155;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.ph_sm = reducedImage;
                }, file.type);
            };
            img.src = URL.createObjectURL(file);
        },

        inpFieldVisible() {
            this.$route.query.typeOfAnimal === 'true' ? this.typeOfAnimal = true : this.typeOfAnimal = false;
            this.$route.query.propertyType === 'true' ? this.propertyType = true : this.propertyType = false;
            this.$route.query.bedRooms === 'true' ? this.bedRooms = true : this.bedRooms = false;
            this.$route.query.bathRooms === 'true' ? this.bathRooms = true : this.bathRooms = false;
            this.$route.query.bikeType === 'true' ? this.bikeType = true : this.bikeType = false;
            this.$route.query.landType === 'true' ? this.landType = true : this.landType = false;
            this.$route.query.condition === 'true' ? this.condition = true : this.condition = false;
            this.$route.query.conditionRe === 'true' ? this.conditionRe = true : this.conditionRe = false;
            this.$route.query.itemType === 'true' ? this.itemType = true : this.itemType = false;
            this.$route.query.landSize === 'true' ? this.landSize = true : this.landSize = false;
            this.$route.query.houseSize === 'true' ? this.houseSize = true : this.houseSize = false;
            this.$route.query.size === 'true' ? this.size = true : this.size = false;
            this.$route.query.address === 'true' ? this.address = true : this.address = false;
            this.$route.query.brand === 'true' ? this.brand = true : this.brand = false;
            this.$route.query.model === 'true' ? this.model = true : this.model = false;
            this.$route.query.title === 'true' ? this.title = true : this.title = false;
            this.$route.query.screenSize === 'true' ? this.screenSize = true : this.screenSize = false;
            this.$route.query.capacity === 'true' ? this.capacity = true : this.capacity = false;
            this.$route.query.edition === 'true' ? this.edition = true : this.edition = false;
            this.$route.query.yearOfManufacture === 'true' ? this.yearOfManufacture = true : this.yearOfManufacture = false;
            this.$route.query.modelYear === 'true' ? this.modelYear = true : this.modelYear = false;
            this.$route.query.mileage === 'true' ? this.mileage = true : this.mileage = false;
            this.$route.query.engineCapacity === 'true' ? this.engineCapacity = true : this.engineCapacity = false;
            this.$route.query.fuelType === 'true' ? this.fuelType = true : this.fuelType = false;
            this.$route.query.transmission === 'true' ? this.transmission = true : this.transmission = false;
            this.$route.query.bodyType === 'true' ? this.bodyType = true : this.bodyType = false;
            this.$route.query.features === 'true' ? this.features = true : this.features = false;
            this.$route.query.jobType === 'true' ? this.jobType = true : this.jobType = false;
            this.$route.query.jobPosition === 'true' ? this.jobPosition = true : this.jobPosition = false;
            this.$route.query.requiredExperience === 'true' ? this.requiredExperience = true : this.requiredExperience = false;
            this.$route.query.requiredEducation === 'true' ? this.requiredEducation = true : this.requiredEducation = false;
            this.$route.query.negotiable === 'false' ? this.negotiable = false : this.negotiable = true;
            this.$route.query.bid === 'false' ? this.bid = false : this.bid = true;
            this.$route.query.price === 'false' ? this.price = false : this.price = true;
            this.$route.query.addPhotos === 'false' ? this.addPhotos = false : this.addPhotos = true;
            this.$route.query.addCompanyLogo === 'true' ? this.addCompanyLogo = true : this.addCompanyLogo = false;
        },

        validation() {
            if (this.districtInp === '') {
                this.errors.district = 'Please select the district';
            } else {
                this.errors.district = '';
            }

            if (this.descriptionInp === '') {
                this.errors.description = 'Description required';
            } else {
                this.errors.description = '';
            }

            if (this.categoryMain !== 'Jobs') {
                if (this.imgURL_1 === null) {
                    this.errors.image = 'Image required';
                } else {
                    this.errors.image = '';
                }
            } else {
                if (this.imgURLCLogo == null) {
                    this.errors.cLogo = 'Logo required';
                } else {
                    this.errors.cLogo = '';
                }

                if(this.jobType_2Inp === '') {
                    this.errors.jobType2 = 'Job type required'
                } else {
                    
                }
            }

            if (this.categoryMain !== 'Cars & Vehicles') {
                if (this.titleInp === '') {
                    this.errors.title = 'Title Required';
                } else {
                    if (this.invalidTitles.some(invalidTitle => invalidTitle.toLowerCase() === this.titleInp.toLowerCase())) {
                        this.errors.title = 'Invalid title'
                    } else {
                        this.errors.title = '';
                    }
                }
            } else {
                if(this.brandInp === '') {
                    this.errors.brand = 'Brand required'
                } else {
                    this.errors.brand = ''
                }

                if(this.modelInp === '') {
                    this.errors.model = 'Model Required'
                } else {
                    this.errors.model = ''
                }
            }

            if (this.price !== false) {
                if (this.priceInp === '') {
                    this.errors.price = 'Price required'
                } else {
                    if (!isNaN(parseInt(this.priceInp))) {
                        this.errors.price = '';
                    } else {
                        this.errors.price = 'Invalid input'
                    }
                }
            }

            if (this.isBid === true) {
                if (this.startPrice === null) {
                    this.errors.bidStartPrice = 'Start price required'
                } else {
                    if (!isNaN(parseInt(this.startPrice))) {
                        this.errors.bidStartPrice = ''
                    } else {
                        this.errors.bidStartPrice = 'Invalid input';
                    }
                }

                if (this.duration === null) {
                    this.errors.bidDuration = 'Duration required'
                } else {
                    this.errors.bidDuration = '';
                }

                if (this.bidGap === null) {
                    this.errors.bidGap = 'Bid gap required'
                } else {
                    this.errors.bidGap = '';
                }
            }
        },

        async postAd() {
            this.validation();
            if (this.errors.district === '' && this.errors.title === '' && this.errors.description === '' && this.errors.price === '' && this.errors.image === '' && this.errors.cLogo === '' && this.errors.bidStartPrice === '' && this.errors.bidDuration === '' && this.errors.bidGap === '' && this.errors.brand === '' && this.errors.model === '') {
                let separator = " : ";
                let subCategory_1 = this.categorySub.split(separator)[0];
                let subCategory_2 = this.categorySub.split(separator)[1];

                this.isLoading = true;
                let images = [];

                if (this.addPhotos === true) {
                    images[0] = typeof this.$refs.file_1.files[0] !== 'undefined' ? this.ph_1 : null;
                    images[1] = typeof this.$refs.file_2.files[0] !== 'undefined' ? this.ph_2 : null;
                    images[2] = typeof this.$refs.file_3.files[0] !== 'undefined' ? this.ph_3 : null;
                    images[3] = typeof this.$refs.file_4.files[0] !== 'undefined' ? this.ph_4 : null;
                    images[4] = typeof this.$refs.file_5.files[0] !== 'undefined' ? this.ph_5 : null;
                    images[5] = typeof this.$refs.file_6.files[0] !== 'undefined' ? this.ph_6 : null;
                }

                if (this.addCompanyLogo === true) {
                    images[6] = typeof this.$refs.file_c_logo.files[0] !== 'undefined' ? this.$refs.file_c_logo.files[0] : null;
                }

                const formData = new FormData();
                formData.append('images', this.ph_1);
                formData.append('images', images[1]);
                formData.append('images', images[2]);
                formData.append('images', images[3]);
                formData.append('images', images[4]);
                formData.append('images', images[5]);
                formData.append('images', images[6]);
                formData.append('images', this.ph_sm);
                formData.append('user_id', this.userID);
                formData.append('district', this.districtInp);
                formData.append('type_of_animal', this.typeOfAnimalInp);
                formData.append('property_type', this.propertyTypeInp);
                formData.append('bed_rooms', this.bedRoomsInp);
                formData.append('bath_rooms', this.bathRoomsInp);
                formData.append('bike_type', this.bikeTypeInp);
                formData.append('land_type', this.landTypeInp);
                formData.append('condition_1', this.conditionInp);
                formData.append('condition_2', this.conditionReInp);
                formData.append('item_type', this.itemTypeInp);
                formData.append('land_size', this.landSizeInp);
                formData.append('house_size', this.houseSizeInp);
                formData.append('size', this.sizeInp);
                formData.append('address', this.addressInp);
                formData.append('brand', this.brandInp);
                formData.append('model', this.modelInp);
                formData.append('title', this.titleInp);
                formData.append('screen_size', this.screenSizeInp);
                formData.append('capacity', this.capacityInp);
                formData.append('edition', this.editionInp);
                formData.append('year_of_manufacture', this.yearOfManufactureInp);
                formData.append('model_year', this.modelYearInp);
                formData.append('mileage', this.mileageInp);
                formData.append('engine_capacity', this.engineCapacityInp);
                formData.append('fuel_type', this.fuelTypeInp);
                formData.append('transmission', this.transmissionInp);
                formData.append('body_type', this.bodyTypeInp);
                formData.append('features', this.featuresInp);
                formData.append('description', this.descriptionInp);
                formData.append('job_type_1', this.jobType_1Inp);
                formData.append('job_type_2', this.jobType_2Inp);
                formData.append('experience', this.requiredExperienceInp);
                formData.append('required_education', this.requiredEducationInp);
                formData.append('price', this.priceInp);
                formData.append('negotiable', true);
                formData.append('is_bid', this.isBid);
                formData.append('start_price', this.startPrice);
                formData.append('duration', this.duration);
                formData.append('bid_gap', this.bidGap);
                formData.append('bids', this.bids);

                await axios.post(`${this.api}/post-ad/${this.categoryMain}/${subCategory_1}/${subCategory_2}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${this.jwt}`
                    }
                }).then(async (response) => {
                    console.log(this.userID)
                    console.log(response.data.adID)
                    this.isLoading = false;
                    await axios.post(`${this.api}/notifications/post-ad`, {
                        mainCategory: this.categoryMain,
                        itemId: response.data.adID,
                        userId: this.userID,
                        title: this.categoryMain === 'Cars & Vehicles' ? this.brandInp + ' ' + this.modelInp : this.titleInp,
                        encryptedItemId: this.encrypt(response.data.adID.toString()),
                        encryptedUserId: this.encrypt(this.userID.toString()),
                    });
                    Swal.fire({
                        title: 'Success',
                        text: "Your ad will be in ads fields soon",
                        icon: 'success',
                    }).then(() => {
                        this.$router.push('/');
                    })
                }).catch(error => {
                    this.isLoading = false;
                    Swal.fire({
                        title: 'Error',
                        text: "Something went wrong",
                        icon: 'error',
                    });
                    console.log(error);
                })
            } else {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        },
    }
}
</script>

<style scoped>
.txt-error {
    color: red;
}

.inp-error {
    border: 1px solid red;
}

.img-upload-error {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid rgb(255, 0, 0);
}

#duration {
    padding-left: 0;
}

#gap {
    padding-right: 0;
}

.img-sample {
    width: 0000%;
}

.row {
    padding: 0;
    margin: 0;
}

.test {
    width: 0;
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.pho-pre {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid black;
}

.pho-pre img,
.img-upload-error img {
    max-width: 100%;
    max-height: 100%;
}

.top {
    padding: 10px 0;
}

.top p {
    font-size: 18px;
    font-weight: 500;
}

.logo-box {
    height: 40px;
}

.content {
    min-height: 70vh;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: rgba(0, 0, 0, 0.041);
}

.title {
    text-align: center;
    font-size: 20px;
}

.sub-title {
    text-align: center;
}

.form {
    width: 700px;
    margin: 0 auto;
    margin-top: 50px;
}

.sec {
    margin-bottom: 20px;
}

label {
    margin-bottom: 10px;
}

.item-images {
    display: flex;
    flex-wrap: wrap;
}

.btn-post-ad {
    width: 200px;
    height: 40px;
    border: none;
    color: white;
    border-radius: 5px;
}

@media only screen and (max-width: 991px) {
    .form {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .btn-post-ad {
        width: 100%;
    }
}

.bid-trans-enter-from {
    opacity: 0;
}

.bid-trans-enter-active {
    transition: all .5s ease;
}</style>