<template>
    <div class="main">
        <div class="filter-popup-box dis-fl jus-con-cen container-xxl">
            <div class="filter-popup-wrapper">
                <div class="category-wrapper dis-fl">
                    <div class="main-category">
                        <h5>Services</h5>
                        <button class="pad-mar-no dis-fl jus-con-sp-bt al-it-cen"
                            v-for="(category, index) in categories" :key="index" @click="CategorySelect(index)">
                            <p class="pad-mar-no">{{ category.name }}</p>
                            <img :class="category.hasSub ? 'icn-fo-arr' : 'icn-fo-arr-hide'"
                                src="../../assets/icons/forward-black.png" alt="">
                        </button><br>
                    </div>
                    <div class="sub-category">
                        <a class="a-txt-dec-no" v-for="(item, index) in displaySub" :key="index" href="">{{
                            item.name
                        }}</a>
                        <div class="sub-cat-bg-img-box dis-fl">
                            <!-- <img class="img-fit" src="../../assets/test/house.png" alt=""> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="filter-popup-mob">
            <transition name="main-cat-trans">
                <div v-if="mainCatIsVisible" class="main-category-mob">
                    <div class="dis-fl jus-con-sp-bt al-it-cen">
                        <h5 class="pad-mar-no">Services</h5>
                        <button class="btn-close" @click="mainClose()"></button>
                    </div>
                    <button class="btn-mob-cat pad-mar-no dis-fl jus-con-sp-bt al-it-cen"
                        v-for="(category, index) in categories" :key="index" @click="CategorySelect(index)">
                        <p class="pad-mar-no">{{ category.name }}</p>
                        <img :class="category.hasSub ? 'icn-fo-arr' : 'icn-fo-arr-hide'"
                            src="../../assets/icons/forward-black.png" alt="">
                    </button>
                </div>
            </transition>
            <transition name="sub-cat-trans">
                <div v-if="subCatIsVisible" class="sub-category-mob">
                    <div class="top dis-fl jus-con-sp-bt al-it-cen">
                        <button class="btn-back pad-mar-no dis-fl al-it-cen" @click="subClose()">
                            <img src="../../assets/icons/backward_theme.png" alt="">
                            <p class="pad-mar-no">Back to Services</p>
                        </button>
                        <button class="btn-close" @click="mainClose()"></button>
                    </div>
                    <div class="bot dis-fl">
                        <p class="selected-cat"> {{ selectedCat }} </p>
                        <!-- <a href="" class="a-txt-dec-no">See All in Audio</a> -->
                        <button class="dis-fl jus-con-sp-bt" v-for="(item, index) in displaySub" :key="index">
                            <p class="pad-mar-no">{{ item.name }}</p>
                        </button>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
export default {

    data() {
        return {
            mainCatIsVisible: true,
            subCatIsVisible: false,
            selectedCat: '',
            displaySub: [],
            categories: [
                { name: 'Childcare & Nanny', hasSub: false, link: '/post-ad/Services/Childcare & Nanny', title: true, bid: false, },
                { name: 'Cleaners & Cleaning', hasSub: false, link: '/post-ad/Services/Cleaners & Cleaning', title: true, bid: false, },
                { name: 'Entertainment', hasSub: false, link: '/post-ad/Services/Entertainment', title: true, bid: false, },
                { name: 'Financial & Legal', hasSub: false, link: '/post-ad/Services/Financial & Legal', title: true, bid: false, },
                { name: 'Fitness & Personal Trainer', hasSub: false, link: '/post-ad/Services/Fitness & Personal Trainer', title: true, bid: false, },
                { name: 'Food & Catering', hasSub: false, link: '/post-ad/Services/Food & Catering', title: true, bid: false, },
                { name: 'Health & Beauty', hasSub: false, link: '/post-ad/Services/Health & Beauty', title: true, bid: false, },
                { name: 'Moving & Storage', hasSub: false, link: '/post-ad/Services/Moving & Storage', title: true, bid: false, },
                { name: 'Music Lessons', hasSub: false, link: '/post-ad/Services/Music Lessons', title: true, bid: false, },
                { name: 'Photography & Video', hasSub: false, link: '/post-ad/Services/Photography & Video', title: true, bid: false, },
                { name: 'Skilled Trades', hasSub: true, id: 'Skilled Trades', bid: false, },
                { name: 'Tutors & Languages', hasSub: false, link: '/post-ad/Services/Tutors & Languages', title: true, bid: false, },
                { name: 'Wedding', hasSub: false, link: '/post-ad/Services/Wedding', title: true, bid: false, },
                { name: 'Travel & Vacations', hasSub: false, link: '/post-ad/Services/Travel & Vacations', title: true, bid: false, },
                { name: 'Other', hasSub: false, link: '/post-ad/Services/Other', title: true, bid: false, },
            ],

            skilledTrade: [
                { name: 'Appliance Repair & Installation', link: '/post-ad/Services/Skilled Trades : Appliance Repair & Installation', title: true, bid: false, },
                { name: 'Brick, Masonry & Concrete', link: '/post-ad/Services/Skilled Trades : Brick, Masonry & Concrete', title: true, bid: false, },
                { name: 'Carpentry, Crown Moulding & Trimwork', link: '/post-ad/Services/Skilled Trades : Carpentry, Crown Moulding & Trimwork', title: true, bid: false, },
                { name: 'Electrician', link: '/post-ad/Services/Skilled Trades : Electrician', title: true, bid: false, },
                { name: 'Excavation, Demolition & Waterproofing', link: '/post-ad/Services/Skilled Trades : Excavation, Demolition & Waterproofing', title: true, bid: false, },
                { name: 'Fence, Deck, Railing & Siding', link: '/post-ad/Services/Skilled Trades : Fence, Deck, Railing & Siding', title: true, bid: false, },
                { name: 'Flooring', link: '/post-ad/Services/Skilled Trades : Flooring', title: true, bid: false, },
                { name: 'Garage Door', link: '/post-ad/Services/Skilled Trades : Garage Door', title: true, bid: false, },
                { name: 'Heating, Ventilation & Air Conditioning', link: '/post-ad/Services/Skilled Trades : Heating, Ventilation & Air Conditioning', title: true, bid: false, },
                { name: 'Insulation', link: '/post-ad/Services/Skilled Trades : Insulation', title: true, bid: false, },
                { name: 'Interlock, Paving & Driveways', link: '/post-ad/Services/Skilled Trades : Interlock, Paving & Driveways', title: true, bid: false, },
                { name: 'Lawn, Tree Maintenance & Eavestrough', link: '/post-ad/Services/Skilled Trades : Lawn, Tree Maintenance & Eavestrough', title: true, bid: false, },
                { name: 'Painters & Painting', link: '/post-ad/Services/Skilled Trades : Painters & Painting', title: true, bid: false, },
                { name: 'Phone, Network, Cable & Home-wiring', link: '/post-ad/Services/Skilled Trades : Phone, Network, Cable & Home-wiring', title: true, bid: false, },
                { name: 'Plumbing', link: '/post-ad/Services/Skilled Trades : Plumbing', title: true, bid: false, },
                { name: 'Renovations, General Contracting & Handyman', link: '/post-ad/Services/Skilled Trades : Renovations, General Contracting & Handyman', title: true, bid: false, },
                { name: 'Roofing', link: '/post-ad/Services/Skilled Trades : Roofing', title: true, bid: false, },
                { name: 'Snow Removal & Property Maintenance', link: '/post-ad/Services/Skilled Trades : Snow Removal & Property Maintenance', title: true, bid: false, },
                { name: 'Welding', link: '/post-ad/Services/Skilled Trades : Welding', title: true, bid: false, },
                { name: 'Windows & Doors', link: '/post-ad/Services/Skilled Trades : Windows & Doors', title: true, bid: false, },
                { name: 'Other', link: '/post-ad/Services/Skilled Trades : Other', title: true, bid: false, },
            ],
        }
    },

    methods: {
        mainClose() {
            this.$emit('isVisible', false);
        },

        subClose() {
            this.subCatIsVisible = false;
            this.mainCatIsVisible = true;
        },

        CategorySelect(index) {
            if (this.categories[index].hasSub === true) {
                switch (this.categories[index].id) {
                    case 'skilledTrade':
                        this.displaySub = this.skilledTrade;
                        break;
                    default:
                        return;
                }
                this.mainCatIsVisible = false;
                this.subCatIsVisible = true;
                this.selectedCat = this.categories[index].name;
            } else {
                this.$router.push({ path: this.categories[index].link, query: this.categories[index] });
            }
        },
    },

}

</script>

<style scoped>
.selected-cat {
    font-weight: bold;
    font-size: 20px;
}

.icn-fo-arr {
    width: 10px;
    height: 15px;
}

.icn-fo-arr-hide {
    display: none;
}

.main{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.filter-popup-box {
    width: 100%;
    height: 80vh;
    position: absolute;
    top: 100px;
    z-index: 2;
}

.category-wrapper {
    height: 100%;
    padding: 20px;
    padding-right: 0;
}

.filter-popup-wrapper {
    width: 100%;
    height: 100%;
    margin: 0 50px;
    background-color: white;
}

.filter-popup-mob {
    display: none;
}

.main-category {
    width: 300px;
    height: 100%;
    overflow: auto;
}

.main-category::-webkit-scrollbar {
    width: 6px;
}

.main-category::-webkit-scrollbar-track {
    border-radius: 10px;
}

.main-category::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.315);
    border-radius: 10px;
}

.main-category::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 128, 128, 0.493);
}

.main-category button,
.btn-mob-cat,
.sub-category-mob .bot button {
    width: 100%;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.050);
    background-color: transparent;
    text-align: left;
}

.sub-category {
    width: 100%;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
}

.sub-category a {
    margin: 8px 0;
    color: black;
    z-index: 1;
}

.sub-cat-bg-img-box {
    height: 100%;
    opacity: .5;
    align-items: flex-end;
    position: absolute;
    right: 0;
}

@media only screen and (max-width: 1200px) {
    .filter-popup-box {
        top: 130px;
    }

    .sub-cat-bg-img-box {
        display: none;
    }
}

@media only screen and (max-width: 991px) {

    .filter-popup-box {
        display: none;
    }

    .filter-popup-mob {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 2;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.281);
        backdrop-filter: blur(2px);
    }

    .main-category-mob {
        width: 700px;
        margin: auto;
        padding: 20px 10px 50px 10px;
        display: flex;
        position: relative;
        flex-direction: column;
        background-color: white;
    }

    .sub-category-mob {
        width: 700px;
        margin: auto;
        display: flex;
        position: relative;
        flex-direction: column;
        background-color: white;
    }

    .sub-category-mob .top {
        padding: 15px 10px;
        background-color: #8EC64111;
    }

    .btn-back {
        border: none;
        background-color: transparent;
    }

    .btn-back img {
        width: 6px;
        height: 11px;
        margin-right: 5px;
    }

    .btn-back p {
        color: #8EC641;
    }

    .sub-category-mob .bot {
        padding: 20px 10px 50px 10px;
        flex-direction: column;
    }
}

@media only screen and (max-width: 767px) {

    .main-category-mob,
    .sub-category-mob {
        width: 100%;
    }
}
</style>