<template>
    <div>
        <div class="main col-theme dis-fl jus-con-cen al-it-cen">
            <h3 style="color: white">Ad Large</h3>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.main {
    width: 300px;
    height: 218px;
    padding: 0 10px;
}

@media only screen and (max-width: 600px) {
    .main{
        width: 100%;
    }
}

</style>