<template>
  <div>
    <div class="main res-py dis-fl jus-con-sp-bt container-xxl">
      <div class="le dis-fl">
        <div class="logo-box">
          <a href="/">
            <img class="img-fit" src="../assets/logos/popit-logo-black.png" alt="" />
          </a>
        </div>
        <div class="search-location-full-dis dis-fl">
          <div class="srch-wrapper dis-fl al-it-cen">
            <div class="icn-srch dis-fl">
              <img src="../assets/icons/search.png" alt="" />
            </div>
            <input v-model="srchKey" class="inp-srch" type="text" name="" id="" placeholder="Search" />
            <div style="width: 1px; height: 70%; background-color: rgba(0, 0, 0, 0.288);"></div>
            <div class="filter-wrapper">
              <button @click="(isFilterVisible = !isFilterVisible)" class="btn-srch-filter f-sz-sm dis-fl al-it-cen">
                <img class="icn-filter" :src="require('../assets/icons/filters/' + selectedFilterImg + '')" alt="" />
                <p class="pad-mar-no">{{ selectedFilter }}</p>
                <img :class="(isFilterVisible === true ? 'icn-expand-tr' : 'icn-expand-fl')"
                  src="../assets/icons/expand.png" alt="" />
              </button>
              <transition name="dropdown-trans">
                <div v-if="isFilterVisible" class="filter-items">
                  <button v-for="(item, index) in filters" :key="index" @click="filterSelect(index)"
                    class="dis-fl f-sz-sm dis-fl al-it-cen">
                    <img class="btn-icn-filter" :src="require('../assets/icons/filters/' + item.image + '')" alt="" />
                    <p class="pad-mar-no">{{ item.name }}</p>
                  </button>
                </div>
              </transition>
            </div>
            <button @click="searchClick()" class="btn-srch f-sz-sm">Search</button>
          </div>
          <div class="location-btn-wrapper">
            <button @click="isLocationvisible = !isLocationvisible"
              class="btn-location f-sz-sm dis-fl al-it-cen jus-con-cen">
              <img class="icn-location" src="../assets/icons/location.png" alt="" />
              <p class="pad-mar-no">{{ selectedLocation }}</p>
            </button>
            <transition name="dropdown-trans">
              <div v-if="isLocationvisible" class="locations dis-fl" ref="myDiv">
                <div class="locations-wrapper">
                  <button v-for="(item, index) in locations" :key="index" @click="locationSelect(index)"
                    class="f-sz-sm pad-mar-no">
                    {{ item }}
                  </button>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="ri dis-fl jus-con-sp-bt al-it-cen">
        <div v-if="isLoggedIn" class="profile-wrapper dis-fl">
          <div v-if="notifications > 0" class="notification-counter-round">
            <p class="txt-notification-counter pad-mar-no">{{ notifications }}</p>
          </div>
          <button @click="profileRedirect()" class="profile pad-mar-no bor-col-theme">
            <img :src="profileImg || require('../assets/icons/user.png')" alt="">
          </button>
        </div>
        <div v-if="!isLoggedIn" class="register-sign-container dis-fl">
          <div class="register-hide dis-fl">
            <router-link class="a-log-reg a-txt-dec-no" to="/register">Register</router-link>
            <p class="pad-mar-no">&nbsp;or&nbsp;</p>
          </div>
          <router-link class="a-log-reg a-txt-dec-no" to="/login">Sign In</router-link>
        </div>
        <div>
          <router-link to="/post-ad" class="a-post-ad a-txt-dec-no col-theme trans-low">Post ad</router-link>
        </div>
      </div>

    </div>
    <!--  -->
    <div class="search-location-max-width-1200 res-py dis-fl jus-con-sp-bt">
      <div class="srch-wrapper dis-fl al-it-cen">
        <div class="icn-srch dis-fl">
          <img src="../assets/icons/search.png" alt="" />
        </div>
        <input class="inp-srch" v-model="srchKey" type="text" name="" id="" placeholder="Search" />
        <div style="width: 1px; height: 70%; background-color: rgba(0, 0, 0, 0.288);"></div>
        <div class="filter-wrapper">
          <button @click="(isFilterVisible = !isFilterVisible)" class="btn-srch-filter f-sz-sm dis-fl al-it-cen">
            <img class="icn-filter" :src="require('../assets/icons/filters/' + selectedFilterImg + '')" alt="" />
            <p class="select-filter-name pad-mar-no">{{ selectedFilter }}</p>
            <img :class="(isFilterVisible === true ? 'icn-expand-tr' : 'icn-expand-fl')" src="../assets/icons/expand.png"
              alt="" />
          </button>
          <transition name="dropdown-trans">
            <div v-if="isFilterVisible" class="filter-items" v-click-outside="filterClickOutside">
              <button v-for="(item, index) in filters" :key="index" @click="filterSelect(index)"
                class="dis-fl f-sz-sm dis-fl al-it-cen">
                <img class="btn-icn-filter" :src="require('../assets/icons/filters/' + item.image + '')" alt="" />
                <p class="pad-mar-no">{{ item.name }}</p>
              </button>
            </div>
          </transition>
        </div>
        <button @click="searchClick()" class="btn-srch f-sz-sm">Search</button>
        <button @click="searchClick()" class="btn-srch-icon pad-mar-no">
          <img class="img-fit" src="../assets/icons/search.png" alt="">
        </button>
      </div>
      <div class="location-btn-wrapper dis-fl">
        <button @click="isLocationvisible = !isLocationvisible" class="btn-location f-sz-sm dis-fl al-it-cen jus-con-cen">
          <img class="icn-location" src="../assets/icons/location.png" alt="" />
          <p class="pad-mar-no">{{ selectedLocation }}</p>
        </button>
        <transition name="dropdown-trans">
          <div v-if="isLocationvisible" class="locations dis-fl" v-click-outside="locationClickOutside">
            <div class="locations-wrapper">
              <button v-for="(item, index) in locations" :key="index" @click="locationSelect(index)"
                class="f-sz-sm pad-mar-no">
                {{ item }}
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import vClickOutside from 'click-outside-vue3';
import jwtDecode from 'jwt-decode'
import axios from 'axios';

export default {
  data() {
    return {
      notifications: 0,
      profileImg: null,
      userId: 9,
      isLocationvisible: false,
      selectedLocation: "",
      locations: [
        "All of Sri Lanka",
        "Colombo",
        "Kandy",
        "Galle",
        "Ampara",
        "Anuradhapura",
        "Badulla",
        "Batticaloa",
        "Gampaha",
        "Hambantota",
        "Jaffna",
        "Kalutara",
        "Kegalle",
        "Kilinochchi",
        "Kurunegala",
        "Manner",
        "Matale",
        "Matara",
        "Monaragala",
        "Mullativu",
        "Nuwara Eliya",
        "Polonnaruwa",
        "Puttalam",
        "Ratnapura",
        "Trincomalee",
        "Vavunia",
      ],

      isFilterVisible: false,
      selectedFilter: 'All Categories',
      srchKey: '',
      selectedFilterImg: 'all.png',
      filters: [
        { name: 'All Categories', image: 'all.png' },
        { name: 'Buy & Sell', image: 'buy-sell.png' },
        { name: 'Cars & Vehicles', image: 'cars-vehicles.png' },
        { name: 'Real Estate', image: 'real-estate.png' },
        { name: 'Jobs', image: 'jobs.png' },
        { name: 'Services', image: 'services.png' },
        { name: 'Pets', image: 'pet.png' },
        { name: 'Community', image: 'community.png' },
        { name: 'Vacation Rentals', image: 'vacation-rentals.png' },
        { name: 'Vapes', image: 'vapes.png' },
      ],
      isLoggedIn: null,
    };
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  mounted() {
    this.getNotificationsCount();
    this.getSelectedLocation();
    this.isUserLoggedIn();
    this.setUserImg();
  },

  computed: {
    ...mapState(['api', 'token', 'profileImgURL'])
  },

  methods: {

    async getNotificationsCount() {
      try {
        const token = localStorage.getItem('token');
      const decoded = jwtDecode(token);
      try{
        await axios.get(`${this.api}/notifications/push-notifications/${decoded.id}`).then((response) => {
          const unreadNotifications = response.data.filter((notification) => notification.is_read === 0);
          this.notifications = unreadNotifications.length;
        });
      } catch(e) {
        console.log(e);
      }
      setInterval(async () => {
        try {
        await axios.get(`${this.api}/notifications/push-notifications/${decoded.id}`).then((response) => {
          const unreadNotifications = response.data.filter((notification) => notification.is_read === 0);
          this.notifications = unreadNotifications.length;
        })
      } catch (err) {
        console.log(err);
      }
      }, 10000);
      } catch (error) {}
    },

    async setUserImg() {
      this.profileImg = this.profileImgURL !== 'data:image/jpeg;base64,' ? this.profileImgURL : null;
    },

    isUserLoggedIn() {
      if (this.token !== null) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    },

    getSelectedLocation() {
      if (localStorage.getItem("selectedLocation") === null) {
        localStorage.setItem("selectedLocation", "All of Sri Lanka");
      } else {
        if (localStorage.getItem("selectedLocation") == "") {
          localStorage.setItem("selectedLocation", "All of Sri Lanka");
        }
      }
      this.selectedLocation = localStorage.getItem("selectedLocation");
    },

    locationSelect(index) {
      this.selectedLocation = this.locations[index];
      localStorage.setItem("selectedLocation", this.selectedLocation);
      this.isLocationvisible = false;
      location.reload();
    },

    filterSelect(index) {
      this.selectedFilter = this.filters[index].name;
      this.selectedFilterImg = this.filters[index].image;
      this.isFilterVisible = false;
      this.selectedFilterImg = this.filters[index].image;
    },

    profileRedirect() {
      this.$router.push(`/profile/ads`);
    },

    filterClickOutside() {
      this.isFilterVisible = false;
    },

    locationClickOutside() {
      this.isLocationvisible = false;
    },

    searchClick() {
      if (this.srchKey != '') {
        this.$router.push(`/ads/search/${this.selectedFilter}/${this.srchKey}`);
      }
    }
  },
};
</script>

<style scoped>
.icn-filter {
  width: 20px;
  height: 20px;
}

.btn-icn-filter {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.icn-expand-tr {
  width: 15px;
  height: 8px;
  transition: .2s;
  transform: rotate(-180deg);
}

.icn-expand-fl {
  width: 15px;
  height: 8px;
  transition: .2s;
  transform: rotate(0deg);
}

.icn-location {
  width: 15px;
  height: 20px;
  margin-right: 10px;
}

.main {
  padding-top: 10px;
  padding-bottom: 10px;
}

.le {
  width: 75%;
}

.ri {
  width: 250px;
}

.logo-box {
  min-width: 100px;
  height: 40px;
  margin-right: 30px;
}

.a-log-reg {
  font-weight: 500;
}

.a-post-ad {
  font-weight: 500;
  padding: 9px 10px;
  border-radius: 5px;
  color: white;
}

.a-post-ad:hover {
  box-shadow: 0px 1px 10px #228B22;
}

.srch-wrapper {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.288);
  border-radius: 5px;
}

.icn-srch {
  min-width: 30px;
  height: 100%;
  padding-bottom: 10px;
  padding-right: 5px;
  justify-content: flex-end;
  align-items: flex-end;
}

.icn-srch img {
  max-width: 60%;
  max-height: 60%;
}

.inp-srch {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.btn-srch-filter {
  min-width: 170px;
  height: 38px;
  font-weight: 500;
  justify-content: space-evenly;
  border: none;
  background-color: transparent;
}

.btn-srch {
  min-width: 100px;
  height: 100%;
  font-weight: 500;
  border: none;
  background-color: transparent;
  border-left: 1px solid rgba(0, 0, 0, 0.288);
}

.location-btn-wrapper {
  min-width: 130px;
  margin-left: 30px;
  position: relative;
}

.btn-location {
  height: 100%;
  font-weight: 500;
  border: none;
  background-color: transparent;
}

.locations {
  width: 130px;
  height: 300px;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  z-index: 2;
  background-color: red;
}

.locations-wrapper {
  overflow: auto;
  background-color: rgb(241, 241, 241);
}

.locations-wrapper::-webkit-scrollbar {
  width: 5px;
}

.locations-wrapper::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.137);
  border-radius: 10px;
}

.locations-wrapper::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.212);
}

.locations-wrapper button {
  width: 100%;
  padding: 8px 0;
  border: none;
  border-top: 1px solid white;
  background-color: transparent;
}

.filter-wrapper {
  position: relative;
}

.filter-items {
  width: 100%;
  position: absolute;
  top: 38px;
  background-color: white;
  z-index: 2;
}

.filter-items button {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.066);
  background-color: transparent;
}

.search-location-max-width-1200 {
  display: none;
}

.profile-wrapper {
  width: 65%;
  height: 40px;
  justify-content: flex-end;
  position: relative;
}

.notification-counter-round {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt-notification-counter {
  color: white;
  font-size: 8px;
}

.profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid;
  background-color: white;
}

.profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .register-sign-container {
  display: none;
} */

@media only screen and (max-width: 1200px) {

  .search-location-full-dis {
    display: none;
  }

  .location-btn-wrapper {
    justify-content: flex-end;
  }

  .search-location-max-width-1200 {
    display: flex;
  }

  .locations {
    top: 40px;
  }

  .btn-srch-icon {
    display: none;
  }

  .srch-wrapper {
    margin-bottom: 10px;
  }
}

/* @media only screen and (max-width: 991px) {
  .filter{
    padding: 0;
  }
} */

@media only screen and (max-width: 767px) {

  .register-hide,
  .select-filter-name,
  .icn-srch {
    display: none;
  }

  .ri {
    justify-content: flex-end;
  }

  .a-post-ad {
    margin-left: 20px;
  }

  .search-location-max-width-1200 {
    flex-direction: column;
  }

  .location-btn-wrapper {
    margin: 10px 0;
    justify-content: flex-start;
  }

  .btn-srch {
    display: none;
  }

  .btn-srch-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    height: 40px;
    border: none;
  }

  .btn-srch-icon img {
    max-width: 50%;
    max-height: 50%;
  }

  .btn-srch-filter {
    min-width: 50px;
    justify-content: space-between;
    padding: 0 8px 0 0;
  }

  .icn-expand-tr,
  .icn-expand-fl {
    width: 10px;
    height: 6px;
  }

  .icn-filter {
    width: 20px;
    height: 20px;
  }

  .filter-items {
    width: 150px;
    right: 0px;
  }

  .inp-srch {
    padding-left: 10px;
  }

  .locations {
    top: 30px;
  }

  .profile-wrapper {
    width: 40px;
  }
}
</style>
