/* eslint-disable */
import { createWebHistory, createRouter } from 'vue-router'
import Home from './views/home-scr.vue'
import Items from './views/items-scr.vue'
import ItemsSearch from './views/items-search-scr.vue'
import Item from './views/item-scr.vue'
import Job from './views/job-scr.vue'
import Bid from './views/bid-scr.vue'
import Shop from './views/shop-scr.vue'
import ProfilePersonal from './views/profile-personal-scr.vue'
import PostAd from './views/post-ad-scr.vue'
import PostAdForm from './views/post-ad-form-scr.vue'
import ProfileEdit from './views/profile-edit-scr.vue'
import Login from './views/login-scr.vue'
import Register from './views/register-scr.vue'
import InsertUserInfo from './views/reg-user-info-scr.vue'
import JobProfile from './views/job-profile-src.vue'
import ResetPassword from './views/reset-password-scr.vue'
import PageNotFound from './views/not-found-scr.vue'
import Test from './views/test-scr.vue'
import Test2 from './views/test2-scr.vue'

const routes = [
    {
        name: 'Home',
        path: '/',
        component: Home,
        meta: {
            title: 'Home'
        }
    },

    {
        name: 'Items',
        path: '/ads/:main/:sub',
        component: Items,
        meta: {
            title: 'Items'
        }
    },

    {
        name: 'ItemsSearch',
        path: '/ads/search/:main/:srchKey',
        component: ItemsSearch,
        meta: {
            title: 'Items-Search'
        }
    },

    {
        name: 'Item',
        path: '/item/:itemCategory/:itemName/:itemId/:userId',
        component: Item,
        meta: {
            title: 'Item'
        }
    },

    {
        name: 'Job',
        path: '/job/:itemCategory/:itemName/:itemId/:userId',
        component: Job,
        meta: {
            title: 'Job'
        }
    },

    {
        name: 'Bid',
        path: '/bid/:itemCategory/:itemName/:itemId/:userId',
        component: Bid,
        meta: {
            title: 'Bid'
        }
    },

    {
        name: 'Shop',
        path: '/shop/:id',
        component: Shop,
        meta: {
            title: 'Shop'
        }
    },

    {
        name: 'Profile-Personal',
        path: '/profile/:adType',
        component: ProfilePersonal,
        meta: {
            title: 'Profile Persoanl'
        }
    },

    {
        name: 'PostAd',
        path: '/post-ad',
        component: PostAd,
        meta: {
            title: 'Post ad'
        }
    },

    {
        name: 'PostAdForm',
        path: '/post-ad/:main/:sub',
        component: PostAdForm,
        meta: {
            title: 'Post ad form'
        }
    },

    {
        name: 'ProfileEdit',
        path: '/profile-edit',
        component: ProfileEdit,
        meta: {
            title: 'Edit Profile'
        }
    },

    {
        name: 'Login',
        path: '/login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },

    {
        name: 'Register',
        path: '/register',
        component: Register,
        meta: {
            title: 'Register'
        }
    },

    {
        name: 'Insert User Info',
        path: '/user-info-add',
        component: InsertUserInfo,
        meta: {
            title: 'Add User Info'
        }
    },

    {
        name: 'JobProfile',
        path: '/job-profile',
        component: JobProfile,
        meta: {
            title: 'Job Profile'
        }
    },

    {
        name: 'ResetPassword',
        path: '/password-reset/:otp',
        component: ResetPassword,
        meta: {
            title: 'Reset Password'
        }
    },

    {
        name: 'Test',
        path: '/test',
        component: Test,
        meta: {
            title: 'Test'
        }
    },

    {
        name: 'Tes2t',
        path: '/test2',
        component: Test2,
        meta: {
            title: 'Test2'
        }
    },

    {
        name: 'NotFound',
        path: '/:pathMatch(.*)*',
        component: PageNotFound,
        meta: {
            title: '404 Not Found'
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        if(to.name === 'Items') {
            return savedPosition || { top: 0 }
        } else {
            return { top: 0 };
        }
      }
})

export default router;