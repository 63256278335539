<template>
  <div>
    <Navi />
    <loading v-model:active="isLoading" loader="bars" color="#228B22" />
    <div v-if="infoLoading">
      <JobSkeleton />
    </div>
    <div v-if="!infoLoading" class="main">
      <div class="content-box">
        <div class="logo-title-wraper">
          <div class="logo-box">
            <img :src="companyLogo" alt="" />
          </div>
          <div class="title-box">
            <p class="pad-mar-no">
              {{ title }} ({{ district }})
            </p>
          </div>
        </div>
        <p class="f-sz-sm">Posted on {{ date }} at {{ time }} by <span @click="aboutSellerClick()"
            class="seller-name f-col-theme">{{ sellerName }}</span></p>
        <button @click="apply()" class="btn-apply col-theme">Easy Apply</button>
        <hr />
        <p>
          <b>Job Type:</b> {{ job_type_2 }},
          {{ job_type_1 }}
        </p>
        <p><b>Required Education:</b> {{ required_education }}</p>
        <p><b>Required Experience:</b> {{ experience }} Year(s)</p>
        <p :style="{ whiteSpace: 'pre-line' }"> <b>Description:</b> {{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Navi from "../components/navigation-com.vue";
import axios from "axios";
import { mapState } from "vuex";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useHead } from '@vueuse/head';
import JobSkeleton from '../components/skeleton-loading/jobs-skel-com.vue'
import CryptoJS from 'crypto-js';

export default {
  data() {
    return {
      isLoading: false,
      infoLoading: true,
      userID: null,
      companyLogo: null,
      title: null,
      district: null,
      date: null,
      time: null,
      job_type_2: null,
      job_type_1: null,
      required_education: null,
      experience: null,
      description: null,
      sellerName: '',
      sellerEmail: null,
    };
  },

  components: {
    Navi,
    Loading,
    JobSkeleton,
  },

  computed: {
    ...mapState(["api", "idSecKey"]),
  },

  created() {
    // this.loadItemInfo();
  },

  async mounted() {
    this.getUserId();
    await this.loadItemInfo();
    useHead({
      title: `${this.title} | popit.lk`,
      meta: [
        {
          name: 'description',
          content: `Best buy & Sell in Sri Lanka | popit.lk`
        },
        {
          property: 'og:title',
          content: `${this.title} | popit.lk`,
        },
        {
          property: 'og:description',
          content: `Best buy & Sell in Sri Lanka | popit.lk`
        },
        {
          property: 'og:type',
          content: 'website'
        },
      ],
    });
  },

  methods: {

    decrypt(encryptedString) {
      const decrypted = CryptoJS.AES.decrypt(encryptedString.replace(/-/g, '/'), this.idSecKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });

      return decrypted.toString(CryptoJS.enc.Utf8)
    },

    async getUserId() {
      const token = localStorage.getItem("token");
      try {
        const decoded = jwtDecode(token);
        this.userID = decoded.id;
      } catch (err) {
        console.log(err);
      }
    },

    async loadItemInfo() {
      this.infoLoading = true;
      await axios.get(
        `${this.api}/get-ad/${this.$route.params.itemCategory}/${this.decrypt(this.$route.params.itemId)}/${this.decrypt(this.$route.params.userId)}`).then((response) => {
          this.companyLogo = `data:image/jpeg;base64,${response.data[0].ph_clogo}`;
          this.title = response.data[0].title
          this.district = response.data[0].district

          const dateTimeString = response.data[0].saved_at;
          const [dateString, timeString] = dateTimeString.split("T");
          const timeWithoutMillis = timeString.slice(0, -8);

          this.date = dateString;
          this.time = timeWithoutMillis;
          this.job_type_2 = response.data[0].job_type_2
          this.job_type_1 = response.data[0].job_type_1
          this.required_education = response.data[0].required_education
          this.experience = response.data[0].experience
          this.description = response.data[0].description

          this.infoLoading = false;
        });

      await axios.get(`${this.api}/users/fetch-user-info/${this.decrypt(this.$route.params.userId)}`).then((response) => {
        this.sellerName = response.data.firstName + ' ' + response.data.lastName;
        this.sellerMobile = response.data.mobile;
        this.sellerEmail = response.data.email;
      })
    },

    aboutSellerClick() {
      this.$router.push(`/shop/${this.$route.params.userId}`);
    },

    async apply() {

      Swal.fire({
        title: "Apply for the job",
        text: `After confirming your CV will send to the ${this.sellerName}. Please confirm to apply for this job.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "CONFIRM",
        cancelButtonText: "CANCEL",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          try {
            await axios
              .post(`${this.api}/emails/apply-job`, {
                id: this.userID,
                jobTitle: this.title,
                sellerEmail: this.sellerEmail,
              });
            this.isLoading = false;
            Swal.fire({
              title: "Success",
              text: "Your CV has been submitted",
              icon: "success",
            });
          } catch (error) {
            this.isLoading = false;
            Swal.fire({
              title: "Oops...!",
              text: "Something went wrong. Please try again",
              icon: "error",
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.seller-name {
  cursor: pointer;
}

.main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.content-box {
  width: 700px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.02);
}

.logo-title-wraper {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo-box {
  width: 80px;
  height: 80px;
  margin-right: 10px;
  position: relative;
}

.logo-box img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  display: flex;
  justify-content: center;
}

.title-box p {
  font-weight: bold;
  font-size: 18px;
}

.btn-apply {
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 5px;
  color: white;
}
</style>
