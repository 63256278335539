<template>
  <div>
    <div class="main pad-mar-no container-xxl">
      <Navi />
      <Filter />
      <div class="con-1">
        <MainBanner />
      </div>
      <!-- <div class="con-2 mar-bet-sec res-py">
        <DailyDeals />
      </div> -->
      <div class="con-3 dis-fl res-py">
        <div class="le-wrapper">
          <div class="mar-bet-sec" id="pop-1">
            <div class="pop-hd dis-fl jus-con-sp-bt al-it-cen">
              <h2 class="popular-head">Popular in Autos in Sri Lanka</h2>
              <router-link to="ads/Cars & Vehicles/All" class="pop-link a-txt-dec-no">Browse all Autos in Sri
                Lanka</router-link>
              <router-link to="ads/Cars & Vehicles/All" class="pop-link-mob a-txt-dec-no">Browse all</router-link>
            </div>
            <div class="row g-3">
              <div v-for="(item, index) in pop1" :key="index" class="col-lg-4 col-md-4 col-sm-6 col-6">
                <router-link :to="'ads' + item.link">
                  <div>
                    <Pop>
                      <template v-slot:img>
                        <div class="pop-skel-box" v-if="pop1ImgsAreLoading">
                          <Skeleton />
                        </div>
                        <img v-show="!pop1ImgsAreLoading" :src="require('../assets/home/autos/' + item.image + '')
                          " alt="" @load="pop1ImgsLoad()"/>
                      </template>
                      <template v-slot:title>{{ item.name }}</template>
                    </Pop>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="ad-sm mar-bet-sec">
            <!-- small banner add goes here -->
            <!-- <AdSmall /> -->
          </div>
          <div class="mar-bet-sec" id="pop-2">
            <div class="pop-hd dis-fl jus-con-sp-bt al-it-cen">
              <h2 class="popular-head">Popular Buy & Sell in Sri Lanka</h2>
              <router-link to="/ads/Buy & Sell/All" class="pop-link a-txt-dec-no">Browse all Buy & Sell in Sri
                Lanka</router-link>
              <router-link to="/ads/Buy & Sell/All" class="pop-link-mob a-txt-dec-no">Browse all</router-link>
            </div>
            <div class="row g-3">
              <div v-for="(item, index) in pop2" :key="index" class="col-lg-4 col-md-4 col-sm-6 col-6">
                <router-link :to="'/ads' + item.link">
                  <div>
                    <Pop>
                      <template v-slot:img>
                        <div class="pop-skel-box" v-if="pop2ImgsAreLoading">
                          <Skeleton />
                        </div>
                        <img v-show="!pop2ImgsAreLoading" :src="require('../assets/home/buy&sell/' + item.image + '')
                          " alt=""  @load="pop2ImgsLoad()"/>
                      </template>
                      <template v-slot:title>{{ item.name }}</template>
                    </Pop>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div v-if="!userID" class="sign-in-note col-theme dis-fl al-it-cen">
            <h5 class="text-center">Poppit's better when you're a member</h5>
            <p class="text-center">
              See more relevant listings, find what you're looking for quicker,
              and more!
            </p>
            <a class="a-txt-dec-no trans-low f-col-theme bor-col-theme" href="/login">Sign In</a>
          </div>
          <div class="mar-bet-sec" id="pop-3">
            <div class="pop-hd dis-fl jus-con-sp-bt al-it-cen">
              <h2 class="popular-head">Popular Vapes in Sri Lanka</h2>
              <router-link to="/ads/Vapes/All" class="pop-link a-txt-dec-no">Browse all Vapes in Sri
                Lanka</router-link>
              <router-link to="/ads/Vapes/All" class="pop-link-mob a-txt-dec-no">Browse all</router-link>
            </div>
            <div class="row g-3">
              <div v-for="(item, index) in pop3" :key="index" class="col-lg-4 col-md-4 col-sm-6 col-6">
                <router-link :to="'/ads' + item.link">
                  <div>
                    <Pop>
                      <template v-slot:img>
                        <div class="pop-skel-box" v-if="pop3ImgsAreLoading">
                          <Skeleton />
                        </div>
                        <img v-show="!pop3ImgsAreLoading" :src="require('../assets/home/vapes/' + item.image + '')
                          " alt=""  @load="pop3ImgsLoad()"/>
                      </template>
                      <template v-slot:title>{{ item.name }}</template>
                    </Pop>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="ri-wrapper dis-fl">
          <div class="quick-links-wrapper">
            <h5 class="">Discover more on Popit</h5><br>
            <a href="/ads/Buy & Sell/Free Stuff" class="dis-fl jus-con-sp-bt al-it-cen a-txt-dec-no">
              <p class="pad-mar-no">Free Stuff</p>
              <img src="../assets/icons/forward-black.png" alt="">
            </a>
            <a href="/ads/Community/All" class="dis-fl jus-con-sp-bt al-it-cen a-txt-dec-no">
              <p class="pad-mar-no">Community</p>
              <img src="../assets/icons/forward-black.png" alt="">
            </a>
            <a style="border: none" href="/ads/Vacation Rentals/All" class="dis-fl jus-con-sp-bt al-it-cen a-txt-dec-no">
              <p class="pad-mar-no">Vacation Rentals</p>
              <img src="../assets/icons/forward-black.png" alt="">
            </a>
          </div>
          <div class="ri-ad-box">
            <!-- <AdRight /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Navi from "../components/navigation-com.vue";
import Pop from "../components/popular-cards-com.vue";
import AdSmall from "../components/ad-small-com.vue";
import AdRight from "../components/ad-right-com.vue";
import DailyDeals from "../components/daily-deals-com.vue";
import MainBanner from "../components/main-banner-com.vue";
import Filter from "../components/filter-com.vue";
import { useHead } from '@vueuse/head';
import jwtDecode from 'jwt-decode';
import Skeleton from '../components/skeleton-loading/skel-com.vue';

export default {
  data() {
    return {
      userID: null,
      pop1: [
        { name: "Cars", image: "cars.webp", link: "/Cars & Vehicles/Cars" },
        { name: "Motorbikes", image: "bikes.webp", link: "/Cars & Vehicles/Motorbikes" },
        { name: "Threewheelers", image: "threewheels.webp", link: "/Cars & Vehicles/Three Wheelers" },
        { name: "Vans", image: "vans.webp", link: "/Cars & Vehicles/Vans" },
        { name: "Lorries", image: "lorries.webp", link: "/Cars & Vehicles/Lorries" },
        { name: "Auto Accessories", image: "cars.webp", link: "/Cars & Vehicles/Motorbikes" },
      ],
      pop2: [
        { name: "Phones", image: "phones.webp", link: "/Buy & Sell/Phones" },
        { name: "Computers", image: "computers.webp", link: "/Buy & Sell/Computers" },
        { name: "Computer Accessories", image: "computer-ac.webp", link: "/Buy & Sell/Computer Accessories" },
        { name: "Furniture", image: "furnitures.webp", link: "/Buy & Sell/Furniture" },
        { name: "Clothing", image: "clothing.webp", link: "/Buy & Sell/Clothing" },
        { name: "Musical Instruments", image: "musical.webp", link: "/Buy & Sell/Musical Instruments" },
      ],
      pop3: [
        { name: "Pods", image: "pods.webp", link: "/Vapes/Pods" },
        { name: "Mods", image: "mods.webp", link: "/Vapes/Mods" },
        { name: "Coils", image: "coils.webp", link: "/Vapes/Coils" },
        { name: "Cottons", image: "tanks.webp", link: "/Vapes/Tanks" },
        { name: "Accessories", image: "accessories.webp", link: "/Vapes/Accessories" },
        { name: "Other", image: "other.webp", link: "/Vapes/Other" },
      ],
      pop1ImgsAreLoading: true,
      pop2ImgsAreLoading: true,
      pop3ImgsAreLoading: true,
    };
  },

  components: {
    Navi,
    Pop,
    AdSmall,
    AdRight,
    DailyDeals,
    MainBanner,
    Filter,
    Skeleton,
  },

  mounted() {
    this.getUserId();
    if(localStorage.getItem('tst1FirstLogin') === null) {
      localStorage.setItem('token', '');
      localStorage.setItem('tst1FirstLogin', 'true')
      location.reload();
    }
    useHead({
            title: `Home | popit.lk`,
            meta: [
                {
                    name: 'description',
                    content: `Best buy & sell website in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:title',
                    content: `Home | popit.lk`,
                },
                {
                    property: 'og:description',
                    content: `Best buy & sell website in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:image',
                    content: 'https://www.octick.com/img/Octic_Logo_Black_landscape.6583c7ab.png'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
            ],
        });
  },

  methods: {
    async getUserId() {
      const token = localStorage.getItem('token');
      try {
        const decoded = await jwtDecode(token);
        this.userID = decoded.id;
      } catch (e) {
        this.userID = null;
      }
    },

    pop1ImgsLoad() {
      this.pop1ImgsAreLoading = false;
    },

    pop2ImgsLoad() {
      this.pop2ImgsAreLoading = false;
    },

    pop3ImgsLoad() {
      this.pop3ImgsAreLoading = false;
    },
  }
};

</script>

<style scoped>

.pop-skel-box{
  width: 100%;
  padding-bottom: 66.46%;
}

.main {
  margin: 0 auto;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.le-wrapper {
  max-width: 100%;
}

.popular-head {
  font-size: 20px;
}

.sign-in-note {
  padding: 30px 20px;
  border-radius: 5px;
  flex-direction: column;
  color: white;
}

.sign-in-note a {
  padding: 15px 20px;
  border: 2px solid;
  border-radius: 5px;
  font-weight: 500;
  background-color: white;
}

.sign-in-note a:hover {
  border: 2px solid white;
  color: white;
  background-color: #228B22;
}

.ri-wrapper {
  min-width: 350px;
  flex-direction: column;
  align-items: flex-end;
}

.quick-links-wrapper {
  width: 300px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.010);
  position: sticky;
  top: 20px;
}

.quick-links-wrapper a {
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.050);
  /* color: black; */
}

.quick-links-wrapper a img {
  width: 12px;
  height: 13px;
}

.ri-ad-box {
  margin-top: 20px;
  position: sticky;
  top: 20px;
}

.pop-link-mob {
  display: none;
}

@media only screen and (max-width: 991px) {
  .con-3 {
    flex-direction: column;
  }

  .ri-wrapper {
    min-width: 0;
    width: 100%;
    margin-bottom: 50px;
    flex-direction: row;
    justify-content: space-between;
  }

  .quick-links-wrapper {
    width: 100%;
    margin-right: 20px;
  }

  .pop-hd {
    flex-direction: column;
    align-items: flex-start;
  }

  .pop-link {
    display: none;
  }

  .pop-link-mob {
    display: block;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .ri-wrapper {
    flex-direction: column;
    justify-content: center;
  }

  .ri-ad-box {
    width: 100%;
    position: relative;
  }

  .quick-links-wrapper {
    margin-right: 0;
  }
}
</style>
