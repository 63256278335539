<template>
    <div>
        <transition name="dropdown-trans">
            <div v-if="pwResetIsVisible" @click="pwResetIsVisible = false;" class="pw-reset-pop">
                <div @click.stop class="pw-reset">
                    <div @click="pwResetIsVisible = false;" class="btn-close"></div>
                    <div class="reset-icon-box">
                        <img class="logo" src="../assets//logos/popit-logo-black.png" alt="">
                    </div>
                    <h5>Forgot your password?</h5>
                    <p class="f-pw-reset-guid f-sz-sm">Don't worry. Resetting your password is easy. Just type in the email
                        you regestered to popit.lk</p>
                    <div class="pw-reset-content">
                        <p class="pad-mar-no">Email</p>
                        <input v-model="pwResetEmail" type="text" name="" id="" class="form-control"
                            placeholder="example@gmail.com" :class="errors.resetEmail === '' ? '' : 'input-field-2-err'">
                        <div class="err f-sz-sm" v-if="errors.resetEmail">{{ errors.resetEmail }}</div>
                        <button @click="pwChangeEmailConfirmClick()" class="btn-confirm col-theme">Confirm</button>
                    </div>
                </div>
            </div>
        </transition>
        <Navi />
        <loading v-model:active="isLoading" loader="bars" color="#228B22" />
        <div class="main res-py container-xxl">
            <div class="login dis-fl jus-con-sp-bt">
                <div class="le ">
                    <p class="tx-title-main pad-mar-no">Sign in</p>
                    <input v-model="email" @input="validateEmail" type="email" id="email"
                        :class="errors.email === '' ? 'input-field' : 'input-field-err'" :required="true"
                        placeholder="Email" @keydown.enter="focusfieldPW" />
                    <div class="err f-sz-sm" v-if="errors.email">{{ errors.email }}</div>
                    <input v-model="password" @input="validateEmail" type="password"
                        :class="errors.password === '' ? 'input-field' : 'input-field-err'" :required="true"
                        placeholder="Password" ref="fieldPW" @keydown.enter="signIn" />
                    <div class="err f-sz-sm" v-if="errors.password">{{ errors.password }}</div>
                    <button @click="pwResetIsVisible = true;" class="for-pw pad-mar-no f-col-theme">Forgot password?</button>
                    <button @click="signIn()" class="btn-main col-theme">Sign in</button>
                    <div class="navigate-other-op dis-fl jus-con-cen al-it-cen">
                        <p class="pad-mar-no">Don't have an account?</p> &nbsp;
                        <router-link to="/register">Register</router-link>
                    </div>
                </div>
                <div class="ri dis-fl al-it-cen">
                    <p class="tx-title-sub">Register</p>
                    <p class="tx-content">Register now to post, edit, and manage ads. It’s quick, easy, and free!</p>
                    <button @click="this.$router.push('/register');" class="btn-sub col-theme">Register Now</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Navi from '../components/navigation-com.vue'
import Swal from 'sweetalert2'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapState } from 'vuex'
import { useHead } from '@vueuse/head';
import CryptoJS from 'crypto-js';

export default {

    data() {
        return {
            pwResetIsVisible: false,
            email: '',
            password: '',
            pwResetEmail: '',
            errors: {
                email: '',
                password: '',
                resetEmail: '',
            },
            isLoading: false,
            encString: '',
        }
    },

    components: {
        Navi,
        Loading,
    },

    mounted() {
        // this.isSignedIn();
        useHead({
            title: `Login | popit.lk`,
            meta: [
                {
                    name: 'description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:title',
                    content: `Login | popit.lk`,
                },
                {
                    property: 'og:description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
            ],
        });

    },

    computed: {
        ...mapState(['api', 'pwResSecKey'])
    },

    methods: {

        generateRandomId() {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let randomId = '';
            for (let i = 0; i < 10; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                randomId += characters.charAt(randomIndex);
            }
            return randomId;
        },

        encryptOtp(otp) {
            const key = CryptoJS.enc.Utf8.parse(this.pwResSecKey);

            const encrypted = CryptoJS.AES.encrypt(otp, key, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            });
            return encrypted.toString().replace(/\//g, '-');
        },

        async pwChangeEmailConfirmClick() {
            this.validateResetEmail();
            if (this.errors.resetEmail === '') {
                this.isLoading = true;
                let otp = this.generateRandomId();
                let encOtp = await this.encryptOtp(otp);
                await axios.post(`${this.api}/emails/send-pw-reset-link`, {
                    email: this.pwResetEmail,
                    otp: encOtp,
                }).then(async (response) => {
                    this.isLoading = false;
                    if (response.status === 200) {
                        localStorage.setItem('pwResEmail', this.pwResetEmail);
                        await axios.post(`${this.api}/users/reset-password-otp`, {
                            email: this.pwResetEmail,
                            otp: otp,
                        })
                        this.pwResetIsVisible = false;
                        Swal.fire({
                            title: 'Reset Password',
                            text: `Please open the email sent to: ${this.pwResetEmail} and follow further instructions.`,
                            icon: 'success',
                            confirmButtonText: 'GOT IT'
                        }).then(() => {
                            this.pwResetEmail = '';
                        })
                    } else {
                        Swal.fire({
                            title: 'Reset Password',
                            text: `Something went wrong. Please try again`,
                            icon: 'error',
                        })
                    }
                })
            }
        },

        async isSignedIn() {
            if (this.token !== '') {
                this.$router.push('/');
            } else {
                return;
            }
        },

        focusfieldPW() {
            this.$refs.fieldPW.focus();
        },

        async signIn() {
            this.validateEmail();
            if (this.errors.email === '' && this.errors.password === '') {
                this.isLoading = true;
                await axios.post(`${this.api}/users/login`, {
                    email: this.email,
                    password: this.password,
                }).then(async (response) => {
                    this.isLoading = false;
                    localStorage.setItem('token', response.data.token);
                    try {
                        const decoded = await jwtDecode(response.data.token);
                        await axios.get(`${this.api}/users/fetch-user-info/${decoded.id}`).then((response) => {
                            let profile = `data:image/jpeg;base64,${response.data.profile}`
                            localStorage.setItem('profileImg', profile);
                        });
                    } catch (err) {
                        console.log(err);
                    }
                    window.location.href = '/';
                }).catch(error => {
                    this.isLoading = false;
                    if (error.response.status === 400) {
                        Swal.fire({
                            title: 'Login Error',
                            text: "Username or password incorrect!",
                            icon: 'error',
                        });
                    } else if (error.response.status === 401) {
                        Swal.fire({
                            title: 'Login Error',
                            text: "Username or password incorrect!",
                            icon: 'error',
                        });
                    } else {
                        Swal.fire({
                            title: 'Login Error',
                            text: "Something went wrong. Please try again",
                            icon: 'error',
                        });
                    }
                });
            }
            // Swal.fire({
            //     title: 'User account required',
            //     text: "This feature is only for logged in users.",
            //     icon: 'error',
            //     showCancelButton: true,
            //     confirmButtonColor: '#3085d6',
            //     cancelButtonColor: '#d33',
            //     confirmButtonText: 'Sign in'
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         this.$router.push('/login');
            //     } else {
            //         this.$router.push('/');
            //     }
            // })
        },

        validateEmail() {
            if (!this.email) {
                this.errors.email = 'Email is required!';
            } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                this.errors.email = 'Invalid email format!';
            } else {
                this.errors.email = '';
            }
            if (!this.password) {
                this.errors.password = 'Password is Required!';
            } else if (this.password.length < 6) {
                this.errors.password = 'Password is too short!'
            } else {
                this.errors.password = '';
            }
        },

        validateResetEmail() {
            if (this.pwResetIsVisible === true) {
                if (!this.pwResetEmail) {
                    this.errors.resetEmail = 'Email is required!';
                } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.pwResetEmail)) {
                    this.errors.resetEmail = 'Invalid email format!';
                } else {
                    this.errors.resetEmail = '';
                }
            }
        }
    }
}
</script>
<style scoped>
.pw-reset-pop {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.422);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.pw-reset {
    width: 500px;
    padding: 50px;
    background-color: white;
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-close {
    margin: 10px 10px 0 0;
    position: absolute;
    right: 0;
    top: 0;
}

.logo {
    width: 80px;
    margin-bottom: 20px;
}

.f-pw-reset-guid {
    margin-top: 10px;
    text-align: center;
}

.pw-reset-content {
    width: 100%;
}

.btn-confirm {
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}

.input-field {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.212);
    border-radius: 5px;
    outline: none;
}

.input-field-2-err {
    border: 1px solid red;
}

.input-field-err {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    border: 1px solid red;
    border-radius: 5px;
    outline: none;
}

.input-field:focus {
    border: 1px solid rgba(0, 0, 255, 0.534);
}

.err {
    color: red;
}

.main {
    min-height: 80vh;
    padding-top: 50px;
    background-color: rgba(0, 0, 0, 0.050);
}

.login {
    align-content: flex-start;
    align-items: flex-start;
}

.le {
    width: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
}

.ri {
    width: 400px;
    padding: 50px;
    margin-left: 30px;
    flex-direction: column;
    background-color: white;
}

.tx-title-main {
    font-size: 25px;
    font-weight: 500;
}

.for-pw {
    margin-top: 15px;
    border: none;
    font-weight: 500;
    /* color: rgb(0, 110, 255); */
    background-color: transparent;
}

.btn-main {
    width: 150px;
    height: 50px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 500;
}

.btn-main-dis {
    width: 150px;
    height: 50px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    background-color: #8EC6417e;
}

.tx-title-sub {
    font-size: 20px;
    font-weight: 500;
}

.tx-content {
    text-align: center;
}

.btn-sub {
    width: 140px;
    height: 40px;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 500;
}

.navigate-other-op {
    display: none;
}

@media only screen and (max-width: 767px) {

    .main {
        padding-top: 20px;
    }

    .le {
        padding: 20px;
    }

    .ri {
        display: none;
    }

    .navigate-other-op {
        width: 100%;
        margin-top: 30px;
        display: flex;
    }
}

@media only screen and (max-width: 575px) {
    .btn-main {
        width: 100%;
    }

    .pw-reset {
        padding: 20px;
        margin: 10px;
    }
}
</style>