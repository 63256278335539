<template>
    <div>
        <div class="filter-popup-box dis-fl jus-con-cen container-xxl">
            <div class="filter-popup-wrapper" v-if="isPcView">
                <div class="category-wrapper dis-fl">
                    <div class="main-category">
                        <h5>Jobs</h5>
                        <router-link class="a-txt-dec-no" to="/ads/Jobs/All">See All in Jobs</router-link><br><br>
                        <button class="pad-mar-no dis-fl jus-con-sp-bt al-it-cen"
                            v-for="(category, index) in categories" :key="index" @click="CategorySelect(index)">
                            <p class="pad-mar-no">{{ category.name }}</p>
                            <img :class="category.hasSub ? 'icn-fo-arr' : 'icn-fo-arr-hide'"
                                src="../../assets/icons/forward-black.png" alt="">
                        </button><br>
                    </div>
                    <div class="sub-category">
                        <a class="a-txt-dec-no" v-for="(item, index) in displaySub" :key="index" href="">{{ item.name}}</a>
                        <div class="sub-cat-bg-img-box dis-fl">
                            <!-- <img class="img-fit" src="../../assets/test/house.png" alt=""> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="filter-popup-mob">
            <transition name="main-cat-trans">
                <div v-if="mainCatIsVisible" class="main-category-mob" v-click-outside="onClickOutside">
                    <div class="dis-fl jus-con-sp-bt al-it-cen">
                        <h5 class="pad-mar-no">Real Estate</h5>
                        <button class="btn-close" @click="mainClose()"></button>
                    </div>
                    <router-link class="a-txt-dec-no" to="/ads/Jobs/All">See All in Real Estate</router-link><br>
                    <button class="btn-mob-cat pad-mar-no dis-fl jus-con-sp-bt al-it-cen"
                        v-for="(category, index) in categories" :key="index" @click="CategorySelect(index)">
                        <p class="pad-mar-no">{{ category.name }}</p>
                        <img :class="category.hasSub ? 'icn-fo-arr' : 'icn-fo-arr-hide'"
                            src="../../assets/icons/forward-black.png" alt="">
                    </button>
                </div>
            </transition>
            <transition name="sub-cat-trans">
                <div v-if="subCatIsVisible" class="sub-category-mob">
                    <div class="top dis-fl jus-con-sp-bt al-it-cen">
                        <button class="btn-back pad-mar-no dis-fl al-it-cen" @click="subClose()">
                            <img src="../../assets/icons/backward_theme.png" alt="">
                            <p class="pad-mar-no">Back to Real Estate</p>
                        </button>
                        <button class="btn-close" @click="mainClose()"></button>
                    </div>
                    <div class="bot dis-fl">
                        <p class="selected-cat"> {{ selectedCat }} </p>
                        <button @click="subCategorySelect(index)" class="dis-fl jus-con-sp-bt" v-for="(item, index) in displaySub" :key="index">
                            <p class="pad-mar-no">{{ item.name }}</p>
                        </button>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {

    data() {
        return {
            mainCatIsVisible: true,
            subCatIsVisible: false,
            selectedCat: '',
            displaySub: [],
            categories: [
                { name: 'Accounting & Management', hasSub: false, link: '/Jobs/Accounting & Management' },
                { name: 'Child Care', hasSub: false, link: '/Jobs/Child Care' },
                { name: 'Bar, Food & Hospitality', hasSub: false, link: '/Jobs/Bar, Food & Hospitality' },
                { name: 'Cleaning & Housekeeping', hasSub: false, link: '/Jobs/Cleaning & Housekeeping' },
                { name: 'Construction & Trades', hasSub: false, link: '/Jobs/Construction & Trades' },
                { name: 'Customer Service', hasSub: false, link: '/Jobs/Customer Service' },
                { name: 'Cannabis Sector', hasSub: false, link: '/Jobs/Cannabis Sector' },
                { name: 'Drivers & Security', hasSub: false, link: '/Jobs/Drivers & Security' },
                { name: 'General Labour', hasSub: false, link: '/Jobs/General Labour' },
                { name: 'Graphic & Web Design', hasSub: false, link: '/Jobs/Graphic & Web Design' },
                { name: 'Healthcare', hasSub: false, link: '/Jobs/Healthcare' },
                { name: 'Hair Stylist & Salon', hasSub: false, link: '/Jobs/Hair Stylist & Salon' },
                { name: 'Office Manager & Receptionist', hasSub: false, link: '/Jobs/Office Manager & Receptionist' },
                { name: 'Part Time & Students', hasSub: false, link: '/Jobs/Part Time & Students' },
                { name: 'Programmers & Computer', hasSub: false, link: '/Jobs/Programmers & Computer' },
                { name: 'Sales & Retail Sales', hasSub: false, link: '/Jobs/Sales & Retail Sales' },
                { name: 'TV, Media, & Fashion', hasSub: false, link: '/Jobs/TV, Media, & Fashion' },
                { name: 'Other', hasSub: false, link: '/Jobs/Other' },
            ],
            deviceWidth: null,
            isPcView: true,
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    mounted() {
        this.getDeviceWidth();
    },

    methods: {

        getDeviceWidth() {
            this.deviceWidth = screen.width;
            if(this.deviceWidth > 991){
                this.isPcView = true;
            } else {
                this.isPcView = false;
            }
        },
        
        mainClose() {
            this.$emit('isVisible', false);
        },

        subClose() {
            this.subCatIsVisible = false;
            this.mainCatIsVisible = true;
        },

        CategorySelect(index) {
            this.$router.push('/ads' + this.categories[index].link);
        },

        onClickOutside() {
            this.$emit('isVisible', false);
        }
    },

}

</script>

<style scoped>

h5{
    margin-bottom: 15px;
}

.selected-cat {
    font-weight: bold;
    font-size: 20px;
}

.icn-fo-arr {
    width: 10px;
    height: 15px;
}

.icn-fo-arr-hide {
    display: none;
}

.filter-popup-box {
    width: 100%;
    height: 50vh;
    position: absolute;
    top: 100px;
    z-index: 2;
}

.category-wrapper {
    height: 100%;
    padding: 20px;
    padding-right: 0;
}

.filter-popup-wrapper {
    width: 100%;
    height: 100%;
    margin: 0 50px;
    background-color: white;
}

.filter-popup-mob {
    display: none;
}

.main-category {
    width: 300px;
    height: 100%;
    overflow: auto;
}

.main-category::-webkit-scrollbar {
    width: 6px;
}

.main-category::-webkit-scrollbar-track {
    border-radius: 10px;
}

.main-category::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.315);
    border-radius: 10px;
}

.main-category::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 128, 128, 0.493);
}

.main-category button,
.btn-mob-cat,
.sub-category-mob .bot button {
    width: 100%;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.050);
    background-color: transparent;
}

.sub-category {
    width: 100%;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
}

.sub-category a {
    margin: 8px 0;
    color: black;
    z-index: 1;
}

.sub-cat-bg-img-box {
    height: 100%;
    opacity: .5;
    align-items: flex-end;
    position: absolute;
    right: 0;
}

@media only screen and (max-width: 1200px) {
    .filter-popup-box {
        top: 130px;
    }

    .sub-cat-bg-img-box {
        display: none;
    }
}

@media only screen and (max-width: 991px) {

    .filter-popup-box {
        display: none;
    }

    .filter-popup-mob {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 2;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.281);
        backdrop-filter: blur(2px);
    }

    .main-category-mob {
        width: 700px;
        margin: auto;
        padding: 20px 10px 50px 10px;
        display: flex;
        position: relative;
        flex-direction: column;
        background-color: white;
    }

    .sub-category-mob {
        width: 700px;
        margin: auto;
        display: flex;
        position: relative;
        flex-direction: column;
        background-color: white;
    }

    .sub-category-mob .top {
        padding: 15px 10px;
        background-color: #8EC64111;
    }

    .btn-back {
        border: none;
        background-color: transparent;
    }

    .btn-back img {
        width: 6px;
        height: 11px;
        margin-right: 5px;
    }

    .btn-back p {
        color: #8EC641;
    }

    .sub-category-mob .bot {
        padding: 20px 10px 50px 10px;
        flex-direction: column;
    }
}

@media only screen and (max-width: 767px) {

    .main-category-mob,
    .sub-category-mob {
        width: 100%;
    }
}
</style>