<template>
  <div class="main">
    <div class="test"></div>
    <!-- <div style="width: 100%; padding: 20px; background-color: rgba(0, 0, 0, 0.111);">
      <table cellspacing="0" cellpadding="0" border="0" width="100%" align="center"
        style="max-width: 600px; padding: 50px; background-color: white; margin: 0 auto;">
        <tr>
          <td align="center">
            <img style="width: 100px;" src="../assets/logos/popit-logo-black.png" alt="">
          </td>
        </tr>
        <tr>
          <td align="center">
            <h4 style="margin-bottom: 30px;">Reset Your Popit Password</h4>
          </td>
        </tr>
        <tr>
          <td>
            <p>Dear user,</p>
            <p>To set up a new password for your Fiverr account, click "Reset Your Password" below, or use this link:</p>
            <p><a href="#">test</a></p>
            <p>The link will expire in 24 hours. If nothing happens after clicking, copy and paste the link in your
              browser.</p>
          </td>
        </tr>
        <tr>
          <td align="center" style="margin-top: 30px; margin-bottom: 30px;">
            <p><a href="#"
                style="background-color: #228B22; color: white; font-weight: bold; padding: 10px; text-decoration: none;">Reset
                Your Password</a></p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Thanks,<br>The Popit.lk Team</p>
          </td>
        </tr>
      </table>
    </div> -->

  </div>
</template>


<script>
/* eslint-disable */
import CryptoJS from 'crypto-js';
import { mapState } from 'vuex'
export default {
  data() {
    return {

    }
  },

  computed: {
    ...mapState(['api', 'pwResSecKey'])
  },

  methods: {
    encryptOtp() {
      const key = CryptoJS.enc.Utf8.parse(this.pwResSecKey);

      const encrypted = CryptoJS.AES.encrypt('pathum', key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      console.log(encrypted.toString().replace(/\//g, '-'))
    },

    decryptOtp() {
      const key = CryptoJS.enc.Utf8.parse(this.pwResSecKey);
      let encString = 'aeiN5xDa6op8zBW6iA-I8w==';

      const decrypted = CryptoJS.AES.decrypt(encString.replace(/-/g, '/'), key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });

      console.log(decrypted.toString(CryptoJS.enc.Utf8))
    },
  }

}

</script>
<style scoped>
.main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.111); */
}

.test{
  width: 100%;
  padding-bottom: 40%;
  background-color: red;
}

</style>
