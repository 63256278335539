<template>
  <swiper
  :spaceBetween="30"
  :loop="true"
    :centeredSlides="true"
    :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    :pagination="{
      clickable: true,
      dynamicBullets: true,
    }"
    :navigation="false"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide>
        <div class="banner-img-only-box">
          <div class="skel-box" v-if="banner1IsLoading">
            <Skeleton />
          </div>
          <img v-show="!banner1IsLoading" class="img-only" src="../assets/banners/main-banner-2.jpg" alt="" @load="banner1Load()">
        </div>
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

import Skeleton from '../components/skeleton-loading/skel-com.vue'

export default {

  data() {
    return {
      banner1IsLoading: true,
    }
  },

  components: {
    Swiper,
    SwiperSlide,
    Skeleton,
  },

  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },

  methods: {
    banner1Load() {
      this.banner1IsLoading = false;
    }
  }
};
</script>

<style scoped>

.skel-box{
  width: 100%;
  padding-bottom: 50.77%;
}

.banner-img-only-box{
  width: 100%;
}

.img-only {
  max-width: 100%;
}

.banner-wrapper{
    width: 100%;
    height: 300px;
    background-color: #ffc000;
}

.le{
  width: 50%;
  height: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
}

.le .header{
  font-size: 40px;
  font-weight: 500;
}

.le a{
  margin-top: 20px;
  padding: 10px 5px;
  border: 2px solid black;
  color: black;
}

.le a img{
  width: 20px;
  height: 15px;
  margin-left: 10px;
}

.ri{
  width: 50%;
  height: 100%;
  justify-content: flex-end;
}

.ri img{
  max-height: 100%;
}

@media only screen and (max-width: 991px) {

  .banner-wrapper{
    height: 300px;
  }

  .banner-wrapper{
    flex-direction: column;
  }

  .le{
    width: 100%;
    height: 100px;
    padding: 5px 10px;
  }

  .le .header{
    font-size: 25px;
  }

  .le a{
    padding: 0;
    margin-top: 5px;
    border: none;
  }

  .ri{
    width: 100%;
    height: 200px;
    align-items: flex-end;
  }

  .ri img{
    max-width: 100%;
  }
}

</style>
