<template>
    <div>
        <Navi ref="naviRef" />
        <EditAd :mainCategory="passMainCategory" :itemId="passItemId" :formVisible="editFormIsVisible"
            @passFormVisible="getFormVisible" />
        <BidsView :popupVisible="bidsFormIsVisible" :mainCategory="passMainCategory" :itemId="passItemId"
            @bidPopupVisibleToParent="bidPopupVisibleFromChild" />
        <ProFollowersPopup :popupVisible="followersPopupIsVisible" @popupVisibleToParent="followersPopupVisibleToParent"
            @followersCount="followersCount" />
        <ProFollowingPopup :popupVisible="followingPopupIsVisible" @popupVisibleToParent="followingPopupVisibleToParent"
            @followingCount="followingCount" />
        <Notification :popupVisible="notificationIsVisible" @notificationVisibleFromChild="notificationVisibleFromChild"
            @update-notification-count="getNotificationsCount" />
        <Chat :hideChat="chatIsVisible" :adOwnerId="null" :mentionedItemImg="null" :mentionedItemName="null"
            @hideChat="hideChat" />
        <div class="main res-py dis-fl container-xxl">
            <div class="left">
                <button @click="mainProfileClick()" class="btn-left-navi-top pad-mar-no dis-fl al-it-cen">
                    <img src="../assets/profile/ads-pro.png" alt="">
                    <p class="pad-mar-no">Ads</p>
                </button>
                <button @click="mainNotificationClick()" class="btn-left-navi pad-mar-no dis-fl al-it-cen">
                    <div v-if="notificationsCount > 0" class="noti-chat-count-container">
                        <p class="txt-noti-chat-count pad-mar-no">{{ notificationsCount }}</p>
                    </div>
                    <img src="../assets/profile/notification-pro.png" alt="">
                    <p class="pad-mar-no">Notification</p>
                </button>
                <button @click="mainMessageClick()" class="btn-left-navi pad-mar-no dis-fl al-it-cen">
                    <img src="../assets/profile/chat-pro.png" alt="">
                    <p class="pad-mar-no">Message</p>
                </button>
                <button @click="mainJobProfileClick()" class="btn-left-navi pad-mar-no dis-fl al-it-cen">
                    <img src="../assets/profile/jobs-pro.png" alt="">
                    <p class="pad-mar-no">Job Profile</p>
                </button>
            </div>
            <div class="right">
                <div v-if="userDataIsLoading">
                    <ProfileInfoSkeleton />
                </div>
                <div v-if="!userDataIsLoading" class="cover-img-box">
                    <img :class="cover === '' ? 'cover-img-box-def' : 'cover-img-box-img'"
                        :src="cover || require('../assets/icons/image-icn.png')" id="cover-img-height" alt="">
                </div>
                <div v-if="!userDataIsLoading" class="pro-det-wrapper dis-fl">
                    <div class="pro-container">
                        <div class="pro-box dis-fl jus-con-cen al-it-cen bor-col-theme">
                            <img :class="profile === '' ? 'profile-image-def' : 'profile-img'"
                                :src="profile || require('../assets/icons/user.png')" alt="">
                        </div>
                    </div>
                    <div class="details">
                        <div class="dis-fl al-it-cen">
                            <p class="name pad-mar-no">{{ firstName }} {{ lastName }}</p>
                            <router-link to="/profile-edit">
                                <div class="img-edit-box dis-fl jus-con-cen al-it-cen">
                                    <img src="../assets/icons/edit-box.png" alt="">
                                </div>
                            </router-link>
                            <button @click="logOut" class="log-out pad-mar-no">LOG OUT</button>
                        </div>
                        <p class="username pad-mar-no f-sz-sm">{{ username }}</p>
                        <p class="follow pad-mar-no">{{ items }} items &nbsp;<span id="count-items-click"
                                @click="followersPopupIsVisible = true;">{{ followers }} followers</span> &nbsp; <span
                                id="count-items-click" @click="followingPopupIsVisible = true;">{{
                                    following
                                }} following</span></p>
                        <div class="count-mob dis-fl jus-con-sp-bt">
                            <div class="count-item dis-fl al-it-cen">
                                <p class="pad-mar-no">{{ items }}</p>
                                <p class="pad-mar-no f-sz-sm">items</p>
                            </div>
                            <div @click="followersPopupIsVisible = true;" class="count-item dis-fl al-it-cen"
                                id="count-items-click">
                                <p class="pad-mar-no">{{ followers }}</p>
                                <p class="pad-mar-no f-sz-sm">followers</p>
                            </div>
                            <div @click="followingPopupIsVisible = true;" class="count-item dis-fl al-it-cen"
                                id="count-items-click">
                                <p class="pad-mar-no">{{ following }}</p>
                                <p class="pad-mar-no f-sz-sm">following</p>
                            </div>
                        </div>
                        <p class="status pad-mar-no f-sz-sm" :style="{ whiteSpace: 'pre-line' }">{{ about }}</p>
                    </div>
                </div>
                <div class="cat-items-wrapper">
                    <div class="items-bid-wishl-wrapper dis-fl jus-con-cen">
                        <button @click="adsClick()"
                            :class="adsIsVisible === true ? 'items-bid-wishl-wrapper-op-button-active' : 'items-bid-wishl-wrapper-op-button'">
                            <div class="ads-types-icon-box-lg">
                                <div class="items-bid-wishl-wrapper-op-button-icon-box">
                                    <img src="../assets/icons/ads-icon-pro.png" alt="">
                                </div>
                                <p class="pad-mar-no">Ads</p>
                            </div>
                            <div class="ads-types-icon-box-sm">
                                <img src="../assets/icons/ads-icon-pro.png" alt="">
                            </div>
                        </button>
                        <button @click="bidsClick()"
                            :class="bidsIsVisible === true ? 'items-bid-wishl-wrapper-op-button-active' : 'items-bid-wishl-wrapper-op-button'">
                            <div class="ads-types-icon-box-lg">
                                <div class="items-bid-wishl-wrapper-op-button-icon-box">
                                    <img src="../assets/icons/bids-icon-pro.png" alt="">
                                </div>
                                <p class="pad-mar-no">Bids</p>
                            </div>
                            <div class="ads-types-icon-box-sm">
                                <img src="../assets/icons/bids-icon-pro.png" alt="">
                            </div>
                        </button>
                        <button @click="placedBidsClick()"
                            :class="placedBidsIsVisible === true ? 'items-bid-wishl-wrapper-op-button-active' : 'items-bid-wishl-wrapper-op-button'">
                            <div class="ads-types-icon-box-lg">
                                <div class="items-bid-wishl-wrapper-op-button-icon-box">
                                    <img src="../assets/icons/bids-icon-pro.png" alt="">
                                </div>
                                <p class="pad-mar-no">Placed Bids</p>
                            </div>
                            <div class="ads-types-icon-box-sm">
                                <img src="../assets/icons/bids-icon-pro.png" alt="">
                            </div>
                        </button>
                        <button @click="wishlistClick()"
                            :class="wishlistIsVisible === true ? 'items-bid-wishl-wrapper-op-button-active bor-col-theme' : 'items-bid-wishl-wrapper-op-button'">
                            <div class="ads-types-icon-box-lg">
                                <div class="items-bid-wishl-wrapper-op-button-icon-box">
                                    <img src="../assets/icons/wishlist-icon-pro.png" alt="">
                                </div>
                                <p class="pad-mar-no">Wishlist</p>
                            </div>
                            <div class="ads-types-icon-box-sm">
                                <img src="../assets/icons/wishlist-icon-pro.png" alt="">
                            </div>
                        </button>
                    </div>
                    <div class="hor-divider"></div>
                    <ProfileItemSkeleton v-if="itemIsLoading" />
                    <div v-if="itemIsLoading === false && displayItems.length === 0" class="item-not-found">
                        <p class="pad-mar-no">Item(s) not found</p>
                    </div>
                    <div v-if="!itemIsLoading" class="cat-items dis-fl">
                        <div class="wish-wrp row">
                            <div @click="itemClick(index)" v-for="(item, index) in displayItems" :key="index"
                                class="col-lg-4 col-md-4 col-4">
                                <div class="item-img-box">
                                    <div v-if="placedBidsIsVisible" class="dissable-icon-box-2">
                                        <img v-if="item.is_user_bid_valid" src="../assets/icons/bid_on.png" alt="">
                                        <img v-if="!item.is_user_bid_valid" src="../assets/icons/bid_off.png" alt="">
                                    </div>
                                    <div v-if="item.is_bid" class="dissable-icon-box">
                                        <img v-if="item.is_dissable" src="../assets/icons/time-on.png" alt="">
                                        <img v-if="!item.is_dissable" src="../assets/icons/time-off.png" alt="">
                                    </div>
                                    <div v-if="!item.is_dissable === true && wishlistIsVisible === false && !item.is_bid"
                                        class="dissable-icon-box">
                                        <img src="../assets/icons/pro-ad-dissable-white.png" alt="">
                                    </div>
                                    <img :class="!item.is_dissable === true && wishlistIsVisible === false ? 'item-img-box-img-dissabled' : 'item-img-box-img'"
                                        :src="item.itemImg" alt="" @load="imgLoad()">
                                    <div class="item-content-box">
                                        <p class="pad-mar-no">{{ item.title }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bot-navi-mob-wrapper">
            <button @click="mainProfileClick()" class="pad-mar-no dis-fl jus-con-cen al-it-cen">
                <img src="../assets/profile/ads-pro.png" alt="">
                <p class="pad-mar-no">Ads</p>
            </button>
            <div style="width: 1px; height: 80%; background-color: rgba(0, 0, 0, 0.171); margin: auto 0;"></div>
            <button @click="mainNotificationClick()" class="pad-mar-no dis-fl jus-con-cen al-it-cen">
                <div class="mob-img-box">
                    <div v-if="notificationsCount > 0" class="mob-noti-chat-counter"></div>
                    <img src="../assets/profile/notification-pro.png" alt="">
                </div>
                <p class="pad-mar-no">Notification</p>
            </button>
            <div style="width: 1px; height: 80%; background-color: rgba(0, 0, 0, 0.171); margin: auto 0;"></div>
            <button @click="mainMessageClick()" class="pad-mar-no dis-fl jus-con-cen al-it-cen">
                <img src="../assets/profile/chat-pro.png" alt="">
                <p class="pad-mar-no">Message</p>
            </button>
            <div style="width: 1px; height: 80%; background-color: rgba(0, 0, 0, 0.171); margin: auto 0;"></div>
            <button @click="mainJobProfileClick()" class="pad-mar-no dis-fl jus-con-cen al-it-cen">
                <img src="../assets/profile/jobs-pro.png" alt="">
                <p class="pad-mar-no">Jobs</p>
            </button>
        </div>
    </div>
</template>

<script>
import Navi from '../components/navigation-com.vue'
import ProfileItemSkeleton from '../components/skeleton-loading/profile-items-skel-com.vue'
import ProfileInfoSkeleton from '../components/skeleton-loading/profile-info-skel-com.vue'
import ProFollowersPopup from '../components/profile-followers-com.vue'
import ProFollowingPopup from '../components/profile-following-com.vue'
import EditAd from '../components/edit-ad-com.vue'
import BidsView from '../components/bids-view-profile-com.vue'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { mapState } from 'vuex'
import Notification from '../components/notification-com.vue'
import Chat from '../components/chat-com.vue'
import { useHead } from '@vueuse/head';

export default {

    data() {
        return {
            notificationsCount: 0,
            itemIsLoading: false,
            userDataIsLoading: true,
            userID: null,
            firstName: '',
            lastName: '',
            username: '',
            items: 0,
            followers: 0,
            following: 0,
            about: '',
            profile: '',
            cover: '',
            adsIsVisible: false,
            bidsIsVisible: false,
            placedBidsIsVisible: false,
            wishlistIsVisible: false,
            displayItems: [],
            displayItemsIsEmpty: false,
            wishlist: [],
            ads: [],
            bids: [],
            editFormIsVisible: false,
            bidsFormIsVisible: false,
            passMainCategory: null,
            passItemId: null,
            followersPopupIsVisible: false,
            followingPopupIsVisible: false,
            notificationIsVisible: false,
            chatIsVisible: false,
            adsPage: 1,
            bidsPage: 1,
            pageSize: 10,
        }
    },

    components: {
        Navi,
        ProfileItemSkeleton,
        ProfileInfoSkeleton,
        EditAd,
        BidsView,
        ProFollowersPopup,
        ProFollowingPopup,
        Notification,
        Chat,
    },

    computed: {
        ...mapState(['api'])
    },

    mounted() {
        this.getUserId();
        this.getNotificationsCount();
        this.fetchUserData();
        this.getViewType();
        this.mainProfileClick();
        useHead({
            title: `Profile | popit.lk`,
            meta: [
                {
                    name: 'description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:title',
                    content: `Profile | popit.lk`,
                },
                {
                    property: 'og:description',
                    content: `Best buy & Sell in Sri Lanka | popit.lk`
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
            ],
        });
    },

    methods: {

        getViewType() {
            if(this.$route.params.adType === 'bids') {
                this.bidsClick();
            } else if(this.$route.params.adType === 'placed-bids') {
                this.placedBidsClick()
            } else {
                this.adsClick();
            }
        },

        async getNotificationsCount() {
            const token = localStorage.getItem('token');
            const decoded = jwtDecode(token);
            try {
                await axios.get(`${this.api}/notifications/push-notifications/${decoded.id}`).then((response) => {
                    const unreadNotifications = response.data.filter((notification) => notification.is_read === 0);
                    this.notificationsCount = unreadNotifications.length;
                });
            } catch (e) {
                console.log(e);
            }
            setInterval(async () => {
                try {
                    await axios.get(`${this.api}/notifications/push-notifications/${decoded.id}`).then((response) => {
                        const unreadNotifications = response.data.filter((notification) => notification.is_read === 0);
                        this.notificationsCount = unreadNotifications.length;
                    })
                } catch (err) {
                    console.log(err);
                }
            }, 10000);
            this.$refs.naviRef.getNotificationsCount();
        },

        logOut() {
            localStorage.setItem('token', '');
            window.location.href = '/login';
        },

        async getUserId() {
            const token = localStorage.getItem('token');
            try {
                const decoded = jwtDecode(token);
                this.userID = decoded.id;
            } catch (err) {
                console.log(err);
            }
        },

        async fetchUserData() {
            this.userDataIsLoading = true;
            await axios.get(`${this.api}/users/fetch-user-info/${this.userID}`).then((response) => {
                this.userDataIsLoading = false;
                if (!response.data.firstName || !response.data.lastName) {
                    this.$router.push('/profile-edit');
                }
                this.firstName = response.data.firstName;
                this.lastName = response.data.lastName;
                this.username = response.data.email;
                this.about = response.data.about;
                this.profile = response.data.profile !== '' ? `data:image/jpeg;base64,${response.data.profile}` : '';
                this.cover = response.data.cover !== '' ? `data:image/jpeg;base64,${response.data.cover}` : '';
                // this.profile = `data:image/jpeg;base64,${response.data.profile}` !== 'data:image/jpeg;base64,undefined' ? `data:image/jpeg;base64,${response.data.profile}` : '';
                // this.cover = `data:image/jpeg;base64,${response.data.cover}` !== 'data:image/jpeg;base64,undefined' ? `data:image/jpeg;base64,${response.data.cover}` : '';
                localStorage.setItem('profileImg', `data:image/jpeg;base64,${response.data.profile}` !== 'data:image/jpeg;base64,undefined' ? `data:image/jpeg;base64,${response.data.profile}` : '');
            });
        },

        async adsClick() {
            this.adsPage = 1;
            this.itemIsLoading = true;
            this.ads = [];
            this.bids = [];
            this.displayItems = [];
            this.adsIsVisible = true;
            this.bidsIsVisible = false;
            this.placedBidsIsVisible = false;
            this.wishlistIsVisible = false;
            await axios.get(`${this.api}/users/get-ads/${this.userID}?page=${this.adsPage}&pageSize=${this.pageSize}`).then((response) => {
                this.items = response.data.length;
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].main_cat === 'Jobs') {
                        response.data[i].ph_clogo = response.data[i].ph_clogo !== null ? `data:image/jpeg;base64,${response.data[i].ph_clogo}` : '';
                        response.data[i].itemImg = response.data[i].ph_clogo;
                        this.ads.push(response.data[i]);
                    } else {
                        if (response.data[i].is_bid === 0 || response.data[i].is_bid === null) {
                            response.data[i].ph_1 = response.data[i].ph_1 !== null ? `data:image/jpeg;base64,${response.data[i].ph_1}` : '';
                            if (response.data[i].ph_1 !== '') {
                                response.data[i].itemImg = response.data[i].ph_1;
                            }
                            this.ads.push(response.data[i]);
                        }
                    }
                }
            })
            this.adsPage = this.adsPage + 1;
            this.displayItems = this.ads;
            this.itemIsLoading = false;
        },

        async bidsClick() {
            this.itemIsLoading = true;
            this.bidsPage = 1;
            this.bids = [];
            this.displayItems = [];
            this.bidsIsVisible = true;
            this.adsIsVisible = false;
            this.placedBidsIsVisible = false;
            this.wishlistIsVisible = false;
            await axios.get(`${this.api}/users/get-ads/${this.userID}?page=${this.bidsPage}&pageSize=${this.pageSize}`).then((response) => {
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].is_bid !== null) {
                        if (response.data[i].is_bid === 1) {
                            response.data[i].ph_1 = response.data[i].ph_1 !== '' ? `data:image/jpeg;base64,${response.data[i].ph_1}` : '';
                            response.data[i].itemImg = response.data[i].ph_1;
                            this.bids.push(response.data[i]);
                        }
                    }
                }
            })
            this.bidsPage = this.bidsPage + 1;
            this.displayItems = this.bids;
            this.itemIsLoading = false;
        },

        async placedBidsClick() {
            this.itemIsLoading = true;
            this.bids = [];
            this.displayItems = [];
            this.bidsIsVisible = false;
            this.placedBidsIsVisible = true;
            this.adsIsVisible = false;
            this.wishlistIsVisible = false;
            await axios.get(`${this.api}/users/get-placed-bid-ads/${this.userID}`).then((response) => {
                for (const ad of response.data) {
                    ad.itemImg = `data:image/jpeg;base64,${ad.ph_1}`;
                }
                this.displayItems = response.data;
            })
            this.itemIsLoading = false;
        },

        async wishlistClick() {
            this.itemIsLoading = true;
            this.wishlist = [];
            this.displayItems = [];
            this.wishlistIsVisible = true;
            this.adsIsVisible = false;
            this.bidsIsVisible = false;
            this.placedBidsIsVisible = false;
            await axios.get(`${this.api}/wishlist/get-wishlist/${this.userID}`).then((response) => {
                this.wishlist = response.data;
                for (let i = 0; i < this.wishlist.length; i++) {
                    this.wishlist[i].itemImg = this.wishlist[i].itemImg !== '' ? `data:image/jpeg;base64,${this.wishlist[i].itemImg}` : '';
                }
            })
            this.displayItems = this.wishlist;
            this.itemIsLoading = false;
        },

        itemClick(index) {
            if (this.wishlistIsVisible === true) {
                this.$router.push('/item/' + this.displayItems[index].main_cat + '/' + this.displayItems[index].title + '/' + this.displayItems[index].id + '/' + this.displayItems[index].user_id);
            } else if (this.adsIsVisible === true) {
                this.passMainCategory = this.displayItems[index].main_cat;
                this.passItemId = this.displayItems[index].id;
                this.editFormIsVisible = true;
            } else if (this.placedBidsIsVisible === true) {
                this.$router.push(`/bid/${this.displayItems[index].main_cat}/${this.displayItems[index].title}/${this.displayItems[index].id}/${this.displayItems[index].user_id}`);
            } else {
                this.passMainCategory = this.displayItems[index].main_cat;
                this.passItemId = this.displayItems[index].id;
                this.bidsFormIsVisible = true;
            }

        },

        getFormVisible(val) {
            this.editFormIsVisible = val;
            this.passMainCategory = null;
            this.passItemId = null;
            this.adsClick();
        },

        bidPopupVisibleFromChild(val) {
            this.bidsFormIsVisible = val;
            this.passMainCategory = null;
            this.passItemId = null;
            this.bidsClick();
        },

        async wishlistRemove(index, event) {
            if (event) {
                event.stopPropagation();
            }
        },

        imgLoad() {
            this.itemIsLoading = false;
        },

        followersPopupVisibleToParent(val) {
            this.followersPopupIsVisible = val;
        },

        followingPopupVisibleToParent(val) {
            this.followingPopupIsVisible = val;
        },

        followersCount(val) {
            this.followers = val;
        },

        followingCount(val) {
            this.following = val;
        },

        notificationVisibleFromChild(val) {
            this.notificationIsVisible = val;
        },

        hideChat(val) {
            this.chatIsVisible = val;
        },

        mainProfileClick() {
            this.chatIsVisible = false;
            this.notificationIsVisible = false;
        },

        mainNotificationClick() {
            this.chatIsVisible = false;
            this.notificationIsVisible = !this.notificationIsVisible;
        },

        mainMessageClick() {
            this.chatIsVisible = !this.chatIsVisible;
            this.notificationIsVisible = false;
        },

        mainJobProfileClick() {
            this.$router.push('/job-profile');
        }
    },
}
</script>

<style scoped>
.row {
    padding: 0;
    margin: 0;
}

.img-edit-box img {
    width: 15px;
    height: 15px;
    margin-left: 10px;
}

.left {
    width: 300px;
    height: 100vh;
    padding-top: 50px;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.158);
    position: sticky;
    top: 0;
}

.btn-left-navi {
    width: 100%;
    padding: 10px 0 10px 20px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.158);
    background-color: transparent;
    position: relative;
}

.noti-chat-count-container {
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
}

.txt-noti-chat-count {
    color: white;
    font-size: 8px;
}

.btn-left-navi-top {
    width: 100%;
    padding: 10px 0 10px 20px;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.158);
    border-bottom: 1px solid rgba(0, 0, 0, 0.158);
    background-color: transparent;
}

.btn-left-navi img,
.btn-left-navi-top img,
.btn-left-navi-bottom img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.right {
    width: 100%;
    min-height: 100vh;
    /* background-color: red; */
}

.cover-img-box {
    width: 100%;
    height: 0;
    padding-bottom: 27.25%;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.089);
}

.cover-img-box-img {
    width: 100%;
    position: absolute;
}

.cover-img-box-def {
    width: 150px;
    height: 150px;
    margin-top: 6%;
}

/* .cover-img-box img {
    width: 100%;
} */

.pro-container {
    width: 150px;
    height: 100px;
    margin-right: 20px;
    position: relative;
    flex-shrink: 0;
}

.pro-box {
    width: 78px;
    height: 78px;
    border: 2px solid;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: -10px;
    background-color: white;
    overflow: hidden;
}

.profile-img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.profile-image-def {
    max-width: 100%;
    max-height: 100%;
}

.details .name {
    font-weight: 500;
    font-size: 18px;
}

.details .username {
    color: rgba(0, 0, 0, 0.555);
}

.count-mob {
    margin-top: 10px;
    display: none;
}

.count-mob p {
    font-weight: 500;
}

.count-mob .count-item {
    flex-direction: column;
}

#count-items-click {
    cursor: pointer;
}

.details .status {
    max-width: max-content;
    margin-top: 10px;
}

.cat-items-wrapper {
    margin-top: 30px;
}

.items-bid-wishl-wrapper-op-button {
    width: 120px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-bottom: 3px solid transparent;
    transition: all .3s ease;
}

.items-bid-wishl-wrapper-op-button-active {
    width: 120px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-bottom: 3px solid;
    transition: all .3s ease;
}

.items-bid-wishl-wrapper-op-button-icon-box {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.items-bid-wishl-wrapper-op-button-icon-box img {
    max-width: 100%;
    max-height: 100%;
}

.ads-types-icon-box-lg {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ads-types-icon-box-sm {
    display: none;
}

.hor-divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.219);
}

.item-not-found {
    width: 100%;
    height: 20vh;
    display: flex;

    justify-content: center;
    align-items: flex-end;
}

.item-not-found p {
    margin-top: 30px;

}

.cat-items {
    margin-top: 30px;
    /* background-color: #8EC641; */
}

.items-wrp,
.wish-wrp {
    width: 100%;
}

.item-img-box {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    position: relative;
    background-color: white;
}

.dissable-icon-box {
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.452);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5px;
    top: 5px;
    border-radius: 50%;
}

.dissable-icon-box-2 {
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.452);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 40px;
    top: 5px;
    border-radius: 50%;
}

.dissable-icon-box img,
.dissable-icon-box-2 img {
    max-width: 60%;
    max-height: 60%;
}

.edit-del-btns-wrapper {
    width: 52px;
    height: 25px;
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: space-between;
}

.edit-del-btns-wrapper button {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-del-btns-wrapper button img {
    max-width: 150%;
    max-height: 150%;
}

/* .btn-wishlist-remove{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    padding: 0;
    border: none;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.425);
} */

/* .btn-wishlist-remove img{
    max-width: 70%;
    max-height: 70%;
} */

.item-img-box-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-img-box-img-dissabled {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .2;
}

.item-content-box {
    width: 100%;
    height: 80px;
    padding: 5px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.384);
}

.item-content-box p {
    color: white;
    text-align: center;
}

.bid-wrp {
    width: 100%;
    margin: 0 5px;
}

.sel-op-content {
    margin-top: 30px;
}

.sel-op-content .heading {
    font-size: 20px;
    font-weight: bold;
}

.bot-navi-mob-wrapper {
    display: none;
}

.log-out {
    margin-left: 10px;
    padding: 2px 5px;
    border-radius: 5px;
    border: none;
    font-size: 10px;
    background-color: red;
    color: white;
}

.item-wrp-lg {
    padding: 10px;
    padding-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.048);
    color: black;
    cursor: pointer;
}

.item-wrp-lg .img-box {
    width: 200px;
    height: 115px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    background-color: rgba(0, 0, 0, 0.034);
}

.item-wrp-lg .img-box img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;

}

.item-wrp-lg .content-box {
    width: 100%;
}

.item-wrp-lg .content-box .item-name {
    font-weight: 500;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.condition-1 {
    color: black;
}

.item-wrp-lg .content-box .membership {
    padding: 0 4px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.048);
}

.item-wrp-lg .content-box .membership-none {
    display: none;
}

.item-wrp-lg .content-box .price {
    font-weight: 500;
    color: #8EC641;
}

.bid-icon-box {
    width: 50px;
    height: 12px;
    margin-left: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bid-icon-box img {
    max-width: 100%;
    max-height: 100%;
}

@media only screen and (max-width: 1200px) {

    .cover-img-box {
        height: 170px;
    }

    .item-img-box {
        height: 160px;
    }
}

@media only screen and (max-width: 991px) {
    .left {
        display: none;
    }

    .cover-img-box {
        height: 160px;
    }

    .bot-navi-mob-wrapper {
        width: 100%;
        height: 45px;
        background-color: white;
        border-top: 1px solid rgba(0, 0, 0, 0.171);
        display: flex;
        justify-content: space-evenly;
        position: sticky;
        bottom: 0;
        left: 0;
    }

    .bot-navi-mob-wrapper button {
        width: 100%;
        border: none;
        background-color: transparent;
        position: relative;
    }

    .mob-img-box {
        position: relative;
    }

    .mob-noti-chat-counter {
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
        position: absolute;
    }

    .bot-navi-mob-wrapper button img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 767px) {

    .pro-container {
        max-width: 80px;
    }

    .details .status {
        width: 100%;
    }

    .cover-img-box {
        height: 150px;
    }

    .pro-box {
        top: 10px;
        left: 0;
    }

    .details {
        margin-top: 5px;
    }

    .details .name {
        font-size: 13px;
    }

    .follow {
        display: none;
    }

    .count-mob {
        width: 250px;
        display: flex;
    }

    .col-4 {
        padding: 1px;
    }

    .item-content-box {
        font-size: 13px;
    }

    .item-img-box {
        margin-bottom: 1px;
    }
}

@media only screen and (max-width: 575px) {

    .cover-img-box {
        height: 100px;
    }

    .bot-navi-mob-wrapper button p {
        display: none;
    }

    .bot-navi-mob-wrapper button img {
        margin: 0;
    }

    .item-img-box {
        height: 130px;
    }

    .item-content-box {
        font-size: 13px;
        line-height: 15px;
    }

    .ads-types-icon-box-lg {
        display: none;
    }

    .ads-types-icon-box-sm {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 500px) {

    .pro-container {
        max-width: 65px;
    }

    .pro-box {
        width: 65px;
        height: 65px;
    }

    .item-img-box {
        height: 120px;
    }
}

@media only screen and (max-width: 370px) {
    .count-mob {
        width: 170px;
    }
}

@media only screen and (max-width: 300px) {
    .item-img-box {
        height: 80px;
    }

    .item-content-box {
        height: 50px;
    }
}
</style>