<template>
    <div>
        <transition name="fade">
            <div class="main" v-if="chatIsVisible" v-click-outside="onClickOutside">
                <div class="close-wrp col-theme">
                    <div v-if="true"></div>
                    <button v-if="false" class="btn-back-close">
                        <img src="../assets/icons/backward.png" alt="" />
                    </button>
                    <p class="pad-mar-no">Chat</p>
                    <button @click="close()" class="btn-back-close pad-mar-no">
                        <img src="../assets/icons/close.png" alt="" />
                    </button>
                </div>
                <!-- <div class="title-wrp">
                    <div class="item-img-box">
                        <img class="item-img" src="../assets/test/ads_sample.jpg" alt="">
                    </div>
                    <p class="item-name pad-mar-no">Huawei Nova 2i</p>
                </div> -->
                <div v-if="chatListIsVisible" class="chat-users-main">
                    <div v-if="usersInfo.length === 0" class="msg-empty">
                        <p class="pad-mar-no f-sz sm">No conversation</p>
                    </div>
                    <div class="chat-user" v-for="(item, index) in usersInfo" :key="index"
                        @click="conversationClick(index)">
                        <div class="le">
                            <div class="profile-img-box bor-col-theme">
                                <img :src="item.profile" alt="" />
                            </div>
                            <div class="user-name-mail-box">
                                <p class="name pad-mar-no">
                                    {{ item.firstName + " " + item.lastName }}
                                </p>
                                <p class="email pad-mar-no f-sz-sm">{{ item.email }}</p>
                            </div>
                        </div>
                        <div class="ri">
                            <p class="pad-mar-no f-sz-sm">{{ item.updatedAt }}</p>
                        </div>
                    </div>
                </div>
                <div v-if="!chatListIsVisible" class="msg-area-box" ref="msgArea" id="msg-area">
                    <div v-for="(conver, index) in convers" :key="index"
                        :class="conver.isUser === true ? 'msg-w100-le' : 'msg-w100-ri'" id="msg-box">
                        <div :class="conver.isUser === true ? 'msg-le' : 'msg-ri'">
                            <div v-if="conver.itemName !== null" class="mention-item-box">
                                <div class="mention-item-info">
                                    <div class="mention-item-img-box">
                                        <img :src="conver.itemImg" alt="">
                                    </div>
                                    <p class="mention-item-name pad-mar-no f-sz-sm">{{ conver.itemName }}</p>
                                </div>
                                <div style="width: 100%; height: 1px; margin: 5px 0; background-color: grey;"></div>
                            </div>
                            <span class="msg-txt pad-mar-no">{{ conver.message }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="!chatListIsVisible" class="type-area-box col-theme">
                    <input v-model="inpMessage" type="text" name="" id="" placeholder="Type your message here"
                        @keydown.enter="sendMessage()" />
                    <button @click="sendMessage()" class="btn-send pad-mar-no">
                        <img src="../assets/icons/sent.png" alt="" />
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
/* eslint-disable */
import vClickOutside from "click-outside-vue3";
import { mapState } from "vuex";
import axios from "axios";
import jwtDecode from "jwt-decode";

export default {
    props: {
        hideChat: Boolean,
        adOwnerId: Number,
        mentionedItemImg: String,
        mentionedItemName: String,
    },

    data() {
        return {
            chatIsVisible: false,
            chatListIsVisible: true,
            userId: null,
            userEmail: null,
            toMail: null,
            inpMessage: "",
            convers: [],
            usersInfo: [],
            selectedConversationId: null,
            selectedItemImg: null,
            intervalId: null,
        };
    },

    computed: {
        ...mapState(["api"]),
    },

    directives: {
        clickOutside: vClickOutside.directive,
    },

    watch: {
        hideChat(value) {
            this.chatIsVisible = value;
            // this.convers = [];
            if (this.chatListIsVisible === false) {
                this.$nextTick(() => {
                    var myDiv = document.getElementById("msg-area");
                    myDiv.scrollTop = myDiv.scrollHeight;
                });
            }
        },

        adOwnerId(val) {
            if (val !== null) {
                this.chatListIsVisible = false;
                this.loadChat();
            } else {
                this.chatListIsVisible = true;
            }
        },

        chatListIsVisible(val) {
            if (val === false && this.hideChat === true) {
                this.$nextTick(() => {
                    var myDiv = document.getElementById("msg-area");
                    myDiv.scrollTop = myDiv.scrollHeight;
                });
            }
        },

        mentionedItemImg(val) {
            if (val !== null) {
                let img = new Image();
                img.src = val;

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    const maxWidth = 50;
                    const maxHeight = 50;

                    let width = img.width;
                    let height = img.height;

                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }

                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }

                    canvas.width = width;
                    canvas.height = height;

                    ctx.drawImage(img, 0, 0, width, height);

                    this.$nextTick(() => {
                        this.selectedItemImg = canvas.toDataURL('image/jpeg');
                    });
                };
            } else {
                this.selectedItemImg = null;
            }
        }
    },

    mounted() {
        this.loadChatUsers();
    },

    methods: {
        async loadChat() {
            await axios.get(`${this.api}/users/get-user-mail/${this.adOwnerId}`).then((response) => {
                this.toMail = response.data.email;
            })
            await axios.get(`${this.api}/chat/get-all`).then((response) => {
                for (let i = 0; i < response.data.response.length; i++) {
                    let dividedId = response.data.response[i].id.split("%^sp^%");

                    if ((dividedId[0] === this.userEmail || dividedId[1] === this.userEmail) && (dividedId[0] === this.toMail || dividedId[1] === this.toMail)) {
                        this.selectedConversationId = response.data.response[i].id;

                        axios.post(`${this.api}/chat/get-user-chat`, {
                            id: this.selectedConversationId,
                        }).then((response) => {
                            for (let i = 0; i < response.data.length; i++) {
                                if (response.data[i].fromMail === this.userEmail) {
                                    this.convers[i] = {};
                                    this.convers[i].message = response.data[i].message;
                                    this.convers[i].itemImg = response.data[i].itemImg;
                                    this.convers[i].itemName = response.data[i].itemName;
                                    this.convers[i].isUser = true;
                                } else {
                                    this.convers[i] = {};
                                    this.convers[i].message = response.data[i].message;
                                    this.convers[i].itemImg = response.data[i].itemImg;
                                    this.convers[i].itemName = response.data[i].itemName;
                                    this.convers[i].isUser = false;
                                }
                            }
                        });
                    }
                }
            });

            this.intervalId = setInterval(async () => {
                await axios.get(`${this.api}/chat/get-all`).then((response) => {
                    for (let i = 0; i < response.data.response.length; i++) {
                        let dividedId = response.data.response[i].id.split("%^sp^%");

                        if ((dividedId[0] === this.userEmail || dividedId[1] === this.userEmail) && (dividedId[0] === this.toMail || dividedId[1] === this.toMail)) {
                            this.selectedConversationId = response.data.response[i].id;

                            axios.post(`${this.api}/chat/get-user-chat`, {
                                id: this.selectedConversationId,
                            }).then((response) => {
                                for (let i = 0; i < response.data.length; i++) {
                                    if (response.data[i].fromMail === this.userEmail) {
                                        this.convers[i] = {};
                                        this.convers[i].message = response.data[i].message;
                                        this.convers[i].itemImg = response.data[i].itemImg;
                                        this.convers[i].itemName = response.data[i].itemName;
                                        this.convers[i].isUser = true;
                                    } else {
                                        this.convers[i] = {};
                                        this.convers[i].message = response.data[i].message;
                                        this.convers[i].itemImg = response.data[i].itemImg;
                                        this.convers[i].itemName = response.data[i].itemName;
                                        this.convers[i].isUser = false;
                                    }
                                }
                            });
                        }
                    }
                });
            }, 5000);
        },

        async loadChatUsers() {
            const token = localStorage.getItem("token");
            const decoded = jwtDecode(token);

            this.userId = decoded.id;
            this.userEmail = decoded.email;

            await axios.post(`${this.api}/chat/get-chat-user`, {
                email: decoded.email,
            }).then((response) => {
                let divider = "%^sp^%";
                for (let i = 0; i < response.data.length; i++) {
                    this.usersInfo[i] = {};
                    this.usersInfo[i].id = response.data[i].id;
                    let currentDate = new Date();
                    let pastDate = new Date(response.data[i].updatedAt);

                    let timeDifference = currentDate.getTime() - pastDate.getTime();
                    let secondsAgo = Math.floor(timeDifference / 1000);

                    if (secondsAgo < 60) {
                        this.usersInfo[i].updatedAt = 'just now';
                    } else if (secondsAgo < 3600) {
                        let minutesAgo = Math.floor(secondsAgo / 60);
                        this.usersInfo[i].updatedAt = minutesAgo + " m ago";
                    } else if (secondsAgo < 86400) {
                        let hoursAgo = Math.floor(secondsAgo / 3600);
                        this.usersInfo[i].updatedAt = hoursAgo + " h ago";
                    } else {
                        let daysAgo = Math.floor(secondsAgo / 86400);
                        this.usersInfo[i].updatedAt = daysAgo + " d ago";
                    }

                    let seperatedMails = response.data[i].id.split(divider);
                    this.usersInfo[i].email = seperatedMails[0] === this.userEmail ? seperatedMails[1] : seperatedMails[0];

                    if (decoded.id !== response.data[i].user1Id) {
                        axios.get(`${this.api}/users/fetch-user-info/${response.data[i].user1Id}`).then((response) => {
                            // response.data.profile = `data:image/jpeg;base64,${response.data.profile}`;
                            this.usersInfo[i].profile = `data:image/jpeg;base64,${response.data.profile}`;
                            this.usersInfo[i].firstName = response.data.firstName;
                            this.usersInfo[i].lastName = response.data.lastName;
                        });
                    }

                    if (decoded.id !== response.data[i].user2Id) {
                        axios.get(`${this.api}/users/fetch-user-info/${response.data[i].user2Id}`).then((response) => {
                            this.usersInfo[i].profile = `data:image/jpeg;base64,${response.data.profile}`;
                            this.usersInfo[i].firstName = response.data.firstName;
                            this.usersInfo[i].lastName = response.data.lastName;
                        });
                    }
                }
            });

            // this.intervalId = setInterval(async () => {
            //     await axios.post(`${this.api}/chat/get-chat-user`, {
            //         email: decoded.email,
            //     }).then((response) => {
            //         for (let i = 0; i < response.data.length; i++) {
            //             this.usersInfo[i] = {};
            //             this.usersInfo[i].id = response.data[i].id;
            //             let currentDate = new Date();
            //             let pastDate = new Date(response.data[i].updatedAt);

            //             let timeDifference = currentDate.getTime() - pastDate.getTime();
            //             let secondsAgo = Math.floor(timeDifference / 1000);

            //             if (secondsAgo < 60) {
            //                 this.usersInfo[i].updatedAt = 'just now';
            //             } else if (secondsAgo < 3600) {
            //                 let minutesAgo = Math.floor(secondsAgo / 60);
            //                 this.usersInfo[i].updatedAt = minutesAgo + " m ago";
            //             } else if (secondsAgo < 86400) {
            //                 let hoursAgo = Math.floor(secondsAgo / 3600);
            //                 this.usersInfo[i].updatedAt = hoursAgo + " h ago";
            //             } else {
            //                 let daysAgo = Math.floor(secondsAgo / 86400);
            //                 this.usersInfo[i].updatedAt = daysAgo + " d ago";
            //             }

            //             if (decoded.id !== response.data[i].user1Id) {
            //                 axios.get(`${this.api}/users/fetch-user-info/${response.data[i].user1Id}`).then((response) => {
            //                     // response.data.profile = `data:image/jpeg;base64,${response.data.profile}`;
            //                     this.usersInfo[i].profile = `data:image/jpeg;base64,${response.data.profile}`;
            //                     this.usersInfo[i].firstName = response.data.firstName;
            //                     this.usersInfo[i].lastName = response.data.lastName;
            //                 });
            //             }

            //             if (decoded.id !== response.data[i].user2Id) {
            //                 axios.get(`${this.api}/users/fetch-user-info/${response.data[i].user2Id}`).then((response) => {
            //                     this.usersInfo[i].profile = `data:image/jpeg;base64,${response.data.profile}`;
            //                     this.usersInfo[i].firstName = response.data.firstName;
            //                     this.usersInfo[i].lastName = response.data.lastName;
            //                 });
            //             }
            //             console.log(this.usersInfo);
            //         }
            //     });
            // }, 5000);
        },

        async conversationClick(index) {
            this.convers = [];
            this.selectedConversationId = this.usersInfo[index].id;

            await axios.post(`${this.api}/chat/get-user-chat`, {
                id: this.usersInfo[index].id,
            }).then((response) => {
                // this.convers = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].fromMail === this.userEmail) {
                        this.convers[i] = {};
                        this.convers[i].message = response.data[i].message;
                        this.convers[i].itemImg = response.data[i].itemImg;
                        this.convers[i].itemName = response.data[i].itemName;
                        this.convers[i].isUser = true;
                    } else {
                        this.convers[i] = {};
                        this.convers[i].message = response.data[i].message;
                        this.convers[i].itemImg = response.data[i].itemImg;
                        this.convers[i].itemName = response.data[i].itemName;
                        this.convers[i].isUser = false;
                    }
                }
            });
            this.chatListIsVisible = false;
            this.$nextTick(() => {
                var myDiv = document.getElementById("msg-area");
                myDiv.scrollTop = myDiv.scrollHeight;
            });
            this.intervalId = setInterval(async () => {
                await axios.post(`${this.api}/chat/get-user-chat`, {
                    id: this.usersInfo[index].id,
                }).then((response) => {
                    this.convers = [];
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].fromMail === this.userEmail) {
                            this.convers[i] = {};
                            this.convers[i].message = response.data[i].message;
                            this.convers[i].itemImg = response.data[i].itemImg;
                            this.convers[i].itemName = response.data[i].itemName;
                            this.convers[i].isUser = true;
                        } else {
                            this.convers[i] = {};
                            this.convers[i].message = response.data[i].message;
                            this.convers[i].itemImg = response.data[i].itemImg;
                            this.convers[i].itemName = response.data[i].itemName;
                            this.convers[i].isUser = false;
                        }
                    }
                });
            },
                5000);
        },

        scrolToBottom() {
            let height = this.$refs.msgArea.clientHeight;
        },

        async sendMessage() {

            if (this.adOwnerId !== null) {
                await axios.get(`${this.api}/users/get-user-mail/${this.adOwnerId}`).then((response) => {
                    this.toMail = response.data.email;
                })
            } else {
                let divider = "%^sp^%";
                let seperatedMails = this.selectedConversationId.split(divider);

                if (this.userEmail !== seperatedMails[0]) {
                    this.toMail = seperatedMails[0];
                }

                if (this.userEmail !== seperatedMails[1]) {
                    this.toMail = seperatedMails[1];
                }
            }

            await axios.post(`${this.api}/chat/insert`, {
                fromMail: this.userEmail,
                toMail: this.toMail,
                user1Id: this.userId,
                user2Id: this.adOwnerId,
                itemImg: this.selectedItemImg,
                itemName: this.mentionedItemName,
                message: this.inpMessage,
            });

            this.convers.push({ isUser: true, message: this.inpMessage, itemName: this.mentionedItemName, itemImg: this.mentionedItemImg });
            this.inpMessage = "";

            this.$nextTick(() => {
                var myDiv = document.getElementById("msg-area");
                myDiv.scrollTop = myDiv.scrollHeight;
            });
        },

        close() {
            this.$emit("hideChat", false);
            if (this.adOwnerId !== null) {
                this.chatListIsVisible = false;
            } else {
                this.chatListIsVisible = true;
            }
            clearInterval(this.intervalId);
        },

        onClickOutside() {
            this.$emit("hideChat", false);
            if (this.adOwnerId !== null) {
                this.chatListIsVisible = false;
            } else {
                this.chatListIsVisible = true;
            }
            clearInterval(this.intervalId);
        },
    },
};
</script>

<style scoped>
input {
    width: 100%;
    height: 35px;
    padding-left: 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.199);
    border-radius: 15px;
}

.btn-send {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    border: none;
    background-color: transparent;
}

button img {
    max-width: 100%;
    max-height: 100%;
}

.close-wrp {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
}

.close-wrp p {
    color: white;
}

.btn-back-close {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
}

.brn-back-close img {
    max-width: 100%;
    max-height: 100%;
}

.main {
    width: 400px;
    height: 80vh;
    margin: 20px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.363);
    border-radius: 15px;
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.type-area-box {
    width: 100%;
    height: 50px;
    padding: 10px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.075);
}

.title-wrp {
    width: 100%;
    height: 60px;
    padding: 10px;
    margin-bottom: 5px;
    background-color: rgba(0, 0, 0, 0.034);
    display: flex;
    align-items: center;
}

.item-img-box {
    width: 40px;
    height: 100%;
    margin-right: 10px;
    background-color: white;
    position: relative;
}

.item-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
}

.item-name {
    font-weight: bold;
}

.chat-users-main {
    height: 100%;
    overflow-y: auto;
    position: relative;
}

.msg-empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.chat-user {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.082);
}

.chat-user .le {
    width: 85%;
    display: flex;
    align-items: center;
}

.chat-user .le .profile-img-box {
    width: 45px;
    height: 45px;
    margin-right: 10px;
    border: 2px solid;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.chat-user .le .profile-img-box img,
.mention-item-img-box img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
}

.chat-user .ri {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.msg-area-box {
    height: 100%;
    padding: 0 10px;
    padding-bottom: 5px;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.msg-area-box::-webkit-scrollbar {
    width: 6px;
}

.msg-area-box::-webkit-scrollbar-track {
    border-radius: 10px;
}

.msg-area-box::-webkit-scrollbar-thumb {
    background: rgba(128, 128, 128, 0.315);
    border-radius: 10px;
}

.msg-area-box::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 128, 128, 0.493);
}

.mention-item-info {
    display: flex;
}

.mention-item-img-box {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    margin-right: 5px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.093);
}

.mention-item-name {
    font-weight: bold;
}

.msg-le {
    max-width: 200px;
    padding: 5px 10px;
    background-color: lightgreen;
    display: inline-block;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.msg-ri {
    max-width: 200px;
    padding: 5px 10px;
    background-color: lightblue;
    display: inline-block;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.msg-w100-le {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
}

.msg-w100-ri {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
}

.fade-enter-from,
.fade-leave-to {
    right: -100%;
    bottom: -100%;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s ease;
}

@media only screen and (max-width: 575px) {
    .main {
        width: 100%;
        height: 80vh;
        margin: 0;
        margin-bottom: 50px;
    }
}
</style>
