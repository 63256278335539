<template>
    <div>
        <div class="main">
            <div class="box">
                <div class="dis-fl al-it-cen">
                    <div class="logo anim-shine"></div>
                    <div class="title anim-shine"></div>
                </div>
                <div class="date anim-shine"></div>
                <div class="apply col-theme">
                    <p class="pad-mar-no">Easy Apply</p>
                </div>
                <div class="divider"></div>
                <div class="title-2 anim-shine"></div>
                <div class="title-2 anim-shine"></div>
                <div class="title-2 anim-shine"></div>
                <div class="title-3 anim-shine"></div>
                <div class="title-3 anim-shine"></div>
                <div class="title-3 anim-shine"></div>
                <div class="title-3 anim-shine"></div>
                <div class="title-3 anim-shine"></div>
                <div class="title-3 anim-shine"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.anim-shine {
    background-color: rgba(0, 0, 0, 0.164);
    color: white;
    animation: ani 2s both infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.main {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.box {
    width: 700px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.02);
}

.logo {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.title {
    width: 150px;
    height: 30px;
}

.date {
    width: 150px;
    height: 15px;
}

.apply {
    width: 100px;
    height: 40px;
    margin: 20px 0;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.divider{
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    background-color: rgba(0, 0, 0, 0.114);
}

.title-2{
    width: 250px;
    height: 30px;
    margin-bottom: 20px;
}

.title-3{
    width: 100%;
    height: 20px;
    margin-bottom: 10px;
}
</style>