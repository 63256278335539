<template>
    <div>
        <loading v-model:active="isLoading" loader="bars" color="#8EC641" />
        <transition name="form-fade-trans">
            <div @click="close()" v-if="formIsVisible" class="main">
                <div @click.stop v-if="!editAdformIsVisible" class="form">
                    <div class="opt-btn-box">
                        <button @click="editAdformIsVisible = true;" class="btn-edit">
                            <img src="../assets/icons/pro-ad-edit-white.png" alt="">
                        </button>
                        <button @click="adDissableClick()" class="btn-dissable">
                            <img :src="isNotDissable === 1 ? require('../assets/icons/pro-ad-dissable-white.png') : require('../assets/icons/pro-ad-enable-white.png')"
                                alt="">
                        </button>
                        <button @click="adDeleteClick()" class="btn-delete">
                            <img src="../assets/icons/pro-ad-delete-white.png" alt="">
                        </button>
                    </div>
                    <div class="con-box">
                        <div class="close-wrp">
                            <button @click="close()" class="btn-close"></button>
                        </div>
                        <div v-if="itemInfosAreLoading" class="skel-container">
                            <div class="skel-box">
                                <div class="skel-head com-skel"></div>
                                <div class="skel-des com-skel"></div>
                            </div>
                            <div class="skel-box">
                                <div class="skel-head com-skel"></div>
                                <div class="skel-des com-skel"></div>
                            </div>
                            <div class="skel-box">
                                <div class="skel-head com-skel"></div>
                                <div class="skel-des com-skel"></div>
                            </div>
                            <div class="skel-box">
                                <div class="skel-head com-skel"></div>
                                <div class="skel-des com-skel"></div>
                            </div>
                            <div class="skel-box">
                                <div class="skel-imgs">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                            <div class="skel-img-box com-skel"></div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                            <div class="skel-img-box com-skel"></div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                            <div class="skel-img-box com-skel"></div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                            <div class="skel-img-box com-skel"></div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                            <div class="skel-img-box com-skel"></div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                            <div class="skel-img-box com-skel"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!itemInfosAreLoading" class="content">
                            <div class="content-box">
                                <div class="head">
                                    <p>Title :</p>
                                </div>
                                <div class="des">
                                    <p>{{ title }}</p>
                                </div>
                            </div>
                            <div v-if="price !== 'NaN'" class="content-box">
                                <div class="head">
                                    <p>Price (Rs) :</p>
                                </div>
                                <div class="des">
                                    <p>{{ price.toLocaleString() }}</p>
                                </div>
                            </div>
                            <div class="content-box">
                                <div class="head">
                                    <p>Category :</p>
                                </div>
                                <div class="des">
                                    <p>{{ parentMainCat }}</p>
                                </div>
                            </div>
                            <div v-if="parentMainCat !== 'Cars & Vehicles' && parentMainCat !== 'Jobs' && parentMainCat !== 'Real Estate' && parentMainCat !== 'Community' && subCategory !== 'Arts & Collectibles'" class="content-box">
                                <div class="head">
                                    <p>Condition :</p>
                                </div>
                                <div class="des">
                                    <p>{{ condition_1 }}</p>
                                </div>
                            </div>
                            <div v-if="parentMainCat === 'Cars & Vehicles'" class="content-box">
                                <div class="head">
                                    <p>Condition :</p>
                                </div>
                                <div class="des">
                                    <p>{{ condition_2 }}</p>
                                </div>
                            </div>
                            <div class="content-box">
                                <div class="head">
                                    <p>District :</p>
                                </div>
                                <div class="des">
                                    <p>{{ district }}</p>
                                </div>
                            </div>
                            <!-- jobs start -->
                            <div v-if="jobType1" class="content-box">
                                <div class="head">
                                    <p>Job Type :</p>
                                </div>
                                <div class="des">
                                    <p>{{ jobType1 }} {{ jobType2 }}</p>
                                </div>
                            </div>
                            <div v-if="experience" class="content-box">
                                <div class="head">
                                    <p>Experience :</p>
                                </div>
                                <div class="des">
                                    <p>{{ experience }}</p>
                                </div>
                            </div>
                            <div v-if="requiredEducation" class="content-box">
                                <div class="head">
                                    <p>Required Education :</p>
                                </div>
                                <div class="des">
                                    <p>{{ requiredEducation }}</p>
                                </div>
                            </div>
                            <!-- jobs end -->
                            <!-- Vehicles start  -->
                            <div v-if="brand" class="content-box">
                                <div class="head">
                                    <p>Brand :</p>
                                </div>
                                <div class="des">
                                    <p>{{ brand }}</p>
                                </div>
                            </div>
                            <div v-if="model" class="content-box">
                                <div class="head">
                                    <p>Model :</p>
                                </div>
                                <div class="des">
                                    <p>{{ model }}</p>
                                </div>
                            </div>
                            <div v-if="edition" class="content-box">
                                <div class="head">
                                    <p>Edition :</p>
                                </div>
                                <div class="des">
                                    <p>{{ edition }}</p>
                                </div>
                            </div>
                            <div v-if="yearOfManufacture" class="content-box">
                                <div class="head">
                                    <p>Year of Manufacture :</p>
                                </div>
                                <div class="des">
                                    <p>{{ yearOfManufacture }}</p>
                                </div>
                            </div>
                            <div v-if="milage" class="content-box">
                                <div class="head">
                                    <p>Milage :</p>
                                </div>
                                <div class="des">
                                    <p>{{ milage }}</p>
                                </div>
                            </div>
                            <div v-if="engineCapacity" class="content-box">
                                <div class="head">
                                    <p>Engine Capacity :</p>
                                </div>
                                <div class="des">
                                    <p>{{ engineCapacity }}</p>
                                </div>
                            </div>
                            <div v-if="fuelType" class="content-box">
                                <div class="head">
                                    <p>Fuel Type :</p>
                                </div>
                                <div class="des">
                                    <p>{{ fuelType }}</p>
                                </div>
                            </div>
                            <div v-if="transmittion" class="content-box">
                                <div class="head">
                                    <p>Transmittion :</p>
                                </div>
                                <div class="des">
                                    <p>{{ transmittion }}</p>
                                </div>
                            </div>
                            <!-- Vehicle end -->
                            <!-- real estate start -->
                            <div v-if="bedrooms" class="content-box">
                                <div class="head">
                                    <p>Bedrooms :</p>
                                </div>
                                <div class="des">
                                    <p>{{ bedrooms }}</p>
                                </div>
                            </div>
                            <div v-if="bathrooms" class="content-box">
                                <div class="head">
                                    <p>Bathrooms :</p>
                                </div>
                                <div class="des">
                                    <p>{{ bathrooms }}</p>
                                </div>
                            </div>
                            <div v-if="size" class="content-box">
                                <div class="head">
                                    <p>Size :</p>
                                </div>
                                <div class="des">
                                    <p>{{ size }}</p>
                                </div>
                            </div>
                            <div v-if="houseSize" class="content-box">
                                <div class="head">
                                    <p>House Size :</p>
                                </div>
                                <div class="des">
                                    <p>{{ houseSize }}</p>
                                </div>
                            </div>
                            <div v-if="landSize" class="content-box">
                                <div class="head">
                                    <p>Land Size :</p>
                                </div>
                                <div class="des">
                                    <p>{{ landSize }}</p>
                                </div>
                            </div>
                            <div v-if="address" class="content-box">
                                <div class="head">
                                    <p>address :</p>
                                </div>
                                <div class="des">
                                    <p>{{ address }}</p>
                                </div>
                            </div>
                            <div class="content-box">
                                <div class="head">
                                    <p>Description :</p>
                                </div>
                                <div class="des">
                                    <p>{{ description }}</p>
                                </div>
                            </div>
                            <div class="imgs-box row pad-mar-no">
                                <div v-for="(item, index) in subImages" :key="index"
                                    class="col-lg-4 col-md-4 col-sm-4 col-6">
                                    <div class="img-box">
                                        <img :src="item.imgURL" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->
                <!--  -->
                <!--  -->
                <!-- edit ad form -->
                <div @click.stop v-if="editAdformIsVisible" class="form">
                    <div class="con-box">
                        <div class="close-wrp">
                            <button @click="close()" class="btn-close"></button>
                        </div>
                        <div>
                            <div class="inp-box">
                                <label class="f-sz-sm" for="">District</label>
                                <select v-model="district" class="form-control" name="" id="">
                                    <option value="Colombo">Colombo</option>
                                    <option value="Kandy">Kandy</option>
                                    <option value="Galle">Galle</option>
                                    <option value="Ampara">Ampara</option>
                                    <option value="Anuradhapura">Anuradhapura</option>
                                    <option value="Badulla">Badulla</option>
                                    <option value="Batticaloa">Batticaloa</option>
                                    <option value="Gampaha">Gampaha</option>
                                    <option value="Hambantota">Hambantota</option>
                                    <option value="Jaffna">Jaffna</option>
                                    <option value="Kalutara">Kalutara</option>
                                    <option value="Kegalle">Kegalle</option>
                                    <option value="Kilinochchi">Kilinochchi</option>
                                    <option value="Kurunegala">Kurunegala</option>
                                    <option value="Manner">Manner</option>
                                    <option value="Matale">Matale</option>
                                    <option value="Matara">Matara</option>
                                    <option value="Monaragala">Monaragala</option>
                                    <option value="Mullativu">Mullativu</option>
                                    <option value="Nuwara Eliya">Nuwara Eliya</option>
                                    <option value="Polonnaruwa">Polonnaruwa</option>
                                    <option value="Puttalam">Puttalam</option>
                                    <option value="Ratnapura">Ratnapura</option>
                                    <option value="Trincomalee">Trincomalee</option>
                                    <option value="Vavunia">Vavunia</option>
                                </select>
                            </div>
                            <!-- Type of animal -->
                            <!-- <div v-if="parentMainCat === 'Pets'" class="inp-box">
                                <label class="f-sz-sm" for="">Type of animal</label>
                                <select v-model="type" class="form-control" name="" id="">
                                    <option value="">Bird</option>
                                    <option value="">Cat</option>
                                    <option value="">Dog</option>
                                    <option value="">Fish</option>
                                    <option value="">Guinea pig / mounse</option>
                                    <option value="">Rabbit</option>
                                    <option value="">Reptile</option>
                                    <option value="">Other</option>
                                </select>
                            </div> -->
                            <!-- property type -->
                            <!-- <div v-if="parentMainCat === 'Real Estate'" class="inp-box">
                                <label class="f-sz-sm" for="">Property type</label><br>
                                <select v-model="propertyTypeInp" class="form-control" name="" id="">
                                    <option value="" disabled selected>Select category</option>
                                    <option value="">Building</option>
                                </select>
                            </div> -->
                            <!-- Bedrooms -->
                            <div v-if="subCategory === 'Room Rentals & Roommates' || subCategory === 'Houses for Sale'"
                                class="inp-box">
                                <label class="f-sz-sm" for="">Bedrooms</label><br>
                                <select v-model="bedrooms" class="form-control" name="" id="">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">5+</option>
                                </select>
                            </div>
                            <!-- Bathrooms -->
                            <div v-if="subCategory === 'Room Rentals & Roommates' || subCategory === 'Houses for Sale'"
                                class="inp-box">
                                <label class="f-sz-sm" for="">Bathrooms</label><br>
                                <select v-model="bathrooms" class="form-control" name="" id="">
                                    <option value="" disabled selected>Select category</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">5+</option>
                                </select>
                            </div>
                            <!-- Bike type -->
                            <!-- <div v-if="bikeType" class="inp-box">
                                <label class="f-sz-sm" for="">Bike type</label><br>
                                <select v-model="bikeTypeInp" class="form-control" name="" id="">
                                    <option value="" disabled selected>Select category</option>
                                    <option value="">cable</option>
                                </select>
                            </div> -->
                            <!-- land type -->
                            <!-- <div class="inp-box">
                                <label class="f-sz-sm" for="">Land type</label><br>
                                <div class="row">
                                    <div class="dis-fl col-lg-6">
                                        <input v-model="landTypeInp" value="Agricultural" type="radio" name="condition"
                                            id="">&nbsp;
                                        <p class="pad-mar-no">Agricultural</p>
                                    </div>
                                    <div class="dis-fl col-lg-6">
                                        <input v-model="landTypeInp" value="Residential" type="radio" name="condition"
                                            id="">&nbsp;
                                        <p class="pad-mar-no">Residential</p>
                                    </div>
                                    <div class="dis-fl col-lg-6">
                                        <input v-model="landTypeInp" value="Commercial" type="radio" name="condition"
                                            id="">&nbsp;
                                        <p class="pad-mar-no">Commercial</p>
                                    </div>
                                    <div class="dis-fl col-lg-6">
                                        <input v-model="landTypeInp" value="Other" type="radio" name="condition"
                                            id="">&nbsp;
                                        <p class="pad-mar-no">Other</p>
                                    </div>
                                </div>
                            </div> -->
                            <!-- condition -->
                            <div v-if="parentMainCat !== 'Cars & Vehicles' && parentMainCat !== 'Jobs' && parentMainCat !== 'Real Estate' && parentMainCat !== 'Community' && subCategory !== 'Arts & Collectibles'"
                                class="inp-box">
                                <label class="f-sz-sm" for="">Condition</label><br>
                                <div class="row">
                                    <div class="dis-fl col-lg">
                                        <input v-model="condition_1" value="Used" type="radio" name="condition" id="">&nbsp;
                                        <p class="pad-mar-no">Used</p>
                                    </div>
                                    <div class="dis-fl col-lg">
                                        <input v-model="condition_1" value="New" type="radio" name="condition" id="">&nbsp;
                                        <p class="pad-mar-no">New</p>
                                    </div>
                                </div>
                            </div>
                            <!-- condition recon-->
                            <div v-if="parentMainCat === 'Cars & Vehicles'" class="inp-box">
                                <label class="f-sz-sm" for="">Condition</label><br>
                                <div class="row">
                                    <div class="dis-fl col-lg-6">
                                        <input v-model="condition_2" value="Used" type="radio" name="condition" id="">&nbsp;
                                        <p class="pad-mar-no">Used</p>
                                    </div>
                                    <div class="dis-fl col-lg-6">
                                        <input v-model="condition_2" value="New" type="radio" name="condition" id="">&nbsp;
                                        <p class="pad-mar-no">New</p>
                                    </div>
                                    <div class="col-lg-6"></div>
                                    <div class="dis-fl col-lg-6">
                                        <input v-model="condition_2" value="Reconditioned" type="radio" name="condition"
                                            id="">&nbsp;
                                        <p class="pad-mar-no">Reconditioned</p>
                                    </div>
                                </div>
                            </div>
                            <!-- item type -->
                            <!-- <div class="inp-box">
                                <label class="f-sz-sm" for="">Item type</label><br>
                                <select v-model="itemTypeInp" class="form-control" name="" id="">
                                    <option value="" disabled selected>Select category</option>
                                    <option value="cable">cable</option>
                                </select>
                            </div> -->
                            <!-- Land size -->
                            <div v-if="subCategory === 'Land for Sale'" class="inp-box">
                                <label class="f-sz-sm" for="">Land size (perches)</label><br>
                                <input v-model="landSize" type="number" class="form-control" name="" id="">
                            </div>
                            <!-- house size -->
                            <div v-if="subCategory === 'Houses for Sale'" class="inp-box">
                                <label class="f-sz-sm" for="">House size (sqft)</label><br>
                                <input v-model="houseSize" type="number" class="form-control" name="" id="">
                            </div>
                            <!-- size -->
                            <div v-if="subCategory === 'Room Rentals & Roommates' || subCategory === 'Storage & Parkin' || subCategory === 'Commercial & Office Space'"
                                class="inp-box">
                                <label class="f-sz-sm" for="">Size (sqft)</label><br>
                                <input v-model="size" type="number" class="form-control" name="" id="">
                            </div>
                            <!-- Address -->
                            <div v-if="parentMainCat === 'Real Estate'" class="inp-box">
                                <label class="f-sz-sm" for="">Address (optional)</label><br>
                                <input v-model="address" type="text" class="form-control" name="" id="">
                            </div>
                            <!-- brand -->
                            <div v-if="parentMainCat === 'Cars & Vehicles'" class="inp-box">
                                <label class="f-sz-sm" for="">Brand</label><br>
                                <input v-model="brand" type="text" class="form-control" name="" id="">
                            </div>
                            <!-- model -->
                            <div v-if="parentMainCat === 'Cars & Vehicles'" class="inp-box">
                                <label class="f-sz-sm" for="">Model</label><br>
                                <input v-model="model" type="text" class="form-control" name="" id="">
                            </div>
                            <!-- title -->
                            <div class="inp-box">
                                <label class="f-sz-sm" for="">Title</label><br>
                                <input v-model="title" type="text" class="form-control" name="" id="">
                            </div>
                            <div class="inp-box">
                                <label class="f-sz-sm" for="">Price</label><br>
                                <input v-model="price" type="text" class="form-control" name="" id="">
                            </div>
                            <!-- screen size -->
                            <!-- <div class="inp-box">
                                <label class="f-sz-sm" for="">Screen size (optional)</label><br>
                                <select v-model="screenSizeInp" class="form-control" name="" id="">
                                    <option value="" disabled selected>Select category</option>
                                    <option value="">23</option>
                                </select>
                            </div> -->
                            <!-- Capacity -->
                            <!-- <div v-if="parentMainCat === 'Cars & Vehicles'" class="inp-box">
                                <label class="f-sz-sm" for="">Capacity (optional)</label><br>
                                <select v-model="capacityInp" class="form-control" name="" id="">
                                    <option value="" disabled selected>Select category</option>
                                    <option value="">cable</option>
                                </select>
                            </div> -->
                            <!-- edition -->
                            <div v-if="parentMainCat === 'Cars & Vehicles'" class="inp-box">
                                <label class="f-sz-sm" for="">Edition (optional)</label><br>
                                <input v-model="edition" type="text" class="form-control" name="" id="">
                            </div>
                            <!-- Year of Manufacture -->
                            <div v-if="parentMainCat === 'Cars & Vehicles'" class="inp-box">
                                <label class="f-sz-sm" for="">Year of Manufacture</label><br>
                                <input v-model="yearOfManufacture" type="text" class="form-control" name="" id="">
                            </div>
                            <!-- Model year -->
                            <!-- <div v-if="parentMainCat === 'Cars & Vehicles'" class="inp-box">
                                <label class="f-sz-sm" for="">Model Year</label><br>
                                <input v-model="" type="number" class="form-control" name="" id="">
                            </div> -->
                            <!-- Mileage (km) -->
                            <div v-if="parentMainCat === 'Cars & Vehicles'" class="inp-box">
                                <label class="f-sz-sm" for="">Mileage (km)</label><br>
                                <input v-model="milage" type="text" class="form-control" name="" id="">
                            </div>
                            <!-- Engine capacity (cc) -->
                            <div v-if="parentMainCat === 'Cars & Vehicles'" class="inp-box">
                                <label class="f-sz-sm" for="">Engine capacity (cc)</label><br>
                                <input v-model="engineCapacity" type="text" class="form-control" name="" id="">
                            </div>
                            <!-- Fuel type -->
                            <div v-if="parentMainCat === 'Cars & Vehicles'" class="inp-box">
                                <label class="f-sz-sm" for="">Fuel type</label><br>
                                <select v-model="fuelType" class="form-control" name="" id="">
                                    <option value="" disabled selected>Select category</option>
                                    <option value="Diesel">Diesel</option>
                                    <option value="Petrol">Petrol</option>
                                    <option value="CNG">CNG</option>
                                    <option value="Hybrid">Hybrid</option>
                                    <option value="Electric">Electric</option>
                                    <option value="Other fuel type">Other fuel type</option>
                                </select>
                            </div>
                            <!-- Transmission -->
                            <div v-if="parentMainCat === 'Cars & Vehicles'" class="inp-box">
                                <label class="f-sz-sm" for="">Transmission</label><br>
                                <select v-model="transmittion" class="form-control" name="" id="">
                                    <option value="" disabled selected>Select category</option>
                                    <option value="Manual">Manual</option>
                                    <option value="Automatick">Automatick</option>
                                    <option value="Tiptronic">Tiptronic</option>
                                    <option value="Other Transmission">Other Transmission</option>
                                </select>
                            </div>
                            <!-- Body type (optional) -->
                            <!-- <div class="inp-box">
                                <label class="f-sz-sm" for="">Body type (optional)</label><br>
                                <select v-model="bodyTypeInp" class="form-control" name="" id="">
                                    <option value="" disabled selected>Select category</option>
                                    <option value="test">cable</option>
                                </select>
                            </div> -->
                            <!-- description -->
                            <div class="inp-box">
                                <label class="f-sz-sm" for="">Description</label><br>
                                <textarea v-model="description" class="form-control" name="" id="" rows="5"></textarea>
                            </div>
                            <!-- Job type -->
                            <div v-if="parentMainCat === 'Jobs'" class="inp-box">
                                <label class="f-sz-sm" for="">Job Type</label><br>
                                <div class="row">
                                    <div class="dis-fl col-lg-6">
                                        <input v-model="jobType1" value="On Site" type="radio" name="condition" id="">&nbsp;
                                        <p class="pad-mar-no">On Site</p>
                                    </div>
                                    <div class="dis-fl col-lg-6">
                                        <input v-model="jobType1" value="Work From Home" type="radio" name="condition"
                                            id="">&nbsp;
                                        <p class="pad-mar-no">Work From Home</p>
                                    </div>
                                    <div class="col-lg-6"></div>
                                    <div class="dis-fl col-lg-6">
                                        <input v-model="jobType1" value="Hydbrid" type="radio" name="condition" id="">&nbsp;
                                        <p class="pad-mar-no">Hydbrid</p>
                                    </div>
                                </div>
                            </div>
                            <!-- job position -->
                            <div v-if="parentMainCat === 'Jobs'" class="inp-box">
                                <select v-model="jobType2" class="form-control" name="" id="">
                                    <option value="" disabled selected>Select category</option>
                                    <option value="Full Time">Full Time</option>
                                    <option value="Part Time">Part Time</option>
                                </select>
                            </div>
                            <!-- required experience -->
                            <div v-if="parentMainCat === 'Jobs'" class="inp-box">
                                <label class="f-sz-sm" for="">Required Experience (optional)</label><br>
                                <input v-model="experience" type="text" class="form-control" />
                            </div>
                            <!-- required education -->
                            <div v-if="parentMainCat === 'Jobs'" class="inp-box">
                                <label class="f-sz-sm" for="">Required Education</label><br>
                                <select v-model="requiredEducation" class="form-control" name="" id="">
                                    <option value="" disabled selected>Select category</option>
                                    <option value="Ordinary Level">Ordinary Level</option>
                                    <option value="Advanced Level">Advanced Level</option>
                                    <option value="Certificate">Certificate</option>
                                    <option value="Diploma">Diploma</option>
                                    <option value="Higer Diploma">Higer Diploma</option>
                                    <option value="Degree">Degree</option>
                                    <option value="Masters">Masters</option>
                                    <option value="Doctorate">Doctorate</option>
                                    <option value="Skilled Apprentice">Skilled Apprentice</option>
                                </select>
                            </div>
                            <br>
                            <div v-if="parentMainCat !== 'Jobs'" class="inp-box">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                        <label class="pho-pre dis-fl jus-con-cen al-it-cen" for="upload-photo-1">
                                            <img :src="imgURL_1" alt="">
                                            <input class="test" type="file" name="" id="upload-photo-1"
                                                @change="onFileChange_1" ref="file_1">
                                        </label>
                                    </div>
                                    <div v-if="imgURL_1" class="col-lg-4 col-md-4 col-sm-4 col-6">
                                        <label class="pho-pre dis-fl jus-con-cen al-it-cen" for="upload-photo-2">
                                            <img :src="imgURL_2" alt="">
                                            <input class="test" type="file" name="" id="upload-photo-2"
                                                @change="onFileChange_2" ref="file_2">
                                        </label>
                                    </div>
                                    <div v-if="imgURL_2" class="col-lg-4 col-md-4 col-sm-4 col-6">
                                        <label class="pho-pre dis-fl jus-con-cen al-it-cen" for="upload-photo-3">
                                            <img :src="imgURL_3" alt="">
                                            <input class="test" type="file" name="" id="upload-photo-3"
                                                @change="onFileChange_3" ref="file_3">
                                        </label>
                                    </div>
                                    <div v-if="imgURL_3" class="col-lg-4 col-md-4 col-sm-4 col-6">
                                        <label class="pho-pre dis-fl jus-con-cen al-it-cen" for="upload-photo-4">
                                            <img :src="imgURL_4" alt="">
                                            <input class="test" type="file" name="" id="upload-photo-4"
                                                @change="onFileChange_4" ref="file_4">
                                        </label>
                                    </div>
                                    <div v-if="imgURL_4" class="col-lg-4 col-md-4 col-sm-4 col-6">
                                        <label class="pho-pre dis-fl jus-con-cen al-it-cen" for="upload-photo-5">
                                            <img :src="imgURL_5" alt="">
                                            <input class="test" type="file" name="" id="upload-photo-5"
                                                @change="onFileChange_5" ref="file_5">
                                        </label>
                                    </div>
                                    <div v-if="imgURL_5" class="col-lg-4 col-md-4 col-sm-4 col-6">
                                        <label class="pho-pre dis-fl jus-con-cen al-it-cen" for="upload-photo-6">
                                            <img :src="imgURL_6" alt="">
                                            <input class="test" type="file" name="" id="upload-photo-6"
                                                @change="onFileChange_6" ref="file_6">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="parentMainCat === 'Jobs'" class="sec">
                                <p>Add Company logo</p>
                                <label class="pho-pre dis-fl jus-con-cen al-it-cen" for="upload-photo">
                                    <img :src="imgURLCLogo" alt="">
                                    <input class="test" type="file" name="" id="upload-photo" @change="onFileChange_cLogo"
                                        ref="file_c_logo">
                                </label>
                            </div>
                            <div class="cancel-save-btns-box">
                                <button @click="editAdformIsVisible = false;" class="cancel">CANCEL</button>
                                <button @click="saveEdit()" class="save">SAVE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import Skeleton from '../components/skeleton-loading/skel-com.vue'
import { mapState } from 'vuex'
// import jwtDecode from 'jwt-decode';
import Swal from "sweetalert2"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {

    props: {
        formVisible: Boolean,
        mainCategory: String,
        itemId: Number,
    },

    data() {
        return {
            isLoading: false,
            itemInfosAreLoading: true,
            formIsVisible: false,
            jwt: '',
            subCategory: null,
            id: null,
            mainCat: null,
            title: null,
            price: 0,
            condition_1: null,
            condition_2: null,
            district: null,
            description: null,
            brand: null,
            model: null,
            edition: null,
            yearOfManufacture: null,
            milage: null,
            engineCapacity: null,
            fuelType: null,
            transmittion: null,
            jobType1: null,
            jobType2: null,
            experience: null,
            requiredEducation: null,
            bedrooms: null,
            bathrooms: null,
            size: null,
            houseSize: null,
            landSize: null,
            address: null,
            subImages: [],
            parentMainCat: null,
            parentItemId: null,
            imgURL_1: null,
            imgURL_2: null,
            imgURL_3: null,
            imgURL_4: null,
            imgURL_5: null,
            imgURL_6: null,
            imgURLCLogo: null,
            ph_1: null,
            ph_2: null,
            ph_3: null,
            ph_4: null,
            ph_5: null,
            ph_6: null,
            ph_clogo: null,
            ph_sm: null,
            ph_fake_sample: null,
            isNotDissable: null,
            editAdformIsVisible: false,
            watermark: "popit.lk",
            watermarkFont: "30px Arial",
            watermarkColor: "white",
            watermarkAlign: "center",
            watermarkOpacity: ".5",
            phLargeWidth: 1000,
            phLargeHeight: 600,
        }
    },

    components: {
        Skeleton,
        Loading,
    },

    mounted() {
        this.getUserId();
        this.createFakeImage();
    },

    watch: {
        mainCategory(val) {
            this.parentMainCat = val;
        },

        itemId(val) {
            this.parentItemId = val;
        },

        formVisible(val) {
            this.formIsVisible = val;
        },

        parentMainCat(val) {
            this.itemInfoLoad();
        }
    },

    computed: {
        ...mapState(['api'])
    },

    methods: {

        async getUserId() {
            this.jwt = localStorage.getItem('token');
        },

        createFakeImage() {
            const width = 10;
            const height = 10;
            const mimeType = 'image/jpeg';

            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            const context = canvas.getContext('2d');
            context.fillStyle = 'lightblue';
            context.fillRect(0, 0, width, height);

            canvas.toBlob((blob) => {
                const fileName = 'popit20%fake20%img.jpg';
                this.imageSrc = URL.createObjectURL(blob);
                this.ph_1 = new File([blob], fileName, { type: mimeType });
                this.ph_2 = new File([blob], fileName, { type: mimeType });
                this.ph_3 = new File([blob], fileName, { type: mimeType });
                this.ph_4 = new File([blob], fileName, { type: mimeType });
                this.ph_5 = new File([blob], fileName, { type: mimeType });
                this.ph_6 = new File([blob], fileName, { type: mimeType });
                this.ph_clogo = new File([blob], fileName, { type: mimeType });
                this.ph_sm = new File([blob], fileName, { type: mimeType });
                this.ph_fake_sample = new File([blob], fileName, { type: mimeType });
            }, mimeType);
        },

        onFileChange_1() {
            let file = this.$refs.file_1.files[0];
            this.imgURL_1 = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();

            img.onload = () => {
                const canvas_sm = document.createElement('canvas');
                const ctx_sm = canvas_sm.getContext('2d');
                const canvas_1 = document.createElement('canvas');
                const ctx_1 = canvas_1.getContext('2d');

                const maxWidth = 250;
                const maxHeight = 155;
                const customWidth = this.phLargeWidth;
                const customHeight = this.phLargeHeight;
                const customText = this.watermark;
                const customFont = this.watermarkFont;

                let width_sm = img.width;
                let height_sm = img.height;
                let width_1 = img.width;
                let height_1 = img.height;

                // Resize for ph_sm
                if (width_sm > maxWidth) {
                    height_sm *= maxWidth / width_sm;
                    width_sm = maxWidth;
                }

                if (height_sm > maxHeight) {
                    width_sm *= maxHeight / height_sm;
                    height_sm = maxHeight;
                }

                // Resize for ph_1
                if (width_1 > customWidth) {
                    height_1 *= customWidth / width_1;
                    width_1 = customWidth;
                }

                if (height_1 > customHeight) {
                    width_1 *= customHeight / height_1;
                    height_1 = customHeight;
                }

                canvas_sm.width = width_sm;
                canvas_sm.height = height_sm;
                canvas_1.width = width_1;
                canvas_1.height = height_1;

                ctx_sm.drawImage(img, 0, 0, width_sm, height_sm);
                ctx_1.drawImage(img, 0, 0, width_1, height_1);

                canvas_sm.toBlob((blob_sm) => {
                    const reducedImage = new File([blob_sm], file.name, { type: file.type });
                    this.ph_sm = reducedImage;
                }, file.type);

                // Add custom font to ph_1
                ctx_1.font = customFont;
                ctx_1.fillStyle = this.watermarkColor;
                ctx_1.textAlign = this.watermarkAlign;
                ctx_1.globalAlpha = this.watermarkOpacity;
                ctx_1.fillText(customText, width_1 / 2, height_1 / 2);

                canvas_1.toBlob((blob_1) => {
                    const customizedImage = new File([blob_1], file.name, { type: file.type });
                    this.ph_1 = customizedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);

        },

        onFileChange_2() {
            let file = this.$refs.file_2.files[0];
            this.imgURL_2 = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = this.phLargeWidth;
                const maxHeight = this.phLargeHeight;
                const customText = this.watermark;
                const customFont = this.watermarkFont;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Add custom font to the middle of the image
                ctx.font = customFont;
                ctx.fillStyle = this.watermarkColor;
                ctx.textAlign = this.watermarkAlign;
                ctx.globalAlpha = this.watermarkOpacity;
                ctx.fillText(customText, width / 2, height / 2);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.ph_2 = reducedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);
        },

        onFileChange_3() {
            let file = this.$refs.file_3.files[0];
            this.imgURL_3 = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = this.phLargeWidth;
                const maxHeight = this.phLargeHeight;
                const customText = this.watermark;
                const customFont = this.watermarkFont; // Customize font style and size

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Add custom font to the middle of the image
                ctx.font = customFont;
                ctx.fillStyle = this.watermarkColor;
                ctx.textAlign = this.watermarkAlign;
                ctx.globalAlpha = this.watermarkOpacity;
                ctx.fillText(customText, width / 2, height / 2);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.ph_3 = reducedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);
        },

        onFileChange_4() {
            let file = this.$refs.file_4.files[0];
            this.imgURL_4 = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = this.phLargeWidth;
                const maxHeight = this.phLargeHeight;
                const customText = this.watermark;
                const customFont = this.watermarkFont;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Add custom font to the middle of the image
                ctx.font = customFont;
                ctx.fillStyle = this.watermarkColor;
                ctx.textAlign = this.watermarkAlign;
                ctx.globalAlpha = this.watermarkOpacity;
                ctx.fillText(customText, width / 2, height / 2);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.ph_4 = reducedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);
        },

        onFileChange_5() {
            let file = this.$refs.file_5.files[0];
            this.imgURL_5 = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = this.phLargeWidth;
                const maxHeight = this.phLargeHeight;
                const customText = this.watermark;
                const customFont = this.watermarkFont;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Add custom font to the middle of the image
                ctx.font = customFont;
                ctx.fillStyle = this.watermarkColor;
                ctx.textAlign = this.watermarkAlign;
                ctx.globalAlpha = this.watermarkOpacity;
                ctx.fillText(customText, width / 2, height / 2);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.ph_5 = reducedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);
        },

        onFileChange_6() {
            let file = this.$refs.file_6.files[0];
            this.imgURL_6 = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = this.phLargeWidth;
                const maxHeight = this.phLargeHeight;
                const customText = this.watermark;
                const customFont = this.watermarkFont;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Add custom font to the middle of the image
                ctx.font = customFont;
                ctx.fillStyle = this.watermarkColor;
                ctx.textAlign = this.watermarkAlign;
                ctx.globalAlpha = this.watermarkOpacity;
                ctx.fillText(customText, width / 2, height / 2);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.ph_6 = reducedImage;
                }, file.type);
            };

            img.src = URL.createObjectURL(file);
        },

        onFileChange_cLogo() {
            let file = this.$refs.file_c_logo.files[0];
            this.imgURLCLogo = URL.createObjectURL(file);

            // reduce the main image's resolution
            let img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const maxWidth = 250;
                const maxHeight = 155;

                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    const reducedImage = new File([blob], file.name, { type: file.type });
                    this.ph_sm = reducedImage;
                }, file.type);
            };
            img.src = URL.createObjectURL(file);
        },

        async itemInfoLoad() {
            await axios.get(`${this.api}/get-ad/${this.parentMainCat}/${this.parentItemId}/1}`).then((response) => {
                if (response.data[0].main_cat === 'Jobs') {
                    this.subImages[0] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_clogo}`, isSelected: true }
                    this.imgURL_1 = `data:image/jpeg;base64,${response.data[0].ph_clogo}`;
                } else {
                    this.subCategory = response.data[0].sub_category;
                    this.mainImage = `data:image/jpeg;base64,${response.data[0].ph_1}`;
                    this.subImages[0] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_1}`, isSelected: true };
                    this.imgURL_1 = `data:image/jpeg;base64,${response.data[0].ph_1}`;
                    if (response.data[0].ph_2 !== "") {
                        this.subImages[1] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_2}`, isSelected: false }
                        this.imgURL_2 = `data:image/jpeg;base64,${response.data[0].ph_2}`;
                    }
                    if (response.data[0].ph_3 !== "") {
                        this.subImages[2] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_3}`, isSelected: false }
                        this.imgURL_3 = `data:image/jpeg;base64,${response.data[0].ph_3}`;
                    }
                    if (response.data[0].ph_4 !== "") {
                        this.subImages[3] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_4}`, isSelected: false }
                        this.imgURL_4 = `data:image/jpeg;base64,${response.data[0].ph_4}`;
                    }
                    if (response.data[0].ph_5 !== "") {
                        this.subImages[4] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_5}`, isSelected: false }
                        this.imgURL_5 = `data:image/jpeg;base64,${response.data[0].ph_5}`;
                    }
                    if (response.data[0].ph_6 !== "") {
                        this.subImages[5] = { imgURL: `data:image/jpeg;base64,${response.data[0].ph_6}`, isSelected: false }
                        this.imgURL_6 = `data:image/jpeg;base64,${response.data[0].ph_6}`;
                    }
                }

                this.id = response.data[0].id;
                this.mainCat = response.data[0].main_cat;
                this.title = response.data[0].title;
                this.price = response.data[0].price !== 0 ? parseInt(response.data[0].price).toLocaleString() : 'Free';
                this.condition_1 = !response.data[0].condition_1 ? null : response.data[0].condition_1;
                this.condition_2 = !response.data[0].condition_2 ? null : response.data[0].condition_2;
                this.district = response.data[0].district;
                this.description = response.data[0].description;
                this.brand = !response.data[0].brand ? null : response.data[0].brand;
                this.model = !response.data[0].model ? null : response.data[0].model;
                this.edition = !response.data[0].edition ? null : response.data[0].edition;
                this.yearOfManufacture = !response.data[0].year_of_manufacture ? null : response.data[0].year_of_manufacture;
                this.milage = !response.data[0].milage ? null : response.data[0].milage;
                this.engineCapacity = !response.data[0].engine_capacity ? null : !response.data[0].engine_capacity;
                this.fuelType = !response.data[0].fuel_type ? null : response.data[0].fuelType;
                this.jobType1 = !response.data[0].job_type_1 ? null : response.data[0].job_type_1;
                this.jobType2 = !response.data[0].job_type_2 ? null : response.data[0].job_type_2;
                this.experience = !response.data[0].experience ? null : response.data[0].experience;
                this.requiredEducation = !response.data[0].required_education ? null : response.data[0].required_education;
                this.bedrooms = !response.data[0].bedrooms ? null : response.data[0].bedrooms;
                this.bathrooms = !response.data[0].bathrooms ? null : response.data[0].bathrooms;
                this.size = !response.data[0].size ? null : response.data[0].size;
                this.houseSize = !response.data[0].houseSize ? null : response.data[0].houseSize;
                this.landSize = !response.data[0].landSize ? null : response.data[0].landSize;
                this.address = !response.data[0].address ? null : response.data[0].address;
                this.isNotDissable = response.data[0].is_dissable;
                this.itemInfosAreLoading = false;
            })
        },

        async saveEdit() {
            let images = [];
            const formData = new FormData();
            formData.append('images', this.ph_1);
            formData.append('images', this.ph_2);
            formData.append('images', this.ph_3);
            formData.append('images', this.ph_4);
            formData.append('images', this.ph_5);
            formData.append('images', this.ph_6);
            formData.append('images', this.ph_clogo);
            formData.append('images', this.ph_sm);
            formData.append('id', this.id);
            formData.append('main_cat', this.mainCat);
            formData.append('title', this.title);
            formData.append('price', parseInt(this.price.replace(",", "")));
            formData.append('description', this.description);
            formData.append('condition_1', this.condition_1);
            formData.append('condition_2', this.condition_2);
            formData.append('district', this.district);
            formData.append('brand', this.brand);
            formData.append('model', this.model);
            formData.append('edition', this.edition);
            formData.append('year_of_manufacture', this.yearOfManufacture);
            formData.append('milage', this.milage);
            formData.append('engine_capacity', this.engineCapacity);
            formData.append('fuel_type', this.fuelType);
            formData.append('transmittion', this.transmittion);
            formData.append('job_type_1', this.jobType1);
            formData.append('job_type_2', this.jobType2);
            formData.append('experience', this.experience);
            formData.append('required_education', this.requiredEducation);
            formData.append('bedrooms', this.bedrooms);
            formData.append('bathrooms', this.bathrooms);
            formData.append('size', this.size);
            formData.append('house_size', this.houseSize);
            formData.append('land_size', this.landSize);
            formData.append('address', this.address);

            Swal.fire({
                title: "Warning..!",
                text: "Are you sure want to edit this ad? ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                cancelButtonText: "NO",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    await axios.post(`${this.api}/edit-ad`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${this.jwt}`
                        }
                    }).then((response) => {
                        this.formIsVisible = false;

                        this.isLoading = false;
                        if (response.status === 201) {
                            Swal.fire({
                                title: "Success",
                                text: "Ad updated Successfull",
                                icon: "success"
                            }).then(() => {
                                this.close();
                            });
                        } else {
                            Swal.fire({
                                title: "Oops..!",
                                text: "Something went wrong. Please try again",
                                icon: "error"
                            }).then(() => {
                                this.close();
                            });
                        }
                    });
                }
            });
        },

        close() {
            this.formIsVisible = false;
            this.id = null;
            this.mainCat = null;
            this.subImages = [];
            this.title = null;
            this.price = 0;
            this.district = null;
            this.description = null;
            this.condition_1 = null;
            this.condition_2 = null;
            this.brand = null;
            this.model = null;
            this.edition = null;
            this.yearOfManufacture = null;
            this.milage = null;
            this.engineCapacity = null;
            this.fuelType = null;
            this.jobType1 = null;
            this.jobType2 = null;
            this.experience = null;
            this.requiredEducation = null;
            this.bedrooms = null;
            this.bathrooms = null;
            this.size = null;
            this.houseSize = null;
            this.landSize = null;
            this.address = null;
            this.itemInfosAreLoading = true;
            this.editAdformIsVisible = false;
            this.imgURL_1 = null;
            this.imgURL_2 = null;
            this.imgURL_3 = null;
            this.imgURL_4 = null;
            this.imgURL_5 = null;
            this.imgURL_6 = null;
            this.ph_1 = this.ph_fake_sample;
            this.ph_2 = this.ph_fake_sample;
            this.ph_3 = this.ph_fake_sample;
            this.ph_4 = this.ph_fake_sample;
            this.ph_5 = this.ph_fake_sample;
            this.ph_6 = this.ph_fake_sample;
            this.ph_clogo = this.ph_fake_sample;
            this.ph_sm = this.ph_fake_sample;
            this.$emit('passFormVisible', false);
        },

        async adDissableClick() {
            if (this.isNotDissable === 1) {
                Swal.fire({
                    title: "Warning..!",
                    text: "Dissable this ad? ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "YES",
                    cancelButtonText: "NO",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        await axios.get(`${this.api}/ads-op/en-dis/${this.parentMainCat}/${this.isNotDissable}/${this.parentItemId}`).then((response) => {
                            this.isLoading = false;
                            if (response.status === 200) {
                                this.isNotDissable = 0;
                                this.close();
                            }
                        })
                        location.reload();
                    }
                });
            } else {
                Swal.fire({
                    title: "Warning..!",
                    text: "Enable this ad? ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "YES",
                    cancelButtonText: "NO",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        await axios.get(`${this.api}/ads-op/en-dis/${this.parentMainCat}/${this.isNotDissable}/${this.parentItemId}`).then((response) => {
                            this.isLoading = false;
                            if (response.status === 200) {
                                this.isNotDissable = 1;
                                this.close();
                            }
                        })
                        location.reload();
                    }
                });
            }
        },

        adDeleteClick() {
            Swal.fire({
                title: "Warning..!",
                text: "Are you sure want to delete this ad? ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                cancelButtonText: "NO",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    await axios.get(`${this.api}/ads-op/delete/${this.parentMainCat}/${this.parentItemId}`)
                    this.close();
                }
            });
        }
    }
}
</script>
<style scoped>
.main {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.527);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form {
    width: 500px;
    height: 500px;
    border-radius: 10px;
    background-color: white;
    position: relative;
}

.con-box {
    height: 480px;
    padding: 10px 20px;
    overflow-y: auto;
}

.con-box::-webkit-scrollbar {
    width: .5vw;
}

.con-box::-webkit-scrollbar-thumb {
    background-color: #8EC64127;
    border-radius: 20px;
}

.con-box::-webkit-scrollbar-thumb:hover {
    background-color: #8EC64152;
}

.con-box::-webkit-scrollbar-track {
    background-color: transparent;
}

.opt-btn-box {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.opt-btn-box button {
    width: 40px;
    height: 40px;
    padding: 0 10px;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 1;
}

.opt-btn-box button img {
    max-width: 100%;
    max-height: 100%;
}

.opt-btn-box .btn-edit {
    background-color: rgb(255, 208, 0);
}

.opt-btn-box .btn-dissable {
    background-color: orangered;
}

.opt-btn-box .btn-delete {
    background-color: red;
}

.close-wrp {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.content-box {
    display: flex;
}

.content-box .head {
    min-width: 130px;
}

.col-lg-4 {
    padding: 3px;
    /* padding:  */
}

.imgs-box .img-box {
    width: 100%;
    height: 100px;
    margin-bottom: 2px;
    /* background-color: yellow; */
    position: relative;
    overflow: hidden;
}

.imgs-box .img-box img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
}

.skel-box {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}

.skel-head {
    min-width: 100px;
    height: 30px;
    margin-right: 30px;
}

.skel-des {
    width: 100%;
    height: 30px;
}

.skel-imgs {
    width: 100%;
}

.skel-img-box {
    width: 100%;
    height: 100px;
    margin-bottom: 2px;
    position: relative;
}

.com-skel {
    background-color: rgba(0, 0, 0, 0.164);
    color: white;
    animation: ani 2s both infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.edit-form-wrp {
    width: 500px;
    height: 500px;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    position: relative;
}

.inp-box {
    margin-bottom: 20px;
}

.pho-pre {
    width: 100%;
    height: 100px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.205);
    position: relative;
}

.pho-pre img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    display: flex;
    justify-content: center;
}

.test {
    width: 0;
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.cancel-save-btns-box {
    margin: 20px 0 30px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.cancel-save-btns-box .cancel,
.cancel-save-btns-box .save {
    border: none;
    color: white;
    width: 130px;
    height: 40px;
    border-radius: 5px;
}

.cancel-save-btns-box .cancel {
    margin-right: 5px;
    background-color: red;
}

.cancel-save-btns-box .save {
    margin-left: 5px;
    background-color: green;
}

.form-fade-trans-enter-from,
.form-fade-trans-leave-to {
    opacity: 0;
}

.form-fade-trans-enter-active,
.form-fade-trans-leave-active {
    transition: all .3s ease;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@media only screen and (max-width: 575px) {

    .main {
        align-items: flex-start;
    }

    .form {
        width: 100%;
        height: 100vh;
        /* background-color: yellow; */
    }

    .close-wrp {
        position: sticky;
        top: 0;
    }

    .con-box {
        height: 100%;
    }

    .opt-btn-box {
        bottom: 100px;
    }

    .imgs-box .img-box,
    .skel-img-box {
        height: 130px;
    }

    .pho-pre {
        height: 180px;
    }
}

@media only screen and (max-width: 420px) {
    .pho-pre {
        height: 140px;
    }

    .cancel-save-btns-box {
        flex-direction: column;
    }

    .cancel-save-btns-box .cancel,
    .cancel-save-btns-box .save {
        width: 100%;
        margin: 0;
        margin-bottom: 5px;
    }
}
</style>