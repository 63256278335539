<template>
    <div>
        <div class="info-box">
            <div class="name anim-shine"></div>
            <div class="divider"></div>
            <div class="chat-mobile-box">
                <div class="icon anim-shine"></div>
                <div class="txt-1 anim-shine "></div>
            </div>
            <div class="divider"></div>
            <div class="chat-mobile-box">
                <div class="icon anim-shine"></div>
                <div class="txt-1 anim-shine"></div>
            </div>
            <div class="divider"></div>
            <div class="address-box">
                <div class="icon anim-shine"></div>
                <div class="address-wrapper">
                    <div class="txt-1 mar-bottom anim-shine"></div>
                    <div class="txt-1 mar-bottom anim-shine"></div>
                    <div class="txt-1 mar-bottom anim-shine"></div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="desscription">
                <div class="txt-2 mar-bottom anim-shine"></div>
                <div class="txt-2 mar-bottom anim-shine"></div>
                <div class="txt-2 mar-bottom anim-shine sm"></div>
                <div class="txt-2 mar-bottom anim-shine sm"></div>
                <div class="txt-2 anim-shine"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.mar-bottom {
    margin-bottom: 5px;
}

.anim-shine {
    background-color: rgba(0, 0, 0, 0.164);
    color: white;
    animation: ani 2s both infinite;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.info-box {
    width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.137);
    border-radius: 5px;
}

.name {
    margin: 10px;
    height: 30px;
}

.divider {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.137);
}

.chat-mobile-box {
    padding: 10px;
    display: flex;
    align-items: center;
}

.icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    flex-shrink: 0;
}

.txt-1, .txt-2 {
    width: 100%;
    height: 20px;
}

.address-box {
    padding: 10px;
    display: flex;
}

.address-wrapper {
    width: 100%;
}

.desscription {
    padding: 10px;
}

.sm{
    display: block;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@media only screen and (max-width: 991px) {
    .info-box{
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .info-box {
        width: 100%;
        display: block;
    }

    .name{
        width: 200px;
    }

    .txt-1{
        width: 100px;
    }

    .sm{
        display: none;
    }
}

@media only screen and (max-width: 575px) {}
</style>